import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { storeStep } from 'app/components/onboarding/utils/trialStorage';
import { selectToken, selectUser } from 'app/components/auth/store/selectors';
import {
  storeToken,
  getToken,
  destroyToken,
} from 'app/components/auth/utils/tokenStorage';
import { apiClient, ApiError } from 'app/config/axios';
import { Users } from 'app/components/users/types';
import { Token } from 'app/components/auth/interfaces';
import { actions } from 'app/components/auth/store/slice';
import { useMutation, useQuery } from 'react-query';
import { ROLES } from '../constants';
import {
  destroyImpersonateUser,
  getImpersonateUser,
  storeImpersonateUser,
} from '../utils/impersonateUserStorage';

export const ADMIN_TOKEN = 'adminToken';

interface AuthUser {
  setImpersonateUser(impersonateUser: Users): void;
  setToken(token: Token): void;
  logout(): void;
  error?: ApiError | null;
  user?: Users;
  isAuthorized: boolean;
  impersonateIsLoading: boolean;
  isLoading: boolean;
  refetch: () => void;
  isAdmin: boolean;
  impersonate: (data) => Promise<any>;
}

const loadUser = async () => {
  return await apiClient.get('/auth/user');
};

const impersonateUser = async data => {
  return await apiClient.post('/auth/impersonate', { ...data });
};

export const useAuthUser = (): AuthUser => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const [isReady, setIsReady] = useState(false);
  const isAuthorized = !!user;

  const setToken = useCallback(
    (newToken: Token) => {
      dispatch(actions.setToken(newToken));
      storeToken(newToken);
    },
    [dispatch],
  );

  const setImpersonateUser = useCallback(
    (newImpersonateUser: Users) => {
      dispatch(actions.setImpersonateUser(newImpersonateUser));
      storeImpersonateUser(newImpersonateUser);
    },
    [dispatch],
  );

  const logout = useCallback(async () => {
    await destroyToken();
    await destroyImpersonateUser();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(actions.clear());
    storeStep();
  }, [dispatch]);

  const {
    isLoading: userIsLoading,
    error,
    refetch,
  } = useQuery<Users, any, any>(
    ['authUser', token?.token],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => loadUser(),
    {
      retry: false,
      enabled: Boolean(token),
      onSuccess: result => {
        return dispatch(actions.setUser(result));
      },
      onError: async () => {
        await logout();
      },
    },
  );

  const { isLoading: impersonateIsLoading, mutateAsync: impersonate } =
    useMutation(['impersonate'], data => impersonateUser(data), {
      retry: false,
      onSuccess: (result: any) => {
        localStorage.setItem(ADMIN_TOKEN, JSON.stringify(token));
        setToken(result);
      },
    });

  const userRoles = useMemo(() => user?.roles || [], [user]);
  const isAdmin = useMemo(
    () => Boolean(userRoles.includes(ROLES.SUPER_ADMIN)),
    [userRoles],
  );

  useEffect(() => {
    if (!isReady) {
      if (!token) {
        const restoreToken = async () => {
          try {
            const tokenFromStorage = await getToken();

            if (tokenFromStorage) {
              dispatch(actions.setToken(tokenFromStorage));
            }
          } catch (e) {
            // ¯\_(ツ)_/¯
          } finally {
            setIsReady(true);
          }
        };
        restoreToken();
      } else {
        setIsReady(true);
      }
    }
  }, [token, isReady, dispatch]);

  useEffect(() => {
    const restoreImpersonateUser = async () => {
      try {
        const impersonateUserFromStorage = await getImpersonateUser();

        // if (impersonateUserFromStorage) {
        dispatch(actions.setImpersonateUser(impersonateUserFromStorage as any));
        // }
      } catch (e) {
        // ¯\_(ツ)_/¯
      }
    };

    restoreImpersonateUser();
  }, [dispatch]);

  return {
    setToken,
    setImpersonateUser,
    logout,
    error,
    user,
    isAuthorized,
    refetch,
    isLoading: !isReady || userIsLoading,
    impersonateIsLoading,
    isAdmin,
    impersonate,
  };
};

export const useShowUser = options =>
  useQuery([`user`], () => loadUser(), { ...options });
