import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorWhite,
  buttonSize,
  buttonSizeSmall,
  borderRadiusEllipse,
  boxShadowSmall,
  colorPrimary500,
  colorPrimary900,
  colorSecondary300,
} from 'configs/variables';

const bcIndentHorizontal = 24;
const bcIndentHorizontalMobile = 16;

export default (theme: Theme) =>
  createStyles({
    bcBlock: {
      overflow: 'auto',
    },
    bcList: {
      color: colorWhite,
      padding: 0,
      margin: 0,
      flexWrap: 'nowrap',
    },
    bcListItem: {
      lineHeight: 1.3,
      padding: 0,
      margin: '2px 0',
      marginRight: -bcIndentHorizontalMobile,
      height: buttonSizeSmall,

      [theme.breakpoints.up('md')]: {
        height: buttonSize,
        marginRight: -bcIndentHorizontal,
      },

      '&:first-child': {
        '& $bcField': {
          borderRadius: `${borderRadiusEllipse}px 0 0 ${borderRadiusEllipse}px`,
        },
        '&:last-child $bcField': {
          borderRadius: borderRadiusEllipse,
        },
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    bcListItemSeparator: {
      backgroundColor: colorWhite,
      color: colorPrimary500,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: bcIndentHorizontalMobile + 4,
      paddingRight: bcIndentHorizontalMobile + 4,

      [theme.breakpoints.up('md')]: {
        paddingLeft: bcIndentHorizontal + 6,
        paddingRight: bcIndentHorizontal + 6,
      },
      '& svg': {
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
        },
      },
    },
    bcField: {
      backgroundColor: colorWhite,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      padding: `8px ${bcIndentHorizontalMobile}px`,
      minWidth: 130,
      maxWidth: 130,

      [theme.breakpoints.up('sm')]: {
        minWidth: 200,
        maxWidth: 300,
      },

      [theme.breakpoints.up('md')]: {
        padding: `8px ${bcIndentHorizontal}px`,
      },
    },
    bcFieldCurrent: {
      backgroundColor: colorWhite,
      borderRadius: borderRadiusEllipse,
      boxShadow: '0px 3px 4.5px 0px #673AB71F',
      color: colorPrimary500,
      position: 'relative',
    },
    bcFieldWithoutLink: {
      '&:not($bcFieldDropdown)': {
        cursor: 'default',

        '&:hover': {
          opacity: 1,
        },
      },
    },
    bcFieldStartIconButton: {
      color: colorPrimary500,
      marginLeft: -bcIndentHorizontalMobile,

      [theme.breakpoints.up('md')]: {
        marginLeft: -bcIndentHorizontal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        padding: 10,
      },
    },
    bcFieldStartIconNotButton: {
      cursor: 'auto',

      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    bcFieldWithSecondaryName: {
      '& $bcFieldName': {
        color: colorPrimary500,
        fontWeight: 400,
      },
    },
    bcFieldNameBlock: {
      color: colorPrimary900,
      fontSize: 12,
      fontWeight: 500,
      maxWidth: '100%',
      width: '100%',
      minWidth: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
    bcFieldName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bcFieldSecondaryName: {},
    open: {},
    textEllipsis: {
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    bcFieldDropdown: {
      cursor: 'pointer',
      position: 'relative',
      zIndex: 1,
      // paddingRight: 35,

      // [theme.breakpoints.up('md')]: {
      //   paddingRight: 45,
      // },

      '& $bcField': {
        position: 'relative',
        zIndex: 2,
      },
      '&$open': {
        '& $bcFieldDropdownIcon': {
          transform: 'rotate(180deg)',
        },
      },
    },
    bcFieldDropdownIcon: {
      color: colorPrimary900,
      fontSize: 16,
      marginTop: -8,
      position: 'absolute',
      top: '50%',
      right: 12,
      zIndex: 1,

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        marginTop: -10,
        right: 14,
      },
    },
    bcFieldDropdownPaper: {
      backgroundColor: colorWhite,
      borderRadius: borderRadiusEllipse,
      paddingTop: buttonSizeSmall,
      marginTop: -buttonSizeSmall,
      zIndex: -1,

      '&$open': {
        maxHeight: 400,
        paddingBottom: 10,
        ...boxShadowSmall,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: buttonSize,
        marginTop: -buttonSize,
      },
    },
    bcFieldDropdownItem: {
      display: 'block',
      fontSize: 12,
      color: colorPrimary500,
      padding: `4px ${bcIndentHorizontalMobile}px`,

      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        padding: `8px ${bcIndentHorizontal}px`,
      },
      '&:not(:last-child)': {
        marginBottom: 2,
      },
    },
    bcFieldDropdownPaperFirstItem: {
      '& $bcFieldDropdownItem': {
        paddingLeft: bcIndentHorizontalMobile + 22,

        [theme.breakpoints.up('md')]: {
          paddingLeft: bcIndentHorizontal + 24,
        },
        [theme.breakpoints.down('xs')]: {
          paddingRight: 10,
        },
      },
    },
    greenIcon: {
      boxSizing: 'content-box',
      fontSize: '12px',
      color: colorSecondary300,
      borderRadius: '50%',
    },
  });
