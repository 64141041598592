export const TABS_CONFIG = {
  DATA: {
    fields: {
      // key - row name
      // value - row is bordered (true || false)
      locationName: true,
      displayName: true,
      salutation: true,
      role: true,
      tagline: true,
      mobile: true,
      email: true,
      website: true,
      telephone: true,
      telephoneExt: true,
      fax: true,
      addressLine1: false,
      addressLine2: false,
      city: false,
      state: false,
      stateInitials: false,
      zip: false,
      country: false,
      countryInitials: true,
      postalLine1: false,
      postalLine2: false,
      postalCity: false,
      postalState: false,
      postalStateInitials: false,
      postalZip: false,
      postalCountry: false,
      postalCountryInitials: false,
    },
  },
  MESSAGES: {
    fields: {
      status: true,
      green: true,
      customMessage: true,
      unsubscribe: true,
    },
  },
  IMAGES: {
    fields: {
      locationLogo: true,
      primaryImage: true,
      secondaryImage: true,
      addressMap: true,
      postalMap: true,
      image1: true,
      image2: true,
      image3: true,
      image4: true,
      image5: true,
      image6: true,
      image7: true,
      image8: true,
      image9: true,
      banner1: true,
      banner2: true,
      banner3: true,
      banner4: true,
      banner5: true,
      banner6: true,
      banner7: true,
      banner8: true,
      banner9: true,
    },
  },
  BRANDING: {
    fields: {
      featureColor: true,
      baseColor: true,
      iconStyle: true,
      iconSize: true,
      originalColor: true,
      iconFeatureColor: true,
      iconBaseColor: true,
      fontFamily: true,
      fontSize: true,
      statusMessageFont: true,
      statusMessageFontSize: true,
      statusMessageColor: true,
      statusMessageLinkColor: true,
      brandStatusMessage: true,
      greenMessageFont: true,
      greenMessageFontSize: true,
      greenMessageColor: true,
      greenMessageLinkColor: true,
      brandGreenMessage: true,
      customMessageFont: true,
      customMessageFontSize: true,
      customMessageColor: true,
      customMessageLinkColor: true,
      brandCustomMessage: true,
      unsubscribeMessageFont: true,
      unsubscribeMessageFontSize: true,
      unsubscribeMessageColor: true,
      unsubscribeMessageLinkColor: true,
      brandUnsubscribeMessage: true,
    },
  },
  ICONS: {
    fields: {
      customIcon1: true,
      customIcon2: true,
      customIcon3: true,
      customIcon4: true,
      customIcon5: true,
      customIcon6: true,
      customIcon7: true,
      customIcon8: true,
      customIcon9: true,
    },
  },
};
