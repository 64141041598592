import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorDanger,
  colorWhite,
  colorBlue2,
  colorBlue3,
  bgColorLightGrey2,
  bgColorLightGrey3,
  borderRadiusEllipse,
  textPrimary,
  colorBlue,
  bgColorBlue,
  dialogVerticalMobile,
  dialogHorizontalMobile,
  dialogVertical,
  dialogHorizontal,
  bgBody,
  colorPrimary,
} from 'configs/variables';

const imagePreviewIconSize = 30;

export default (theme: Theme) =>
  createStyles({
    // Upload Image
    imageField: {
      '& .text-input': {
        '&::placeholder': {
          font: 'inherit',
          opacity: 1,
          lineHeight: 1.3,
        },
      },
    },
    imageFieldLabel: {},

    imageFieldError: {
      '& .upload-image-block': {
        borderColor: colorDanger,
      },
    },

    // Image Text Field
    imageInputUpload: {
      display: 'none !important',
      visibility: 'hidden',
    },
    imageFieldTooltipPopper: {
      [theme.breakpoints.down('sm')]: {
        zIndex: theme.zIndex.drawer,
      },
    },
    imageFieldTooltip: {
      padding: '8px 10px',
      lineHeight: 1,
      maxWidth: 500,
    },
    imageFieldTooltipImage: {
      '&, & $svgIcon': {
        display: 'block',
        maxWidth: '100%',
        maxHeight: 500,
      },
    },
    imageFieldAvatar: {
      backgroundColor: bgColorLightGrey3,
      width: imagePreviewIconSize,
      height: imagePreviewIconSize,
    },

    // Svg Icon
    svgIcon: {},
    svgIconBlock: {
      '&, & $svgIcon': {
        width: imagePreviewIconSize,
        height: imagePreviewIconSize,
      },
      '.text-input-disabled &': {
        opacity: 0.5,
      },
    },

    // Image Crop Modal
    imageModal: {
      '& .upload-image-block': {
        backgroundColor: bgColorBlue,

        '&:hover': {
          backgroundColor: bgColorLightGrey2,
        },
      },
      '& .upload-image-block, & .empty-image-block': {
        height: 200,

        [theme.breakpoints.up('sm')]: {
          height: 300,
        },
      },
      '& .upload-button-area': {
        color: colorBlue,
        fontSize: 14,
        fontWeight: 400,
      },
    },
    imageModalFooter: {
      backgroundColor: bgBody,
      padding: 20,
      margin: `15px -${dialogHorizontalMobile}px -${dialogVerticalMobile}px `,

      [theme.breakpoints.up('sm')]: {
        padding: 26,
        margin: `25px -${dialogHorizontal}px -${dialogVertical}px`,
      },
    },
    imageFieldCheckbox: {
      color: colorBlue,
      padding: 0,
      margin: 0,

      '&, & svg': {
        color: colorBlue,
      },
      '& span': {
        lineHeight: 1.2,
        color: colorBlue,
      },
    },

    // Stepper
    imageStepper: {
      backgroundColor: bgColorLightGrey3,
      alignItems: 'initial',
      padding: '0 10px',
      marginBottom: 30,
      borderRadius: borderRadiusEllipse,
    },
    imageStep: {
      padding: 0,
      flex: 1,
      minWidth: 0,
    },
    imageStepLabel: {
      backgroundColor: bgColorLightGrey3,
      color: textPrimary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.2,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '4px 20px 4px 10px',
      margin: '0 -10px',
      borderRadius: borderRadiusEllipse,
      position: 'relative',
      zIndex: 1,

      '&$imageStepLabelActive': {
        backgroundColor: colorBlue3,
        color: colorWhite,
        fontWeight: 400,
      },
      '&$imageStepLabelCompleted': {
        backgroundColor: colorBlue2,
      },
    },
    imageStepLabelActive: {},
    imageStepLabelCompleted: {},
    imageStepConnector: {
      backgroundColor: colorBlue2,
      minWidth: 20,

      '&[disabled]': {
        backgroundColor: bgColorLightGrey3,
      },
    },
    imageStepIndex: {
      paddingRight: 10,
    },

    // Scale
    scaleImageBlock: {
      position: 'relative',
      display: 'flex',
      lineHeight: 1,
      margin: '0 auto',
      maxWidth: '100%',
      width: 1,
      height: 1,

      '&:before': {
        content: '""',
        backgroundColor: bgColorLightGrey3,
        border: `1px solid ${bgColorLightGrey3}`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
      },
    },
    scaleLoader: {
      color: colorBlue2,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -20,
      marginLeft: -15,
      zIndex: 0,
    },
    scaleImage: {
      display: 'block',
      marginTop: 'auto',
      marginRight: 'auto',
      position: 'relative',
      zIndex: 2,
    },
    scaleImageCircle: {
      borderRadius: '50%',
    },
    scaleImageSizeBlock: {
      color: colorBlue,
      fontSize: 14,
      textAlign: 'center',
      margin: '15px 0',

      [theme.breakpoints.up('sm')]: {
        marginBottom: 30,
      },
    },
    scaleSlider: {
      maxWidth: '100%',
      width: 400,
      margin: '15px auto',
    },
    sliderRoot: {
      padding: '15px 0',
    },
    sliderThumb: {
      width: 24,
      height: 24,
      marginTop: -12,
      marginLeft: -12,
      backgroundColor: colorWhite,
      border: `2px solid ${colorPrimary}`,
      boxShadow: 'none !important',
      '&::after': {
        content: 'none',
      },
    },
  });
