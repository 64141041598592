import React, { Dispatch, SetStateAction, useState } from 'react';
import cx from 'classnames';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { PeopleSearch } from 'app/components/forms';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { ROLES, TYPES } from 'app/components/auth/constants';
import { useDeleteUserSettings } from 'app/components/users/hooks/crud';
import { useConfirmation } from 'app/components/ui/Modal';
import { success } from 'app/components/snackbars';
import { DATE_TIME_FORMAT } from 'app/constants';
import { USER_STATUSES } from 'app/components/entities/users/constants';
import { useAcl } from 'app/components/auth';
import { Loader } from 'app/components/ui/Loader';

import { ReactComponent as banLightIcon } from 'images/icon/FontAwesome5Pro/Light/ban.svg';
import { ReactComponent as editLightIcon } from 'images/icon/FontAwesome5Pro/Light/edit.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';
import { ReactComponent as mailIcon } from 'images/icon/FontAwesome5Pro/Light/mail.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

dayJs.extend(utc);
dayJs.extend(timezone);

export interface Props {
  data: any;
  total: number;
  page: number;
  companyId: string;
  pageCount: number;
  indexQuery: any;
  fetchData: (data: any) => void;
  status: USER_STATUSES;
  showModal: () => void;
  refetchUsers: () => void;
  setUserId: Dispatch<SetStateAction<undefined>>;
}

export default function PeopleList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  indexQuery,
  companyId,
  status,
  showModal,
  setUserId,
  refetchUsers,
}: Props) {
  const classes = useStyles();
  const { isOneOf } = useAcl();
  const { t } = useTranslation();
  const { mutateAsync: callDeleteUserSettings, isLoading: isRemoveLoading } =
    useDeleteUserSettings();

  const [activeId, setActiveUser] = useState(null);

  const copyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    success(t(translations.ui.notificationMessages.copiedToClipboard));
  };

  const [showDeleteConfirmation] = useConfirmation({
    onSubmit: async () => {
      await callDeleteUserSettings(String(activeId));
      success(t(translations.people.message.success.delete));
      refetchUsers();
    },
    maxWidth: 'lg',
    titleBlockStyle: 'default',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    description: (
      <div>
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson)}
        </Typography>
        <br />
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson_Part2)}
        </Typography>
        <br />
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson_Part3)}
        </Typography>
      </div>
    ),
    title: t(translations.ui.titles.deletePerson),
    agreeLabel: t(translations.ui.buttons.deletePerson),
    cancelButtonProps: {
      className: 'modal-button modal-button-white',
      variant: 'contained',
      size: 'medium',
    },
    agreeButtonProps: {
      className: 'modal-button button-danger',
      size: 'medium',
    },
  });

  const columns = [
    {
      Header: t(translations.table.columns.email),
      accessor: 'email',
      Cell: ({ cell: { value } }: { cell: Cell }) => (
        <Tooltip placement="top" arrow={true} title={value}>
          <Typography
            {...(true
              ? {
                  className: classes.link,
                  onClick: () => copyEmail(value),
                }
              : {})}
            noWrap
          >
            {value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: t(translations.table.columns.name),
      accessor: 'fullName',
      customProps: row => `/people/${row.id}/edit`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.roles),
      accessor: 'roles',
      disableSortBy: true,
      contentBuilder: row => row?.roles?.length || '-',
      customProps: row => `/people/${row.id}/edit/roles`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.companies),
      accessor: 'companies',
      disableSortBy: true,
      contentBuilder: row => row?.companies?.length || '-',
      customProps: row => `/people/${row.id}/edit/companies`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.locations),
      accessor: 'locations',
      disableSortBy: true,
      contentBuilder: row => row?.locations?.length || '-',
      customProps: row => `/people/${row.id}/edit/locations`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.signatures),
      accessor: 'signatureUsers',
      disableSortBy: true,
      contentBuilder: row => row?.signatureUsers?.length || '-',
      customProps: row => `/people/${row.id}/edit/signatures`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.dateJoined),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) =>
        dayJs(value).format(DATE_TIME_FORMAT),
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      minWidth: 200,
      className: 'table-action-cell',
      accessor: ({ id, status, email }) => {
        return { id, status, email };
      },
      Cell: ({
        cell: {
          value: { id, status, email },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link className={classes.actionItem} href={`/people/${id}/edit`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.people.actions.edit)}
              >
                <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
              </Tooltip>
            </Link>
            <span
              className={classes.actionItem}
              onClick={() => {
                setUserId(id);
                showModal();
              }}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={
                  status === 'active'
                    ? t(translations.people.actions.disable)
                    : t(translations.people.actions.enable)
                }
              >
                <SvgIcon
                  viewBox="0 0 576 512"
                  component={
                    status === USER_STATUSES.ACTIVE
                      ? banLightIcon
                      : checkCircleLightIcon
                  }
                />
              </Tooltip>
            </span>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.copyEmail)}
              >
                <SvgIcon
                  component={mailIcon}
                  viewBox="0 0 512 512"
                  onClick={() => copyEmail(email)}
                />
              </Tooltip>
            </span>
            <span
              className={cx({
                [classes.disabled]: !isOneOf([
                  ROLES.GHOST_USER,
                  ROLES.SUPER_ADMIN,
                ]),
                [classes.actionItem]: isOneOf([
                  ROLES.GHOST_USER,
                  ROLES.SUPER_ADMIN,
                ]),
              })}
              onClick={() => {
                if (isOneOf([ROLES.GHOST_USER, ROLES.SUPER_ADMIN])) {
                  setActiveUser(id);
                  showDeleteConfirmation();
                }
              }}
            >
              {isOneOf([ROLES.GHOST_USER, ROLES.SUPER_ADMIN]) ? (
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.people.actions.delete)}
                >
                  <SvgIcon component={trashIcon} viewBox="0 0 576 512" />
                </Tooltip>
              ) : (
                <SvgIcon component={trashIcon} viewBox="0 0 576 512" />
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const initialState = {
    globalFilter: {
      type: { $eq: TYPES.PAID },
      'company.id': companyId,
      status,
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  return (
    <Paper>
      <Loader loading={isRemoveLoading} />
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <PeopleSearch
              name="filter"
              initialState={initialState}
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
