import { createStyles, Theme } from '@material-ui/core/styles';

import { bgOverlay, colorWhite } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    loaderWrapper: {
      backgroundColor: bgOverlay,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      borderRadius: 'inherit',
    },
    loaderFullPageWrapper: {
      position: 'fixed',
    },
    loader: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 100,
    },
    loaderText: {
      color: colorWhite,
      fontWeight: 700,
      fontSize: 16,
    },
    animationIcon: {
      display: 'inline-block',
      width: 240,
      height: 180,
    },
  });
