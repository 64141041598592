import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { file } from 'app/components/files';
import { translations } from 'locales/i18n';
import { Loader } from 'app/components/ui/Loader';

import styles from 'app/components/forms/styles/image';

const useStyles = makeStyles(styles);

export interface Props {
  value: any;
  onSubmit: (width, height) => void;
  handleClose: () => void;
  imageCircle?: boolean;
  loading?: boolean;
}

const ScaleImage = ({
  handleClose,
  onSubmit,
  value,
  imageCircle = false,
  loading = false,
}: Props) => {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();

  const [sliderValue, setSliderValue] = useState(100);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  let widthProportion = width;
  let heightProportion = height;

  if (width > 523) {
    widthProportion = 523;
    heightProportion = (523 / width) * height;
  }
  const scaleImageProportionWidth = useMemo(() => {
    return Math.round((widthProportion * sliderValue) / 100);
  }, [sliderValue, widthProportion]);

  const scaleImageProportionHeight = useMemo(() => {
    return Math.round((heightProportion * sliderValue) / 100);
  }, [sliderValue, heightProportion]);

  const scaleImageRealWidth = useMemo(() => {
    return Math.round((width * sliderValue) / 100);
  }, [sliderValue, width]);

  const scaleImageRealHeight = useMemo(() => {
    return Math.round((height * sliderValue) / 100);
  }, [sliderValue, height]);

  const isLoading = loading || !value || typeof value === 'object';

  if (!isLoading && value && typeof value !== 'object') {
    const img = new Image();
    img.onload = function () {
      setWidth(img.width);
      setHeight(img.height);
    };

    img.src = file(value);
  }

  const loaderWithParameters = isLoading || !width || !height;

  const handleSubmit = () => {
    onSubmit(scaleImageRealWidth, scaleImageRealHeight);
  };

  return isLoading ? (
    <div className="empty-image-block">
      <Loader
        fullPage={false}
        loading={loaderWithParameters || value === 'loading'}
      />
    </div>
  ) : (
    <>
      <div
        className={classes.scaleImageBlock}
        style={{ width: widthProportion, height: heightProportion }}
      >
        <img
          className={cx(
            classes.scaleImage,
            imageCircle ? classes.scaleImageCircle : '',
          )}
          src={file(value, undefined, undefined, imageCircle)}
          alt=""
          width={scaleImageProportionWidth}
          height={scaleImageProportionHeight}
        />
        <CircularProgress className={classes.scaleLoader} size={30} />
      </div>
      <div className={classes.scaleImageSizeBlock}>
        {scaleImageRealWidth} x {scaleImageRealHeight} / {sliderValue}%
      </div>
      <div className={classes.scaleSlider}>
        <Slider
          classes={{
            root: classes.sliderRoot,
            thumb: classes.sliderThumb,
          }}
          marks={false}
          valueLabelDisplay="off"
          step={1}
          min={10}
          max={100}
          defaultValue={100}
          onChange={handleSliderChange}
        />
      </div>
      <div className={cx(classes.imageModalFooter, 'modal-button-block')}>
        <Button
          className="cta-button modal-button modal-button-white"
          variant="contained"
          onClick={handleClose}
          fullWidth
        >
          {t(translations.ui.buttons.cancel)}
        </Button>
        <Button
          className="cta-button modal-button"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          fullWidth
        >
          {t(translations.ui.buttons.finish)}
        </Button>
      </div>
    </>
  );
};

export default memo(ScaleImage);
