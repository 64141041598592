import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SvgIcon from '@material-ui/core/SvgIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Table } from 'app/components/table';
import { translations } from 'locales/i18n';
import { UserRoles, UserCompany, ROLES } from 'app/components/auth/constants';
import RoleCell from 'app/components/grid/roleCell/RoleCell';
import { useConfirmation, useModal } from 'app/components/ui/Modal';
import { useAcl } from 'app/components/auth';

import { AssignCompanyModal } from './AssignCompanyModal';

import { ReactComponent as modalCompanyIcon } from 'images/icon/FontAwesome5Pro/Light/modal-company.svg';
import { ReactComponent as removeUserIcon } from 'images/icon/remove-user.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  userRoles: UserRoles[];
  userName: string;
  isProfile?: boolean;
  userCompanies?: UserCompany[];
  allCompanies?: UserCompany[];
  handleAssignToCompany: (...args: any) => void;
  handleRemoveCompany: (...args: any) => void;
}

export default function CompaniesList({
  userRoles,
  userName,
  userCompanies,
  allCompanies,
  handleAssignToCompany,
  handleRemoveCompany,
  isProfile = false,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isOneOf } = useAcl();
  const theme = useTheme();
  const respMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedCompany, setCompany] = useState<Record<string, any> | null>(
    null,
  );

  const [showRemoveConfirmation] = useConfirmation({
    onSubmit: async () => {
      await handleRemoveCompany(selectedCompany?.id);
    },
    maxWidth: 'lg',
    titleBlockStyle: 'default',
    withCheckBox: true,
    modalIcon: modalCompanyIcon,
    title: t(translations.people.removeCompany),
    description: selectedCompany ? (
      <>
        <Typography color="error">
          {t(translations.ui.titles.warning)}
        </Typography>
        <Typography>
          <Trans
            i18nKey="people.removeFromCompany"
            values={{
              person: userName,
              company: selectedCompany.name,
            }}
          />
        </Typography>
        <br />
        <Typography>
          {t(translations.people.removeFromCompanyDescription)}
        </Typography>
        <br />
        <Typography>
          {t(translations.people.removeFromCompanyQuestion)}
        </Typography>
      </>
    ) : (
      <div />
    ),
    cancelLabel: t(translations.ui.buttons.cancel),
    agreeLabel: t(translations.ui.buttons.remove),
    cancelButtonProps: {
      className: 'modal-button modal-button-white',
      variant: 'contained',
      size: 'medium',
    },
    agreeButtonProps: {
      className: 'modal-button button-danger',
      size: 'medium',
    },
  });

  const handleRemoveClick = useCallback(
    item => {
      setCompany(item);
      showRemoveConfirmation();
    },
    [showRemoveConfirmation],
  );

  const isLocationAdmin = isOneOf([
    ROLES.LOCATION_ADMIN_PRIMARY,
    ROLES.LOCATION_ADMIN,
  ]);

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'isActive',
          Cell: () => <FiberManualRecordIcon className="table-status-icon" />,
          disableSortBy: true,
          className: 'table-info-first-cell table-status-cell',
          headerClassName: 'table-status-cell',
        },
        {
          Header: t(translations.table.columns.company),
          accessor: 'name',
          Cell: ({ cell: { value } }: { cell: Cell }) => <div>{value}</div>,
          className: 'table-header-cell-mobile left-indent',
          minWidth: 200,
          maxWidth: 300,
          disableSortBy: true,
        },
        {
          Header: t(translations.table.columns.role),
          disableSortBy: true,
          Cell: ({ cell: { row } }) => {
            const roles = userRoles.reduce<Array<{ id: number; name: string }>>(
              (acc, item) => {
                if (
                  item.companyId === row.original.id &&
                  item.locationId === null
                ) {
                  acc.push({
                    id: item.role.id,
                    name: item.role.name,
                  });
                }
                return acc;
              },
              [],
            );

            return <RoleCell roles={roles} />;
          },
        },
        {
          Header: !isProfile ? t(translations.table.columns.quickActions) : '',
          id: 'id',
          minWidth: 180,
          maxWidth: 180,
          disableSortBy: true,
          className: 'table-action-cell',
          accessor: item => item,
          Cell: ({ cell: { value: item } }: { cell: Cell }) => {
            return (
              <>
                {!isProfile ? (
                  <div className="action-cell-wrapper">
                    {!isLocationAdmin ? (
                      <span className={classes.actionItem}>
                        <Tooltip
                          placement="top"
                          arrow={true}
                          title={t(
                            translations.actions.names.removeFromCompany,
                          )}
                        >
                          <SvgIcon
                            component={removeUserIcon}
                            viewBox="0 0 23 19"
                            onClick={() => handleRemoveClick(item)}
                          />
                        </Tooltip>
                      </span>
                    ) : (
                      <span className={classes.disabled}>
                        <SvgIcon
                          component={removeUserIcon}
                          viewBox="0 0 23 19"
                        />
                      </span>
                    )}
                  </div>
                ) : null}
              </>
            );
          },
        },
      ].filter(Boolean),
    [userRoles],
  );

  const [showAssignToCompany] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: AssignCompanyModal,
    props: { companies: allCompanies, handleSubmit: handleAssignToCompany },
    unmount: true,
  });

  if (!userCompanies?.length) {
    return <div className="table-no-data">{t(translations.table.noData)}</div>;
  }

  return (
    <>
      <Table
        renderGlobalFilter={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm />
            <Grid item xs={12} sm="auto">
              {!isProfile ? (
                <Button
                  className="cta-button"
                  variant="contained"
                  color="secondary"
                  onClick={showAssignToCompany}
                  startIcon={<AddRoundedIcon style={{ fontSize: 22 }} />}
                  fullWidth={respMobile}
                  disabled={
                    allCompanies?.length === 0 ||
                    isOneOf([
                      ROLES.LOCATION_ADMIN_PRIMARY,
                      ROLES.LOCATION_ADMIN,
                    ])
                  }
                >
                  {t(translations.people.buttons.assignToCompany)}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        )}
        isPagination={false}
        columns={columns}
        data={userCompanies?.length ? userCompanies : []}
      />
    </>
  );
}
