import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';

import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import SvgIcon from '@material-ui/core/SvgIcon';
import Popper from '@material-ui/core/Popper';

import { BreadcrumbsOption } from '../types';

import { ReactComponent as chevronRightLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-right.svg';
import { ReactComponent as chevronDownLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-down.svg';
import { ReactComponent as ellipseIcon } from 'images/icon/FontAwesome5Pro/Light/ellipse.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  options: BreadcrumbsOption[];
}

const Breadcrumbs = ({ options }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const respMobUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiBreadcrumbs
      classes={{
        root: classes.bcBlock,
        ol: classes.bcList,
        li: classes.bcListItem,
        separator: cx(
          classes.bcListItem,
          classes.bcListItemSeparator,
          'bc-separator',
        ),
      }}
      maxItems={respMobUp ? 5 : 2}
      separator={
        <SvgIcon component={chevronRightLightIcon} viewBox="0 0 256 512" />
      }
    >
      {options.map(
        (
          { name, to = '', secondaryName, icon, iconTo, dropdown, show = true },
          index,
        ) => {
          const last = index === options.length - 1;
          const first = index === 0;

          return (
            show && (
              <PopupState key={name} variant="popper">
                {popupState => (
                  <ButtonBase
                    className={cx(classes.bcField, {
                      [classes.bcFieldCurrent]: last,
                      [classes.bcFieldWithoutLink]: !to,
                      [classes.bcFieldWithSecondaryName]: secondaryName,
                      [classes.bcFieldDropdown]: dropdown,
                      [classes.open]: popupState.isOpen,
                    })}
                    component={to ? RouterLink : 'div'}
                    to={to ? to : undefined}
                    disableRipple={!to}
                    disableTouchRipple={!to}
                    color="inherit"
                    {...bindHover(popupState)}
                  >
                    {icon && (
                      <IconButton
                        className={cx(classes.bcFieldStartIconButton, {
                          [classes.bcFieldStartIconNotButton]: !iconTo,
                        })}
                        component={iconTo ? RouterLink : 'div'}
                        to={iconTo ? iconTo : undefined}
                        disableRipple={!iconTo}
                        disableTouchRipple={!iconTo}
                      >
                        {icon}
                      </IconButton>
                    )}
                    <div className={classes.bcFieldNameBlock}>
                      <div
                        className={cx(
                          classes.bcFieldName,
                          classes.textEllipsis,
                        )}
                      >
                        {name}
                        {last && !dropdown?.length && (
                          <SvgIcon
                            component={ellipseIcon}
                            viewBox="0 0 16 16"
                            className={classes.greenIcon}
                          />
                        )}
                      </div>
                      {secondaryName && (
                        <div
                          className={cx(
                            classes.bcFieldSecondaryName,
                            classes.textEllipsis,
                          )}
                        >
                          {secondaryName}
                        </div>
                      )}
                    </div>
                    {dropdown && dropdown.length && (
                      <>
                        <SvgIcon
                          className={classes.bcFieldDropdownIcon}
                          component={chevronDownLightIcon}
                          viewBox="0 0 448 512"
                        />
                        <Popper
                          placement="bottom"
                          style={{
                            width: popupState.anchorEl
                              ? popupState.anchorEl.clientWidth
                              : 'auto',
                          }}
                          {...bindPopper(popupState)}
                        >
                          <div
                            className={cx(classes.bcFieldDropdownPaper, {
                              [classes.open]: popupState.isOpen,
                              [classes.bcFieldDropdownPaperFirstItem]: first,
                            })}
                          >
                            {dropdown.map(
                              ({ name, to, show = true }) =>
                                show && (
                                  <ButtonBase
                                    className={classes.bcFieldDropdownItem}
                                    key={name}
                                    component={RouterLink}
                                    to={to}
                                  >
                                    {name}
                                  </ButtonBase>
                                ),
                            )}
                          </div>
                        </Popper>
                      </>
                    )}
                  </ButtonBase>
                )}
              </PopupState>
            )
          );
        },
      )}
    </MuiBreadcrumbs>
  );
};

export default memo(Breadcrumbs);
