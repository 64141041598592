import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorPrimary,
  colorPrimaryLight2,
  colorPrimaryExtraLight2,
  boxShadow,
  borderRadiusLargeSize,
  borderRadiusSize,
  colorWhite,
  bgColorLightGrey2,
} from 'configs/variables';

const headerHeight = 56;
const indentHeight = '12px 24px';

export default (theme: Theme) =>
  createStyles({
    paperRoot: {
      width: '100%',
    },
    paperShadow: {
      ...boxShadow,
    },
    noneBorderRadius: {
      '&, & $paperHeader, & $paperFooter': {
        borderRadius: 0,
      },
    },
    defaultBorderRadius: {
      borderRadius: borderRadiusSize,

      '& $paperHeader': {
        borderRadius: `${borderRadiusSize}px ${borderRadiusSize}px 0 0`,
      },
      '& $paperFooter': {
        borderRadius: `0 0 ${borderRadiusSize}px ${borderRadiusSize}px`,
      },
    },
    largeBorderRadius: {
      borderRadius: borderRadiusLargeSize,

      '& $paperHeader': {
        borderRadius: `${borderRadiusLargeSize}px ${borderRadiusLargeSize}px 0 0`,
      },
      '& $paperFooter': {
        borderRadius: `0 0 ${borderRadiusLargeSize}px ${borderRadiusLargeSize}px`,
      },
    },
    paperHeader: {
      backgroundColor: colorPrimaryLight2,
      minHeight: headerHeight,
      color: colorWhite,
      padding: indentHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      position: 'relative',

      '& h5': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 15,
        },
      },
    },
    paperFooter: {
      backgroundColor: bgColorLightGrey2,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperHeaderWithBack: {
      paddingLeft: 50,
    },
    paperBackButton: {
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 20,
      padding: 6,
    },
    paperContent: {},
    paperContentIndented: {
      padding: 24,
    },
    paperSubheader: {
      backgroundColor: colorPrimaryExtraLight2,
      minHeight: headerHeight,
      color: colorPrimary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: indentHeight,
    },

    // View
    defaultSize: {},
    smallSize: {
      [theme.breakpoints.up('sm')]: {
        width: 375,
      },
    },
    mediumSize: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    largeSize: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
  });
