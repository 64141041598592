import { getDaysToDelete } from 'app/components/onboarding/components/utils';

import { CompanyEntity } from '../interfaces';

export const isCompanyActive = (company: CompanyEntity): boolean =>
  getDaysToDelete(company.timeZone, company.trialPeriodStartDate) > 0;

export const getCompanyStatusStyle = (company: CompanyEntity): string => {
  if (!company.trialPeriodStartDate) {
    return 'yellow';
  }

  return getDaysToDelete(company.timeZone, company.trialPeriodStartDate) > 0
    ? ''
    : 'grey';
};
