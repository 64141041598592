/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import { ReactCropperProps } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';

import { Cropper } from 'app/components/cropper';
import { translations } from 'locales/i18n';
import {
  DEFAULT_CANVAS_OPTIONS_WO_DIM,
  DEFAULT_IMAGE_NAME,
} from 'app/components/cropper/constants';
import { CropperBlob } from 'app/components/cropper/types';
import { Checkbox } from 'app/components/ui/Checkbox';

import { ReactComponent as drawSquareLightIcon } from 'images/icon/FontAwesome5Pro/Light/draw-square.svg';
import { ReactComponent as drawCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/draw-circle.svg';

import styles from 'app/components/forms/styles/image';

const useStyles = makeStyles(styles);

interface Props {
  name: string;
  label?: React.ReactNode;
  className?: string;
  cropperProps?: ReactCropperProps;
  canvasOptions?: object;
  uploadAreaText?: string;
  margin?: 'none' | 'dense' | 'normal';
  onSubmit: (data, isCircle) => void;
  handleClose: () => void;
  setIsGif: (isGif) => void;
  fullWidth?: boolean;
  control?: boolean;
  isGif?: boolean;
  image: any;
  setImage: (data: any) => void;
  fromOnboarding?: boolean;
  src?: any;
  isEditMode?: boolean;
  editFileName?: string;
  editIsCircle?: boolean;
}

const ImageInner: React.FC<Props> = ({
  name,
  label,
  className = '',
  cropperProps,
  uploadAreaText,
  margin = 'none',
  fullWidth = true,
  onSubmit,
  handleClose,
  control = true,
  isGif,
  setIsGif,
  image,
  setImage,
  fromOnboarding = false,
  src,
  isEditMode = false,
  editFileName = '',
  editIsCircle = false,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const [{ value }, meta, helper] = useField(name);
  const classes = useStyles();
  const { error } = meta;
  const { setError, setValue } = helper;
  const [showCrop, setShowCrop] = useState(false);
  const [cropper, setCropper] = useState();
  const [filename, setFilename] = useState(DEFAULT_IMAGE_NAME);
  const [isCircle, setIsCircle] = useState(false);

  const handleCircleCrop = useCallback(() => {
    // @ts-ignore
    cropper.setAspectRatio(1);
    setIsCircle(true);
  }, [cropper]);

  useEffect(() => {
    if (isEditMode && editIsCircle) {
      setIsCircle(true);
    }
  }, [editIsCircle, isEditMode]);

  const handleSquareCrop = useCallback(() => {
    // @ts-ignore
    cropper.setAspectRatio(null);
    setIsCircle(false);
  }, [cropper]);

  const handleChange = useCallback(
    file => {
      setValue(file);
      onSubmit(file, isCircle);
    },
    [setValue, onSubmit, isCircle],
  );

  const handleSave = useCallback(() => {
    if (isGif) {
      handleChange(image);
    } else {
      // @ts-ignore
      const { originalWidth, originalHeight } = cropper.imageData;
      // @ts-ignore
      const canvas = cropper.getCroppedCanvas({
        width: originalWidth,
        height: originalHeight,
        ...DEFAULT_CANVAS_OPTIONS_WO_DIM,
      });

      canvas.toBlob((blob: CropperBlob) => {
        blob.name = filename;
        blob.lastModifiedDate = new Date();
        handleChange(blob); // Use toBlob instead to get Image
      });
    }
  }, [cropper, filename, handleChange, image, isGif]);

  const handleCheckGif = () => {
    setIsGif(!isGif);
  };

  const cropperValue = fromOnboarding ? undefined : value;

  return (
    <>
      <FormControl
        margin={margin}
        fullWidth={fullWidth}
        className={cx(
          classes.imageField,
          error ? classes.imageFieldError : '',
          isCircle ? 'circle-crop' : '',
          className,
        )}
      >
        {label ? (
          <InputLabel className={classes.imageFieldLabel}>{label}</InputLabel>
        ) : (
          ''
        )}
        <Cropper
          onError={setError}
          onChange={handleChange}
          value={cropperValue}
          cropperProps={cropperProps}
          uploadAreaText={uploadAreaText}
          showCrop={showCrop}
          setShowCrop={setShowCrop}
          isGif={isGif}
          cropper={control && cropper}
          setCropper={setCropper}
          filename={filename}
          setFilename={setFilename}
          setFile={setImage}
          isCircle={isCircle}
          setIsGif={setIsGif}
          withDropZone
          fromOnboarding={fromOnboarding}
          src={src}
          isEditMode={isEditMode}
          editFileName={editFileName}
          {...rest}
        />
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
        {showCrop && (
          <Box display="flex" justifyContent="center">
            <FormControlLabel
              className={classes.imageFieldCheckbox}
              control={<Checkbox onChange={handleCheckGif} checked={isGif} />}
              label={t(translations.labels.uploadGifCheckBox)}
            />
          </Box>
        )}
      </FormControl>
      {showCrop && !isGif && (
        <Box mt={2} mb={2}>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm="auto">
              <Button
                className="cta-button"
                variant={isCircle ? 'outlined' : 'contained'}
                onClick={handleSquareCrop}
                fullWidth
                startIcon={
                  <SvgIcon
                    component={drawSquareLightIcon}
                    viewBox="0 0 448 512"
                  />
                }
              >
                Square Crop
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                className="cta-button"
                variant={isCircle ? 'contained' : 'outlined'}
                onClick={handleCircleCrop}
                fullWidth
                startIcon={
                  <SvgIcon
                    component={drawCircleLightIcon}
                    viewBox="0 0 512 512"
                  />
                }
              >
                Circle Crop
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <div className={cx(classes.imageModalFooter, 'modal-button-block')}>
        <Button
          className="cta-button modal-button modal-button-white"
          variant="contained"
          onClick={handleClose}
          fullWidth
        >
          {t(translations.ui.buttons.cancel)}
        </Button>
        <Button
          className="cta-button modal-button"
          variant="contained"
          color="secondary"
          onClick={handleSave}
          disabled={!showCrop}
          fullWidth
        >
          {t(
            isGif
              ? translations.ui.buttons.finish
              : translations.ui.buttons.next,
          )}
        </Button>
      </div>
    </>
  );
};

export default memo(ImageInner);
