import React, { memo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import { useLocation, useNavigate } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Tooltip } from 'app/components/ui/Tooltip';
import useAcl from 'app/components/auth/hooks/useAcl';
import { sideBarActionConfig } from 'app/components/layouts/constants';
import { ROLES } from 'app/components/auth';

import AccordionHeaderBlock from './AccordionHeaderBlock';

import { ReactComponent as plusIcon } from 'images/icon/FontAwesome5Pro/Light/plus.svg';
import { ReactComponent as switchIcon } from 'images/icon/FontAwesome5Pro/Light/switch.svg';
import { ReactComponent as menuItemIcon } from 'images/icon/FontAwesome5Pro/Light/menu-item-tree.svg';
import { ReactComponent as menuItemEndIcon } from 'images/icon/FontAwesome5Pro/Light/menu-item-tree-end.svg';
import { ReactComponent as editCompanyIcon } from 'images/icon/FontAwesome5Pro/Light/edit-company.svg';
import { ReactComponent as gearIcon } from 'images/icon/FontAwesome5Pro/Light/gear.svg';
import { ReactComponent as helpCentreIcon } from 'images/icon/FontAwesome5Pro/Light/help-centre.svg';
import { ReactComponent as menuInstallsIcon } from 'images/icon/FontAwesome5Pro/Light/menu-installs.svg';
import { ReactComponent as usdIcon } from 'images/icon/FontAwesome5Pro/Light/usd.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface AccordionBlockProps {
  companyId: number;
  name: string;
  drawerMinimize: boolean;
  handleDrawerMinimizeToggle: any;
}

const AccordionBlock: React.FC<AccordionBlockProps> = ({
  companyId,
  name,
  drawerMinimize,
  handleDrawerMinimizeToggle,
}) => {
  const { isOneOf, user } = useAcl();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} expanded={true}>
      <AccordionSummary
        className={classes.accordionSummary}
        classes={{
          content: cx(classes.accordionSummaryContent, {
            isHeaderMinimized: drawerMinimize,
          }),
        }}
      >
        <div className={classes.accordionSummaryItem}>
          <AccordionHeaderBlock
            companyId={companyId}
            name={name}
            drawerMinimize={drawerMinimize}
            handleDrawerMinimizeToggle={handleDrawerMinimizeToggle}
          />
          {isOneOf(sideBarActionConfig.billingAdmin) && (
            <div
              className={cx(classes.companyIconsBlock, {
                [classes.isMinimized]: drawerMinimize,
              })}
            >
              <Button className={classes.actionButton} disabled={true}>
                <SvgIcon
                  component={switchIcon}
                  className={cx(classes.headerIcon, {
                    [classes.switchIcon]: isOneOf(
                      sideBarActionConfig.accountHolder,
                    ),
                  })}
                  viewBox="0 0 14 14"
                />
              </Button>
              {isOneOf(sideBarActionConfig.accountHolder) && (
                <Button className={classes.actionButton} disabled={true}>
                  <SvgIcon
                    className={classes.headerIcon}
                    component={plusIcon}
                    viewBox="0 0 12 12"
                  />
                </Button>
              )}
            </div>
          )}
        </div>
        {user?.mainRole === ROLES.SIGNATURE_USER ? (
          <div className={classes.accordionSummaryItem}>
            <div
              className={cx(classes.companyIconsBlock, {
                minimized: drawerMinimize,
              })}
            >
              <Button
                disabled={true}
                className={cx(classes.actionHeaderButton, {
                  minimized: drawerMinimize,
                })}
              >
                <SvgIcon
                  className={cx({ [classes.companyIcon]: !drawerMinimize })}
                  component={menuInstallsIcon}
                  viewBox="0 0 24 21"
                />
                {!drawerMinimize ? (
                  <div className={classes.linkItem}>
                    <Typography>Installs</Typography>
                  </div>
                ) : null}
              </Button>
            </div>
          </div>
        ) : null}
        {user?.mainRole === ROLES.BILLING_ADMIN ||
        user?.mainRole === ROLES.SIGNATURE_USER ||
        user?.mainRole === ROLES.COMPANY_USER ||
        user?.mainRole === ROLES.LOCATION_USER ? (
          <div className={classes.accordionSummaryItem}>
            <div
              className={cx(classes.companyIconsBlock, {
                minimized: drawerMinimize,
              })}
            >
              <Button
                disabled={true}
                className={cx(classes.actionHeaderButton, {
                  minimized: drawerMinimize,
                })}
              >
                <SvgIcon
                  className={cx({ [classes.companyIcon]: !drawerMinimize })}
                  component={helpCentreIcon}
                  viewBox="0 0 20 21"
                />
                {!drawerMinimize ? (
                  <div className={classes.linkItem}>
                    <Typography>Help Centre</Typography>
                  </div>
                ) : null}
              </Button>
            </div>
          </div>
        ) : null}
      </AccordionSummary>
      {isOneOf(sideBarActionConfig.locationAdmin) && (
        <AccordionDetails
          className={cx(classes.accordionDetails, {
            [classes.isMinimized]: drawerMinimize,
          })}
        >
          <Button
            onClick={() => navigate(`/locations/${companyId}`)}
            className={classes.listItem}
          >
            <SvgIcon
              component={menuItemIcon}
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography
                className={cx({
                  [classes.active]: location.pathname.includes(
                    `/locations/${companyId}`,
                  ),
                })}
              >
                Locations
              </Typography>
            </div>
          </Button>
          <Button
            onClick={() => navigate(`/user-signatures/active`)}
            className={classes.listItem}
          >
            <SvgIcon
              component={menuItemIcon}
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography
                className={cx({
                  [classes.active]:
                    location.pathname.includes(`/user-signatures`),
                })}
              >
                Signatures
              </Typography>
            </div>
          </Button>
          <Button
            onClick={() => navigate(`/people/${companyId}`)}
            className={classes.listItem}
          >
            <SvgIcon
              component={menuItemIcon}
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography
                className={cx({
                  [classes.active]: location.pathname.includes(
                    `/people/${companyId}`,
                  ),
                })}
              >
                People
              </Typography>
            </div>
          </Button>
          <Button
            onClick={() => navigate(`/invites/${companyId}`)}
            className={classes.listItem}
          >
            <SvgIcon
              component={menuItemIcon}
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography
                className={cx({
                  [classes.active]: location.pathname.includes(
                    `/invites/${companyId}`,
                  ),
                })}
              >
                Invites
              </Typography>
            </div>
          </Button>
          <Button
            onClick={() => navigate(`/queue/${companyId}`)}
            className={classes.listItem}
          >
            <SvgIcon
              component={menuItemIcon}
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography
                className={cx({
                  [classes.active]: location.pathname.includes(
                    `/queue/${companyId}`,
                  ),
                })}
              >
                Queues
              </Typography>
            </div>
          </Button>
          {isOneOf(sideBarActionConfig.locationAdmin) && (
            <Button
              onClick={() => navigate(`/collections/active`)}
              className={classes.listItem}
            >
              <SvgIcon
                component={menuItemIcon}
                className={classes.listItemIcon}
                viewBox="0 0 48 48"
              />
              <div className={classes.linkItem}>
                <Typography
                  className={cx({
                    [classes.active]:
                      location.pathname.includes(`collections`) ||
                      location.pathname.includes(`signature-designs`) ||
                      location.pathname.includes(`add-new-signature-designs`) ||
                      location.pathname.includes(`new-signature-designs`),
                  })}
                >
                  {t(translations.names.collections)}
                </Typography>
              </div>
            </Button>
          )}
          {isOneOf(sideBarActionConfig.companyAdmin) && (
            <Button
              onClick={() => navigate(`/icon-sets/active`)}
              className={classes.listItem}
            >
              <SvgIcon
                component={menuItemIcon}
                className={classes.listItemIcon}
                viewBox="0 0 48 48"
              />
              <div className={classes.linkItem}>
                <Typography
                  className={cx({
                    [classes.active]: location.pathname.includes(`/icon-sets`),
                  })}
                >
                  Icon Sets
                </Typography>
              </div>
            </Button>
          )}
          <Button disabled={true} className={classes.listItem}>
            <SvgIcon
              component={
                user?.mainRole === ROLES.LOCATION_ADMIN ||
                user?.mainRole === ROLES.LOCATION_ADMIN_PRIMARY
                  ? menuItemEndIcon
                  : menuItemIcon
              }
              className={classes.listItemIcon}
              viewBox="0 0 48 48"
            />
            <div className={classes.linkItem}>
              <Typography>Help Centre</Typography>
            </div>
          </Button>
          {isOneOf(sideBarActionConfig.companyAdmin) && (
            <div className={cx(classes.listItem, classes.disabled)}>
              <SvgIcon
                component={menuItemEndIcon}
                className={classes.listItemIcon}
                viewBox="0 0 48 48"
              />
              <Button
                className={classes.actionButton}
                onClick={() => navigate(`/companies/${companyId}/edit`)}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.editData)}
                >
                  <SvgIcon
                    className={classes.actionIcon}
                    component={editCompanyIcon}
                    viewBox="0 0 22 21"
                  />
                </Tooltip>
              </Button>
              <Button
                onClick={() =>
                  navigate(`/companies/${companyId}/edit/settings`)
                }
                className={classes.actionButton}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.settings)}
                >
                  <SvgIcon
                    component={gearIcon}
                    className={classes.actionIcon}
                    viewBox="0 0 20 21"
                  />
                </Tooltip>
              </Button>
              <Button
                onClick={() => navigate(`/companies/${companyId}/edit/billing`)}
                className={classes.actionButton}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.billing)}
                >
                  <SvgIcon
                    component={usdIcon}
                    className={classes.actionIcon}
                    viewBox="0 0 12 21"
                  />
                </Tooltip>
              </Button>
            </div>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default memo(AccordionBlock);
