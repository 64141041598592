import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DialogContentProps {
  children: React.ReactNode;
  className?: string;
}

const ModalContent = ({
  children,
  className,
  ...DialogContentProps
}: Props) => {
  const classes = useStyles();

  return (
    <DialogContent
      className={cx(classes.modalContent, className)}
      {...DialogContentProps}
    >
      {children}
    </DialogContent>
  );
};

export default memo(ModalContent);
