import { createStyles, Theme } from '@material-ui/core/styles';

import reactTableStyle from 'app/components/table/styles';
import { bgColorLightGrey2, textSecondary } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    ...reactTableStyle(theme),
    dataTableBlock: {
      '&.data-table-with-action': {
        '& $tableBodyRow': {
          [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            overflow: 'visible',

            '& .table-action-cell': {
              top: -15,
              right: 15,

              '& .mobile-cell-head': {
                display: 'none',
              },
            },
          },
          [theme.breakpoints.down('xs')]: {
            marginTop: 10,

            '& .table-action-cell': {
              top: 14,
            },
          },
        },
      },
    },
    dataTableRow: {
      minWidth: 100,
      overflow: 'hidden',

      '&.row-clear-border': {
        borderBottomColor: 'transparent',
      },
    },
    dataTableHeadRow: {},
    dataTableBodyRow: {
      '@media (hover)': {
        '&:hover': {
          backgroundColor: bgColorLightGrey2,
        },
      },
      '& $dataTableCell': {
        color: textSecondary,
      },
    },
    dataTableCell: {
      boxSizing: 'border-box',
      margin: 0,
      alignItems: 'flex-start',

      '& .cell-content': {
        flexGrow: 1,
      },
      '& .mobile-cell-head': {
        marginTop: 17,
      },
      '& .table-status-icon': {
        [theme.breakpoints.up('md')]: {
          marginTop: 20,
        },
      },
      '&.data-table-status-cell': {
        [theme.breakpoints.only('sm')]: {
          marginTop: 17,
        },

        '& + .data-table-cell': {
          '& .mobile-cell-head': {
            [theme.breakpoints.down('sm')]: {
              paddingLeft: 17,
            },
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',

        '& .cell-content, & .mobile-cell-head': {
          width: '100%',
        },
        '& .mobile-cell-head': {
          marginTop: 2,
          marginBottom: 10,
        },
      },
      '&.data-cell-action': {
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      '&.data-cell-center': {
        alignItems: 'center',

        '& .mobile-cell-head': {
          marginTop: 0,
        },
      },
    },
  });
