import cloudinary from 'app/config/cloudinary';

export function file(
  id: number,
  width?: number,
  height?: number,
  isCircle = false,
  prefix = '',
) {
  const publicId = buildPublicId(prefix + String(id));

  return cloudinary.url(publicId, {
    width,
    height,
    quality: 'auto',
    fetch_format: 'auto',
    dpr: '3.0',
    crop: 'fit',
    ...(isCircle ? { radius: 'max' } : {}),
  });
}

export function fileSvg(id: number, prefix = '') {
  const publicId = buildPublicId(prefix + String(id));

  return cloudinary.url(publicId);
}

export function fileCrop(id: number) {
  const publicId = buildPublicId(id);

  return cloudinary.url(publicId, {
    quality: 'auto',
    fetch_format: 'auto',
    height: 300,
    width: 300,
    crop: 'fill',
  });
}

export function downloadFile(id: number) {
  return `${process.env.API_URL}/files/${id}/download`;
}

export function buildPublicId(id: number | string | null, folder = ''): string {
  const fileFolder = process.env.REACT_APP_DISK_PREFIX
    ? `${process.env.REACT_APP_DISK_PREFIX}${folder ? `/${folder}` : ''}`
    : folder;

  return fileFolder ? `${fileFolder}/${String(id)}` : String(id);
}
