import { createStyles } from '@material-ui/core/styles';

import { colorYellowLight3 } from 'configs/variables';

export default () =>
  createStyles({
    roleSwitch: {
      marginRight: '12px',
      background: '#FFFAEB',
      border: `1px solid ${colorYellowLight3}`,
    },
    roleIcon: {
      fill: colorYellowLight3,
      '& > path': {
        fill: colorYellowLight3,
      },
    },
  });
