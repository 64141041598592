import {
  MutationOptions,
  QueryOptions,
  QueryObserverOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { ChildIndex, PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';
import { UserSettings } from 'app/components/entities/companies/interfaces';
import { Users as UserEntity } from '../types';

const endpoint = '/users';
const addExtraSharesEndpoint = '/shares/extra';

export const userShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, UserEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const userIndex = (queryData: any) =>
  apiClient.get<any, PaginatedResponse<UserEntity>>(endpoint, {
    params: queryData,
  });

export const userUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, UserEntity>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const extraShareStore = data => {
  return apiClient.post<any, { success: boolean }>(
    addExtraSharesEndpoint,
    data,
  );
};

export const sendUserLoginInfo = (id, data) => {
  return apiClient.post<any, { success: boolean }>(
    `${endpoint}/${id}/send-login-info`,
    data,
  );
};

export const assignUserToRole = (id, data) => {
  return apiClient.post<any, { success: boolean }>(
    `${endpoint}/${id}/roles`,
    data,
  );
};

export const assignUserToCompany = (id, data) => {
  return apiClient.post<any, { success: boolean }>(
    `${endpoint}/${id}/companies`,
    data,
  );
};

export const assignUserToLocation = (id, data) => {
  return apiClient.post<any, { success: boolean }>(
    `${endpoint}/${id}/locations`,
    data,
  );
};

export const removeRole = (id, roleId) => {
  return apiClient.delete<any, { success: boolean }>(
    `${endpoint}/${id}/roles/${roleId}`,
  );
};
export const removeFromCompany = (id, companyId) => {
  return apiClient.delete<any, { success: boolean }>(
    `${endpoint}/${id}/companies/${companyId}`,
  );
};
export const removeFromLocation = (id, locationId) => {
  return apiClient.delete<any, { success: boolean }>(
    `${endpoint}/${id}/locations/${locationId}`,
  );
};

export const useUserIndex: <T = PaginatedResponse<UserEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['userIndex', any], any>(
    ['userIndex', queryData],
    () => userIndex(queryData),
    options,
  );

export const useUserShow = (id: number, queryData?, key?) =>
  useQuery<UserEntity, ['userShow', number]>([key || 'userShow', id], () =>
    userShow(id, queryData),
  );

export const useUserFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<UserEntity>, any>,
) => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch: refetchUsers,
  } = useUserIndex(queryData, options);

  return {
    data: idData,
    isLoading: idsIsloading,
    refetchUsers,
  };
};

export const useUserFilter = ({
  key = ['useUserFilter'],
  builder,
  config = {},
}: ChildIndex<UserEntity>) => {
  const query = builder?.query() ?? '';
  return useQuery<PaginatedResponse<UserEntity>, any>(
    [key, builder],
    () => apiClient.get(`${endpoint}?${query}`),
    {
      ...config,
      keepPreviousData: true,
      retry: false,
    },
  );
};

export const useUserUpdate = (
  id?: number,
  options?: MutationOptions<UserEntity, any>,
) =>
  useMutation<UserEntity, any>(
    ['userUpdate'],
    (data: any) => userUpdate(id || data.id, data),
    {
      ...options,
    },
  );

export const useExtraShareStore = (
  options?: MutationOptions<{ success: boolean }, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any>(
    ['extraShareStore'],
    data => extraShareStore(data),
    {
      ...options,
    },
  );

export const useSendUserLoginInfo = (
  id,
  options?: MutationOptions<{ success: boolean }, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any, any>(
    ['sendUserLoginInfo'],
    (data: any) => sendUserLoginInfo(id, data),
    {
      ...options,
    },
  );

export const useAssignUserToRole = (
  id,
  options?: MutationOptions<{ success: boolean }, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any>(
    ['assignUserToRole'],
    data => assignUserToRole(id, data),
    {
      ...options,
    },
  );

export const useAssignUserToCompany = (
  id,
  options?: MutationOptions<{ success: boolean }, any, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any, any>(
    ['assignUserToCompany'],
    data => assignUserToCompany(id, data),
    {
      ...options,
    },
  );

export const useAssignUserToLocation = (
  id,
  options?: MutationOptions<{ success: boolean }, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any>(
    ['assignUserToLocation'],
    data => assignUserToLocation(id, data),
    {
      ...options,
    },
  );

export const useRemoveRole = (
  id,
  options?: MutationOptions<{ success: boolean }>,
) =>
  useMutation<{ success: boolean; error?: string }>(
    ['removeRole'],
    roleId => removeRole(id, roleId),
    {
      ...options,
    },
  );

export const useRemoveFromCompany = (
  id,
  options?: MutationOptions<{ success: boolean }, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any>(
    ['removeFromCompany'],
    companyId => removeFromCompany(id, companyId),
    {
      ...options,
    },
  );

export const useRemoveFromLocation = (
  id,
  options?: MutationOptions<{ success: boolean }, any, any>,
) =>
  useMutation<{ success: boolean; error?: string }, any, any>(
    ['removeFromLocation'],
    locationId => removeFromLocation(id, locationId),
    {
      ...options,
    },
  );

export const getUserSettings = (userId: number): Promise<UserSettings> =>
  apiClient.get(`${endpoint}/${userId}/settings`);

export const useGetUserSettings = (userId: number, options?) =>
  useQuery<UserSettings, string>(
    ['userSettings'],
    () => getUserSettings(userId),
    {
      ...options,
    },
  );

export const useUpdateUserSettings = (options?) =>
  useMutation(['updateUserSettings'], updateUserSettings, {
    ...options,
  });

export const updateUserSettings = ({ userId, ...data }): Promise<any> =>
  apiClient.post(`${endpoint}/${userId}/settings`, data);

export const useDeleteUserSettings = (options?) =>
  useMutation(['deleteUserSettings'], deleteUserSettings, {
    ...options,
  });

export const deleteUserSettings = (userId: string): Promise<any> =>
  apiClient.delete(`${endpoint}/${userId}`);

export const peopleClone = (id: number): Promise<UserEntity> => {
  return apiClient.post(`${endpoint}/ghosts/${id}/clone`);
};

export const usePeopleClone = (
  options?: MutationOptions<UserEntity, any, any>,
) =>
  useMutation<any, UserEntity, any>(['usePeopleClone'], id => peopleClone(id), {
    ...options,
  });

export const peopleDelete = (id: number): Promise<UserEntity> => {
  return apiClient.delete(`${endpoint}/ghosts/${id}`);
};

export const usePeopleDelete = (
  options?: MutationOptions<UserEntity, any, any>,
) =>
  useMutation<any, UserEntity, any>(
    ['usePeopleDelete'],
    id => peopleDelete(id),
    {
      ...options,
    },
  );

export const selectRole = (id, data): Promise<UserEntity> => {
  return apiClient.patch(`${endpoint}/${id}/select-role`, data);
};

export const useSelectRole = (
  id: number,
  options?: MutationOptions<UserEntity, any, any>,
) =>
  useMutation<any, UserEntity, any>(
    ['useSelectRole'],
    data => selectRole(id, data),
    {
      ...options,
    },
  );
