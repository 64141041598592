import React, { forwardRef } from 'react';
import { Slide, SlideProps } from '@material-ui/core';

export type Props = SlideProps;

const Transition = (props: Props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
};

export default forwardRef(Transition);
