import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';

import { translations } from 'locales/i18n';
import AutocompleteField from 'app/components/ui/Autocomplete';
import { UserCompany } from 'app/components/auth/constants';

import { ReactComponent as arrowCircleRightLightIcon } from 'images/icon/FontAwesome5Pro/Light/arrow-circle-right.svg';

export interface Props {
  handleSubmit: (...data: any) => void;
  companies?: UserCompany[];
}

const customOptionLabel = ({ name }) => name;

const initialValues: {
  company: UserCompany | null;
} = {
  company: null,
};

export default function AssignCompanyForm({ handleSubmit, companies }: Props) {
  const { t } = useTranslation();

  const userCompaniesOptions = useMemo(
    () =>
      companies?.map(({ id, name }) => ({
        id,
        name,
      })),
    [companies],
  );

  const validationSchema = yup.object().shape({
    company: yup
      .object()
      .nullable()
      .required(t(translations.validation.required)),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <AutocompleteField
            disableClearable={false}
            name="company"
            label={t(translations.people.fields.company)}
            options={userCompaniesOptions}
            customOptionLabel={customOptionLabel}
          />
          <div className="modal-button-block modal-dialog-actions">
            <Button
              className="modal-button cta-button"
              variant="contained"
              color="secondary"
              type="submit"
              startIcon={
                <SvgIcon
                  component={arrowCircleRightLightIcon}
                  viewBox="0 0 512 512"
                />
              }
            >
              {t(translations.ui.buttons.save)}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
