import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { LocalAuthenticateData, Users } from 'app/components/users/types';
import { apiClient, ApiError } from 'app/config/axios';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { Token } from 'app/components/auth/interfaces';

interface UseAuth {
  readonly user: Users;
  readonly isLoading: boolean;
  readonly isAuth: boolean;
  readonly token?: string;
}

interface LocalProvider {
  authenticate(data: LocalAuthenticateData): void;

  isLoading: boolean;
  user?: Users;
  error?: ApiError;
}

export default function useAuth(): UseAuth {
  const { user, token, isLoading } = useSelector(
    ({ auth }: { auth: UseAuth }) => auth,
  );

  const isAuth = Boolean(token);

  return { user, isLoading, isAuth, token };
}

export const useLocalAuth = (): LocalProvider => {
  const [error, setError] = useState<ApiError>();
  const { setToken, isLoading: userIsLoading, user } = useAuthUser();

  const { mutate, isLoading: mutationIsLoading } = useMutation<
    Token,
    any,
    LocalAuthenticateData
  >(
    ['localAuth'],
    ({ email, password }: { email: string; password: string }) =>
      apiClient.post('/auth/login', { email, password }),
    {
      onSuccess: input => setToken(input),
      onError: (e: ApiError) => setError(e),
    },
  );

  const isLoading = mutationIsLoading || userIsLoading;

  const authenticate = useCallback(
    (input: LocalAuthenticateData) =>
      mutate({ email: input.email, password: input.password }),
    [mutate],
  );

  return { authenticate, isLoading, error, user };
};
