import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { Loader } from 'app/components/ui/Loader';
import { useSeventhStep } from 'app/components/entities/accounts/hooks/crud';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { error, success } from 'app/components/snackbars';
import { Text } from 'app/components/forms';

import { StepFooter } from '../StepFooter/StepFooter';

import { ReactComponent as copyLightIcon } from 'images/icon/FontAwesome5Pro/Light/copy.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

const buildInviteLink = linkId =>
  `https://${window.location.host}/invite/${linkId}`;

export interface Props {
  nextStep: () => void;
}

export const SeventhStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
  );

  const { data: invite, isLoading: isInviteLoading } = useSeventhStep(
    {
      companyId: Number(company?.id),
      locationId: Number(company?.locations[0]?.id),
      signatureId: Number(company?.locations[0]?.signatures[0]?.id),
    },
    Boolean(company),
  );

  const initialValues = useMemo(() => {
    return {
      invite: user && invite ? buildInviteLink(invite?.linkId) : '',
    };
  }, [user, invite]);

  const handleFinishStep = useCallback(() => {
    try {
      nextStep();
    } catch (e) {
      error(t(translations.errors.somethingWentWrongError));
    }
  }, [t]);

  const copyInviteLink = useCallback(
    (inviteLink: string) => {
      navigator.clipboard.writeText(inviteLink);
      success(t(translations.ui.notificationMessages.copiedToClipboard));
    },
    [t],
  );

  const isLoading = isCompanyLoading || isInviteLoading;

  return (
    <>
      <Loader loading={isLoading} fullPage={false} />
      {!isLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.seventh.title)}
          </Typography>
          <Typography>
            {t(translations.accountHolderOnboarding.steps.seventh.text1)}
          </Typography>
          <br />
          <Typography>
            <Trans
              i18nKey="accountHolderOnboarding.steps.seventh.text2"
              components={{
                b: <b />,
              }}
            />
          </Typography>

          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => {
              return;
            }}
          >
            {({ handleSubmit, values: data }) => (
              <div className={classes.stepContainer}>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <div className={classes.inviteBlock}>
                    <Typography className={classes.shareText}>
                      {t(
                        translations.accountHolderOnboarding.steps.seventh
                          .shareText,
                      )}
                    </Typography>

                    <Text
                      className={classes.inviteField}
                      name="invite"
                      type="text"
                      variant="outlined"
                      endIcon={
                        <SvgIcon
                          className={classes.copyIcon}
                          component={copyLightIcon}
                          viewBox="0 0 512 512"
                          onClick={() => copyInviteLink(data.invite)}
                        />
                      }
                    />
                  </div>
                  <Typography>
                    <Trans
                      i18nKey="accountHolderOnboarding.steps.seventh.text3"
                      components={{
                        signatureName:
                          company?.locations[0]?.signatures[0]?.name,
                      }}
                    />
                  </Typography>
                  <br />
                  <Typography>
                    {t(
                      translations.accountHolderOnboarding.steps.seventh.text4,
                    )}
                  </Typography>
                  <br />
                </form>
                <StepFooter
                  activeStep={7}
                  handleNext={() => handleFinishStep()}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
