/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createStyles, Theme } from '@material-ui/core/styles';

import { textFieldHeight } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    dataField: {},
    dataFieldWithoutLabel: {},
    dataFieldLabel: {},
    inputData: {
      ...theme.overrides!.MuiInputBase!.input,
      '&, & > *': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        [theme.breakpoints.down('xs')]: {
          whiteSpace: 'initial',
          textOverflow: 'initial',
          overflow: 'visible',
        },
      },
    },
    multilineData: {
      ...theme.overrides!.MuiInputBase!.input,
      height: 'auto',
      minHeight: textFieldHeight,
    },
    autoData: {
      padding: 0,
      height: 'auto',
    },
    dataFieldData: {
      display: 'block',
      paddingLeft: 0,
      paddingRight: 0,
    },
  });
