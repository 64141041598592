import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorPrimary,
  colorSecondary,
  colorSecondaryDark,
  colorYellow,
  colorPink,
  colorBlue,
  colorPrimaryLight2,
  textDisabled,
  colorPinkDark,
  tableBorderColor,
  colorDanger,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    messageBlock: {
      borderBottom: `1px solid ${colorPrimary}`,
      padding: '2px 16px 16px',

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 56,
        paddingRight: 56,
      },
    },
    messageText: {
      fontSize: 14,
    },
    messageTextFontSize24: {
      fontSize: 24,
    },
    messageBlockAction: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    textWarning: {
      color: colorDanger,
      fontSize: 14,
      fontWeight: 400,
    },
    textWarningTitle: {
      color: colorDanger,
      fontSize: 18,
      fontWeight: 600,
    },
    messageActionButton: {
      marginTop: 10,
      minWidth: 170,

      [theme.breakpoints.up('sm')]: {
        marginLeft: 5,
        marginTop: 0,
      },
    },
    tabContentBlock: {
      padding: '24px 16px',

      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 56,
        paddingRight: 56,
      },
    },
    tabContentFooter: {
      borderTop: `1px solid ${tableBorderColor}`,
    },

    planTitle: {
      fontSize: 20,
      color: colorPink,
    },
    planTitlePro: {
      color: colorBlue,
    },
    planAmount: {
      color: colorPrimary,
      fontSize: 20,
      fontWeight: 500,
      verticalAlign: 'middle',
      lineHeight: 1,
    },
    planAmountLabel: {
      color: colorPrimaryLight2,
      fontSize: 12,
    },
    userAmount: {
      color: colorSecondaryDark,
      fontSize: 30,
      fontWeight: 700,
      verticalAlign: 'middle',
      lineHeight: 1,

      [theme.breakpoints.up('md')]: {
        fontSize: 40,
      },
    },
    userAmountLabel: {
      color: colorSecondary,
      fontSize: 18,
      fontWeight: 600,

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    textLarge: {
      fontSize: 30,

      [theme.breakpoints.up('md')]: {
        fontSize: 40,
      },
    },
    planDate: {
      color: '#434343',
    },
    userCount: {
      color: colorSecondary,
    },
    userCountFull: {
      color: colorYellow,
    },
    actionBlock: {
      marginTop: 24,

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },

      '& .cta-button': {
        '&:not(:first-child)': {
          marginTop: 16,
          [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            marginLeft: 16,
          },
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    alertInfo: {
      alignItems: 'flex-start',

      [theme.breakpoints.up('sm')]: {
        maxWidth: 430,
        padding: '16px 44px 12px 16px',
      },
    },
    alertError: {
      alignItems: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 430,
        padding: '16px 44px 12px 16px',
      },
    },
    plansDescription: {
      color: colorSecondary,
      fontSize: 18,
      fontWeight: 700,

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    amountTextField: {
      marginRight: 8,
      verticalAlign: 'middle',

      '& input': {
        fontSize: 30,
        lineHeight: 1,
        fontWeight: 700,
        padding: '5px 10px',
        width: 110,
        textAlign: 'right',
      },
      '& fieldset': {
        borderWidth: 2,
      },
    },
    switchLabel: {
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    switchLabelOff: {
      '&, & *': {
        color: textDisabled,
      },
    },
    alertSuccessBox: {
      padding: 16,

      '& .alert-icon': {
        display: 'none',
      },
    },
    textMedium: {
      fontWeight: 500,
    },
    planTextColorPink: {
      color: `${colorPink} !important`,
    },
    planTextColorPinkDark: {
      color: `${colorPinkDark} !important`,
    },
    billingForm: {
      '& .text-input-wrapper': {
        [theme.breakpoints.up('sm')]: {
          maxWidth: 327,
        },
      },
    },
    textFormSmall: {
      '& .text-input-wrapper': {
        [theme.breakpoints.up('sm')]: {
          maxWidth: 160,
        },
      },
    },
    informBlock: {
      display: 'inline-flex',
      alignItems: 'center',

      '& .tooltip-init-block': {
        marginLeft: 6,

        '& svg': {
          color: colorPrimaryLight2,
          fontSize: 16,
        },
      },
    },

    tableSettings: {
      '& .text-field': {
        [theme.breakpoints.up('sm')]: {
          width: 200,

          '&.text-field-select': {
            width: 135,
          },
        },
      },
      '& .table-row-inner': {
        borderColor: 'transparent',
      },
    },
  });
