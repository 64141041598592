/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'cropperjs/dist/cropper.css';
import { ReactCropperProps } from 'react-cropper';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import { Modal, ModalTitle, ModalContent } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';

import { CropperBlob } from '../types';
import { calculateSize } from '../utils';
import { DEFAULT_CANVAS_OPTIONS } from '../constants';
import ImageEditorInner from './ImageEditorInner';

interface Props {
  onSubmit: (image: CropperBlob) => void;
  onClose: () => void;
  image: any;
  open: boolean;
  filename?: string;
  cropperProps?: ReactCropperProps;
  canvasOptions?: object;
}

const ImageEditorModal: React.FC<Props> = ({
  onSubmit,
  onClose,
  image,
  open,
  filename = '',
  cropperProps,
  canvasOptions = {},
}) => {
  const [cropper, setCropper] = useState();
  const { t } = useTranslation();

  const handleSave = useCallback(() => {
    const options = { ...DEFAULT_CANVAS_OPTIONS, ...canvasOptions };
    const { minWidth, maxWidth, minHeight, maxHeight } = options;
    // @ts-ignore
    const { naturalWidth, naturalHeight } = cropper.imageData;
    // @ts-ignore
    const canvas = cropper.getCroppedCanvas({
      width: calculateSize(naturalWidth, minWidth, maxWidth),
      height: calculateSize(naturalHeight, minHeight, maxHeight),
      ...options,
    });

    canvas.toBlob((blob: CropperBlob) => {
      blob.name = filename;
      blob.lastModifiedDate = new Date();
      onSubmit(blob); // Use toBlob instead to get Image
    });
  }, [canvasOptions, cropper, filename, onSubmit]);

  return (
    <Modal open={open} maxWidth="md">
      <ModalTitle onClose={onClose}>
        {t(translations.ui.titles.uploadImage)}
      </ModalTitle>
      <ModalContent>
        <ImageEditorInner
          setCropper={setCropper}
          image={image}
          cropper={cropper}
          cropperProps={cropperProps}
        />
      </ModalContent>
      <DialogActions className="modal-button-block">
        <Button
          className="modal-button"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t(translations.ui.buttons.cancel)}
        </Button>
        <Button
          className="modal-button"
          variant="contained"
          color="secondary"
          onClick={handleSave}
        >
          {t(translations.ui.buttons.save)}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default ImageEditorModal;
