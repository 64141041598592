import React, { memo } from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { translations } from 'locales/i18n';
import { error } from 'app/components/snackbars';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useAuth } from 'app/components/auth';
import { useSelectRole } from 'app/components/users/hooks/crud';
import {
  ROLES,
  ROLES_ICONS,
  ROLES_ICONS_VIEWBOX,
  ROLES_ORDER,
  ROLES_TITLE,
} from 'app/components/auth/constants';
import Dropdown, { Props as DropdownProps } from './Dropdown';
import { Loader } from '../../Loader';

import { ReactComponent as editProfileIcon } from 'images/icon/edit-profile.svg';
import { ReactComponent as notificationIcon } from 'images/icon/notification.svg';
import { ReactComponent as logoutIcon } from 'images/icon/logout.svg';
import { ReactComponent as questionIcon } from 'images/icon/question.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

const MenuDropdownList = (props: DropdownProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout, refetch } = useAuthUser();
  const { user } = useAuth();
  const navigate = useNavigate();

  const roles = [...(user?.roles || [])]?.sort(
    (a, b) => ROLES_ORDER.indexOf(a.name) - ROLES_ORDER.indexOf(b.name),
  );
  const { mutateAsync: selectRole, isLoading: isSelectRoleLoading } =
    useSelectRole(Number(user?.id));

  const handleSelectRole = async role => {
    try {
      await selectRole({ role });
      (refetch() as any).then(() => {
        navigate('/');
      });
    } catch (err) {
      error(err.message);
    }
  };

  const isTrialUser = roles.some(role => role.name === ROLES.TRIAL_USER);
  const singularText = {
    superAdmin: 'Account',
    accountHolderPrimary: 'Account',
    accountHolder: 'Account',
    companyAdmin: 'Company',
    companyAdminPrimary: 'Company',
    locationAdminPrimary: 'Location',
    locationAdmin: 'Location',
    billingAdmin: 'Company',
    signatureUser: 'Signature',
    companyUser: 'Company',
    locationUser: 'Location',
  };

  const pluralText = {
    superAdmin: 'Accounts',
    accountHolderPrimary: 'Accounts',
    accountHolder: 'Accounts',
    companyAdmin: 'Companies',
    companyAdminPrimary: 'Companies',
    locationAdminPrimary: 'Locations',
    locationAdmin: 'Locations',
    billingAdmin: 'Companies',
    signatureUser: 'Signatures',
    companyUser: 'Companies',
    locationUser: 'Locations',
  };

  return (
    <>
      <Loader loading={isSelectRoleLoading} />
      <Dropdown
        {...props}
        classNamePopper={classes.dropdownMenu}
        classNameScrollbar={classes.dropdownScroll}
      >
        {({ handleClose }) => (
          <div>
            {!isTrialUser ? (
              <>
                <div className={classes.roleSwitcherText}>
                  <Typography variant="h6">
                    {t(translations.dropdownMenu.roleSwitcher)}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.roleSwitcherSubText}
                  >
                    {t(translations.dropdownMenu.roleSwitcherSubText)}
                  </Typography>
                </div>
                <div>
                  {roles.map(role => {
                    const amount = 1;
                    return (
                      <div
                        className={cx(classes.roleBlock, {
                          [classes.activeRole]: user.mainRole === role.name,
                        })}
                        onClick={
                          user.mainRole !== role.name
                            ? async () => await handleSelectRole(role.name)
                            : () => {
                                return;
                              }
                        }
                      >
                        <SvgIcon
                          className={classes.roleIcon}
                          component={ROLES_ICONS[role.name]}
                          viewBox={ROLES_ICONS_VIEWBOX[role.name]}
                        />
                        <div>
                          <Typography variant="h6">
                            {ROLES_TITLE[role.name]}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.roleSwitcherSubText}
                          >
                            <Trans
                              i18nKey={`dropdownMenu.${role.name}`}
                              values={{
                                amount,
                                text:
                                  amount === 1
                                    ? singularText[role.name]
                                    : pluralText[role.name],
                              }}
                            />
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            <div className={classes.dropdownMenuFooter}>
              <div className={classes.footerRaw}>
                <div
                  className={classes.footerIconBlock}
                  onClick={() => {
                    navigate(`/profile/${user?.id}/edit/login`);
                    handleClose();
                  }}
                >
                  <SvgIcon
                    className={classes.footerIcon}
                    component={editProfileIcon}
                    viewBox="0 0 13 13"
                  />
                  <Typography className={classes.footerText}>
                    {t(translations.dropdownMenu.editProfile)}
                  </Typography>
                </div>
                <div className={cx(classes.footerIconBlock, classes.disabled)}>
                  <SvgIcon
                    className={classes.footerIcon}
                    component={notificationIcon}
                    viewBox="0 0 13 11"
                  />
                  <Typography className={classes.footerText}>
                    {t(translations.dropdownMenu.notification)}
                  </Typography>
                </div>
              </div>
              <div className={classes.footerRaw}>
                <div className={cx(classes.footerIconBlock, classes.disabled)}>
                  <SvgIcon
                    className={classes.footerIcon}
                    component={questionIcon}
                    viewBox="0 0 12 13"
                  />
                  <Typography className={classes.footerText}>
                    {t(translations.dropdownMenu.helpCentre)}
                  </Typography>
                </div>

                <div className={classes.footerIconBlock} onClick={logout}>
                  <SvgIcon
                    className={classes.footerIcon}
                    component={logoutIcon}
                    viewBox="0 0 13 11"
                  />
                  <Typography className={classes.footerText}>
                    {t(translations.dropdownMenu.logout)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropdown>
    </>
  );
};

export default memo(MenuDropdownList);
