import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestQueryBuilder } from '@dataui/crud-request';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { translations } from 'locales/i18n';
import { Loader } from 'app/components/ui/Loader';
import { error } from 'app/components/snackbars';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useSecondStep } from 'app/components/entities/accounts';

import UsersList from './UsersList';

import styles from './styles';
import { useGetBillingInfo } from 'app/components/entities/components/tabs/hooks/crud';

const useStyles = makeStyles(styles);

export interface Props {
  nextStep: () => void;
}

export const SecondStep = ({ nextStep }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { mutateAsync: finishStep } = useSecondStep();
  const [isLoading, setIsLoading] = useState(false);

  const { isFetching: billingIsLoading, data: billingData } = useGetBillingInfo(
    user?.companies[0]?.id as number,
  );

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep({ ...data, accountId: user?.account?.id });
        setIsLoading(false);
        nextStep();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={billingIsLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!billingIsLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.second.title)}
          </Typography>

          {Number(user?.companies[0]?.usersCount) <
          Number(billingData?.quantity) ? (
            <Typography>
              {t(translations.accountHolderOnboarding.steps.second.text1)}
            </Typography>
          ) : (
            <>
              <Typography>
                {t(translations.accountHolderOnboarding.steps.second.text2)}
              </Typography>
              <br />
              <Typography>
                {t(translations.accountHolderOnboarding.steps.second.text3)}
              </Typography>
            </>
          )}
          <br />
          <UsersList
            userId={user?.id as number}
            quantity={billingData?.quantity as number}
            finishStep={handleFinishStep}
          />
        </div>
      )}
    </>
  );
};
