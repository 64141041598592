import { createStyles, Theme } from '@material-ui/core/styles';
import {
  containerMaxWidth,
  drawerWidth,
  drawerMinimizeWidth,
  colorPrimary,
  colorWhite,
  colorSecondary,
  textGrey,
  transition,
  layoutHorizontalPadding,
  colorGrey,
  colorGrey1,
  colorRose3,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    rootContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      maxWidth: containerMaxWidth,

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },

    // Header
    header: {
      width: '100%',
      left: 0,
      right: 0,
      zIndex: theme.zIndex.drawer + 1,

      [theme.breakpoints.up('md')]: {
        left: 'auto',
        marginLeft: drawerMinimizeWidth,
        maxWidth: `calc(100% - ${drawerMinimizeWidth}px)`,
        transitionProperty: 'max-width, margin',
        transition,
      },
      [theme.breakpoints.up('xl')]: {
        right: 'auto',
        maxWidth: `calc(${containerMaxWidth} - ${drawerMinimizeWidth}px)`,
      },
    },
    headerShift: {
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        maxWidth: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up('xl')]: {
        marginLeft: drawerWidth,
        maxWidth: `calc(${containerMaxWidth} - ${drawerWidth}px)`,
      },
    },
    headerToolbar: {
      backgroundColor: colorWhite,
      color: textGrey,
      justifyContent: 'space-between',
      ...layoutHorizontalPadding(theme),
    },

    menuButton: {
      marginRight: 20,
      fontSize: 20,

      '& svg': {
        fontSize: 'inherit',
      },
    },
    headerUserDropdown: {
      color: colorPrimary,
      fontWeight: 600,
      fontSize: 14,

      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },

      '& .avatar': {
        fontSize: 14,
        marginRight: 7,

        [theme.breakpoints.up('sm')]: {
          fontSize: 16,
          marginRight: 7,
        },
        [theme.breakpoints.down('xs')]: {
          width: 30,
          height: 30,
        },
      },
    },
    headerUserDropdownText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    headerUserDropdownSubText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
    headerUserDropdownPopper: {
      zIndex: theme.zIndex.drawer + 2,
    },
    headerSavingBox: {
      color: colorSecondary,
      marginRight: 8,

      [theme.breakpoints.up('sm')]: {
        marginRight: 16,
      },
      '& svg': {
        fontSize: 16,
        marginRight: 5,

        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          marginRight: 7,
        },
      },
    },

    // Logo
    logo: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      transitionProperty: 'opacity',
      transition,

      '&, &:hover, &:focus': {
        textDecoration: 'none',
      },
      '&:hover': {
        opacity: 0.85,
      },
    },
    logoDark: {
      '& $logoName': {
        color: colorPrimary,
      },
    },
    logoImage: {
      width: 40,
      height: 40,

      [theme.breakpoints.up('sm')]: {
        width: 50,
        height: 50,
      },
    },
    logoName: {
      color: colorWhite,
      fontSize: 22,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      marginLeft: 10,

      [theme.breakpoints.up('sm')]: {
        fontSize: 26,
        marginLeft: 12,
      },
    },

    // Main
    mainContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      maxWidth: '100%',
      minWidth: 0,
    },
    mainContentIndent: {
      ...layoutHorizontalPadding(theme),
    },
    mainContent: {
      flexGrow: 1,
      paddingTop: 15,
      paddingBottom: 20,

      [theme.breakpoints.up('md')]: {
        paddingTop: 32,
        paddingBottom: 40,
      },
    },
    impersonateButton: {
      minWidth: '40px',
      minHeight: '40px',
      marginRight: '8px',
      border: `1px solid ${colorGrey1}`,
      fill: colorGrey,
      '& path': {
        fill: colorGrey,

        '& > .impersonate': {
          fill: `${colorRose3} !important`,
        },
      },
      '&  .MuiButton-startIcon': {
        margin: 0,
      },
      '&.impersonate': {
        border: `1px solid ${colorRose3}`,
        '& path': {
          fill: colorRose3,
        },
      },
    },
  });
