import {
  QueryOptions,
  UseQueryResult,
  useQuery,
  QueryObserverOptions,
} from 'react-query';
import { ApiError, apiClient } from 'app/config/axios';

import objectToFormData from 'utils/objectToFormData';
import { CompanyField } from '../interfaces';
import { PaginatedResponse } from 'app/interfaces';

const endpoint = '/company-fields';

export const companyFieldUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, CompanyField>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const companyFieldsIndex = queryData =>
  apiClient.get<any, PaginatedResponse<CompanyField>>(endpoint, {
    params: queryData,
  });

export const useCompanyFieldsIndex: <T = PaginatedResponse<CompanyField>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useCompanyFieldsIndex', any], any>(
    ['useCompanyFieldsIndex', queryData],
    () => companyFieldsIndex(queryData),
    options,
  );

export const useCompanyFieldsFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<CompanyField>, ApiError>,
): {
  data: PaginatedResponse<CompanyField> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useCompanyFieldsIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};
