import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalContent, ModalTitle } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';
import { UserCompany } from 'app/components/auth/constants';

import AssignCompanyForm from './AssignCompanyForm';

interface Props {
  onClose: () => void;
  open: boolean;
  handleSubmit: (...data: any) => void;
  companies?: UserCompany[];
}

export const AssignCompanyModal: React.FC<Props> = ({
  onClose,
  open,
  handleSubmit,
  companies,
}) => {
  const { t } = useTranslation();
  const onSubmit = useCallback(
    data => {
      handleSubmit(data, onClose);
    },
    [handleSubmit, onClose],
  );

  return (
    <Modal open={open} maxWidth="sm">
      <ModalTitle onClose={onClose}>
        {t(translations.people.assignToCompany)}
      </ModalTitle>
      <ModalContent>
        <AssignCompanyForm handleSubmit={onSubmit} companies={companies} />
      </ModalContent>
    </Modal>
  );
};
