import React, { useCallback, useState } from 'react';
import { useModal as useModalHook } from 'react-modal-hook';

import { Loader } from 'app/components/ui/Loader';

import { ModalProps } from '../types';

export interface Props {
  readonly onSubmit?: (...rest: any) => void;
  readonly onClose?: () => void;
  readonly Modal: React.FC<ModalProps>;
  readonly props?: object;
  readonly unmount?: boolean;
  readonly isSupportShowProps?: boolean;
}

const useModal = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  Modal,
  props = {},
  unmount = false,
  isSupportShowProps = true,
}: Props): [(props?: any) => void, () => void] => {
  const [showProps, setShowProps] = useState<{ [key: string]: any }>({});

  const [showModal, hideModal] = useModalHook(
    ({ in: open }) => {
      const [isLoading, setIsLoading] = useState(false);

      const handleSubmit = async (...rest: any) => {
        try {
          setIsLoading(true);

          const submit = showProps.onSubmit || onSubmit;

          await submit(...rest, showProps);
          hideModal();
        } catch (e) {}
        setIsLoading(false);
      };

      const handleClose = () => {
        const close = showProps.onClose || onClose;
        close();
        hideModal();
      };

      return (
        (!unmount || open) && (
          <Loader loading={isLoading}>
            <Modal
              open={open}
              {...props}
              {...showProps}
              onSubmit={handleSubmit}
              onClose={handleClose}
            />
          </Loader>
        )
      );
    },
    [showProps, props, onSubmit, onClose],
  );

  const handleShow = useCallback(
    (props = {}) => {
      if (isSupportShowProps) {
        setShowProps(props);
      }
      showModal();
    },
    [isSupportShowProps, showModal],
  );

  return [handleShow, hideModal];
};

export default useModal;
