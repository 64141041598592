import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { PAGE_TYPE } from 'app/containers/constants';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { UsersSearch } from 'app/components/forms/components/Edit/Search/UsersSearch';
import { translations } from 'locales/i18n';
import { useConfirmation } from 'app/components/ui/Modal';

import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as editLightIcon } from 'images/icon/FontAwesome5Pro/Light/edit.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId?: string;
  locationId?: string;
  signatureId?: string;
  pageType: PAGE_TYPE;
  backLink?: string;
  indexQuery: any;
  refetch: () => void;
  handleDeleteSignatureUser: (id: any) => void;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  locationId,
  signatureId,
  pageType,
  backLink,
  indexQuery,
  refetch,
  handleDeleteSignatureUser,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [signatureUserId, setSignatureUserId] = useState<number>();
  const [signature, setSignature] = useState<Record<string, any> | null>();

  const urlEntityType = useCallback(
    params => {
      switch (pageType) {
        case PAGE_TYPE.GHOSTS:
          return `/ghost-${params}`;
        case PAGE_TYPE.PAID:
          return `/${params}`;
        default:
          return `/trial-${params}`;
      }
    },
    [pageType],
  );

  const [showGhostDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeleteSignatureUser(signatureUserId),
    onClose: () =>
      signature
        ? (() => {
            setSignature(null);
            refetch();
          })()
        : null,
    title: t(translations.ui.titles.confirmationGhostSignatureUserDeleteTitle),
    description: (
      <>
        {signature ? (
          <>
            {t(translations.ui.titles.confirmationGhostSignatureUserDelete2)}
            <Link
              target="_blank"
              href={`/ghost-signatures/${signature.id}/edit-data`}
            >
              {signature?.name}.
            </Link>
            <br />
            <br />
            {t(translations.ui.titles.confirmationGhostSignatureUserDelete3)}
          </>
        ) : (
          <>
            {t(translations.ui.titles.confirmationGhostSignatureUserDelete1)}
            <br />
          </>
        )}
      </>
    ),
    maxWidth: 'md',
    withAgreeButton: !signature?.name?.length,
    withCheckBox: !signature?.name?.length,
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleGhostSignatureUserDeleteClick = useCallback(
    item => {
      setSignatureUserId(item.id);
      if (item.user.id === item.signature.userId) {
        setSignature(item.signature);
      }
      showGhostDeleteConfirmation();
    },
    [showGhostDeleteConfirmation],
  );

  const userEditLink = {
    [PAGE_TYPE.PAID]: 'people',
    [PAGE_TYPE.GHOSTS]: 'ghost-users',
    [PAGE_TYPE.TRIALS]: 'trial-users',
  };

  const columns = [
    {
      Header: t(translations.table.columns.name),
      accessor: 'name',
      customProps: row => urlEntityType(`signature-users/${row.id}/edit`),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    companyId
      ? null
      : {
          Header: t(translations.table.columns.companies),
          id: 'signature.location.companyId',
          accessor: ({
            signature: {
              location: { company },
            },
          }) => company?.name,
        },
    locationId
      ? null
      : {
          Header: t(translations.table.columns.locations),
          id: 'signature.locationId',
          accessor: ({
            signature: {
              location: { name },
            },
          }) => name,
        },
    signatureId
      ? null
      : {
          Header: t(translations.table.columns.signatures),
          id: 'signatureId',
          accessor: ({ signature: { name } }) => name,
        },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({ id, user, signature }) => {
        return { id, user, signature };
      },
      Cell: ({
        cell: {
          value: { id, user, signature },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              className={classes.actionItem}
              href={urlEntityType(`signature-users/${id}/edit`)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/${userEditLink[pageType]}/${user?.id}/edit/account`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.people.actions.edit)}
              >
                <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
              </Tooltip>
            </Link>

            {pageType === PAGE_TYPE.GHOSTS ? (
              <span
                className={classes.actionItem}
                onClick={() =>
                  handleGhostSignatureUserDeleteClick({ id, user, signature })
                }
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.delete)}
                >
                  <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
          </div>
        );
      },
    },
  ].filter(Boolean);

  const pageName = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.GHOSTS:
        return t(translations.names.ghost.users);
      case PAGE_TYPE.PAID:
        return t(translations.names.paid.users);
      default:
        return t(translations.names.trials.users);
    }
  }, [pageType, t]);

  const initialState = useMemo(
    () => ({
      globalFilter: {
        ...(companyId ? { 'signature.location.companyId': companyId } : {}),
        ...(locationId ? { 'signature.locationId': locationId } : {}),
        ...(signatureId ? { signatureId } : {}),
      },
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    }),
    [companyId, locationId, signatureId],
  );

  return (
    <Paper
      header={<Typography variant="h5">{pageName()}</Typography>}
      backLink={backLink}
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <UsersSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
