import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { DataTable } from 'app/components/DataTable';
import { DataRowOption } from 'app/components/DataTable/types';
import { Switch } from 'app/components/forms';
import useAcl from 'app/components/auth/hooks/useAcl';
import { ROLES } from 'app/components/auth';
import { useConfirmation } from 'app/components/ui/Modal';
import { success } from 'app/components/snackbars';
import { Users } from 'app/components/users/types';
import { CompanyEntity } from 'app/components/entities/companies';
import { Loader } from 'app/components/ui/Loader';

import { SETTINGS_CONFIG, SETTINGS_CONFIG_PROFILE } from '../constants';

import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as trashLightIcon } from 'images/icon/FontAwesome5Pro/Light/trash.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../styles/indexForm';

import {
  useDeleteUserSettings,
  useGetUserSettings,
  useUpdateUserSettings,
} from '../hooks/crud';
import { TIERS } from '../../onboarding/constants';

const useStyles = makeStyles(styles);

export interface Props {
  user: Users;
  isProfile?: boolean;
  isImpersonated?: boolean;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}

function Settings({
  user,
  isLoading,
  isProfile,
  isImpersonated,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsLoading = () => {},
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutateAsync: callUpdateUserSettings } = useUpdateUserSettings();
  const { mutateAsync: callDeleteUserSettings, isLoading: isRemoveLoading } =
    useDeleteUserSettings();
  const navigate = useNavigate();
  const { isOneOf } = useAcl();

  const getCompaniesNameWithPro = useCallback(() => {
    const res = user.companies?.reduce(
      (arNames: string[], company: CompanyEntity) => {
        if (company.subscriptions.length) {
          company.subscriptions?.reduce(
            (arNames: string[], subscription: any) => {
              if (subscription.tier === TIERS.PRO_STARTER) {
                arNames.push(company.name);
              }
            },
            arNames,
          );
        }
        return arNames;
      },
      [],
    );
    return res && res.join(', ');
  }, [user.companies]);

  const [showDeleteConfirmation] = useConfirmation({
    onSubmit: () => {
      callDeleteUserSettings(String(user.id)).then(() => {
        success(t(translations.people.message.success.delete));
        navigate(`/people/${user?.companies[0]?.id}`);
      });
    },
    maxWidth: 'lg',
    titleBlockStyle: 'default',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    description: (
      <div>
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson)}
        </Typography>
        <br />
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson_Part2)}
        </Typography>
        <br />
        <Typography>
          {t(translations.ui.titles.confirmationMessageDeletePerson_Part3)}
        </Typography>
      </div>
    ),
    title: t(translations.ui.titles.deletePerson),
    agreeLabel: t(translations.ui.buttons.deletePerson),
    cancelButtonProps: {
      className: 'modal-button modal-button-white',
      variant: 'contained',
      size: 'medium',
    },
    agreeButtonProps: {
      className: 'modal-button button-danger',
      size: 'medium',
    },
  });

  const {
    data: userSettings,
    isLoading: settingsLoading,
    isFetching: settingsFetching,
    refetch: refetchSettings,
  } = useGetUserSettings(user.id);

  const buildFieldsMap = useCallback(
    () => ({
      allowLogin: <Switch fullWidth={false} name="allowLogin" />,
      allowToCreateInstalls: (
        <Switch fullWidth={false} name="allowToCreateInstalls" />
      ),
      prefixTimeplateStructuralTag: (
        <Switch fullWidth={false} name="prefixTimeplateStructuralTag" />
      ),
    }),
    [],
  );

  const defaultValue = {
    allowLogin: true,
    allowToCreateInstalls: true,
    prefixTimeplateStructuralTag: true,
  };

  const getValueByCode = useCallback(
    code => {
      if (!userSettings) return defaultValue[code];
      return userSettings[code];
    },
    [defaultValue, userSettings],
  );

  const initialValues = useMemo(() => {
    return {
      allowLogin: getValueByCode('allowLogin'),
      allowToCreateInstalls: getValueByCode('allowToCreateInstalls'),
      prefixTimeplateStructuralTag: getValueByCode(
        'prefixTimeplateStructuralTag',
      ),
    };
  }, [getValueByCode]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        allowLogin: yup.boolean().required(t(translations.validation.required)),
        allowToCreateInstalls: yup
          .boolean()
          .required(t(translations.validation.required)),
        prefixTimeplateStructuralTag: yup
          .boolean()
          .required(t(translations.validation.required)),
      }),
    [t],
  );

  // table rows with fields
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const buildRows: (values) => DataRowOption[] = useCallback(
    () =>
      Object.values(
        isProfile
          ? isImpersonated
            ? SETTINGS_CONFIG.fields
            : SETTINGS_CONFIG_PROFILE.fields
          : SETTINGS_CONFIG.fields,
      )
        .map(item => {
          if (!item.isShown) {
            return;
          }
          const fieldName = item.key;
          const rowClassName = item.className;

          return {
            id: fieldName,
            rowClassName,
            cells: [
              {
                id: 'isActive',
                cell: '',
                className: 'table-info-first-cell table-status-cell',
              },
              {
                id: 'name',
                head: t(translations.company.field.head.name),
                cell: (
                  <div className={classes.informBlock}>
                    {t(translations.people.field[fieldName])}{' '}
                  </div>
                ),
                className: 'table-header-cell-mobile data-cell-center',
                width: 250,
                maxWidth: 250,
              },
              {
                id: 'choice',
                head: t(translations.company.field.head.choice),
                className: 'mobile-cell-head-remove data-cell-center',
                cell: buildFieldsMap()[fieldName],
              },
            ],
          };
        })
        .filter(Boolean),
    [buildFieldsMap, classes.informBlock, t],
  );

  const handleUpdateSettings = useCallback(
    async (data, { resetForm }) => {
      setIsLoading(true);
      await callUpdateUserSettings({
        userId: user.id,
        ...data,
      });
      await refetchSettings();
      success(t(translations.people.message.success.settings));
      if (getCompaniesNameWithPro()) {
        success(
          t(
            translations.people.message.success
              .prefixTimeplateStructuralTagSuccess,
          ),
        );
      }

      resetForm();
    },
    [
      setIsLoading,
      callUpdateUserSettings,
      user.id,
      refetchSettings,
      t,
      getCompaniesNameWithPro,
    ],
  );

  return (
    <>
      <Loader loading={isRemoveLoading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateSettings}
        enableReinitialize
      >
        {({ handleSubmit, isValid, touched, values }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Loader loading={settingsLoading || settingsFetching} />
            <DataTable
              className={classes.tableSettings}
              withForm={false}
              rows={buildRows(values)}
            />
            <div className={classes.tabContentBlock}>
              <Button
                className="cta-button"
                variant="contained"
                color="secondary"
                type="submit"
                startIcon={
                  <SvgIcon
                    component={checkCircleLightIcon}
                    viewBox="0 0 512 512"
                  />
                }
                disabled={
                  isLoading || !isValid || !Object.values(touched).length
                }
              >
                {t(translations.ui.buttons.save)}
              </Button>
              {!isProfile && isOneOf([ROLES.GHOST_USER, ROLES.SUPER_ADMIN]) && (
                <Box mt={3}>
                  <Button
                    className="modal-button button-danger cta-button"
                    variant="contained"
                    onClick={showDeleteConfirmation}
                    startIcon={
                      <SvgIcon
                        component={trashLightIcon}
                        viewBox="0 0 512 512"
                      />
                    }
                  >
                    {t(translations.ui.buttons.delete)}
                  </Button>
                </Box>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default memo(Settings);
