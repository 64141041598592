export function calculateSize(original: number, min: number, max: number) {
  if (original < min) {
    return min;
  }

  if (original > max) {
    return max;
  }

  return original;
}
