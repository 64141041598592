import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Cell } from 'react-table';

import { Link } from '@material-ui/core';

interface Props {
  cell: Cell;
  children?: React.ReactNode;
  childrenOnly?: boolean;
}

function LinkCell({ cell, children, childrenOnly = false }: Props) {
  const {
    value,
    column: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      customProps: buildLink,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contentBuilder,
    },
  } = cell;
  let linkContent = children || (!childrenOnly && value);
  if (contentBuilder) {
    linkContent = contentBuilder(cell.row.original);
  }

  return (
    <Link
      component={RouterLink}
      to={buildLink(cell.row.original)}
      color="inherit"
    >
      {linkContent}
    </Link>
  );
}

export default memo(LinkCell);
