import React, { memo, useMemo } from 'react';
import IframeResizer from 'iframe-resizer-react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends IframeResizer.IframeResizerProps {
  srcDoc?: string;
  scrollbar?: boolean;
  className?: string;
}

const CustomIframeResizer = ({
  srcDoc = '',
  scrollbar = true,
  scrolling = false,
  className,
  ...props
}: Props) => {
  const classes = useStyles();

  const srcDocWithResizer = useMemo(() => {
    if (srcDoc) {
      return srcDoc.replace(
        /(?=<\/body>)/i,
        `<script type="text/javascript" src="/js/iframeResizer.contentWindow.min.js"></script>`,
      );
    }

    return '';
  }, [srcDoc]);

  return (
    <div
      className={cx(classes.iframeResizerBlock, className, {
        'native-custom-scrollbar': scrollbar,
      })}
    >
      <div className={classes.iframeResizerInner}>
        <IframeResizer
          className={classes.iframeResizer}
          srcDoc={srcDocWithResizer}
          checkOrigin={false}
          scrolling={scrolling}
          sizeWidth
          {...props}
        />
      </div>
    </div>
  );
};

export default memo(CustomIframeResizer);
