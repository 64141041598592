import React, { memo, useMemo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AvatarMaterial, { AvatarProps } from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

import googleName from 'utils/googleName';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  id?: number;
  name?: string;
  colorId?: number;
  color?:
    | 'primary'
    | 'secondary'
    | 'yellow'
    | 'role1'
    | 'role1B'
    | 'role2'
    | 'role2B'
    | 'role3'
    | 'role4'
    | 'role5'
    | 'role6'
    | 'role7'
    | string;
  size?: 'small' | 'normal' | 'large';
  className?: string;
}

const Avatar: React.FC<AvatarProps<React.FC, Props>> = ({
  id,
  name,
  size = 'normal',
  colorId = 0,
  color,
  className = '',
  ...props
}) => {
  const classes = useStyles();

  const avatar = useMemo(() => {
    if (name) {
      return googleName(name);
    }

    return <PersonIcon />;
  }, [name]);

  return (
    <AvatarMaterial
      src={undefined}
      classes={{
        root: cx(
          classes.avatarRoot,
          id && !color ? '' : (classes as any)[`avatar${colorId % 6}`],
          color ? classes[`${color}Color`] : '',
          size ? classes[size] : '',
          className,
          'avatar',
        ),
      }}
      {...props}
    >
      {avatar}
    </AvatarMaterial>
  );
};

export default memo(Avatar);
