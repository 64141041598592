import { createStyles, Theme } from '@material-ui/core/styles';

import {
  bgBody,
  bgColorLightGrey2,
  colorDanger,
  colorDangerDark,
  colorGrey,
  colorGrey1,
  colorPrimary,
  colorRose3,
  colorSecondary,
  colorWhite,
  textGrey,
} from 'configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    onboard: {
      backgroundColor: bgBody,
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      minHeight: '100%',

      [theme.breakpoints.up('md')]: {
        height: '100%',
        minHeight: 0,
      },
    },
    onboardScrolling: {
      height: 'auto',
      minHeight: '100%',
    },
    onboardHeader: {
      backgroundColor: colorWhite,
    },
    onboardHeaderContainer: {
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.up('sm')]: {
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    onboardLogo: {
      [theme.breakpoints.down('xs')]: {
        '& .logo-name': {
          display: 'none',
        },
      },
    },
    onboardHeaderTitle: {
      color: textGrey,
      borderLeft: `1px solid ${textGrey}`,
      paddingLeft: 10,
      marginLeft: 20,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 15,
        marginLeft: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    onboardContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '24px 16px',
      minHeight: 0,

      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
      [theme.breakpoints.up('md')]: {
        padding: 32,
      },
      '& .migrate > .paper-header': {
        backgroundColor: bgColorLightGrey2,
        color: colorPrimary,
      },
      '& .migrate-decline': {
        backgroundColor: colorDanger,
        marginRight: 12,
        '&:hover': {
          backgroundColor: colorDangerDark,
        },
      },
    },
    headerUserDropdown: {
      color: colorPrimary,
      fontWeight: 600,
      fontSize: 14,
      marginLeft: 'auto',

      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },

      '& .avatar': {
        fontSize: 14,
        marginRight: 7,

        [theme.breakpoints.up('sm')]: {
          fontSize: 16,
          marginRight: 7,
        },
        [theme.breakpoints.down('xs')]: {
          width: 30,
          height: 30,
        },
      },
    },
    headerUserDropdownPopper: {
      zIndex: theme.zIndex.drawer + 2,
    },
    headerSavingBox: {
      color: colorSecondary,
      marginRight: 8,

      [theme.breakpoints.up('sm')]: {
        marginRight: 16,
      },
      '& svg': {
        fontSize: 16,
        marginRight: 5,

        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          marginRight: 7,
        },
      },
    },
    headerUserDropdownText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    headerUserDropdownSubText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
    impersonateButton: {
      minWidth: '40px',
      minHeight: '40px',
      marginRight: '8px',
      border: `1px solid ${colorGrey1}`,
      fill: colorGrey,
      '& path': {
        fill: colorGrey,

        '& > .impersonate': {
          fill: `${colorRose3} !important`,
        },
      },
      '&  .MuiButton-startIcon': {
        margin: 0,
      },
      '&.impersonate': {
        border: `1px solid ${colorRose3}`,
        '& path': {
          fill: colorRose3,
        },
      },
    },
  });
