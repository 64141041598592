/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';

// Theme provide
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { GlobalStyle } from 'styles/global-styles';

// Import custom Providers
import { SnackbarsProvider } from 'app/components/snackbars';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import './locales/i18n';

// Load configs
import theme from 'configs/theme';

//Modal
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { store } from 'app/config/store';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const queryConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

const queryClient = new QueryClient(queryConfig);
interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <HelmetProvider>
      {/* MuiThemeProvider makes the theme available down the React tree thanks to React context. */}
      <MuiThemeProvider theme={theme}>
        <SnackbarsProvider>
          <React.StrictMode>
            <QueryClientProvider client={queryClient}>
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <ModalProvider rootComponent={TransitionGroup}>
                  <Component />
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  <GlobalStyle />
                </ModalProvider>
              </MuiPickersUtilsProvider>
            </QueryClientProvider>
          </React.StrictMode>
        </SnackbarsProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  </Provider>
);
const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], async () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = (await import('./app')).App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
