import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorPrimary,
  colorPrimaryLight2,
  colorPrimaryExtraLight3,
  textGrey,
  colorWhite,
  transition,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    tabsWrapper: {
      marginBottom: 30,
    },
    tabsInnerLevel: {
      '&, & > .tabs-content-block': {
        marginTop: 15,
      },
    },
    tabsRoot: {
      backgroundColor: colorPrimaryLight2,
      color: colorWhite,
      position: 'relative',
      zIndex: 1,
      boxShadow: '0px 5px 6px -4px rgba(0, 0, 0, 0.25)',
    },
    tabsIndicator: {
      backgroundColor: colorWhite,
    },
    tabRoot: {
      color: colorPrimaryExtraLight3,
      opacity: 0.5,
      transitionProperty: 'opacity',
      transition,

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      '&:hover': {
        opacity: 0.8,
      },
    },
    tabSelected: {
      opacity: 1,
    },
    tabLabelWrapper: {
      flexDirection: 'row',
    },
    tabLabel: {
      textTransform: 'capitalize',
    },
    tabsContentBlock: {},

    //Color
    primaryTabsColor: {},
    primaryTabColor: {},
    secondaryTabsColor: {
      backgroundColor: colorWhite,

      '& $tabsIndicator': {
        backgroundColor: colorPrimary,
      },
    },
    secondaryTabColor: {
      color: textGrey,

      '&$tabSelected': {
        color: colorPrimary,
      },
    },
  });
