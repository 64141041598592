import { useMutation, useQuery } from 'react-query';

import { apiClient } from 'app/config/axios';

import { getEntityFieldUpdateEndpoint } from '../components/utils';
import { PlanInfoI, PaymentIntentI, IsPaidUserI } from '../constants';

const endpoint = '/signature-user-install';
const installDetailsEndpoint = '/signature-user-install-clients';
const onboardingEndpoint = '/onboarding';
const signatureDataEndpoint = '/timeplate-versions/onboarding/onboarding-data';
const shareSignatureEndpoint = '/shares/trial';
const shareDataEndpoint = '/shares/info';
const reSendInvitationEndpoint = '/shares/re-send';
const paymentEndpoint = '/payments';
const usersEndpoint = '/users';

export interface setUpSignatureInstallInterface {
  trialUserId: number;
  os: string;
  software: string;
  platform: string | null;
  browser: string | null;
  desktopVersion: string | null;
  email: string | null;
}

export const setUpSignatureInstall = (data: setUpSignatureInstallInterface) =>
  apiClient.post(endpoint, data);

export const useSetUpSignatureInstall = (options?) =>
  useMutation(['setUpSignatureInstall'], setUpSignatureInstall, { ...options });

export const getInstallDetails = (userId: number) =>
  apiClient.post(`${installDetailsEndpoint}/installationDetails`, { userId });

export const shareSignature = ({
  id,
  ...data
}): Promise<{ success: boolean }> =>
  apiClient.post(`${shareSignatureEndpoint}/${id}`, data);

export const reSendInvitation = ({ data }): Promise<{ success: boolean }> =>
  apiClient.post(`${reSendInvitationEndpoint}/${data.id}`);

export const removeTrialUser = (id: number): Promise<{ success: boolean }> =>
  apiClient.delete(`${shareSignatureEndpoint}/${id}`);

export const useGetInstallDetails = (options?) =>
  useMutation(['getInstallDetails'], getInstallDetails, { ...options });

export const useShareSignature = (options?) =>
  useMutation(['shareSignature'], shareSignature, { ...options });

export const useReSendInvitation = (options?) =>
  useMutation(['reSendInvitation'], reSendInvitation, { ...options });

export const useRemoveTrialUser = (options?) =>
  useMutation(['removeTrialUser'], removeTrialUser, { ...options });

export const checkExtensionToken = (
  userId: number,
): Promise<{ hasToken: boolean }> =>
  apiClient.get(`${onboardingEndpoint}/extensionToken/user/${userId}`);

export const useCheckExtensionToken = (userId: number, options?) =>
  useQuery<{ hasToken: boolean }, string>(
    ['userExtensionToken'],
    () => checkExtensionToken(Number(userId)),
    { ...options },
  );

export const uninstallSignatureUser = (userId: number) =>
  apiClient.delete(`${endpoint}/deleteInstall/${userId}`);

export const useUninstallSignatureUser = (userId: number) =>
  useMutation(['uninstallSignatureUser'], () => uninstallSignatureUser(userId));

export const getSignatureData = timeplateVersionId =>
  apiClient.get(`${signatureDataEndpoint}/${timeplateVersionId}`);

export const useGetSignatureData = (timeplateVersionId: number, options?) =>
  useQuery(['trialSignatureData'], () => getSignatureData(timeplateVersionId), {
    ...options,
  });

export const getSharesData = (): Promise<{
  availableSpots: number;
  activeShares: Array<Record<string, any>>;
  usedEmails: Array<string>;
}> => apiClient.get(shareDataEndpoint);

export const getPlansData = (): Promise<PlanInfoI> =>
  apiClient.get(`${paymentEndpoint}/plans`);

export const checkIsPaidUser = (): Promise<IsPaidUserI> =>
  apiClient.get(`${paymentEndpoint}/is-paid`);

export const getPaymentIntent = (data): Promise<PaymentIntentI> =>
  apiClient.post(`${paymentEndpoint}/payment-intent`, data);

export const markUserAsWaitingForPaid = (): Promise<any> =>
  apiClient.post(`${paymentEndpoint}/waiting`);

export const initializeTrialUser = (): Promise<any> =>
  apiClient.post(`${usersEndpoint}/initialize`);

export const useGetSharesData = (options?) =>
  useQuery<
    {
      availableSpots: number;
      activeShares: Array<Record<string, any>>;
      usedEmails: Array<string>;
    },
    string
  >(['shareData'], () => getSharesData(), {
    ...options,
  });

export const useGetPlansData = (options?) =>
  useQuery<PlanInfoI, string>(['plansData'], () => getPlansData(), {
    ...options,
  });

export const useCheckIsPaidUser = (options?) =>
  useQuery<IsPaidUserI, string>(['isPaidUser'], () => checkIsPaidUser(), {
    ...options,
  });

export const useGetPaymentIntent = (options?) =>
  useMutation<PaymentIntentI, any, any>(
    ['getPaymentIntent'],
    getPaymentIntent,
    {
      ...options,
    },
  );

export const useMarkUserAsWaitingForPaid = (options?) =>
  useMutation(['markUserAsWaitingForPaid'], markUserAsWaitingForPaid, {
    ...options,
  });

export const updateTrialSignatureField = (
  entity: string,
  id: number,
  value: any,
  isImage,
  isCircleProperty = false,
) => {
  const entityEndpoint = getEntityFieldUpdateEndpoint(entity);
  return apiClient.patch(`${entityEndpoint}/${id}`, {
    ...(isImage
      ? { fileId: value }
      : isCircleProperty
      ? { isCircle: value }
      : { value }),
  });
};

export const useUpdateTrialSignatureField = (options?) =>
  useMutation<
    any,
    {
      entity: string;
      id: number;
      value: any;
      isImage: boolean;
      isCircleProperty?: boolean;
    },
    any
  >(
    ({ entity, id, value, isImage, isCircleProperty }) =>
      updateTrialSignatureField(entity, id, value, isImage, isCircleProperty),
    { ...options },
  );

export const useInitializeTrialUser = (options?) =>
  useMutation(['initializeTrialUser'], initializeTrialUser, {
    ...options,
  });
