import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { Breadcrumbs } from 'app/components/ui//Breadcrubms';
import { BreadcrumbsOption } from 'app/components/ui/Breadcrubms/types';

import styles from '../styles/page';

const useStyles = makeStyles(styles);

export interface Props {
  readonly children: React.ReactNode;
  readonly title?: string | React.ReactNode;
  readonly titleIcon?: React.ReactNode;
  readonly titleVariant?: 'h1' | 'h2' | 'h3' | 'h4';
  readonly titleAction?: string | React.ReactNode;
  readonly headerAction?: React.ReactNode;
  readonly breadcrumbs?: BreadcrumbsOption[];
}

const Page = ({
  children,
  title,
  titleIcon,
  titleVariant = 'h4',
  titleAction,
  headerAction,
  breadcrumbs,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {(title || headerAction || breadcrumbs) && (
        <div
          className={cx(
            classes.pageHeader,
            headerAction ? classes.pageHeaderWithAction : '',
          )}
        >
          {breadcrumbs && <Breadcrumbs options={breadcrumbs} />}
          {title && (
            <Typography
              className={classes.pageHeaderTitle}
              variant={titleVariant}
              component="h2"
            >
              {titleIcon && (
                <div className={classes.pageHeaderTitleIcon}>{titleIcon}</div>
              )}
              <div className={classes.pageHeaderTitleText}>{title}</div>
              {titleAction && (
                <div className={classes.pageHeaderTitleAction}>
                  {titleAction}
                </div>
              )}
            </Typography>
          )}
          {headerAction && (
            <div className={classes.pageHeaderAction}>{headerAction}</div>
          )}
        </div>
      )}
      {children}
    </>
  );
};

export default memo(Page);
