import { USER_SIGNATURES_STATUSES } from 'app/components/entities/queue/types';
import { ReactComponent as superAdminIcon } from 'images/icon/super-admin.svg';
import { ReactComponent as accountHolderIcon } from 'images/icon/account-holder.svg';
import { ReactComponent as accountHolderPrimaryIcon } from 'images/icon/account-holder-primary.svg';
import { ReactComponent as companyAdminIcon } from 'images/icon/company-admin.svg';
import { ReactComponent as companyAdminPrimaryIcon } from 'images/icon/company-admin-primary.svg';
import { ReactComponent as locationAdminIcon } from 'images/icon/location-admin.svg';
import { ReactComponent as locationAdminPrimaryIcon } from 'images/icon/location-admin-primary.svg';
import { ReactComponent as billingAdminIcon } from 'images/icon/billing-admin.svg';
import { ReactComponent as signatureUserIcon } from 'images/icon/signature-user.svg';
import { ReactComponent as companyLocationUserIcon } from 'images/icon/company-location-user.svg';

export enum TYPES {
  PUBLIC = 'public',
  INACTIVE = 'inactive',
  DUMMY = 'dummy',
  HIDDEN = 'hidden',
  DELETED = 'deleted',
  TRIAL = 'trial',
  PAID = 'paid',
}

export enum ROLES {
  SUPER_ADMIN = 'superAdmin',
  GHOST_USER = 'ghostUser',
  ACCOUNT_HOLDER_PRIMARY = 'accountHolderPrimary',
  ACCOUNT_HOLDER = 'accountHolder',
  COMPANY_ADMIN = 'companyAdmin',
  COMPANY_ADMIN_PRIMARY = 'companyAdminPrimary',
  SIGNATURE_USER = 'signatureUser',
  TRIAL_USER = 'trialUser',
  ANONYMOUS = 'anonymous',
  BILLING_ADMIN = 'billingAdmin',
  LOCATION_ADMIN = 'locationAdmin',
  LOCATION_ADMIN_PRIMARY = 'locationAdminPrimary',
  COMPANY_USER = 'companyUser',
  LOCATION_USER = 'locationUser',
}

export const ROLES_ORDER = [
  ROLES.SUPER_ADMIN,
  ROLES.ACCOUNT_HOLDER_PRIMARY,
  ROLES.ACCOUNT_HOLDER,
  ROLES.BILLING_ADMIN,
  ROLES.COMPANY_ADMIN_PRIMARY,
  ROLES.COMPANY_ADMIN,
  ROLES.LOCATION_ADMIN_PRIMARY,
  ROLES.LOCATION_ADMIN,
  ROLES.SIGNATURE_USER,
  ROLES.COMPANY_USER,
  ROLES.LOCATION_USER,
];

export const ROLES_IDS = {
  SUPER_ADMIN: 1,
  GHOST_USER: 2,
  ACCOUNT_HOLDER: 3,
  COMPANY_ADMIN: 4,
  SIGNATURE_USER: 5,
  TRIAL_USER: 6,
  COMPANY_ADMIN_PRIMARY: 7,
  BILLING_ADMIN: 8,
  LOCATION_ADMIN: 9,
  ACCOUNT_HOLDER_PRIMARY: 10,
  LOCATION_ADMIN_PRIMARY: 11,
  COMPANY_USER: 12,
  LOCATION_USER: 13,
};

export interface UserCompany {
  id: number;
  name: string;
}

export interface UserLocation {
  id: number;
  name: string;
  companyId?: number;
}

export interface UserRole {
  id: number;
  name: string;
  roleId: number;
}

export interface UserRoles {
  name: string;
  company: UserCompany;
  location: UserLocation;
  role: UserRole;
  companyId?: number;
  locationId?: number;
}

export const ROLES_AVATAR_COLOR = {
  [ROLES.SUPER_ADMIN]: 'role1B',
  [ROLES.GHOST_USER]: 'role1',
  [ROLES.ACCOUNT_HOLDER_PRIMARY]: 'role2B',
  [ROLES.ACCOUNT_HOLDER]: 'role2',
  [ROLES.COMPANY_ADMIN]: 'role5',
  [ROLES.COMPANY_ADMIN_PRIMARY]: 'role5B',
  [ROLES.LOCATION_ADMIN]: 'role6',
  [ROLES.LOCATION_ADMIN_PRIMARY]: 'role6B',
  [ROLES.SIGNATURE_USER]: 'role3',
  [ROLES.TRIAL_USER]: 'role4',
  [ROLES.BILLING_ADMIN]: 'role2',
  [ROLES.COMPANY_USER]: 'role8B',
  [ROLES.LOCATION_USER]: 'role8B',
  [USER_SIGNATURES_STATUSES.ACTIVE]: 'role3',
  [USER_SIGNATURES_STATUSES.PROCESSED]: 'role8B',
  [USER_SIGNATURES_STATUSES.QUEUED]: 'role8B',
};

export const ROLES_TITLE = {
  [ROLES.SUPER_ADMIN]: 'Super Admin',
  [ROLES.GHOST_USER]: 'Ghost User',
  [ROLES.ACCOUNT_HOLDER_PRIMARY]: 'Primary Account Holder',
  [ROLES.ACCOUNT_HOLDER]: 'Account Holder',
  [ROLES.COMPANY_ADMIN]: 'Company Admin',
  [ROLES.COMPANY_ADMIN_PRIMARY]: 'Primary Company Admin',
  [ROLES.SIGNATURE_USER]: 'Signature User',
  [ROLES.TRIAL_USER]: 'Trial User',
  [ROLES.BILLING_ADMIN]: 'Billing Admin',
  [ROLES.LOCATION_ADMIN]: 'Location Admin',
  [ROLES.LOCATION_ADMIN_PRIMARY]: 'Primary Location Admin',
  [ROLES.COMPANY_USER]: 'Company User',
  [ROLES.LOCATION_USER]: 'Location User',
};

export const ROLES_ICONS = {
  [ROLES.SUPER_ADMIN]: superAdminIcon,
  [ROLES.ACCOUNT_HOLDER_PRIMARY]: accountHolderPrimaryIcon,
  [ROLES.ACCOUNT_HOLDER]: accountHolderIcon,
  [ROLES.COMPANY_ADMIN_PRIMARY]: companyAdminPrimaryIcon,
  [ROLES.COMPANY_ADMIN]: companyAdminIcon,
  [ROLES.LOCATION_ADMIN_PRIMARY]: locationAdminPrimaryIcon,
  [ROLES.LOCATION_ADMIN]: locationAdminIcon,
  [ROLES.BILLING_ADMIN]: billingAdminIcon,
  [ROLES.SIGNATURE_USER]: signatureUserIcon,
  [ROLES.COMPANY_USER]: companyLocationUserIcon,
  [ROLES.LOCATION_USER]: companyLocationUserIcon,
};

export const ROLES_ICONS_VIEWBOX = {
  [ROLES.SUPER_ADMIN]: '0 0 25 15',
  [ROLES.ACCOUNT_HOLDER_PRIMARY]: '0 0 24 24',
  [ROLES.ACCOUNT_HOLDER]: '0 0 23 19',
  [ROLES.COMPANY_ADMIN_PRIMARY]: '0 0 16 21',
  [ROLES.COMPANY_ADMIN]: '0 0 15 21',
  [ROLES.LOCATION_ADMIN_PRIMARY]: '0 0 16 21',
  [ROLES.LOCATION_ADMIN]: '0 0 15 21',
  [ROLES.BILLING_ADMIN]: '0 0 15 21',
  [ROLES.SIGNATURE_USER]: '0 0 23 19',
  [ROLES.COMPANY_USER]: '0 0 18 21',
  [ROLES.LOCATION_USER]: '0 0 18 21',
};

export const ROLES_STATUSES_TITLE = {
  [USER_SIGNATURES_STATUSES.ACTIVE]: 'S U',
  [USER_SIGNATURES_STATUSES.QUEUED]: 'Signature User Queued',
  [USER_SIGNATURES_STATUSES.PROCESSED]: 'Signature User Processed',
  [USER_SIGNATURES_STATUSES.PAUSED]: 'PSU',
};

export const TO_ASSIGN_ROLES_TITLE = {
  [ROLES.ACCOUNT_HOLDER_PRIMARY]: 'Primary Account Holder',
  [ROLES.ACCOUNT_HOLDER]: 'Account Holder',
  [ROLES.COMPANY_ADMIN_PRIMARY]: 'Primary Company Admin',
  [ROLES.COMPANY_ADMIN]: 'Company Admin',
  [ROLES.LOCATION_ADMIN_PRIMARY]: 'Primary Location Admin',
  [ROLES.LOCATION_ADMIN]: 'Location Admin',
  [ROLES.BILLING_ADMIN]: 'Billing Admin',
};
