import { ApiError, apiClient } from 'app/config/axios';

import objectToFormData from 'utils/objectToFormData';
import { TimeplateField } from '../interfaces';
import { PaginatedResponse } from 'app/interfaces';
import {
  QueryOptions,
  UseQueryResult,
  useQuery,
  QueryObserverOptions,
} from 'react-query';

const endpoint = '/timeplate-fields';

export const timeplateFieldUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, TimeplateField>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const timeplateFieldsIndex = queryData =>
  apiClient.get<any, PaginatedResponse<TimeplateField>>(endpoint, {
    params: queryData,
  });

export const useTimeplateFieldsIndex: <T = PaginatedResponse<TimeplateField>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useTimeplateFieldsIndex', any], any>(
    ['useTimeplateFieldsIndex', queryData],
    () => timeplateFieldsIndex(queryData),
    options,
  );

export const useTimeplateFieldsFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<TimeplateField>, ApiError>,
): {
  data: PaginatedResponse<TimeplateField> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useTimeplateFieldsIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};
