import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

import { Tab } from '../types';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends Omit<TabsProps, 'onChange'> {
  tabs: Tab[];
  onChange: (event: React.ChangeEvent<object>, value: number | string) => void;
  color?: 'primary' | 'secondary';
  inner?: boolean;
  className?: string;
}

const Tabs: React.FC<Props> = ({
  tabs,
  value,
  onChange,
  variant = 'scrollable',
  color = 'primary',
  inner,
  className,
}) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.tabsWrapper, className, {
        [classes.tabsInnerLevel]: inner,
      })}
    >
      <MuiTabs
        classes={{
          root: cx(classes.tabsRoot, {
            [classes[`${color}TabsColor`]]: color,
          }),
          indicator: classes.tabsIndicator,
        }}
        value={value}
        onChange={onChange}
        variant={variant}
      >
        {tabs.map(({ id, label, tabRootClass, disabled = false }) => {
          return (
            <MuiTab
              classes={{
                root: cx(classes.tabRoot, tabRootClass, {
                  [classes[`${color}TabColor`]]: color,
                }),
                selected: classes.tabSelected,
                wrapper: classes.tabLabelWrapper,
              }}
              label={<span className={classes.tabLabel}>{label}</span>}
              key={id}
              disabled={disabled}
              value={id}
            />
          );
        })}
      </MuiTabs>
      {tabs.map(({ content = '', id, classNameContent, disabled }) => {
        return (
          value === id &&
          !disabled && (
            <div
              className={cx(
                classes.tabsContentBlock,
                'tabs-content-block',
                classNameContent,
              )}
              key={id}
            >
              {content}
            </div>
          )
        );
      })}
    </div>
  );
};

export default memo(Tabs);
