import React, { useEffect } from 'react';
import { useNavigate, RouteProps } from 'react-router-dom';

import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { USER_TYPES } from 'app/interfaces';

const defaultRedirect = '/login';

export const TrialProtectedRoute = (props: RouteProps) => {
  const { isLoading, isAuthorized, user } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized && !isLoading) {
      navigate(defaultRedirect);
    }

    if (user && user?.type !== USER_TYPES.TRIAL) {
      navigate(`/`);
    }
  }, [navigate, isAuthorized, isLoading, user]);

  if (isLoading) {
    return null;
  }

  return <>{props.children}</>;
};
