import { Theme } from '@material-ui/core/styles';

// Variables - Styles
const fontFamily = '"Poppins", Arial, Helvetica, sans-serif';
const boxShadow = {
  boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.25)',
};
const boxShadowSmall = {
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
};
const boxShadowExtraSmall = {
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
};
const transitionEase = '0.1s ease-in-out';
const transition = '0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)';
const transitionAll = {
  transition: 'all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const layoutHorizontalPadding = (theme: Theme) => ({
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

// Sizes
const containerMaxWidth = '100%';

const drawerWidth = 298;
const drawerMinimizeWidth = 80;

const textFieldHorizontalSize = 15;
const textFieldVerticalSize = 19;
const textFieldHeight = 36;

const borderRadiusSmallSize = 3;
const borderRadiusSize = 5;
const borderRadiusLargeSize = 10;
const borderRadiusEllipse = 24;

const ctaButtonWidth = 200;
const buttonSize = 48;
const buttonSizeSmall = 42;

const dialogHorizontal = 16;
const dialogVertical = 16;
const dialogHorizontalMobile = 20;
const dialogVerticalMobile = 14;

// System Color
const colorPrimary = '#311B92';
const colorPrimary2 = '#7F56D9';
const colorPrimaryDark = '#221266';
const colorPrimaryDisable = '#B39DDB';
const colorPrimary300 = '#9575CD';
const colorPrimary500 = '#9E77ED';
const colorPrimary700 = '#6941C6';
const colorPrimary900 = '#42307D';
const colorPrimaryLight = '#7E57C2';
const colorPrimaryLight2 = '#673AB7';
const colorPrimaryLight3 = '#8376BD';
const colorPrimaryLight4 = '#B692F6';
const colorPrimaryExtraLight = '#ADA4D3';
const colorPrimaryExtraLight2 = '#F6F0FF';
const colorPrimaryExtraLight3 = '#F2E7FE';

const colorSecondary = '#43A047';
const colorSecondary2 = '#079455';
const colorSecondaryDark = '#2E7D32';
const colorSecondaryDisable = '#A5D6A7';
const colorSecondary300 = '#75E0A7';
const colorBlue = '#409CF9';
const colorBlue2 = '#2993FF';
const colorBlue3 = '#63AFFC';
const colorBlue4 = '#53B1FD';
const colorBlue5 = '#2E90FA';
const colorBlueDark = '#1976D2';
const colorBlueDisable = '#88C0F7';
const colorRose = '#FECDD6';
const colorRose1 = '#FFF5F6';
const colorRose2 = '#FEA3B4';
const colorRose3 = '#FD6F8E';
const colorDanger = '#DF2935';
const colorDangerDark = '#C12834';
const colorSuccess = '#1B5E20';
const colorGreen = '#04A293';
const colorWarning = '#DEA54B';
const colorWhite = '#FFF';
const colorPurple = '#8376BE';
const colorPink = '#FF99C8';
const colorPinkDark = '#D776A1';
const colorPinkDark2 = '#E086B0';
const colorPinkDark3 = '#86506A';
const colorPinkExtraDark = '#803357';
const colorMelon = '#FFB7A8';
const colorYellow = '#DEA54B';
const colorYellowExtraDark = '#7D6643';
const colorYellowExtraDark2 = '#AF7D2D';
const colorYellowLight = '#fffbf3';
const colorYellowLight2 = '#FBD69B';
const colorYellowLight3 = '#FDB022';
const colorDark = '#202020';
const colorGrey = '#626262';
const colorGrey1 = '#9AA4B2';
const colorDisable = '#D6BBFB';

// Background Color
const bgBody = '#F7F9FF';
const bgAuthorization = '#4527A0';
const bgColorDanger = '#FFF6F7';
const bgColorSuccess = '#F5FFF6';
const bgColorWarning = '#FFF8EC';
const bgColorBlue = '#F6FBFF';
const bgColorDarkGrey = '#1F201D';
const bgColorLightGrey = '#F6F6F6';
const bgColorLightGrey2 = '#FAFAFA';
const bgColorLightGrey3 = '#E0E0E0';
const bgOverlay = 'rgba(73,66,104,0.8)';
const bgTooltip = '#101828';
const bgOnboardingHeader = '#F9F5FF';
const bgOnboardingActive = '#6941C6';
const bgInviteBlock = '#EFF8FF';

// Button Colors
const buttonColorPrimary = '#7F56D9';
const buttonColorPrimaryDark = '#6941C6';
const buttonColorPrimaryDisable = '#E9D7FE';
const buttonOutColorPrimaryDark = '#53389E';
const buttonOutColorPrimaryDisable = '#D6BBFB';

const buttonColorSecondary = '#079455';
const buttonColorSecondaryDark = '#067647';
const buttonColorSecondaryDisable = '#ABEFC6';
const buttonOutColorSecondaryDark = '#085D3A';
const buttonOutColorSecondaryDisable = '#75E0A7';

const buttonColorBlue = '#2E90FA';
const buttonColorBlueDark = '#1570EF';
const buttonColorBlueDisable = '#B2DDFF';
const buttonOutColorBlueDark = '#1849A9';
const buttonOutColorBlueDisable = '#84CAFF';

const buttonColorPink = '#FF99C8';
const buttonColorPinkDark = '#D776A1';

const buttonColorDanger = '#D92D20';
const buttonColorDangerDark = '#B42318';
const buttonColorDangerDisable = '#FECDCA';
const buttonOutColorDangerDark = '#912018';
const buttonOutColorDangerDisable = '#FDA29B';

const buttonColorPrimaryLight = '#7E57C2';
const buttonColorPrimaryLight2 = '#673AB7';

// Text Color
const textPrimary = '#202020';
const textSecondary = '#626262';
const textGrey = '#999999';
const textDisabled = '#9E9E9E';
const textDefault = '#344054';

// Badge
const badgePrimary = '#7F56D9';
const badgeSecondary = '#079455';
const badgeBlue = '#1570EF';
const badgePink = '#DD2590';
const badgeYellow = '#CA8504';
const badgeDanger = '#D92D20';
const badgeMelon = '#DC6803';

const badgeBgDefault = '#D0D5DD';
const badgeBgPrimary = '#E9D7FE';
const badgeBgSecondary = '#ABEFC6';
const badgeBgBlue = '#B2DDFF';
const badgeBgPink = '#FCCEEE';
const badgeBgYellow = '#FEEE95';
const badgeBgDanger = '#FECDCA';
const badgeBgMelon = '#FEDF89';

const badgeOutPrimary = '#6941C6';
const badgeOutSecondary = '#067647';
const badgeOutBlue = '#175CD3';
const badgeOutPink = '#C11574';
const badgeOutYellow = '#A15C07';
const badgeOutDanger = '#B42318';
const badgeOutMelon = '#B54708';

//Checkbox
const checkBoxPrimary = '#7F56D9';
const checkBoxSecondary = '#079455';
const checkBoxPrimaryBg = '#F4EBFF';
const checkBoxSecondaryBg = '#DCFAE6';
const checkBoxBgDisabled = '#F2F4F7';
const checkBoxBorderDisabled = 'rgba(0, 0, 0, 0.26)';

//Radio
const radioPrimary = '#7F56D9';
const radioSecondary = '#079455';
const radioPrimaryBg = '#F4EBFF';
const radioSecondaryBg = '#DCFAE6';
const radioBgDisabled = '#F2F4F7';
const radioBorderDisabled = 'rgba(0, 0, 0, 0.26)';

//Switch
const switchPrimary = '#7F56D9';
const switchSecondary = '#079455';
const switchBg = '#EAECF0';

// Avatar Colors
const avatarColors = [
  '#FF99C8',
  '#FFB7A8',
  '#AA8CFF',
  '#DEA54B',
  '#409CF9',
  '#A5D6A7',
];
const borderAvatar = '#101828';
const bgAvatar = '#F2F4F7';

// Table
const tableBorderColor = '#C5C5C5';
const tableBackground = '#FFF';
const tableBackgroundLight = '#F6F6F6';
const tableMaxIndent = 23;

// Border Color
const borderPrimaryColor = '#E0E0E0';
const borderGreyColor = '#707070';

export {
  fontFamily,
  boxShadow,
  boxShadowSmall,
  boxShadowExtraSmall,
  transition,
  transitionEase,
  transitionAll,
  layoutHorizontalPadding,
  // Sizes
  containerMaxWidth,
  drawerWidth,
  drawerMinimizeWidth,
  textFieldHorizontalSize,
  textFieldVerticalSize,
  textFieldHeight,
  borderRadiusSmallSize,
  borderRadiusSize,
  borderRadiusLargeSize,
  borderRadiusEllipse,
  ctaButtonWidth,
  buttonSize,
  buttonSizeSmall,
  dialogHorizontal,
  dialogHorizontalMobile,
  dialogVertical,
  dialogVerticalMobile,
  // System Color
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryDisable,
  colorPrimary300,
  colorPrimary700,
  colorPrimaryLight,
  colorPrimaryLight2,
  colorPrimaryLight3,
  colorPrimaryExtraLight,
  colorPrimaryExtraLight2,
  colorPrimaryExtraLight3,
  colorSecondary,
  colorSecondaryDark,
  colorSecondaryDisable,
  colorSecondary300,
  colorBlue,
  colorBlueDark,
  colorBlueDisable,
  colorBlue2,
  colorBlue3,
  colorDanger,
  colorDangerDark,
  colorSuccess,
  colorGreen,
  colorWarning,
  colorWhite,
  colorPurple,
  colorPink,
  colorPinkDark,
  colorPinkDark2,
  colorPinkDark3,
  colorPinkExtraDark,
  colorMelon,
  colorYellow,
  colorYellowExtraDark,
  colorYellowExtraDark2,
  colorYellowLight,
  colorYellowLight2,
  colorYellowLight3,
  colorDark,
  colorGrey,
  colorGrey1,
  colorRose,
  colorRose1,
  colorRose2,
  colorRose3,
  colorDisable,
  colorSecondary2,
  colorPrimary2,
  colorPrimaryLight4,
  colorPrimary500,
  colorPrimary900,
  colorBlue4,
  colorBlue5,
  // Background Color
  bgBody,
  bgAuthorization,
  bgColorDanger,
  bgColorSuccess,
  bgColorWarning,
  bgColorBlue,
  bgColorDarkGrey,
  bgColorLightGrey,
  bgColorLightGrey2,
  bgColorLightGrey3,
  bgOverlay,
  bgTooltip,
  bgOnboardingHeader,
  bgOnboardingActive,
  bgInviteBlock,
  // Text Color
  textPrimary,
  textSecondary,
  textGrey,
  textDisabled,
  textDefault,
  // Avatar Colors
  avatarColors,
  borderAvatar,
  bgAvatar,
  // Table
  tableBorderColor,
  tableBackground,
  tableBackgroundLight,
  tableMaxIndent,
  //Badge
  badgeBgDefault,
  badgeBgPrimary,
  badgeBgSecondary,
  badgeBgBlue,
  badgeBgPink,
  badgeBgYellow,
  badgeBgDanger,
  badgeBgMelon,
  badgePrimary,
  badgeSecondary,
  badgeBlue,
  badgePink,
  badgeYellow,
  badgeDanger,
  badgeMelon,
  badgeOutPrimary,
  badgeOutSecondary,
  badgeOutBlue,
  badgeOutPink,
  badgeOutYellow,
  badgeOutDanger,
  badgeOutMelon,
  // Border Color
  borderPrimaryColor,
  borderGreyColor,
  //CheckBox
  checkBoxPrimary,
  checkBoxSecondary,
  checkBoxPrimaryBg,
  checkBoxSecondaryBg,
  checkBoxBgDisabled,
  checkBoxBorderDisabled,
  //Radio
  radioPrimary,
  radioSecondary,
  radioPrimaryBg,
  radioSecondaryBg,
  radioBgDisabled,
  radioBorderDisabled,
  // Switch
  switchPrimary,
  switchSecondary,
  switchBg,
  // Button
  buttonColorPrimary,
  buttonColorPrimaryDark,
  buttonOutColorPrimaryDark,
  buttonColorPrimaryDisable,
  buttonColorSecondary,
  buttonOutColorBlueDark,
  buttonColorSecondaryDark,
  buttonOutColorPrimaryDisable,
  buttonOutColorBlueDisable,
  buttonOutColorSecondaryDark,
  buttonColorSecondaryDisable,
  buttonOutColorSecondaryDisable,
  buttonOutColorDangerDark,
  buttonOutColorDangerDisable,
  buttonColorBlue,
  buttonColorBlueDark,
  buttonColorBlueDisable,
  buttonColorPink,
  buttonColorPinkDark,
  buttonColorDanger,
  buttonColorDangerDark,
  buttonColorDangerDisable,
  buttonColorPrimaryLight,
  buttonColorPrimaryLight2,
};
