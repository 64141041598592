import React, {
  memo,
  useCallback,
  ReactNode,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  Autocomplete,
  FilterOptionsState,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';

import Option from './Option';

import SavingContext from 'app/components/layouts/components/SavingContext';
import { ReactComponent as crossIcon } from 'images/icon/FontAwesome5Pro/Light/cross.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Options {
  id: string | number;
  name: string | ReactNode;
  onClick?: () => void;
  optionType?: 'button';
  isDisabled?: boolean;
}

export interface Props {
  name: string;
  label?: string;
  onInputChange?: (value: string) => void;
  options?: Options[];
  onChange?: (value: Options | null | object) => void;
  multiple?: boolean;
  freeSolo?: boolean;
  filterOptions?: (
    options: Options[],
    state: FilterOptionsState<Options>,
  ) => Options[];
  margin?: 'none' | 'dense' | 'normal';
  disableClearable: boolean;
  onSuccessChange?: (
    required: boolean | undefined,
    data: (string | Options)[],
  ) => void;
  required?: boolean;
  startIcon?: React.ReactNode;
  renderOption?: (...props: any) => React.ReactNode;
  customOptionLabel?: (...props: any) => React.ReactNode;
  placeholder?: string;
  className?: string;
  startedNoOptionsText?: string;
  optionsIsLoading?: boolean;
  disabled?: boolean;
}

const getDefaultAutocompleteValue = (multiple: boolean) =>
  multiple ? [] : null;

function AutocompleteField({
  name,
  options = [],
  multiple = false,
  freeSolo = false,
  filterOptions,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInputChange = () => {},
  label = '',
  margin = 'normal',
  disableClearable = false,
  onSuccessChange,
  onChange,
  required = false,
  startIcon,
  renderOption = Option,
  customOptionLabel,
  placeholder = '',
  className,
  startedNoOptionsText = '',
  optionsIsLoading = false,
  disabled = false,
}: Props) {
  const classes = useStyles();
  const [{ value: mainValue }, { error, touched }, { setValue }] =
    useField(name);
  const isError: boolean = useMemo(
    () => Boolean(touched && error),
    [error, touched],
  );
  const [inputValue, setInputValue] = useState('');
  const { setIsSaving } = useContext(SavingContext);

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useEffect(() => {
    if (isError) setIsSaving(false);
  }, [isError, setIsSaving]);

  const autocompleteValue = mainValue || getDefaultAutocompleteValue(multiple);
  if (mainValue === undefined) {
    setValue(autocompleteValue);
  }

  const cleanName = values => {
    return values.map(value => {
      if (
        typeof value === 'object' &&
        value.hasOwnProperty('id') &&
        typeof value.id !== 'number' &&
        value.hasOwnProperty('name')
      ) {
        return {
          ...value,
          name: value.name.replace('Add: ', '').replace(/["]+/g, '').trim(),
        };
      }
      return value;
    });
  };

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<object>,
      value: Array<Options>,
      type: AutocompleteChangeReason,
      reason: AutocompleteChangeDetails<Options>,
    ) => {
      if (freeSolo && multiple) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const { optionType, onClick = () => {} } = reason?.option || {};
        const normalizedValues = cleanName(value);
        const lastValue = normalizedValues.pop();

        if (
          reason.hasOwnProperty('option') &&
          typeof reason.option === 'string'
        )
          return;

        if (optionType === 'button' && type === 'select-option') {
          onClick();
        } else {
          const valuesFilteredByLast = normalizedValues.filter(
            value => value.name !== lastValue.name,
          );
          if (
            lastValue != null &&
            valuesFilteredByLast.length === normalizedValues.length
          ) {
            valuesFilteredByLast.push(lastValue);
          }
          setValue(valuesFilteredByLast);
        }

        if (onSuccessChange)
          onSuccessChange(
            false,
            value.map(option =>
              typeof option.id === 'number' ? option : option.id,
            ),
          );
      } else {
        onChange && onChange(value);
        setValue(value);
      }
      if (onSuccessChange) setIsSaving(true);
    },
    [freeSolo, multiple, onChange, onSuccessChange, setIsSaving, setValue],
  );

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<object>,
      value: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (reason === 'input') {
        setInputValue(value);
        onInputChange(value);
      }
    },
    [onInputChange],
  );

  const getOptionLabel = useCallback(
    option => {
      if (!option?.name) {
        return '';
      }
      if (customOptionLabel) {
        return customOptionLabel(option);
      }
      return typeof option.id === 'number' ? option.name : option.id;
    },
    [customOptionLabel],
  );

  const getOptionDisabled = option =>
    multiple
      ? !!autocompleteValue.find(
          ({ id, name }) =>
            [id, name].includes(option.id) || id === option.name,
        )
      : autocompleteValue
      ? autocompleteValue.id === option.id
      : option.isDisabled;

  const noOptionsText = useMemo(
    () =>
      !inputValue && startedNoOptionsText ? startedNoOptionsText : undefined,
    [inputValue, startedNoOptionsText],
  );

  return (
    <Autocomplete
      loading={optionsIsLoading}
      autoHighlight={multiple}
      classes={{
        root: className,
        endAdornment: classes.autocompleteEndAdornment,
        clearIndicator: classes.autocompleteClearIndicator,
        popupIndicator: classes.selectArrowDownIcon,
        focused: 'text-input-focused',
        paper: cx(
          classes.selectPaper,
          'native-custom-scrollbar grey-scrollbar',
        ),
        listbox: 'select-list',
        option: 'select-option',
      }}
      multiple={multiple}
      value={autocompleteValue}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      onInputChange={handleInputChange}
      ChipProps={{
        size: 'medium',
        variant: 'outlined',
        style: { background: 'white' },
        deleteIcon: <SvgIcon component={crossIcon} viewBox="0 0 20 20" />,
      }}
      getOptionDisabled={getOptionDisabled}
      renderInput={params => (
        <TextField
          placeholder={placeholder}
          {...params}
          margin={margin}
          label={label}
          variant="outlined"
          fullWidth
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: 'text-input-wrapper',
            },
            ...(startIcon
              ? {
                  startAdornment: (
                    <InputAdornment
                      className={classes.autocompleteStartAdornment}
                      position="start"
                    >
                      {startIcon}
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          error={isError}
          helperText={isError && error ? error : undefined}
        />
      )}
      noOptionsText={noOptionsText}
      disabled={disabled}
    />
  );
}

export default memo(AutocompleteField);
