import { LINK_TYPES } from 'utils/dataTable';

export enum FIELD_TYPES {
  TEXT,
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  ICON_STYLE,
  ICON_SIZE,
  TOGGLE,
  LINK,
  TEXTAREA,
  DATE,
}

export enum REVERT_OPTIONS {
  ALL_STEPS = 'all_steps',
  ONE_STEP = 'one_step',
}

export enum SETTINGS_TYPES {
  ENABLED,
  FIELD,
}

export const LINK_TYPES_MAP = {
  default: LINK_TYPES.URL,
  tel: LINK_TYPES.PHONE,
  mailto: LINK_TYPES.EMAIL,
  other: LINK_TYPES.OTHER,
  remove_tag: LINK_TYPES.NONE,
};

export enum STATUS {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
}
export enum PANEL_TYPE {
  TRIAL = 'trial',
  ONBOARDING = 'onboarding',
}

export enum CONTENT_TYPE {
  TEXT = 'text',
  MESSAGE = 'message',
}

export const emailRegexCheck = /^([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})$/gi;
export const phoneRegexCheck = /^(\+?[0-9]+)$/g;
export const domainRegexCheck = /^(@[a-z0-9.-]+\.[a-z]{2,4})$/g;
export const trialUserEmailPlaceholder = 'trial.user.email';
export const trialUserTermsAgreementPlaceholder = 'trial.user.termsAgreement';

export const passwordStrengthCheckRegex =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()-=`~.,?<>\/\\|:;'"{}[\]+_])[A-Za-z\d!@#$%^&*()-=`~.,?<>\/\\|:;'"{}[\]+_]{8,}$/g;

export const LINK_FIELD_DEBOUNCE_DELAY = 100;
export const COMMON_DEBOUNCE_DELAY = 1000;

export const emailResendTimer = 60000;

export const watermark = (watermark, isInsideTable) => {
  if (isInsideTable) {
    return (
      '<!--watermark section-->' +
      '             <tr>\n' +
      '                 <td height="18"\n' +
      '                     style="padding: 0;\n' +
      '                            font-size: 12px;\n' +
      '                            line-height: 14px;\n' +
      '                            text-decoration: none;">\n' +
      '                         <span style="font-family: \'Poppins\', Arial, Helvetica, sans-serif; text-decoration: none !important;">' +
      watermark +
      '                         </span>\n' +
      '                 </td>\n' +
      '             </tr>\n' +
      '             <!--watermark section-->\n' +
      '         '
    );
  } else {
    return (
      '<!--watermark section-->' +
      '  <div style="padding: 0;\n' +
      '            font-size: 12px;\n' +
      '            line-height: 14px;\n' +
      '            text-decoration: none;\n' +
      '            height: 18px;">\n' +
      '    <span style="font-family: \'Poppins\', Arial, Helvetica, sans-serif; text-decoration: none !important;">' +
      watermark +
      '    </span>\n' +
      '  </div>\n' +
      '  <!--watermark section-->\n'
    );
  }
};

export const BRANDING_GROUPS: Array<Array<[string, FIELD_TYPES, string?]>> = [
  [['brand.feature.color', FIELD_TYPES.COLOR]],
  [['brand.base.color', FIELD_TYPES.COLOR]],
  [
    ['brand.font.family', FIELD_TYPES.FONT_FAMILY],
    ['brand.font.size', FIELD_TYPES.FONT_SIZE],
  ],
  [
    ['brand.icon.style', FIELD_TYPES.ICON_STYLE],
    ['brand.icon.size', FIELD_TYPES.ICON_SIZE],
    ['brand.icon.original', FIELD_TYPES.TOGGLE],
    ['brand.icon.featurecolor', FIELD_TYPES.COLOR, 'brand.icon.original'],
    ['brand.icon.basecolor', FIELD_TYPES.COLOR, 'brand.icon.original'],
  ],
];

export const TEXT_EDITING_GROUPS: Array<Array<[string, FIELD_TYPES, string?]>> =
  [
    [
      ['company.name.text', FIELD_TYPES.TEXT],
      ['company.name.link', FIELD_TYPES.LINK],
    ],
    [
      ['location.name.text', FIELD_TYPES.TEXT],
      ['location.name.link', FIELD_TYPES.LINK],
    ],
    [
      ['timeplate.name.text', FIELD_TYPES.TEXT],
      ['timeplate.name.link', FIELD_TYPES.LINK],
    ],
    [['first.name.text', FIELD_TYPES.TEXT]],
    [['middle.name.text', FIELD_TYPES.TEXT]],
    [['last.name.text', FIELD_TYPES.TEXT]],
    [['credentials.text', FIELD_TYPES.TEXT]],
    [['title.text', FIELD_TYPES.TEXT]],
    [['birthday.date', FIELD_TYPES.DATE]],
    [
      ['display.name.text', FIELD_TYPES.TEXT],
      ['display.name.link', FIELD_TYPES.LINK],
    ],
    [
      ['salutation.text', FIELD_TYPES.TEXT],
      ['salutation.link', FIELD_TYPES.LINK],
    ],
    [
      ['role.text', FIELD_TYPES.TEXT],
      ['role.link', FIELD_TYPES.LINK],
    ],
    [
      ['tagline.text', FIELD_TYPES.TEXT],
      ['tagline.link', FIELD_TYPES.LINK],
    ],
    [
      ['mobile.text', FIELD_TYPES.TEXT],
      ['mobile.label', FIELD_TYPES.TEXT],
      ['mobile.link', FIELD_TYPES.LINK],
    ],
    [
      ['email.text', FIELD_TYPES.TEXT],
      ['email.label', FIELD_TYPES.TEXT],
      ['email.link', FIELD_TYPES.LINK],
    ],
    [
      ['website.text', FIELD_TYPES.TEXT],
      ['website.label', FIELD_TYPES.TEXT],
      ['website.link', FIELD_TYPES.LINK],
    ],
    [
      ['telephone.text', FIELD_TYPES.TEXT],
      ['telephone.label', FIELD_TYPES.TEXT],
      ['telephone.link', FIELD_TYPES.LINK],
    ],
    [
      ['telephone.ext.text', FIELD_TYPES.TEXT],
      ['telephone.ext.label', FIELD_TYPES.TEXT],
    ],
    [
      ['fax.text', FIELD_TYPES.TEXT],
      ['fax.label', FIELD_TYPES.TEXT],
      ['fax.link', FIELD_TYPES.LINK],
    ],
    [
      ['address.line1.text', FIELD_TYPES.TEXT],
      ['address.line1.label', FIELD_TYPES.TEXT],
      ['address.line1.link', FIELD_TYPES.LINK],
    ],
    [['address.line2.text', FIELD_TYPES.TEXT]],
    [['address.city.text', FIELD_TYPES.TEXT]],
    [['address.state.text', FIELD_TYPES.TEXT]],
    [['address.state.initials.text', FIELD_TYPES.TEXT]],
    [['address.zip.text', FIELD_TYPES.TEXT]],
    [['address.country.text', FIELD_TYPES.TEXT]],
    [['address.country.initials.text', FIELD_TYPES.TEXT]],
    [
      ['postal.line1.text', FIELD_TYPES.TEXT],
      ['postal.line1.label', FIELD_TYPES.TEXT],
      ['postal.line1.link', FIELD_TYPES.LINK],
    ],
    [['postal.line2.text', FIELD_TYPES.TEXT]],
    [['postal.city.text', FIELD_TYPES.TEXT]],
    [['postal.state.text', FIELD_TYPES.TEXT]],
    [['postal.state.initials.text', FIELD_TYPES.TEXT]],
    [['postal.zip.text', FIELD_TYPES.TEXT]],
    [['postal.country.text', FIELD_TYPES.TEXT]],
    [['postal.country.initials.text', FIELD_TYPES.TEXT]],
  ];

export const IMAGES: Array<string> = [
  'photo',
  'logo',
  'primary.image',
  'secondary.image',
  'address.map',
  'postal.map',
  'image1',
  'image2',
  'image3',
  'image4',
  'image5',
  'image6',
  'image7',
  'image8',
  'image9',
  'banner1',
  'banner2',
  'banner3',
  'banner4',
  'banner5',
  'banner6',
  'banner7',
  'banner8',
  'banner9',
];

export const MESSAGES_GROUPS: Array<Array<[string, FIELD_TYPES, string?]>> = [
  [
    ['status.message.text', FIELD_TYPES.TEXTAREA],
    ['status.message.readmoretext', FIELD_TYPES.TEXT],
    ['status.message.readmorelink', FIELD_TYPES.LINK],
  ],
  [
    ['green.text', FIELD_TYPES.TEXTAREA],
    ['green.readmoretext', FIELD_TYPES.TEXT],
    ['green.readmorelink', FIELD_TYPES.LINK],
  ],
  [
    ['custom.message.text', FIELD_TYPES.TEXTAREA],
    ['custom.message.readmoretext', FIELD_TYPES.TEXT],
    ['custom.message.readmorelink', FIELD_TYPES.LINK],
  ],
  [
    ['unsubscribe.messagetext', FIELD_TYPES.TEXTAREA],
    ['unsubscribe.buttontext', FIELD_TYPES.TEXT],
  ],
];

export enum OS {
  WINDOWS = 'Windows',
  MAC_OS = 'MacOS',
  IOS = 'iOS',
  ANDROID = 'Android',
}

export enum SOFTWARE {
  AOL_MAIL = 'AOL Mail',
  EM_CLIENT = 'eM Client',
  FRONT = 'Front',
  GMAIL = 'Gmail',
  OUTLOOK = 'Outlook',
  POLYMAIL = 'Polymail',
  PROTON_MAIL = 'Proton Mail',
  RACKSPACE_EMAIL = 'Rackspace Email',
  MAILBIRD = 'Mailbird',
  MISSIVE = 'Missive',
  POSTBOX = 'Postbox',
  ROUNCUBE = 'Rouncube',
  SPIKE = 'Spike',
  SALESFORCE = 'Salesforce',
  THUNDERBIRD = 'Thunderbird',
  YAHOO_MAIL = 'Yahoo Mail',
  ZOHO_MAIL = 'Zoho Mail',
  AIRMAIL = 'Airmail',
  APPLE_MAIL = 'Apple Mail',
  CANARY_MAIL = 'Canary Mail',
  MAIL_PILOT = 'Mail Pilot',
  NEWTON_MAIL = 'Newton Mail',
  SPARK = 'Spark',
  MAIL = 'Mail',
  AQUA_MAIL = 'Aqua Mail',
}

export enum BROWSER {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  BRAVE = 'Brave',
  SAFARI = 'Safari',
  EDGE = 'Microsoft Edge',
}

export enum PLATFORM {
  WEB = 'Web',
  DESKTOP = 'Desktop',
}

export enum DESKTOP_VERSIONS {
  OUTLOOK_OFFICE_365 = 'Outlook Office 365',
  OUTLOOK_2016 = 'Outlook 2016',
  OUTLOOK_2013 = 'Outlook 2013',
  OUTLOOK_2011 = 'Outlook 2011',
}

export const LEGEND = {
  [OS.WINDOWS]: {
    automaticInstall: true,
    [SOFTWARE.AOL_MAIL]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
    },
    [SOFTWARE.EM_CLIENT]: {
      automaticInstall: false,
    },
    [SOFTWARE.FRONT]: {
      automaticInstall: false,
    },
    [SOFTWARE.GMAIL]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
    },
    [SOFTWARE.OUTLOOK]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
      desktop: [
        DESKTOP_VERSIONS.OUTLOOK_OFFICE_365,
        DESKTOP_VERSIONS.OUTLOOK_2016,
        DESKTOP_VERSIONS.OUTLOOK_2013,
      ],
    },
    [SOFTWARE.POLYMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.PROTON_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.RACKSPACE_EMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.MAILBIRD]: {
      automaticInstall: false,
    },
    [SOFTWARE.MISSIVE]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
    },
    [SOFTWARE.POSTBOX]: {
      automaticInstall: true,
      platform: PLATFORM.DESKTOP,
    },
    [SOFTWARE.ROUNCUBE]: {
      automaticInstall: false,
    },
    [SOFTWARE.SPIKE]: {
      automaticInstall: false,
    },
    [SOFTWARE.SALESFORCE]: {
      automaticInstall: false,
    },
    [SOFTWARE.THUNDERBIRD]: {
      automaticInstall: true,
      platform: PLATFORM.DESKTOP,
    },
    [SOFTWARE.YAHOO_MAIL]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
    },
    [SOFTWARE.ZOHO_MAIL]: {
      automaticInstall: false,
    },
  },
  [OS.MAC_OS]: {
    automaticInstall: true,
    [SOFTWARE.AIRMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.APPLE_MAIL]: {
      automaticInstall: true,
      platform: PLATFORM.DESKTOP,
    },
    [SOFTWARE.CANARY_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.FRONT]: {
      automaticInstall: false,
    },
    [SOFTWARE.MAIL_PILOT]: {
      automaticInstall: false,
    },
    [SOFTWARE.MISSIVE]: {
      automaticInstall: false,
    },
    [SOFTWARE.NEWTON_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.GMAIL]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.EDGE],
    },
    [SOFTWARE.OUTLOOK]: {
      automaticInstall: true,
      web: [BROWSER.CHROME, BROWSER.FIREFOX, BROWSER.BRAVE, BROWSER.SAFARI],
      desktop: [
        DESKTOP_VERSIONS.OUTLOOK_OFFICE_365,
        DESKTOP_VERSIONS.OUTLOOK_2016,
        DESKTOP_VERSIONS.OUTLOOK_2011,
      ],
    },
    [SOFTWARE.POLYMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.POSTBOX]: {
      automaticInstall: true,
      platform: PLATFORM.DESKTOP,
    },
    [SOFTWARE.SPARK]: {
      automaticInstall: false,
    },
    [SOFTWARE.SPIKE]: {
      automaticInstall: false,
    },
    [SOFTWARE.THUNDERBIRD]: {
      automaticInstall: true,
      platform: PLATFORM.DESKTOP,
    },
  },
  [OS.IOS]: {
    automaticInstall: false,
    [SOFTWARE.AIRMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.FRONT]: {
      automaticInstall: false,
    },
    [SOFTWARE.GMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.MISSIVE]: {
      automaticInstall: false,
    },
    [SOFTWARE.NEWTON_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.OUTLOOK]: {
      automaticInstall: false,
    },
    [SOFTWARE.POLYMAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.PROTON_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.YAHOO_MAIL]: {
      automaticInstall: false,
    },
  },
  [OS.ANDROID]: {
    automaticInstall: false,
    [SOFTWARE.AQUA_MAIL]: {
      automaticInstall: false,
    },
    [SOFTWARE.FRONT]: {
      automaticInstall: false,
    },
    [SOFTWARE.MISSIVE]: {
      automaticInstall: false,
    },
    [SOFTWARE.OUTLOOK]: {
      automaticInstall: false,
    },
  },
};

export interface PaymentIntentI {
  id: string;
  clientSecret: string;
}

export interface IsPaidUserI {
  isPaid: boolean;
}

export interface PlanI {
  productName: string;
  flatAmount: number;
  unitAmount: number;
  description?: any;
  tier: TIERS;
  priceId: string;
  starterPriceId: string;
  userPriceId: string;
  signaturesAmount?: number;
  quantity?: number;
}

export interface PlanInfoI {
  plans: {
    standardStarter: PlanI;
    standardUser: PlanI;
    proStarter: PlanI;
    proUser: PlanI;
    defaultStarter: PlanI;
    defaultUser: PlanI;
  };
  activatedUsersAmount: number;
}

export enum TIERS {
  STANDARD_STARTER = 'standard_starter',
  STANDARD_USER = 'standard_user',
  PRO_STARTER = 'pro_starter',
  PRO_USER = 'pro_user',
  DEFAULT_STARTER = 'default_starter',
  DEFAULT_USER = 'default_user',
}

export enum FEATURES {
  SIGNATURE_INCLUDED,
  PER_ONE_SIGNATURE_BASIC,
  PER_ONE_SIGNATURE_PRO,
  PER_ONE_SIGNATURE_DEFAULT,
  PREMADE_DESIGNS,
  SCHEDULE,
  INSTALLERS,
  DESIGNER,
  DATA_MANAGEMENT,
  ADMIN_TOOLS,
  HTML_EDITOR,
}

export const DEFAULT_PLAN_FEATURES = [
  FEATURES.SIGNATURE_INCLUDED,
  FEATURES.PER_ONE_SIGNATURE_DEFAULT,
  FEATURES.PREMADE_DESIGNS,
  FEATURES.SCHEDULE,
  FEATURES.INSTALLERS,
  FEATURES.DESIGNER,
  FEATURES.DATA_MANAGEMENT,
  FEATURES.ADMIN_TOOLS,
  FEATURES.HTML_EDITOR,
];

export const MIN_SIGNATURES_AMOUNT = 5;
