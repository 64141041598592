export enum ONBOARDING_STEPS {
  INITIALIZING = -1,
  ZERO,
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENTH,
  EIGHTH,
  NINTH,
  TENTH,
}

// TODO: remove usage of any
export interface OnboardingState {
  step?: ONBOARDING_STEPS;
  isGeneratingPreviewLoading?: boolean;
  isTimeplatesLoading?: boolean;
  isOneTimeplateLoading?: boolean;
  isInitializing?: boolean;
  widgetId?: number;
  timeplates?: Array<any>;
  isTimeplateFirstlyLoading?: boolean;
  signatureTimeplates: Record<
    number,
    {
      id: number;
      name: string;
      startDate?: Date;
      endDate?: Date;
      isActive: boolean;
      isDefault: boolean;
    }
  >[];
  timeplateId?: number;
  timeplate?: any;
  onboardingData?: {
    placeholderSettings: Record<string, any>;
    preparedData: {
      fields: Record<string, any>;
      fieldsId: Record<string, number>;
      placeholdersMapping: Record<string, any>;
      placeholdersVisibility: Record<string, any>;
      entityIds: Record<string, number>;
      placeholders: Array<string>;
      timestamp?: number;
      customIcons?: Record<string, any>;
    };
    signature: Record<string, any>;
    userEmail?: string;
    country?: string;
    timeZone?: string;
    gdpr?: number;
    firstName?: string;
    lastName?: string;
    isOnboardingCompleted?: boolean;
    watermark?: string;
  };
  stepsPlaceholders: Record<number, string[]>;
  error?: string;
  toSet?: any;
  toSetPlaceholderSettings?: any;
  preview?: string;
  backUrl?: string;
}

export enum TRIAL_STEPS {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENS,
  EIGHTS,
  NINTH,
  TENTH,
  ELEVENTH,
  __LENGTH,
}

export interface NamesI {
  en: string;
}

export interface ContinentI {
  code: string;
  geonameId: number;
  names: NamesI;
}

export interface CountryI {
  geonameId: number;
  isoCode: string;
  names: NamesI;
}

export interface RegisteredCountryI {
  geonameId: 2750405;
  isInEuropeanUnion: boolean;
  isoCode: 'NL';
  names: NamesI;
}

export interface CityI {
  geonameId: number;
  names: NamesI;
}

export interface LocationI {
  accuracyRadius: number;
  latitude: number;
  longitude: number;
  timeZone: string;
}

export interface PostalI {
  code: number;
}

export interface LookupResultI {
  continent: ContinentI;
  country: CountryI;
  registeredCountry: RegisteredCountryI;
  city: CityI;
  location: LocationI;
  postal: PostalI;
}
