import React, {
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import { useDebounce } from 'react-use';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import RootRef from '@material-ui/core/RootRef';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import SavingContext from 'app/components/layouts/components/SavingContext';

import { Options } from '../../types';
import { INPUT_DEBOUNCE_DELAY } from '../../constants';

import styles from '../../styles';

const useStyles = makeStyles(styles);

export interface Props extends OutlinedTextFieldProps {
  name: string;
  options?: Options[];
  className?: string;
  warning?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
    setFieldValue?: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => void;
  readOnly?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  emptyField?: boolean;
  ref?: RefObject<HTMLDivElement>;
  onClick?: (event: any) => void;
  value?: string | unknown;
  wihDebounce?: boolean;
  updateFieldsOnChange?: object[];
  style?: object;
  filled?: boolean;
  debounceTime?: number;
  tabIndex?: number;
  lpignore?: boolean;
  showPassword?: boolean;
}

const Text = ({
  id,
  select,
  label,
  margin = 'normal',
  required,
  type,
  autoComplete,
  InputProps,
  InputLabelProps,
  SelectProps,
  fullWidth = true,
  placeholder,
  autoFocus,
  options,
  multiline,
  rows = 7,
  helperText,
  disabled,
  name,
  variant = 'outlined',
  className = '',
  error: customError,
  warning = false,
  onBlur,
  onChange,
  startIcon = null,
  endIcon = null,
  onSuccessChange,
  readOnly = false,
  emptyField = false,
  ref,
  onClick,
  value: propsValue = '',
  wihDebounce = true,
  setFieldValue,
  updateFieldsOnChange = [],
  style,
  filled = false,
  debounceTime = INPUT_DEBOUNCE_DELAY,
  tabIndex = 0,
  lpignore = true,
}: Props) => {
  const classes = useStyles();
  const [
    { value, onBlur: onBlurFormik, onChange: onChangeFormik, ...rest },
    { touched, error },
    { setError },
  ] = useField<string>(name);
  const { setIsSaving } = useContext(SavingContext);
  const [textValue, setTextValue] = useState<string>();
  const [textValueChanged, setTextValueChanged] = useState(false);

  const isError = Boolean((touched && error) || customError);

  const customSubmit = () => {
    if (onSuccessChange && textValueChanged && textValue !== undefined)
      onSuccessChange(required, textValue, setError, setFieldValue);
  };

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useDebounce(customSubmit, debounceTime, [textValue]);

  useEffect(() => {
    if (isError) setIsSaving(false);
  }, [isError, setIsSaving]);

  const onCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      if (wihDebounce) {
        setTextValue(event.target.value);
        setTextValueChanged(true);
      } else if (onSuccessChange) {
        onSuccessChange(required, event.target.value, setError, setFieldValue);
      }

      if (setFieldValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateFieldsOnChange?.forEach(({ field, value, handleChange }) => {
          setFieldValue(field, value);
          handleChange(required, value);
        });
      }
    },
    [
      onChange,
      onSuccessChange,
      required,
      setError,
      setFieldValue,
      updateFieldsOnChange,
      wihDebounce,
    ],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlurFormik(event);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlurFormik, onBlur],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSuccessChange) setIsSaving(true);
      onChangeFormik(event);
      onCustomChange(event);
    },
    [onChangeFormik, onSuccessChange, setIsSaving, onCustomChange],
  );

  const fieldValue = propsValue || (!emptyField && value) || '';

  const fieldClasses = cx(classes.textFieldRoot, className, 'text-field', {
    'text-field-select': select,
    [classes.textFieldWarning]: warning,
    [classes.textFieldFilled]: filled,
  });

  const result = (
    <TextField
      style={style}
      id={id}
      classes={{ root: fieldClasses }}
      error={isError}
      helperText={isError && error ? error : helperText}
      label={label}
      select={select}
      margin={margin}
      type={type}
      required={required}
      autoComplete={autoComplete}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
        classes: {
          root: 'text-label',
          focused: 'text-label-focused',
          error: 'text-label-error',
        },
      }}
      InputProps={{
        ...InputProps,
        readOnly,
        inputProps: {
          tabIndex: tabIndex,
          'data-lpignore': lpignore, // LastPass ignore
        },
        title: '',
        classes: {
          root: cx(
            startIcon ? classes.inputWrapperStartIcon : '',
            select ? classes.selectWrapper : classes.inputWrapper,
            'text-input-wrapper',
          ),
          input: cx(classes.input, 'text-input'),
          multiline: cx(classes.multilineWrapper, 'multiline-wrapper'),
          inputMultiline: cx(
            classes.inputMultiline,
            'text-input-multiline native-custom-scrollbar grey-scrollbar',
          ),
          focused: cx(classes.inputFocused, 'text-input-focused'),
          error: cx(classes.inputError, 'text-input-error'),
          disabled: 'text-input-disabled',
          notchedOutline: cx(classes.inputNotchedOutline, 'text-input-notched'),
          adornedEnd: cx(classes.textFieldAdornedEnd, 'text-field-adorned-end'),
        },
        startAdornment: startIcon && (
          <InputAdornment
            className={cx(
              classes.textFieldIconBlock,
              'text-field-icon-block text-field-icon-block-start',
              classes.textFieldIconBlockStart,
            )}
            position="start"
          >
            {startIcon}
          </InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment
            className={cx(
              classes.textFieldIconBlock,
              'text-field-icon-block',
              classes.textFieldIconBlockEnd,
            )}
            position="end"
            onClick={e => e.stopPropagation()}
          >
            {endIcon}
          </InputAdornment>
        ),
      }}
      SelectProps={{
        ...SelectProps,
        classes: {
          root: cx(classes.selectMain, 'select-main'),
          icon: classes.selectArrowDownIcon,
        },
        MenuProps: {
          classes: {
            paper: cx(
              classes.selectPaper,
              'native-custom-scrollbar grey-scrollbar',
            ),
            list: 'select-list',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.textFieldHelperText,
        },
      }}
      fullWidth={fullWidth}
      placeholder={placeholder}
      autoFocus={autoFocus}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      variant={variant}
      {...rest}
      value={fieldValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={onClick}
    >
      {select &&
        options &&
        options.map((option: Options) => (
          <MenuItem
            className="select-option"
            key={option.id}
            value={option.id}
            disabled={option.isDisabled || false}
          >
            {option.name}
          </MenuItem>
        ))}
    </TextField>
  );

  if (ref) {
    return <RootRef rootRef={ref}>{result}</RootRef>;
  }

  return result;
};

export default memo(Text);
