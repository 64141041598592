import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    iframeResizerBlock: {
      flex: 1,
      position: 'relative',
      minHeight: 0,
    },
    iframeResizerInner: {
      display: 'flex',
      minHeight: '100%',
    },
    iframeResizer: {
      border: 'none',
      margin: 'auto',
    },
  });
