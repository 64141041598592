import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SvgIcon from '@material-ui/core/SvgIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { Table } from 'app/components/table';
import { translations } from 'locales/i18n';
import {
  UserRoles,
  UserCompany,
  UserLocation,
  ROLES,
} from 'app/components/auth/constants';
import RoleCell from 'app/components/grid/roleCell/RoleCell';
import { useConfirmation, useModal } from 'app/components/ui/Modal';

import { AssignLocationModal } from './AssignLocationModal';

import { ReactComponent as modalCompanyIcon } from 'images/icon/FontAwesome5Pro/Light/modal-company.svg';
import { ReactComponent as removeUserIcon } from 'images/icon/remove-user.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  userRoles: UserRoles[];
  userName: string;
  isProfile?: boolean;
  userCompanies?: UserCompany[];
  userLocations?: UserLocation[];
  allLocations?: UserLocation[];
  handleAssignToCompany: (...args: any) => void;
  handleRemoveLocation: (...args: any) => void;
}

export default function LocationsList({
  userRoles,
  userCompanies,
  userLocations,
  allLocations,
  userName,
  handleAssignToCompany,
  handleRemoveLocation,
  isProfile,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const respMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedLocation, setLocation] = useState<UserLocation | null>(null);

  const [showRemoveConfirmation] = useConfirmation({
    onSubmit: async () => {
      await handleRemoveLocation(selectedLocation?.id);
    },
    maxWidth: 'md',
    titleBlockStyle: 'default',
    withCheckBox: true,
    modalIcon: modalCompanyIcon,
    title: t(translations.people.removeLocation),
    description: selectedLocation ? (
      <>
        <Typography color="error">
          {t(translations.ui.titles.warning)}
        </Typography>
        <Typography>
          <Trans
            i18nKey="people.removeFromLocation"
            values={{
              person: userName,
              location: selectedLocation.name,
            }}
          />
        </Typography>
        <br />
        <Typography>
          {t(translations.people.removeFromLocationDescription)}
        </Typography>
        <br />
        <Typography>
          {t(translations.people.removeFromLocationQuestion)}
        </Typography>
      </>
    ) : (
      <div />
    ),
    cancelLabel: t(translations.ui.buttons.cancel),
    agreeLabel: t(translations.ui.buttons.remove),
    cancelButtonProps: {
      className: 'modal-button modal-button-white',
      variant: 'contained',
      size: 'medium',
    },
    agreeButtonProps: {
      className: 'modal-button button-danger',
      size: 'medium',
    },
  });

  const handleRemoveClick = useCallback(
    item => {
      setLocation(item);
      showRemoveConfirmation();
    },
    [showRemoveConfirmation],
  );

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'isActive',
          Cell: () => <FiberManualRecordIcon className="table-status-icon" />,
          disableSortBy: true,
          className: 'table-info-first-cell table-status-cell',
          headerClassName: 'table-status-cell',
        },
        {
          Header: t(translations.table.columns.location),
          accessor: 'name',
          Cell: ({ cell: { value } }: { cell: Cell }) => <div>{value}</div>,
          className: 'table-header-cell-mobile left-indent',
          minWidth: 200,
          maxWidth: 300,
          disableSortBy: true,
        },
        {
          Header: t(translations.table.columns.company),
          accessor: 'companyName',
          Cell: ({ cell: { value } }: { cell: Cell }) => <div>{value}</div>,
          minWidth: 200,
          maxWidth: 300,
          disableSortBy: true,
        },
        {
          Header: t(translations.table.columns.role),
          disableSortBy: true,
          Cell: ({ cell: { row } }) => {
            const roles = userRoles.reduce<Array<{ id: number; name: string }>>(
              (acc, item) => {
                if (item.locationId === row.original.id) {
                  acc.push({
                    id: item.role.id,
                    name: item.role.name,
                  });
                }
                return acc;
              },
              [],
            );

            return <RoleCell roles={roles} />;
          },
        },
        {
          Header: !isProfile ? t(translations.table.columns.quickActions) : '',
          id: 'id',
          minWidth: 180,
          maxWidth: 180,
          disableSortBy: true,
          className: 'table-action-cell',
          accessor: item => item,
          Cell: ({ cell: { value: item } }: { cell: Cell }) => {
            return (
              <>
                {!isProfile ? (
                  <div className="action-cell-wrapper">
                    <span className={classes.actionItem}>
                      <Tooltip
                        placement="top"
                        arrow={true}
                        title={t(translations.actions.names.removeFromLocation)}
                      >
                        <SvgIcon
                          component={removeUserIcon}
                          viewBox="0 0 23 19"
                          onClick={() => handleRemoveClick(item)}
                        />
                      </Tooltip>
                    </span>
                  </div>
                ) : null}
              </>
            );
          },
        },
      ].filter(Boolean),
    [userRoles],
  );

  const dataWithActions = useMemo(() => {
    return userLocations
      ? userLocations.map(item => {
          const company = userCompanies?.find(
            ({ id }) => id === item.companyId,
          );
          return {
            ...item,
            companyName: company ? company.name : null,
          };
        })
      : null;
  }, [userCompanies, userLocations]);

  const [showAssignToCompany] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: AssignLocationModal,
    props: {
      companies: userCompanies,
      locations: allLocations,
      handleSubmit: handleAssignToCompany,
    },
    unmount: true,
  });

  if (!dataWithActions) {
    return <div className="table-no-data">{t(translations.table.noData)}</div>;
  }

  return (
    <>
      <Table
        renderGlobalFilter={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm />
            <Grid item xs={12} sm="auto">
              {!isProfile ? (
                <Button
                  className="cta-button"
                  variant="contained"
                  color="secondary"
                  onClick={showAssignToCompany}
                  startIcon={<AddRoundedIcon style={{ fontSize: 22 }} />}
                  fullWidth={respMobile}
                  disabled={allLocations?.length === 0}
                >
                  {t(translations.people.buttons.assignToLocation)}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        )}
        isPagination={false}
        columns={columns}
        data={dataWithActions}
      />
    </>
  );
}
