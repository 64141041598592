import React, { memo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { Logo } from './Logo';

import { styles } from '../styles/generalLayout';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
}

const GeneralLayout = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.layoutHeader}>
        <Container className={classes.layoutHeaderContainer} maxWidth="xl">
          <Logo className={classes.layoutLogo} dark hardReload />
          <Typography
            className={classes.layoutHeaderTitle}
            variant="h5"
            component="h1"
          >
            &nbsp;
          </Typography>
        </Container>
      </div>
      <Container className={classes.layoutContent} maxWidth="xl">
        <>{children}</>
      </Container>
    </div>
  );
};

export default memo(GeneralLayout);
