import { Theme, createStyles } from '@material-ui/core/styles';

import {
  bgOnboardingHeader,
  colorWhite,
  bgOnboardingActive,
  colorDisable,
  colorSecondary2,
  colorPrimary2,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    stepPanel: {
      minWidth: '380px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 26px ',
      background: bgOnboardingHeader,
      color: colorPrimary2,
    },
    iconBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    headerIcon: {
      marginRight: '8px',
    },
    checkIcon: { margin: '12px' },
    backIcon: { marginRight: '6px' },
    stepBlock: {
      display: 'flex',
      alignItems: 'center',
      padding: '28px 40px ',
    },
    stepIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      marginRight: '12px',
    },
    isActiveStep: {
      color: colorWhite,
      background: bgOnboardingActive,
    },
    notActiveStep: {
      color: colorDisable,
    },
    doneStep: {
      color: colorSecondary2,
    },
    stepName: { fontSize: '16px' },
  });
