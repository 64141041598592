export const TABS_CONFIG = {
  DATA: {
    fields: {
      // key - row name
      // value - row is bordered (true || false)
      displayName: true,
      salutation: true,
      role: true,
      tagline: true,
      mobile: true,
      email: true,
      website: true,
      telephone: true,
      telephoneExt: true,
      fax: true,
      addressLine1: false,
      addressLine2: false,
      city: false,
      state: false,
      stateInitials: false,
      zip: false,
      country: false,
      countryInitials: true,
      postalLine1: false,
      postalLine2: false,
      postalCity: false,
      postalState: false,
      postalStateInitials: false,
      postalZip: false,
      postalCountry: false,
      postalCountryInitials: false,
    },
  },
  MESSAGES: {
    fields: {
      status: true,
      green: true,
      customMessage: true,
      unsubscribe: true,
    },
  },
  IMAGES: {
    fields: {
      timeplateLogo: true,
      primaryImage: true,
      secondaryImage: true,
      addressMap: true,
      postalMap: true,
      image1: true,
      image2: true,
      image3: true,
      image4: true,
      image5: true,
      image6: true,
      image7: true,
      image8: true,
      image9: true,
      banner1: true,
      banner2: true,
      banner3: true,
      banner4: true,
      banner5: true,
      banner6: true,
      banner7: true,
      banner8: true,
      banner9: true,
    },
  },
  BRANDING: {
    fields: {
      featureColor: true,
      baseColor: true,
      iconStyle: true,
      iconSize: true,
      originalColor: true,
      iconFeatureColor: true,
      iconBaseColor: true,
      fontFamily: true,
      fontSize: true,
      statusMessageFont: true,
      statusMessageFontSize: true,
      statusMessageColor: true,
      statusMessageLinkColor: true,
      brandStatusMessage: true,
      greenMessageFont: true,
      greenMessageFontSize: true,
      greenMessageColor: true,
      greenMessageLinkColor: true,
      brandGreenMessage: true,
      customMessageFont: true,
      customMessageFontSize: true,
      customMessageColor: true,
      customMessageLinkColor: true,
      brandCustomMessage: true,
      unsubscribeMessageFont: true,
      unsubscribeMessageFontSize: true,
      unsubscribeMessageColor: true,
      unsubscribeMessageLinkColor: true,
      brandUnsubscribeMessage: true,
    },
  },
  ICONS: {
    fields: {
      customIcon1: true,
      customIcon2: true,
      customIcon3: true,
      customIcon4: true,
      customIcon5: true,
      customIcon6: true,
      customIcon7: true,
      customIcon8: true,
      customIcon9: true,
    },
  },
};

export const TABS_PLACEHOLDERS = {
  TEXT: [
    'company.name',
    'location.name',
    'signature.name',
    'first.name',
    'middle.name',
    'last.name',
    'credentials',
    'title',
    'birthday',
    'display.name',
    'salutation',
    'role',
    'tagline',
    'mobile',
    'email',
    'website',
    'telephone',
    'telephone.ext',
    'fax',
    'address.line1',
    'address',
    'address.line2',
    'address.city',
    'address.state',
    'address.state.initials',
    'address.zip',
    'address.country',
    'address.country.initials',
    'postal.line1',
    'postal',
    'postal.line2',
    'postal.city',
    'postal.state',
    'postal.state.initials',
    'postal.zip',
    'postal.country',
    'postal.country.initials',
  ],
  MESSAGES: ['status.message', 'green', 'custom.message', 'unsubscribe'],
  IMAGES: [
    'photo',
    'logo',
    'primary.image',
    'secondary.image',
    'address.map',
    'postal.map',
    'image1',
    'image2',
    'image3',
    'image4',
    'image5',
    'image6',
    'image7',
    'image8',
    'image9',
    'banner1',
    'banner2',
    'banner3',
    'banner4',
    'banner5',
    'banner6',
    'banner7',
    'banner8',
    'banner9',
  ],
  BRANDING: [
    'brand.feature',
    'brand.base',
    'brand.icon',
    'brand',
    'brand.font.size',
    'brand.font.family',
    'brand.font',
    'brand.status.message',
    'brand.green.message',
    'brand.custom.message',
    'brand.unsubscribe.message',
  ],
  ICONS: ['icon'],
};

export const UNIQUE_PLACEHOLDERS = [
  'company.name',
  'location.name',
  'signature.name',
  'first.name',
  'middle.name',
  'last.name',
  'credentials',
  'title',
  'birthday',
  'photo',
];
