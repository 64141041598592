import { useCallback, useState } from 'react';

export default function useSteps(initialValue: number) {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback((event, tab) => setValue(tab), [setValue]);
  const isActive = useCallback(tab => value === tab, [value]);

  const handleBack = useCallback(() => setValue(value - 1), [value]);
  const handleNext = useCallback(() => setValue(value + 1), [value]);

  return {
    value,
    onChange,
    isActive,
    handleBack,
    handleNext,
  };
}
