import React, { useCallback, useMemo, useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Schema } from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { DATE_FORMAT } from 'app/components/forms/constants';
import { Password, Text, TextDate } from 'app/components/forms';
import { translations } from 'locales/i18n';
import { DELETE_SIGNATURE_AFTER_DAYS } from 'app/components/onboarding/components/utils';
import { PAGE_TYPE } from 'app/containers/constants';
import { success } from 'app/components/snackbars';

import { Users } from '../types';

import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as envelopeLightIcon } from 'images/icon/FontAwesome5Pro/Light/envelope.svg';
import { ReactComponent as keySkeletonLightIcon } from 'images/icon/FontAwesome5Pro/Light/key-skeleton.svg';
import { ReactComponent as copyLightIcon } from 'images/icon/FontAwesome5Pro/Light/copy.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Props {
  defaultValues: Users;
  schema: Schema;
  onSubmit: (data, resetForm?) => void;
  isLoading?: boolean;
  isActive?: boolean;
  pageType: PAGE_TYPE;
  handleSendUserLinkToLogin?: () => any;
  handleSendUserPasswordReset?: () => any;
}

const UserEdit = ({
  defaultValues: { firstName, lastName, email, trialCompany },
  schema,
  onSubmit,
  isLoading = false,
  isActive = true,
  pageType,
  handleSendUserLinkToLogin,
  handleSendUserPasswordReset,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { timeZone, trialPeriodStartDate, trialPeriodEndDate } =
    trialCompany || {};
  const theme = useTheme();
  const respUp = useMediaQuery(theme.breakpoints.up('md'));
  const loginLink = useMemo(() => `${window.location.origin}/login`, []);
  const copyInviteLink = useCallback(() => {
    navigator.clipboard.writeText(loginLink);
    success(t(translations.ui.notificationMessages.copiedToClipboard));
  }, [loginLink, t]);

  const initialValues = useMemo(() => {
    if (pageType === PAGE_TYPE.TRIALS) {
      return {
        firstName,
        lastName,
        email,
        trialPeriodStartDate: trialPeriodStartDate
          ? dayjs(dayjs.tz(trialPeriodStartDate, timeZone)).format(DATE_FORMAT)
          : '',
        trialPeriodEndDate: trialPeriodEndDate
          ? dayjs(dayjs.tz(trialPeriodEndDate, timeZone)).format(DATE_FORMAT)
          : '',
        password: '',
        trialPeriodDeletedDate: dayjs(dayjs.tz(trialPeriodEndDate, timeZone))
          .add(DELETE_SIGNATURE_AFTER_DAYS, 'days')
          .format(DATE_FORMAT),
      };
    }

    return {
      firstName,
      lastName,
      email,
    };
  }, [
    email,
    firstName,
    lastName,
    pageType,
    timeZone,
    trialPeriodEndDate,
    trialPeriodStartDate,
  ]);

  const minDate = useMemo(() => {
    let min = trialPeriodStartDate ? dayjs(trialPeriodStartDate) : dayjs();
    const now = dayjs();
    if (now.isAfter(min)) {
      min = now;
    }
    return min.format();
  }, [trialPeriodStartDate]);

  const BusinessLogic = () => {
    const { values } = useFormikContext<{
      trialPeriodEndDate: string;
      trialPeriodDeletedDate: string;
    }>();

    useEffect(() => {
      values.trialPeriodDeletedDate = dayjs(
        values.trialPeriodEndDate,
        DATE_FORMAT,
      )
        .add(DELETE_SIGNATURE_AFTER_DAYS, 'days')
        .format(DATE_FORMAT);
    }, [values]);

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <form
          className={classes.userEditForm}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={respUp ? 6 : 3}>
            <Grid item xs={12} sm={6} lg={4}>
              <BusinessLogic />
              <Text
                label={t(translations.form.labels.firstName)}
                name="firstName"
                type="text"
                variant="outlined"
                margin="dense"
                required
                disabled={!isActive}
                fullWidth
              />
              <Text
                label={t(translations.form.labels.lastName)}
                name="lastName"
                type="text"
                variant="outlined"
                margin="dense"
                required
                disabled={!isActive}
              />
              <Text
                label={t(translations.form.labels.email)}
                name="email"
                type="text"
                variant="outlined"
                margin={pageType === PAGE_TYPE.GHOSTS ? 'none' : 'dense'}
                required
                disabled={!isActive}
              />
              {[PAGE_TYPE.PAID, PAGE_TYPE.TRIALS].includes(pageType) && (
                <Password
                  label={t(translations.form.labels.password)}
                  name="password"
                  variant="outlined"
                  margin={pageType === PAGE_TYPE.TRIALS ? 'dense' : 'none'}
                  autoComplete="new-password"
                  disabled={!isActive}
                />
              )}
              {pageType === PAGE_TYPE.TRIALS && (
                <>
                  <TextDate
                    label={t(translations.form.labels.trialStarted)}
                    name="trialPeriodStartDate"
                    margin="dense"
                    required
                    disablePast
                    disabled
                  />
                  <TextDate
                    label={t(translations.form.labels.trialEnds)}
                    name="trialPeriodEndDate"
                    margin="dense"
                    required
                    minDate={minDate}
                  />
                  <TextDate
                    label={t(translations.form.labels.trialDeleted)}
                    name="trialPeriodDeletedDate"
                    margin="none"
                    required
                    minDate={minDate}
                    disabled
                  />
                </>
              )}
              <Box textAlign="center" mt={3}>
                <Button
                  className="cta-button"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isLoading || !isValid}
                  startIcon={
                    <SvgIcon
                      component={checkCircleLightIcon}
                      viewBox="0 0 512 512"
                    />
                  }
                >
                  {t(translations.form.buttons.saveAccount)}
                </Button>
              </Box>
            </Grid>
            {pageType === PAGE_TYPE.PAID && (
              <Grid className={classes.userLinkToLoginBlock} item xs={12} sm>
                <div className={classes.userLinkToLogin}>
                  <Box mb={3}>
                    <Typography
                      className={classes.userLinkToLoginTitle}
                      variant="h6"
                    >
                      {t(translations.people.linkToLogin)}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <TextField
                      classes={{ root: classes.textFieldFilled }}
                      InputProps={{
                        classes: {
                          root: 'text-input-wrapper',
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={e => e.stopPropagation()}
                          >
                            <IconButton
                              className="button-bordered"
                              onClick={copyInviteLink}
                              size="small"
                            >
                              <SvgIcon
                                component={copyLightIcon}
                                viewBox="0 0 512 512"
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={`${window.location.origin}/login`}
                      disabled
                      variant="outlined"
                      margin="none"
                      fullWidth
                    />
                  </Box>
                  <Box mb={3}>
                    <Button
                      className="cta-button"
                      variant="contained"
                      color="default"
                      fullWidth
                      startIcon={
                        <SvgIcon
                          component={envelopeLightIcon}
                          viewBox="0 0 512 512"
                        />
                      }
                      onClick={handleSendUserLinkToLogin}
                    >
                      {t(translations.people.sendUserLink)}
                    </Button>
                  </Box>
                  <Button
                    className="cta-button"
                    variant="contained"
                    color="default"
                    fullWidth
                    startIcon={
                      <SvgIcon
                        component={keySkeletonLightIcon}
                        viewBox="0 0 448 512"
                      />
                    }
                    onClick={handleSendUserPasswordReset}
                  >
                    {t(translations.people.sendUserPasswordReset)}
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default UserEdit;
