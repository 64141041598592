import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ChipMaterial from '@material-ui/core/Chip';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  label: React.ReactNode;
  deleteIcon?: React.ReactElement;
  disabled?: boolean;
  style?: any;
  icon?: React.ReactElement;
  onDelete?: React.EventHandler<any>;
  onClick?: React.EventHandler<any>;
  variant?: 'default' | 'outlined';
  size?: 'medium' | 'small';
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'blue'
    | 'danger'
    | 'pink'
    | 'melon'
    | 'yellow';
  fullText?: boolean;
  status?: boolean;
  className?: string;
}

const Chip = ({
  label,
  variant,
  style,
  size = 'small',
  color = 'default',
  fullText = false,
  status = false,
  className = '',
  ...rest
}: Props) => {
  const classes = useStyles();

  const chipClasses = cx(
    classes.chipRoot,
    className,
    color
      ? `${
          classes[variant === 'outlined' ? `out-${color}` : color]
        } chip-${color}`
      : '',
    fullText ? classes.fullText : '',
    status ? classes.statusChip : '',
    'custom-chip',
  );
  return (
    <ChipMaterial
      classes={{
        root: chipClasses,
        label: cx(classes.label, 'chip-label'),
        icon: cx(classes.chipIcon, classes.chipIconStart, 'chip-icon'),
        deleteIcon: cx(classes.chipIcon, classes.chipDeleteIcon),
        deletable: classes.chipDeletable,
        clickable: classes.chipClickable,
      }}
      {...rest}
      style={style}
      variant={variant}
      size={size}
      label={
        <span className={cx(classes.labelContent, 'chip-label-content')}>
          {label}
        </span>
      }
    />
  );
};

export default memo(Chip);
