import React, { memo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Button,
  ClickAwayListener,
  Collapse,
  Paper,
  Popper,
  SvgIcon,
} from '@material-ui/core';

import { ReactComponent as angleDownLightIcon } from 'images/icon/FontAwesome5Pro/Solid/angle-down.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  children?: React.ReactNode | ((props: any) => React.ReactNode);
  buttonText?: React.ReactNode;
  buttonSize?: 'small' | 'medium' | 'large';
  buttonColor?: 'default' | 'inherit' | 'primary' | 'secondary';
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonStartIcon?: React.ReactNode;
  buttonArrow?: boolean;
  fullWidth?: boolean;
  iconButton?: boolean;
  paperArrow?: boolean;
  paperFullWidth?: boolean;
  paperSize?: 'paperClear' | 'paperSmall' | 'paperDefault';
  disablePortal?: boolean;
  keepMounted?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  className?: string;
  classNamePopper?: string;
  classNamePaper?: string;
  classNameScrollbar?: string;
}

const Dropdown = ({
  children,
  buttonText,
  buttonSize = 'medium',
  buttonColor = 'primary',
  buttonVariant = 'text',
  buttonStartIcon,
  buttonArrow = false,
  fullWidth = false,
  iconButton = false,
  paperArrow = false,
  paperFullWidth = false,
  paperSize = 'paperDefault',
  disablePortal = false,
  keepMounted = false,
  placement = 'bottom',
  className = '',
  classNamePopper = '',
  classNamePaper = '',
  classNameScrollbar = '',
}: Props) => {
  const classes = useStyles();

  const [arrowRef, setArrowRef] = React.useState(null);

  const scrollRef = React.useRef<PerfectScrollbar>(null);
  const handleArrowRef = (node: any) => {
    setArrowRef(node);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      event &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    } else if (scrollRef.current && open) {
      scrollRef.current.updateScroll();
    }

    prevOpen.current = open;
  }, [open, scrollRef]);

  const dropdownButtonClass = cx(
    classes.dropdownButton,
    open ? `${classes.dropdownOpen} open` : '',
    buttonArrow ? classes.dropdownButtonWithArrow : '',
    iconButton ? classes.dropdownIconButton : '',
    buttonVariant ? classes[buttonVariant] : '',
    className,
    'dropdown-button',
  );

  const dropdownPaperClass = cx(
    classes.dropdownPaper,
    paperFullWidth ? classes.dropdownPaperFullWidth : '',
    paperSize ? classes[paperSize] : '',
    classNamePaper,
    'dropdown-paper',
  );

  return (
    <>
      <Button
        className={dropdownButtonClass}
        color={buttonColor}
        size={buttonSize}
        variant={buttonVariant}
        onClick={handleToggle}
        startIcon={buttonStartIcon}
        fullWidth={fullWidth}
        ref={anchorRef}
        classes={{
          label: classes.dropdownButtonLabel,
        }}
      >
        {buttonText}
        {buttonArrow && (
          <SvgIcon
            className={cx(classes.dropdownButtonArrow, 'dropdown-button-arrow')}
            component={angleDownLightIcon}
            viewBox="0 0 320 512"
          />
        )}
      </Button>
      <Popper
        className={cx(
          classes.dropdownPopper,
          paperSize ? classes[paperSize] : '',
          iconButton ? classes.dropdownIconButtonPopper : '',
          paperFullWidth ? classes.dropdownPopperFullWidth : '',
          classNamePopper,
        )}
        open={open}
        anchorEl={anchorRef.current}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: paperArrow,
            element: arrowRef,
          },
        }}
        placement={placement}
        disablePortal={disablePortal}
        keepMounted={keepMounted}
        transition
        style={{
          width:
            paperFullWidth && anchorRef.current
              ? anchorRef.current.clientWidth
              : 'auto',
        }}
      >
        {({ TransitionProps }) => (
          <Collapse
            classes={{ container: classes.dropdownCollapseContainer }}
            {...TransitionProps}
            timeout={400}
          >
            <Paper className={dropdownPaperClass}>
              {paperArrow && (
                <span
                  className={classes.dropdownPaperArrow}
                  ref={handleArrowRef}
                />
              )}
              <ClickAwayListener
                onClickAway={handleClose}
                mouseEvent="onMouseUp"
              >
                <div>
                  <PerfectScrollbar
                    className={cx(
                      classes.dropdownScrollbar,
                      classNameScrollbar,
                    )}
                    ref={scrollRef}
                    options={{ suppressScrollX: true }}
                  >
                    {typeof children === 'function'
                      ? children({
                          handleClose,
                        })
                      : children}
                  </PerfectScrollbar>
                </div>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  );
};

export default memo(Dropdown);
