import React, { memo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AvatarMaterial from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';

import {
  ROLES_ICONS,
  ROLES,
  ROLES_ICONS_VIEWBOX,
} from 'app/components/auth/constants';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  readonly role: ROLES;
}

const RoleAvatar: React.FC<Props> = ({ role }) => {
  const classes = useStyles();

  return (
    <AvatarMaterial className={classes.roleSwitch} src={undefined}>
      <SvgIcon
        className={classes.roleIcon}
        component={ROLES_ICONS[role]}
        viewBox={ROLES_ICONS_VIEWBOX[role]}
      />
    </AvatarMaterial>
  );
};

export default memo(RoleAvatar);
