import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import styles from '../styles/authorization';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  decoratorFooter?: React.ReactNode;
  decoratorBlock?: boolean;
  className?: string;
}

const AuthorizationLayout = ({
  children,
  decoratorFooter,
  decoratorBlock = false,
  className = '',
}: Props) => {
  const classes = useStyles();

  return (
    <Container
      className={cx(classes.authorizationContainer, className)}
      maxWidth={false}
    >
      <div
        className={cx(
          classes.authorizationBlock,
          decoratorBlock ? classes.withDecoratorBlock : '',
        )}
      >
        <div className={classes.authorizationContent}>{children}</div>
        {decoratorBlock && (
          <div className={classes.authorizationDecoratorBlock}>
            {decoratorFooter && (
              <div className={classes.authorizationDecoratorFooter}>
                {decoratorFooter}
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default memo(AuthorizationLayout);
