import { createStyles, Theme } from '@material-ui/core/styles';

import {
  textPrimary,
  bgColorLightGrey,
  borderRadiusSize,
  boxShadowSmall,
  badgeOutDanger,
  badgeBgDanger,
  badgeOutSecondary,
  badgeBgSecondary,
  badgeOutMelon,
  badgeBgMelon,
  badgeOutPrimary,
  badgeBgPrimary,
} from 'configs/variables';

export default ({ breakpoints }: Theme) =>
  createStyles({
    alertRoot: {
      backgroundColor: bgColorLightGrey,
      color: textPrimary,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      padding: '4px 10px 4px 4px',
      minHeight: 32,
      minWidth: 200,
      maxWidth: '100%',
      borderRadius: borderRadiusSize + 11,
      ...boxShadowSmall,

      [breakpoints.up('sm')]: {
        minWidth: 300,
        maxWidth: 800,
        width: '100%',
      },
    },
    alertRootFixed: {
      position: 'fixed',
      top: 5,
      right: 5,

      [breakpoints.up('sm')]: {
        top: 10,
        right: 10,
      },
    },
    alertRootWithClose: {
      paddingRight: 30,
    },

    alertIcon: {
      color: 'inherit',
      fontSize: 20,
      opacity: 1,
      marginRight: 8,
      padding: 0,

      '& svg': {
        color: 'inherit',
        fontSize: 'inherit',
      },
    },
    alertMessage: {
      color: 'inherit',
      fontSize: 14,
      padding: 0,
    },
    alertAction: {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 3,
      margin: 0,
      padding: 0,

      '& svg': {
        fontSize: 18,
      },
    },

    // Alert Color
    error: {
      color: badgeOutDanger,
      backgroundColor: badgeBgDanger,
    },
    success: {
      color: badgeOutSecondary,
      backgroundColor: badgeBgSecondary,
    },
    warning: {
      color: badgeOutMelon,
      backgroundColor: badgeBgMelon,
    },
    info: {
      color: badgeOutPrimary,
      backgroundColor: badgeBgPrimary,
    },
  });
