import React, { useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Button from '@material-ui/core/Button';

import { UsersSignaturesSearch } from 'app/components/forms/components/Edit/Search/UsersSignaturesSearch';
import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { translations } from 'locales/i18n';
import { useModal } from 'app/components/ui/Modal';
import { PreviewModal } from 'app/components/entities/timeplates';

import { ReactComponent as clockLightIcon } from 'images/icon/FontAwesome5Pro/Light/clock.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';
import { ReactComponent as installsIcon } from 'images/icon/FontAwesome5Pro/Light/installs.svg';

import styles from '../styles';
import { ROLES, useAcl } from 'app/components/auth';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId?: string;
  locationId?: string;
  backLink?: string;
  userId: number;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
}

export default function UserProfileSignatureList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  userId,
  initialStateAdditionalParams = {},
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { is } = useAcl();

  const [versionId, setVersionId] = useState();
  const [showPreview] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: PreviewModal,
    props: { versionId, isTimeplate: false },
  });

  const onShowPreview = id => () => {
    if (id) {
      setVersionId(id);
      showPreview();
    }
  };

  const columns = [
    {
      Header: t(translations.table.columns.signature),
      accessor: 'name',
      Cell: ({ cell: { value } }: { cell: Cell }) => value,
    },
    {
      Header: t(translations.table.columns.company),
      accessor: 'location.company.name',
      customProps: row => `/companies/${row.location?.company?.accountId}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.location),
      accessor: 'location.name',
      customProps: row => `/locations/${row.location?.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.installs),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: () => '-',
    },
    {
      Header: t(translations.table.columns.activeTimeplates),
      id: 'timeplates.name',
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original?.timeplates?.find(({ isActive }) => isActive)?.name || '',
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 300,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({ id, timeplates, locationId, location }) => {
        return { id, timeplates, locationId, location };
      },
      Cell: ({
        cell: {
          value: {
            id,
            timeplates,
            locationId,
            location: { companyId },
          },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              className={classes.actionItem}
              href={`/signatures/${id}/edit`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.preview)}
              >
                <SvgIcon
                  component={eyeLightIcon}
                  viewBox="0 0 22 17"
                  onClick={onShowPreview(
                    timeplates?.find(({ isActive }) => !!isActive)
                      ?.activeVersionId,
                  )}
                />
              </Tooltip>
            </span>
            <span className={classes.disabled}>
              <SvgIcon component={installsIcon} viewBox="0 0 22 19" />
            </span>
            {!is(ROLES.SIGNATURE_USER) ? (
              <Link
                className={classes.actionItem}
                href={`/timeplates/${companyId}/${locationId}/${id}`}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.timeplates)}
                >
                  <SvgIcon component={clockLightIcon} viewBox="0 0 18 19" />
                </Tooltip>
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const initialState = {
    globalFilter: {
      'signatureUsers.userId': userId,
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper>
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <UsersSignaturesSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
