import { createStyles, Theme } from '@material-ui/core/styles';
import {
  drawerWidth,
  drawerMinimizeWidth,
  colorPrimary,
  colorPrimaryLight3,
  colorPrimaryExtraLight,
  colorPink,
  colorWhite,
  colorBlue,
  transitionAll,
  colorRose,
  colorPrimaryLight2,
  colorDisable,
} from 'configs/variables';

const sidebarHorizontalSize = 24;
const sidebarHorizontalMobileSize = 16;
const sidebarHorizontalSmallSize = 14;

export default (theme: Theme) =>
  createStyles({
    // Sidebar
    sidebar: {
      flexShrink: 0,
      position: 'relative',

      '&, & $sidebarPaper': {
        width: drawerWidth,
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
          ...transitionAll,
        },
      },
    },
    sidebarMinimize: {
      [theme.breakpoints.up('md')]: {
        '&, & $sidebarPaper': {
          width: drawerMinimizeWidth,
        },
        '& $sidebarHeader, & $sidebarNavItem, & $sidebarFooter': {
          paddingLeft: sidebarHorizontalSmallSize,
          paddingRight: sidebarHorizontalSmallSize,
        },
        '& $sidebarHeader': {
          paddingBottom: 10,
        },
        '& .logo-name, & $sidebarNavTextWrapper, & $sidebarNavCounterBlock': {
          display: 'none',
        },
        '& $sidebarTitle': {
          textAlign: 'center',
          padding: 0,
          fontSize: 10,
        },
        '& $sidebarNavItem.active': {
          paddingTop: 20,
          paddingBottom: 20,
        },
        '& $sidebarNavIcon': {
          marginLeft: 14,
        },
        '& $sidebarFooter': {
          fontSize: 10,
        },
      },
    },
    sidebarPaper: {
      color: colorWhite,
      backgroundColor: colorPrimary,
      borderLeft: 'none',

      [theme.breakpoints.up('md')]: {
        left: 'auto',
      },
    },

    sidebarHeader: {
      padding: `0 ${sidebarHorizontalMobileSize}px 16px`,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: sidebarHorizontalSize,
        paddingRight: sidebarHorizontalSize,
      },
    },
    sidebarHeaderToolbar: {
      color: colorWhite,
      borderBottom: `1px solid ${colorPrimaryExtraLight}`,
    },

    sidebarTitle: {
      color: colorPrimaryLight3,
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'uppercase',
      paddingLeft: sidebarHorizontalSize,
      paddingRight: sidebarHorizontalSize,
    },
    sidebarContent: {
      flexGrow: 1,
    },
    sidebarNav: { padding: '0 24px' },
    companyBlock: { display: 'flex', justifyContent: 'space-between' },
    companyIcon: {
      marginRight: '28px',
    },
    companyNameBlock: {
      display: 'flex',
      alignItems: 'center',
      width: '180px',
      '&.minimize': {
        width: '100%',
      },
      '&.isBillingAdmin': {
        width: '100%',
      },
      '&.isSignatureUser': {
        width: '100%',
      },
    },
    companyIconsBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    switchIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    headerIcon: {
      fontSize: '0.9rem',
    },
    expanded: { transform: 'rotate(180deg)' },
    accordion: { color: colorWhite },
    accordionSummary: {
      padding: 0,
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '48px',
        cursor: 'default',
      },
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minWidth: '276px',

      '&.isHeaderMinimized': {
        minWidth: '48px',
      },

      '&.MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0 !important',
      },
    },

    accordionSummaryItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '276px',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        color: colorRose,
      },
      '&.MuiButton-text': {
        padding: 0,
        borderRadius: '0px',
      },
      '& > .MuiButton-label': {
        justifyContent: 'flex-start',
      },

      '&:last-child': {
        '& > $listItemIcon': {
          marginRight: '-24px',
        },
      },
    },
    linkItem: {
      color: colorWhite,
      display: 'flex',
      alignItems: 'flex-end',
      width: '180px',
      '&:hover': {
        textDecoration: 'none',
        color: colorRose,
      },
    },
    disabled: {
      '&:hover': {
        cursor: 'default',
      },
    },
    active: {
      color: colorRose,
    },
    listItemIcon: { fontSize: '48px', marginRight: '-12px' },
    actionLink: { marginRight: '28px' },
    actionIcon: { fontSize: '20px' },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 0px 0px 12px',
    },
    isMinimized: {
      display: 'none',
    },
    sidebarNavItem: {
      alignItems: 'center',
      padding: `10px ${sidebarHorizontalMobileSize}px`,
      marginBottom: 1,
      position: 'relative',
      color: colorWhite,
      ...transitionAll,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: sidebarHorizontalSize,
        paddingRight: sidebarHorizontalSize,
      },

      '&.active': {
        color: colorPink,
        paddingTop: 20,
        paddingBottom: 20,

        [theme.breakpoints.up('sm')]: {
          paddingTop: 28,
          paddingBottom: 28,
        },
      },
      '&.active, &:hover': {
        backgroundColor: 'rgba(0, 0, 0, .1)',
      },
    },
    sidebarNavIcon: {
      minWidth: 24,
      fontSize: 22,
      color: 'inherit',
      justifyContent: 'center',

      '& svg': {
        fontSize: 'inherit',
      },
    },
    sidebarNavTextWrapper: {
      paddingLeft: 20,
      margin: 0,
    },
    sidebarNavText: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.2,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    sidebarNavCounterBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    sidebarNavCounter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorBlue,
      fontSize: 12,
      color: colorPrimary,
      lineHeight: 1,
      width: 24,
      minWidth: 24,
      height: 24,
      padding: 5,
      marginLeft: 10,
      borderRadius: '50%',
    },

    sidebarFooter: {
      color: colorPrimaryExtraLight,
      fontSize: 12,
      textAlign: 'center',
      padding: `12px ${sidebarHorizontalMobileSize}px`,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: sidebarHorizontalSize,
        paddingRight: sidebarHorizontalSize,
      },
    },
    actionButton: {
      minWidth: '48px',
      color: colorWhite,
      '&:hover': {
        cursor: 'pointer',
        color: colorRose,
      },
    },
    actionHeaderButton: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: '48px',
      color: colorWhite,
      marginLeft: '14px', //6px
      '&.button': {
        '&:hover': {
          cursor: 'pointer',
          color: colorRose,
        },
      },
      '&.minimized': {
        marginLeft: '0px',
      },
    },
    waitingListBlock: {
      background: colorPrimaryLight2,
      padding: '12px',
      margin: '24px',
      borderRadius: '12px',
    },
    waitingCompanyName: {
      color: colorWhite,
      fontWeight: 'bold',
    },
    waitingText: { color: colorDisable, whiteSpace: 'pre-line' },
    waitingButtonsBlock: {
      display: 'flex',
    },
    waitingButton: {
      fontSize: '12px',
      color: colorDisable,
      '&.planButton': {
        color: colorWhite,
      },
    },
  });
