import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AlertMaterial from '@material-ui/lab/Alert';

import Chip from 'app/components/ui/Chip/components/Chip';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  severity: 'error' | 'success' | 'warning' | 'info';
  onClose?: () => void;
  fixed?: boolean;
  icon?: React.ReactNode;
  className?: string;
}

const Alert: React.FC<Props> = ({
  children,
  severity,
  onClose,
  fixed = false,
  icon,
  className = '',
}) => {
  const classes = useStyles();

  const alertClasses = cx(
    classes.alertRoot,
    severity ? `${classes[severity]} alert-${severity}` : '',
    onClose ? classes.alertRootWithClose : '',
    fixed ? classes.alertRootFixed : '',
    className,
    'alert',
  );

  return (
    <AlertMaterial
      onClick={onClose}
      classes={{
        root: alertClasses,
        icon: cx(classes.alertIcon, 'alert-icon'),
        message: classes.alertMessage,
        action: classes.alertAction,
      }}
      onClose={onClose}
      variant="filled"
      severity={severity}
      iconMapping={{
        error: <Chip label="Error" color="danger" />,
        success: <Chip label="Success" color="secondary" />,
        warning: <Chip label="Warning" color="melon" />,
        info: <Chip label="New feature" color="primary" />,
      }}
      icon={icon}
    >
      {children}
    </AlertMaterial>
  );
};

export default memo(Alert);
