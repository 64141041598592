import { ApiError, apiClient } from 'app/config/axios';

import objectToFormData from 'utils/objectToFormData';
import { LocationField } from '../interfaces';
import { PaginatedResponse } from 'app/interfaces';
import {
  QueryOptions,
  UseQueryResult,
  useQuery,
  QueryObserverOptions,
} from 'react-query';

const endpoint = '/location-fields';

export const locationFieldUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, LocationField>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
export const locationFieldsIndex = queryData =>
  apiClient.get<any, PaginatedResponse<LocationField>>(endpoint, {
    params: queryData,
  });

export const useLocationFieldsIndex: <T = PaginatedResponse<LocationField>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useLocationFieldsIndex', any], any>(
    ['useLocationFieldsIndex', queryData],
    () => locationFieldsIndex(queryData),
    options,
  );

export const useLocationFieldsFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<LocationField>, ApiError>,
): {
  data: PaginatedResponse<LocationField> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useLocationFieldsIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};
