import { Theme, createStyles } from '@material-ui/core';

import {
  colorPrimaryExtraLight,
  colorPink,
  bgColorBlue,
  colorWhite,
  colorBlue,
  borderRadiusSize,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    authTopIconBlock: {
      color: colorPink,
      fontSize: 60,
      lineHeight: 1,
      marginBottom: 20,

      [theme.breakpoints.up('sm')]: {
        fontSize: 80,
        marginBottom: 25,
      },
    },
    authButtonBlock: {
      marginTop: 20,

      [theme.breakpoints.up('sm')]: {
        marginTop: 30,
      },
    },
    authLinkBlock: {
      marginTop: 15,

      [theme.breakpoints.up('sm')]: {
        marginTop: 30,
      },
    },
    authHighlightColor: {
      color: colorPrimaryExtraLight,
    },
    authHighlightPink: {
      color: colorPink,
    },
    authSuccessBlock: {
      '& p': {
        marginBottom: 8,
      },
    },
    textBreak: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    userEditForm: {
      marginTop: 24,
    },
    userLinkToLoginBlock: {
      [theme.breakpoints.down('xs')]: {
        order: '-999',
      },
    },
    userLinkToLogin: {
      backgroundColor: bgColorBlue,
      borderRadius: borderRadiusSize,
      textAlign: 'center',
      padding: 24,

      [theme.breakpoints.up('sm')]: {
        maxWidth: 380,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 58,
        paddingRight: 58,
      },
    },
    userLinkToLoginTitle: {
      color: colorBlue,
    },
    textFieldFilled: {
      '& .text-input-wrapper': {
        backgroundColor: colorWhite,
      },
    },
    rolesCell: {
      '& .tooltip-init-block': {
        margin: '2px 4px 2px 0',

        [theme.breakpoints.up('sm')]: {
          margin: '4px 8px 4px 0',
        },
      },
    },
    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    disabled: {
      opacity: 0.5,
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
    },
    waitingModal: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: '400px',
    },
    waitingModalIcon: {
      fill: 'none',
      marginBottom: '24px',
      height: '56px',
      width: '120px',
    },
    waitingModalText1: {
      marginTop: '20px',
      marginBottom: '14px',
    },
    waitingModalText2: {
      marginBottom: '14px',
    },
    waitingModalText3: {
      marginBottom: '14px',
    },
    waitingActionBlock: {
      width: '100%',
    },
  });
