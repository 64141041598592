import { createStyles } from '@material-ui/core/styles';

import selectStyle from 'app/components/forms/styles/select';

import { colorGrey } from 'configs/variables';

export default () =>
  createStyles({
    ...selectStyle(),
    autocompleteStartAdornment: {
      marginRight: 8,

      '& svg, & img': {
        width: 20,
        minWidth: 20,
      },
    },
    autocompleteEndAdornment: {},
    autocompleteClearIndicator: {
      fontSize: 16,
      color: colorGrey,
    },
  });
