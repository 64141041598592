import React from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import dayjs from 'dayjs';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { AccountsSearch } from 'app/components/forms';
import { ROLES, TYPES } from 'app/components/auth/constants';
import { success } from 'app/components/snackbars';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { DATE_TIME_FORMAT } from 'app/constants';

import { ReactComponent as mailIcon } from 'images/icon/FontAwesome5Pro/Light/mail.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as buildingLightIcon } from 'images/icon/FontAwesome5Pro/Light/building.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  indexQuery?: any;
  backLink?: string;
}

export default function AccountsList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  backLink,
  indexQuery,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const copyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    success(t(translations.ui.notificationMessages.copiedToClipboard));
  };

  const columns = [
    {
      Header: t(translations.table.columns.accountId),
      accessor: 'id',
      Cell: ({ cell: { value } }: { cell: Cell }) => `ACC-${value}`,
      maxWidth: 135,
    },
    {
      Header: t(translations.table.columns.pAHEmail),
      accessor: 'email',
      Cell: ({ cell: { value } }: { cell: Cell }) => value,
      minWidth: 270,
      maxWidth: 270,
    },
    {
      Header: t(translations.table.columns.type),
      accessor: 'isZombie',
      disableSortBy: true,
      Cell: () => '-',
      maxWidth: 70,
    },
    {
      Header: t(translations.table.columns.companies),
      accessor: ({ companies, accountId }) => {
        return { accountId, companies };
      },
      contentBuilder: row => row?.companies?.length || '-',
      disableSortBy: true,
      customProps: row => `/companies/${row.accountId}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
      maxWidth: 120,
    },
    {
      Header: t(translations.table.columns.signatures),
      accessor: 'signatureUsers',
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) => value?.length || '-',
      maxWidth: 120,
    },
    {
      Header: t(translations.table.columns.dateJoined),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) =>
        dayjs(value).format(DATE_TIME_FORMAT),
      maxWidth: 225,
    },
    {
      Header: t(translations.table.columns.lastPayment),
      disableSortBy: true,
      Cell: () => '-',
      maxWidth: 225,
    },
    {
      Header: t(translations.table.columns.lastEarnings),

      disableSortBy: true,
      Cell: () => '-',
      maxWidth: 150,
    },
    {
      Header: t(translations.table.columns.quickActions),
      className: 'table-action-cell',
      accessor: ({ email, id, accountId }) => {
        return { id, email, accountId };
      },
      disableSortBy: true,
      minWidth: 200,
      Cell: ({ cell: { value } }: { cell: Cell }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              href={`/accounts/${value.id}/edit`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editAccount)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={pencilIcon}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </Link>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.copyEmail)}
              >
                <SvgIcon
                  component={mailIcon}
                  viewBox="0 0 512 512"
                  onClick={() => copyEmail(value.email)}
                />
              </Tooltip>
            </span>
            <Link
              href={`/companies/${value.accountId}`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.companies)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={buildingLightIcon}
                  viewBox="0 0 448 512"
                />
              </Tooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <Paper
      backLink={backLink}
      header={
        <Typography variant="h5">{t(translations.user.accounts)}</Typography>
      }
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <AccountsSearch name="filter" setGlobalFilter={setGlobalFilter} />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={{
          globalFilter: {
            type: { $eq: TYPES.PAID },
            'filterRoles.name': {
              $in: [ROLES.ACCOUNT_HOLDER_PRIMARY],
            },
          },
          sortBy: [
            {
              id: 'createdAt',
              desc: true,
            },
          ],
        }}
      />
    </Paper>
  );
}
