import { ROLES } from 'app/components/auth';

export interface MenuItem {
  readonly name: string;
  readonly to: string;
  readonly icon: React.ReactElement;
  readonly iconSize?: number;
  readonly show?: boolean;
  readonly exact?: boolean;
  readonly disabled?: boolean;
  readonly activeRoutes?: string[];
  readonly counter?: string | number;
  readonly allowedForRoles?: ROLES[];
}
export interface Item {
  readonly summary: React.ReactNode;
  readonly switchProps?: {
    switchChange?: (...args: any) => void;
    name: string;
  };
  readonly content?: React.ReactNode;
  readonly tabStep?: boolean;
  readonly classNameItem?: string;
  readonly isActive?: boolean;
  readonly onTabClick?: (...args: any) => void;
}

export enum ACCOUNT_HOLDER_STEPS {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENS,
  EIGHTS,
  __LENGTH,
}
