import { RequestQueryBuilder } from '@dataui/crud-request';
import { QueryKey, QueryOptions } from 'react-query';

export interface PaginatedResponse<T> {
  count: number;
  total: number;
  page: number;
  pageCount: number;
  data: T[];
  info: any;
}

export enum USER_TYPES {
  PUBLIC = 'public',
  INACTIVE = 'inactive',
  DUMMY = 'dummy',
  HIDDEN = 'hidden',
  DELETED = 'deleted',
  TRIAL = 'trial',
  PAID = 'paid',
  PAID_WAITING = 'paid_waiting',
}

export interface ChildIndex<R> {
  key?: QueryKey;
  builder?: RequestQueryBuilder;
  config?: QueryOptions<PaginatedResponse<R>>;
}
