import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { ApiError } from 'app/config/axios';
import { ChildIndex, PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { SignatureEntity } from '../interfaces';

const endpoint = '/signatures';

export const signatureStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, SignatureEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const signatureUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, SignatureEntity>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const signatureShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, SignatureEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const signatureIndex = queryData =>
  apiClient.get<any, PaginatedResponse<SignatureEntity>>(endpoint, {
    params: queryData,
  });

export const useSignatureIndex: <T = PaginatedResponse<SignatureEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useSignatureIndex', any], any>(
    ['useSignatureIndex', queryData],
    () => signatureIndex(queryData),
    options,
  );

export const useSignatureStore = (
  options?: MutationOptions<SignatureEntity, FormData, any>,
) =>
  useMutation<SignatureEntity, FormData, any>(data => signatureStore(data), {
    ...options,
  });

export const useSignatureUpdate = (
  id: number,
  options?: MutationOptions<SignatureEntity, any>,
) =>
  useMutation<SignatureEntity, any, any>(
    ['signatureUpdate'],
    data => signatureUpdate(id, data),
    {
      ...options,
    },
  );

export const useSignatureShow = (
  id: number,
  options?: QueryOptions<SignatureEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<SignatureEntity, ['signatureShow', number], any>(
    [key || 'signatureShow', id],
    () => signatureShow(id, queryData),
    options,
  );

export const useSignatureFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<SignatureEntity>, ApiError>,
): {
  data: PaginatedResponse<SignatureEntity> | undefined;
  isLoading: boolean;
  refetch: () => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useSignatureIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};

export const signatureClone = (id: number): Promise<SignatureEntity> => {
  return apiClient.post(`${endpoint}/ghosts/${id}/clone`);
};

export const useSignatureClone = (
  options?: MutationOptions<SignatureEntity, any, any>,
) =>
  useMutation<any, SignatureEntity, any>(
    ['useSignatureClone'],
    id => signatureClone(id),
    {
      ...options,
    },
  );

export const signatureDelete = (id: number): Promise<SignatureEntity> => {
  return apiClient.delete(`${endpoint}/ghosts/${id}`);
};

export const useSignatureDelete = (
  options?: MutationOptions<SignatureEntity, any, any>,
) =>
  useMutation<any, SignatureEntity, any>(
    ['useSignatureDelete'],
    id => signatureDelete(id),
    {
      ...options,
    },
  );
