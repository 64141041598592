import { createStyles } from '@material-ui/core/styles';

import {
  boxShadow,
  colorPrimary,
  colorWhite,
  colorDanger,
  colorPrimaryLight,
  textFieldHorizontalSize,
  colorPrimaryExtraLight,
} from 'configs/variables';

const selectStyle = () =>
  createStyles({
    // Select
    selectWrapper: {},
    selectMain: {},
    selectPaper: {
      backgroundColor: colorWhite,
      margin: '5px 0',
      padding: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      ...boxShadow,

      '& .select-list': {
        padding: '10px 0',
        maxHeight: '200px !important',

        '& .select-option': {
          minHeight: 'auto',
          padding: `10px ${textFieldHorizontalSize}px`,
          whiteSpace: 'initial',

          '&:hover': {
            color: colorWhite,
            backgroundColor: colorPrimaryExtraLight,

            '@media (hover: none)': {
              backgroundColor: colorPrimaryExtraLight,
            },
          },
          '&[aria-selected="true"]': {
            '&, &:hover': {
              color: colorWhite,
              backgroundColor: colorPrimaryLight,
            },
          },
          '&:not(:last-child)': {
            borderBottom: `1px solid ${colorWhite}`,
          },
          '& .select-option-icon': {
            fontSize: '1em',
            marginLeft: '0.35em',
            marginTop: -2,
          },
          '& .option-country': {
            display: 'flex',
            alignItems: 'center',

            '& .option-country-icon': {
              clipPath: 'circle()',
              width: 30,
              minWidth: 30,
              marginRight: 8,
            },
          },
        },
      },
    },
    selectArrowDownIcon: {
      color: 'inherit',

      '.text-input-focused &': {
        color: colorPrimary,
      },
      '.text-input-error &': {
        color: colorDanger,
      },
      '.text-field-adorned-end &': {
        right: 45,
      },
    },
  });

export default selectStyle;
