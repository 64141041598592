export const MAX_IMAGE_DIMENSION = 6000;
export const MAX_FILE_SIZE_MB = 15;
export const DEFAULT_IMAGE_NAME = 'image';

export const DEFAULT_CANVAS_OPTIONS = {
  minWidth: 256,
  minHeight: 256,
  maxWidth: 2048,
  maxHeight: 2048,
  fillColor: '#fff',
  imageSmoothingEnabled: true,
  imageSmoothingQuality: 'high',
};

export const DEFAULT_CANVAS_OPTIONS_WO_DIM = {
  fillColor: '#fff',
  imageSmoothingEnabled: true,
  imageSmoothingQuality: 'high',
};

export const ICON_IMAGES_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
];

export const IMAGES_STATIC_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];

export const IMAGES_ANIMATED_FORMATS = [
  'image/gif',
  'image/bpg',
  'image/apng',
  'image/webp',
];

export const IMAGES_FORMATS = [
  ...IMAGES_STATIC_FORMATS,
  ...IMAGES_ANIMATED_FORMATS,
];
