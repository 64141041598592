import {
  MutationOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  QueryObserverOptions,
} from 'react-query';

import { apiClient, ApiError } from 'app/config/axios';
import { ChildIndex, PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { CompanyEntity } from '../interfaces';

const endpoint = '/companies';
const deleteTrialEndpoint = '/companies/trials';
const ghostEndpoint = '/companies/ghosts';

export const companyStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, CompanyEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const companyUpdate = (id, data) => {
  return apiClient.patch<any, CompanyEntity>(`${endpoint}/${id}`, data);
};

export const companyShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, CompanyEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const companyIndex = queryData =>
  apiClient.get<any, PaginatedResponse<CompanyEntity>>(endpoint, {
    params: queryData,
  });

export const trialDelete = companyId => {
  return apiClient.delete(`${deleteTrialEndpoint}/${companyId}`);
};

export const useTrialDelete = () =>
  useMutation(['trialDelete'], companyId => trialDelete(companyId));

export const companyDelete = id => {
  return apiClient.delete(`${ghostEndpoint}/${id}`);
};

export const useCompanyDelete = () =>
  useMutation(['useCompanyDelete'], id => companyDelete(id));

export const useCompanyIndex: <T = PaginatedResponse<CompanyEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexCompanies', any], any>(
    ['indexCompanies', queryData],
    () => companyIndex(queryData),
    options,
  );

export const useCompanyStore = (
  options?: MutationOptions<CompanyEntity, FormData, any>,
) =>
  useMutation<CompanyEntity, FormData, any>(data => companyStore(data), {
    ...options,
  });

export const useCompanyUpdate = (
  id: number,
  options?: MutationOptions<CompanyEntity, any, any>,
) =>
  useMutation<CompanyEntity, any, any>(
    ['companyUpdate'],
    data => companyUpdate(id, data),
    {
      ...options,
    },
  );

export const useCompanyShow = (
  id: number,
  options?: QueryObserverOptions<CompanyEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<CompanyEntity, ['companyShow', number], any>(
    [key || 'companyShow', id],
    () => companyShow(id, queryData),
    options,
  );

export const useCompanyFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<CompanyEntity>, ApiError>,
): {
  data: PaginatedResponse<CompanyEntity> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useCompanyIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};

export const useCompanyFilter = ({
  key = ['indexCompany'],
  builder,
  config = {},
}: ChildIndex<CompanyEntity>) => {
  const query = builder?.query() ?? '';
  return useQuery<PaginatedResponse<CompanyEntity>, any>(
    [key, builder],
    () => apiClient.get(`${endpoint}?${query}`),
    {
      ...config,
      retry: false,
    },
  );
};

export const companyClone = (id: number): Promise<CompanyEntity> => {
  return apiClient.post(`${ghostEndpoint}/${id}/clone`);
};

export const useCompanyClone = (
  options?: MutationOptions<CompanyEntity, any, any>,
) =>
  useMutation<any, CompanyEntity, any>(
    ['useCompanyClone'],
    id => companyClone(id),
    {
      ...options,
    },
  );
