/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

const SavingContext = React.createContext({
  setIsSaving: (value: boolean) => {},
  setIsSaved: (value: boolean) => {},
});

export default SavingContext;
