import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { translations } from 'locales/i18n';
import { error } from 'app/components/snackbars';
import {
  ADMIN_TOKEN,
  useAuthUser,
} from 'app/components/auth/hooks/useAuthUser';
import { destroyImpersonateUser } from 'app/components/auth/utils/impersonateUserStorage';

import Dropdown, { Props as DropdownProps } from './Dropdown';
import { Loader } from '../../Loader';
import UserDropdown from './UserDropdown';

import styles from '../styles';

const useStyles = makeStyles(styles);

const ImpersonateDropdown = (props: DropdownProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    impersonate,
    refetch,
    impersonateIsLoading,
    setImpersonateUser,
    setToken,
  } = useAuthUser();
  const navigate = useNavigate();

  const handleImpersonate = async user => {
    try {
      setImpersonateUser(user);
      await impersonate({ userId: user.id, email: user.email });
      (refetch() as any).then(() => {
        navigate('/');
      });
    } catch (err) {
      error(err.message);
    }
  };

  const handleCancelImpersonate = async () => {
    try {
      await destroyImpersonateUser();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const adminToken = JSON.parse(localStorage.getItem(ADMIN_TOKEN));
      setToken(adminToken);
      (refetch() as any).then(() => {
        navigate('/');
      });
    } catch (err) {
      error(err.message);
    }
  };

  return (
    <>
      <Loader loading={impersonateIsLoading} />
      <Dropdown
        {...props}
        classNamePopper={classes.impersonateDropdown}
        classNameScrollbar={classes.dropdownScroll}
      >
        {() => (
          <div>
            <div className={classes.impersonateText}>
              <Typography variant="h6">
                {t(translations.impersonate.header)}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.impersonateSubText}
              >
                {t(translations.impersonate.subHeader)}
              </Typography>
            </div>
            <div className={classes.impersonateSearchBlock}>
              <Formik
                initialValues={{}}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={() => {}}
                enableReinitialize
              >
                <UserDropdown
                  name={'user'}
                  onChange={async user => {
                    if (user) {
                      await handleImpersonate(user);
                    } else {
                      await handleCancelImpersonate();
                    }
                  }}
                  required
                  disabled={impersonateIsLoading}
                />
              </Formik>
            </div>
          </div>
        )}
      </Dropdown>
    </>
  );
};

export default memo(ImpersonateDropdown);
