import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalContent } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';
import { Loader } from 'app/components/ui/Loader';
import { IframeResizer } from 'app/components/ui/CustomIframeResizer';

import { useVersionPreviewShow } from '../hooks/versionsCrud';

interface Props {
  onClose: () => void;
  open: boolean;
  versionId: string;
  isTimeplate?: boolean;
  fullScreen?: boolean;
  withReplace?: boolean;
}

const PreviewModal: React.FC<Props> = ({
  onClose,
  open,
  versionId,
  isTimeplate = true,
  fullScreen = false,
  withReplace = true,
}) => {
  const { t }: { t: any } = useTranslation();
  const {
    isLoading,
    isFetching,
    data: preview,
    refetch,
  } = useVersionPreviewShow(
    Number(versionId),
    withReplace,
    { refetchOnMount: true },
    undefined,
    'timeplateDataHtmlPreview',
  );

  useEffect(() => {
    return () => {
      if (!open) refetch();
    };
  }, [open, refetch]);

  return (
    <Modal
      open={open}
      maxWidth={false}
      fullWidth={false}
      fullScreen={fullScreen}
    >
      <ModalTitle onClose={onClose}>
        {[
          t(
            isTimeplate
              ? translations.ui.titles.timeplateHtmlPreview
              : translations.ui.titles.signatureHtmlPreview,
          ),
          isTimeplate ? preview?.timeplateTitle : preview?.signatureTitle,
        ]
          .filter(Boolean)
          .join(' - ')}
      </ModalTitle>
      <ModalContent>
        <Loader loading={isLoading || isFetching} fullPage={false} />
        {preview?.preview ? (
          <IframeResizer
            title="Timeplate Html Preview"
            srcDoc={preview.preview}
          />
        ) : (
          <div className="empty-content">Timeplate code is empty</div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PreviewModal;
