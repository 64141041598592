import React from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { translations } from 'locales/i18n';
import { ACCOUNT_HOLDER_STEPS } from 'app/containers/AccountHolderPage/interfaces';

import { ReactComponent as smileIcon } from 'images/icon/FontAwesome5Pro/Light/smile.svg';
import { ReactComponent as arrowRightIcon } from 'images/icon/FontAwesome5Pro/Light/arrow-right.svg';
import { ReactComponent as assignMeIcon } from 'images/icon/FontAwesome5Pro/Light/assign-me-icon.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  activeStep: number;
  handleNext: () => void;
  assignToMeHandle?: () => void;
  isValid?: boolean;
  assignToMe?: boolean;
}

export const StepFooter = ({
  activeStep,
  handleNext,
  assignToMeHandle,
  isValid = true,
  assignToMe = false,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = event => {
    event.currentTarget.disabled = true;
    handleNext();
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerTextBlock}>
        <SvgIcon
          className={classes.footerIcon}
          component={smileIcon}
          viewBox="0 0 21 20"
        />
        <Typography className={classes.footerText}>
          {t(translations.accountHolderOnboarding.footerText)}
        </Typography>
      </div>

      {/* {assignToMe ? (
        <Button
          variant="contained"
          startIcon={<SvgIcon component={assignMeIcon} viewBox="0 0 21 22" />}
          onClick={assignToMeHandle}
        >
          {t(translations.accountHolderOnboarding.steps.second.text5)}
        </Button>
      ) : null} */}

      <Button
        color={'secondary'}
        variant="contained"
        startIcon={<SvgIcon component={arrowRightIcon} viewBox="0 0 18 15" />}
        disabled={!isValid}
        onClick={handleClick}
        style={{ height: '30px !important' }}
      >
        {activeStep === ACCOUNT_HOLDER_STEPS.EIGHTS
          ? t(translations.ui.buttons.finish)
          : t(translations.ui.buttons.next)}
      </Button>
    </div>
  );
};
