import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { translations } from 'locales/i18n';
import { LinkCell, Table } from 'app/components/table';
import { DATE_FORMAT } from 'app/constants';
import { Paper } from 'app/components/layouts';
import { ROLES } from 'app/components/auth';
import { useConfirmation } from 'app/components/ui/Modal';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { TrialSearch } from 'app/components/forms/components/Edit/Search/TrialSearch';
import { COMPANY_TYPES } from 'app/containers/constants';
import {
  TRIAL_TYPES,
  useCompanyUpdate,
} from 'app/components/entities/companies';
import {
  allowExtend as calculateAllowExtend,
  getDaysToTrialEnd,
} from 'app/components/onboarding/components/utils';

import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as mapMarkerAltLightIcon } from 'images/icon/FontAwesome5Pro/Light/map-marker-alt.svg';
import { ReactComponent as addressCardLightIcon } from 'images/icon/FontAwesome5Pro/Light/address-card.svg';
import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as peopleIcon } from 'images/icon/FontAwesome5Pro/Light/people.svg';
import { ReactComponent as clockIcon } from 'images/icon/FontAwesome5Pro/Light/clock.svg';
import { ReactComponent as banLightIcon } from 'images/icon/FontAwesome5Pro/Light/ban.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalBlockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-block.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../../styles';

const useStyles = makeStyles(styles);

dayJs.extend(utc);
dayJs.extend(timezone);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  backLink?: string;
  handleTrialDelete: (id: any) => void;
  indexQuery: any;
  refetch: () => void;
  type: TRIAL_TYPES;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  backLink,
  handleTrialDelete,
  indexQuery,
  type,
  refetch,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState();
  const now = dayJs();
  const isActive = type === TRIAL_TYPES.ACTIVE;

  const { mutateAsync } = useCompanyUpdate(Number(companyId));

  const [showTrialDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleTrialDelete(companyId),
    description: (
      <>
        <Typography>
          {t(translations.ui.titles.confirmationTrialDelete)}
        </Typography>
        <Typography>
          {t(translations.ui.titles.additionalConfirmationTrialDelete)}
        </Typography>
      </>
    ),
    agreeLabel: t(translations.ui.buttons.delete),
    title: t(translations.ui.titles.deleteTrial),
    titleBlockStyle: 'default',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: 'button-danger',
    },
  });

  const [showInactiveConfirmation] = useConfirmation({
    onSubmit: async () => {
      await mutateAsync({ trialPeriodEndDate: dayJs().subtract(1, 'days') });
      refetch();
    },
    description: t(translations.ui.titles.confirmationMessageMakeInactive),
    agreeLabel: t(translations.ui.buttons.makeInactive),
    modalIcon: modalBlockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: 'button-danger',
    },
  });

  const handleTrialDeleteClick = useCallback(
    id => {
      setCompanyId(id);
      showTrialDeleteConfirmation();
    },
    [showTrialDeleteConfirmation],
  );
  const handleTrialMakeInactiveClick = useCallback(
    id => {
      setCompanyId(id);
      showInactiveConfirmation();
    },
    [showTrialDeleteConfirmation],
  );

  const columns = [
    {
      Header: t(translations.table.columns.companyName),
      accessor: 'name',
      customProps: row => `/trial-locations/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
      width: 170,
    },
    {
      Header: t(translations.table.columns.pAH),
      disableSortBy: true,
      accessor: 'trialUsers',
      Cell: ({ cell: { value } }) => {
        const accountHolders =
          value?.filter(({ roles }) => {
            return roles.some(({ name }) => name === ROLES.ACCOUNT_HOLDER);
          }) || [];

        return (
          <>
            {accountHolders.map(({ id, fullName }) => (
              <div key={id}>{fullName}</div>
            ))}
          </>
        );
      },
    },
    {
      Header: t(translations.table.columns.partner),
      accessor: 'partner.name',
      width: 80,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      width: 80,
      Cell: ({ cell }: { cell: Cell }) => cell.value,
    },
    isActive
      ? {
          Header: t(translations.table.columns.dateLeft),
          accessor: 'trialPeriodEndDate',
          Cell: ({ cell: { row } }) => {
            if (
              row.original.trialPeriodStartDate &&
              row.original.trialPeriodEndDate
            ) {
              return getDaysToTrialEnd(
                row.original.timeZone,
                row.original.trialPeriodEndDate,
                calculateAllowExtend(
                  row.original.timeZone,
                  row.original.trialPeriodStartDate,
                  row.original.trialPeriodEndDate,
                ),
              );
            }

            return '-';
          },
        }
      : null,
    {
      Header: t(translations.table.columns.dateJoined),
      accessor: 'trialPeriodStartDate',
      Cell: ({ cell: { row } }) => {
        if (row.original.trialPeriodStartDate) {
          return dayJs
            .tz(row.original.trialPeriodStartDate, row.original.timeZone)
            .format(DATE_FORMAT);
        }

        return '-';
      },
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      minWidth: 370,
      className: 'table-action-cell',
      accessor: ({ id, trialUsers }) => {
        return { id, trialUsers };
      },
      Cell: ({
        cell: {
          value: { id, trialUsers },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link href={`/trials/${id}/edit`} className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={pencilIcon}
                  viewBox="0 0 576 512"
                />
              </Tooltip>
            </Link>
            <Link
              href={`/trial-locations/${id}`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.locations)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={mapMarkerAltLightIcon}
                  viewBox="0 0 384 512"
                />
              </Tooltip>
            </Link>
            <Link
              href={`/trial-signatures/${id}`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.signatures)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={addressCardLightIcon}
                  viewBox="0 0 576 512"
                />
              </Tooltip>
            </Link>
            <Link
              href={`/trial-signature-users/${id}`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.users)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={userLightIcon}
                  viewBox="0 0 448 512"
                />
              </Tooltip>
            </Link>
            <Link href={`/trial-users/${id}`} className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.people)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={peopleIcon}
                  viewBox="0 0 640 512"
                />
              </Tooltip>
            </Link>
            <Link
              href={`/trial-users/${trialUsers[0]?.id}/edit`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.extend)}
              >
                <SvgIcon component={clockIcon} viewBox="0 0 18 19" />
              </Tooltip>
            </Link>
            {isActive ? (
              <span
                className={classes.actionItem}
                onClick={() => handleTrialMakeInactiveClick(id)}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.names.trials.inactive)}
                >
                  <SvgIcon component={banLightIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.trials.delete)}
              >
                <SvgIcon
                  component={trashIcon}
                  onClick={() => handleTrialDeleteClick(id)}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const initialState = {
    globalFilter: {
      type: COMPANY_TYPES.TRIAL,
      originalCompanyId: { $isnull: true },
      ...(isActive
        ? {
            $and: [
              { trialPeriodEndDate: { $notnull: true } },
              { trialPeriodEndDate: { $gte: now } },
              { originalCompanyId: { $isnull: true } },
            ],
          }
        : {
            $or: [
              { trialPeriodEndDate: { $isnull: true } },
              { trialPeriodEndDate: { $lt: now } },
            ],
          }),
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  return (
    <Paper backLink={backLink}>
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <TrialSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
