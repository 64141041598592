import { ROLES } from 'app/components/auth';

export const sideBarActionConfig = {
  billingAdmin: [
    ROLES.ACCOUNT_HOLDER,
    ROLES.ACCOUNT_HOLDER_PRIMARY,
    ROLES.COMPANY_ADMIN_PRIMARY,
    ROLES.COMPANY_ADMIN,
    ROLES.LOCATION_ADMIN_PRIMARY,
    ROLES.LOCATION_ADMIN,
    ROLES.BILLING_ADMIN,
  ],
  locationAdmin: [
    ROLES.ACCOUNT_HOLDER,
    ROLES.ACCOUNT_HOLDER_PRIMARY,
    ROLES.COMPANY_ADMIN_PRIMARY,
    ROLES.COMPANY_ADMIN,
    ROLES.LOCATION_ADMIN_PRIMARY,
    ROLES.LOCATION_ADMIN,
  ],
  companyAdmin: [
    ROLES.ACCOUNT_HOLDER,
    ROLES.ACCOUNT_HOLDER_PRIMARY,
    ROLES.COMPANY_ADMIN_PRIMARY,
    ROLES.COMPANY_ADMIN,
  ],
  accountHolder: [ROLES.ACCOUNT_HOLDER, ROLES.ACCOUNT_HOLDER_PRIMARY],
};
