import React from 'react';

import { Text } from 'app/components/forms';

export default function Versions({ versions, ...props }) {
  const selectOptions = versions
    ?.map(({ id, versionNumber, notes }) => ({
      id,
      name: [`1.${versionNumber}`, notes].filter(Boolean).join(' - '),
    }))
    ?.sort((v1, v2) => +v2.id - +v1.id);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Text type="text" {...props} select options={selectOptions} />;
}
