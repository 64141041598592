import React from 'react';
import { useDebounce } from 'react-use';

import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { Search } from './Search';
import { SEARCHING_DELAY } from './constants';

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  className?: string;
  initialState: any;
  setGlobalFilter: (...args: any) => void;
}

export function TrialSearch({
  setGlobalFilter,
  initialState: { globalFilter },
  ...props
}: Props) {
  const [val, setVal] = React.useState('');

  useDebounce(
    () => {
      let data: Record<string, any> = {};
      if (val) {
        data.$and = [
          {
            $or: [
              {
                $and: val.split(' ').map(word => ({
                  'partner.name': {
                    $contL: word,
                  },
                })),
              },
              {
                $and: val.split(' ').map(word => ({
                  'trialUsers.fullName': {
                    $contL: word,
                  },
                })),
              },
            ],
          },
          { ...globalFilter },
        ];
      } else {
        data = globalFilter;
      }

      setGlobalFilter(data);
    },
    SEARCHING_DELAY,
    [val],
  );

  return (
    <Search
      handleInputChange={({ currentTarget }) => {
        setVal(currentTarget.value);
      }}
      {...props}
    />
  );
}
