import { createStyles, Theme } from '@material-ui/core/styles';
import {
  tableBorderColor,
  tableBackgroundLight,
  colorPurple,
  colorPrimary,
  colorSecondary,
  textGrey,
  colorYellow,
  textDisabled,
  colorDanger,
} from 'configs/variables';

const cellActionSize = 30;

export default (theme: Theme) =>
  createStyles({
    tableBlock: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    table: {
      flex: 'auto 1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'auto',
    },
    tableHead: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      [theme.breakpoints.down('sm')]: {
        minWidth: '0 !important',
      },
    },
    tableBody: {
      display: 'flex',
      flexDirection: 'column',
      flex: '99999 1 auto',
      [theme.breakpoints.down('sm')]: {
        minWidth: '0 !important',
      },
    },
    tableRow: {
      display: 'flex',
      flex: '1 0 auto',
      position: 'relative',
      minWidth: '0 !important',

      '& .table-failed-invoice': {
        color: colorDanger,
      },

      '& .action-cell-wrapper': { display: 'flex' },
      '& .table-action-cell': {
        textAlign: 'right',
        justifyContent: 'flex-end',
      },
      '& .table-action-cell .dropdown-button, & .table-action-dropdown.dropdown-button':
        {
          fontSize: 14,
          fontWeight: 400,
          minHeight: 0,
        },
      '& .table-action-button': {
        padding: 5,
        '& svg': {
          fontSize: 20,
        },
      },
      '& .table-info-first-cell': {
        lineHeight: 1,
      },
      '& .table-status-cell': {
        [theme.breakpoints.up('md')]: {
          lineHeight: 1,
          flex: '40 0 auto !important',
          minWidth: '40px !important',
          width: '40px !important',
          maxWidth: '40px !important',
          paddingRight: '0 !important',
        },
      },
      '& .table-status-cell, & .mobile-cell-head-remove': {
        '& .mobile-cell-head': {
          display: 'none !important',
        },
      },
      '& .table-status-icon': {
        fontSize: 18,
        color: colorSecondary,
        marginTop: 2,
      },
      '& .table-status-icon-inactive': {
        color: colorYellow,
      },
      '&.table-row-inactive': {
        '& .table-status-color, & .table-status-icon': {
          color: `${textGrey} !important`,
        },
      },
      '& .table-status-icon-inactive-grey': {
        color: textDisabled,
      },
      '& .table-status-icon-inactive-yellow': {
        color: colorYellow,
      },
      '& .table-status-icon-inactive-red': {
        color: colorDanger,
      },
      '& .table-entity-name-inactive': {
        color: textDisabled,
        pointerEvents: 'none',
      },
      '& .table-entity-name-clickable-inactive': {
        color: textDisabled,
      },
      [theme.breakpoints.up('md')]: {
        '& .table-action-button': {
          opacity: 0,
          visibility: 'hidden',
        },
        '&:hover > .table-action-cell .table-action-button': {
          opacity: 1,
          visibility: 'visible',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        padding: ' 10px 15px',
        border: 'none',
        '&:not(:last-child)': {
          borderBottom: `1px solid ${tableBorderColor}`,
        },
        '& .table-action-cell': {
          top: 12,
          right: 10,

          '& .dropdown-button': {
            width: 25,
            minWidth: 25,
            height: 25,
            padding: 5,
          },
        },
        '& $expandButtonCell, & .table-info-first-cell': {
          display: 'block',
          position: 'absolute',
          padding: '0 !important',
          width: `${cellActionSize}px !important`,
          minWidth: `${cellActionSize}px !important`,
          border: 'none !important',
          top: 17,
          left: 17,
          '& + $tableCell': {
            borderTop: 'none',

            '&.table-header-cell-mobile': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    tableRowCanExpand: {
      [theme.breakpoints.down('sm')]: {
        '& > $tableCell.table-header-cell-mobile': {
          paddingLeft: cellActionSize,

          '&.left-indent': {
            paddingLeft: cellActionSize * 2,
          },
        },
        '& .table-info-first-cell': {
          left: 44,
        },
      },
    },
    tableHeadRow: {},
    tableBodyRow: {},
    tableStriped: {
      '& $tableBodyRow': {
        '&:nth-of-type(even)': {
          backgroundColor: tableBackgroundLight,
        },
      },
    },
    tableCell: {
      flex: '1 0 0',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto 0',
      [theme.breakpoints.up('md')]: {
        '&.text-center': {
          justifyContent: 'center',
          textAlign: 'center',
        },
        '&.text-right': {
          justifyContent: 'flex-end',
          textAlign: 'right',
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        maxWidth: '100% !important',
        minWidth: '0 !important',
        display: 'flex',
        alignItems: 'center',
        padding: 7,

        '&, &:first-child, &:last-child': {
          paddingLeft: 7,
          paddingRight: 7,
        },
        '&.table-header-cell-mobile': {
          order: -1,
          paddingRight: cellActionSize,
          borderTop: 'none',
          fontWeight: 700,

          '&.left-indent': {
            paddingLeft: cellActionSize,
          },
          '&:not(:first-child)': {
            // borderBottom: `1px solid ${tableBorderColor}`,
          },
          '& $mobileCellHead': {
            display: 'none',
          },
          '& .data-field-data': {
            padding: 0,
          },
        },
      },
      '& .text-field': {
        minWidth: 0,
      },
    },
    tableHeadCell: {
      position: 'relative',
      display: 'block',
    },
    tableHeadCellIsSort: {},
    tableHeadCellIsSorted: {},
    tableHeaderCellSort: {
      position: 'absolute',
      top: 'calc(50% - 9px)',
      display: 'inline-flex',
    },
    tableHeaderCellSortIcon: {
      color: colorPurple,
      fontSize: 16,
      marginLeft: 5,
    },
    tableHeaderCellSortIconOpen: {
      transform: 'rotate(180deg)',
    },

    tableBodyCell: {},
    tableFooter: {
      [theme.breakpoints.down('sm')]: {
        borderTopWidth: 2,
      },
    },
    tableFooterRow: {},
    tablePaginationButtons: {
      flexShrink: 0,
      marginLeft: 10,
      '& button': {
        padding: 6,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 5,
        '& button': {
          padding: 3,
        },
      },
    },
    tableNoDataRow: {},
    tableNoDataCell: {
      fontWeight: 500,
      textAlign: 'center',
      justifyContent: 'center !important',
    },
    mobileCellHead: {
      color: colorPrimary,
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 600,
      width: 115,
      marginRight: 5,
      flexShrink: 0,

      '.mobile-small-label &': {
        width: 80,
      },
    },
    cellContent: {
      minWidth: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        width: cellActionSize,
        flex: `${cellActionSize} 0 auto`,
        textAlign: 'left',
      },
    },

    // Expand Table
    expandButtonCell: {
      padding: '5px !important',
    },
    expandButtonIcon: {},
    expandButtonIconOpen: {
      transform: 'rotate(180deg)',
    },
    tableCollapseContainer: {
      width: '100%',
    },
    tableRowExpand: {
      padding: 0,
      border: 'none !important',
    },
    tableExpandRowBlock: {
      backgroundColor: tableBackgroundLight,
      padding: '10px 5px',

      [theme.breakpoints.down('sm')]: {
        borderTop: `1px solid ${tableBorderColor}`,
      },
      [theme.breakpoints.up('md')]: {
        borderBottom: `1px solid ${tableBorderColor}`,
        padding: '15px',
      },

      '& .table-no-data': {
        fontWeight: 500,
        textAlign: 'center',
      },
    },
    tableCellExpand: {
      padding: '0 !important',

      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      '& $table': {
        border: 'none',
      },
      '& $table, & $tableHeadRow, & $tableRow': {
        backgroundColor: 'transparent',
        borderRadius: 0,
      },
      [theme.breakpoints.down('sm')]: {
        '& $tableRow': {
          padding: 10,
        },
      },
    },
  });
