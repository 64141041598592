import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { ApiError } from 'app/config/axios';
import { PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';
import { IconListEntity } from '../interfaces';

const endpoint = '/icon-lists';

export const listStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, IconListEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const listUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, IconListEntity>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const listDisable = id => {
  return apiClient.patch<any, IconListEntity>(`${endpoint}/${id}`, {
    isActive: false,
  });
};

export const listEnable = id => {
  return apiClient.patch<any, IconListEntity>(`${endpoint}/${id}`, {
    isActive: true,
  });
};

export const listShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, IconListEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const listIndex = queryData =>
  apiClient.get<any, PaginatedResponse<IconListEntity>>(endpoint, {
    params: queryData,
  });

export const base64Icons = queryData => () =>
  apiClient.get<FormData, IconListEntity>(`checksvg`, {
    params: queryData,
  });

export const useIconListIndex: <T = PaginatedResponse<IconListEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexCompanies', any], any>(
    ['indexCompanies', queryData],
    () => listIndex(queryData),
    options,
  );

export const useIconListStore = (
  options?: MutationOptions<IconListEntity, FormData, any>,
) =>
  useMutation<IconListEntity, FormData, any>(
    ['iconListStore'],
    data => listStore(data),
    {
      ...options,
    },
  );

export const useIconListUpdate = (
  id: number,
  options?: MutationOptions<IconListEntity, any, any>,
) =>
  useMutation<IconListEntity, any, any>(
    ['iconListUpdate'],
    data => listUpdate(id, data),
    {
      ...options,
    },
  );

export const useIconListDisable = (
  options?: MutationOptions<IconListEntity, any, any>,
) =>
  useMutation<IconListEntity, any, any>(
    ['iconListDisable'],
    id => listDisable(id),
    {
      ...options,
    },
  );

export const useIconListEnable = (
  options?: MutationOptions<IconListEntity, any, any>,
) =>
  useMutation<IconListEntity, any, any>(
    ['iconListEnable'],
    id => listEnable(id),
    {
      ...options,
    },
  );

export const useIconListShow = (
  id: number,
  options?: QueryObserverOptions<IconListEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<IconListEntity, ['listShow', number], any>(
    [key || 'listShow', id],
    () => listShow(id, queryData),
    options,
  );

export const useListFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<IconListEntity>, ApiError>,
): {
  data: PaginatedResponse<IconListEntity> | undefined;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isFetching, refetch } = useIconListIndex(queryData, options);

  return { data, isLoading: isFetching, refetch };
};
