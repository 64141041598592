import { createStyles } from '@material-ui/core/styles';

import { colorBlue4 } from 'configs/variables';

export default () =>
  createStyles({
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    footerTextBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    footerIcon: { marginRight: '4px' },
    footerText: {
      color: colorBlue4,
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
    },
  });
