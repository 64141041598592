import React, { memo } from 'react';

import {
  ROLES_AVATAR_COLOR,
  ROLES_STATUSES_TITLE,
} from 'app/components/auth/constants';
import { Avatar } from 'app/components/ui/Avatar';
import { USER_SIGNATURES_STATUSES } from 'app/components/entities/queue/types';

export interface Props {
  status: string;
  className?: string;
}

function SignatureRoleNameCell({ status, className }: Props) {
  return (
    <Avatar
      className={className}
      size="small"
      style={
        status !== USER_SIGNATURES_STATUSES.ACTIVE
          ? { borderRadius: '25px', width: '50px' }
          : {}
      }
      name={ROLES_STATUSES_TITLE[status]}
      color={ROLES_AVATAR_COLOR[status]}
    />
  );
}

export default memo(SignatureRoleNameCell);
