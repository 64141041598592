import { LocalStorageStore, ImmortalStorage } from 'immortal-db';

import { ACCOUNT_HOLDER_STEPS } from '../interfaces';

const stores = [LocalStorageStore];
const db = new ImmortalStorage(stores);
const accountHolderStepKey = 'accountHolderStep';
const defaultStep = ACCOUNT_HOLDER_STEPS.FIRST;

export const storeStep = (step = defaultStep) =>
  db.set(accountHolderStepKey, step.toString());

export const getStep = () => db.get(accountHolderStepKey);
