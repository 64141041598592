import React, { useCallback } from 'react';
import { useField } from 'formik';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Radio } from 'app/components/ui/Radio';

type RadioBtn = {
  label: string;
  value: string;
};
export interface Props {
  name: string;
  label?: React.ReactNode;
  customValues?: Array<{ checked: boolean; value: string }>;
  customChange?: (dada: any) => void;
  margin?: 'dense' | 'none' | 'normal';
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  listBtns: RadioBtn[];
}

export default function RadioBtns({
  name,
  customValues,
  customChange,
  listBtns,
}: Props) {
  const [{ value }, , { setValue: setFieldValue, setTouched }] = useField<
    boolean | string
  >(name);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue: string = event.target.value;

      if (customValues) {
        const item = listBtns.find(i => i.value === newValue);
        if (item) {
          newValue = item.value;
        }
      }
      if (customChange) {
        customChange(newValue);
      }
      setFieldValue(newValue);
      setTouched(true);
    },
    [customChange, customValues, listBtns, setFieldValue, setTouched],
  );

  return (
    <>
      {listBtns.map(row => (
        <FormControlLabel
          control={
            <Radio
              onChange={handleChange}
              color="primary"
              value={row.value}
              checked={row.value === value}
            />
          }
          label={row.label}
        />
      ))}
    </>
  );
}
