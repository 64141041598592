import React from 'react';
import { useQuery } from 'react-query';

import { base64Icons } from '../../components/icons/hooks/setsCrud';
import { Box, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

export function Base64IconsPage() {
  const { id, baseColor, featureColor } = useParams<{
    id: string;
    featureColor: string;
    baseColor: string;
  }>();

  const { data } = useQuery(
    ['base64'],
    base64Icons({ id, baseColor, featureColor }),
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const base64 = data?.data;

  return (
    <Grid container>
      <Box m={4}>
        <Grid item xs={12}>
          <b>Got from the API</b>
        </Grid>
        <Grid item xs={12}>
          {base64}
        </Grid>
      </Box>
      <Box m={4}>
        <Grid item xs={12}>
          <b>Render result</b>
        </Grid>
        <Grid item xs={12}>
          {base64 && <div dangerouslySetInnerHTML={{ __html: base64 }} />}
        </Grid>
      </Box>
    </Grid>
  );
}
