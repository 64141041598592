import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorBlue,
  colorBlueDisable,
  colorWhite,
  bgColorLightGrey,
  bgColorLightGrey2,
  borderRadiusSmallSize,
  borderPrimaryColor,
  buttonSize,
  colorPrimaryLight2,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    cropperBlock: {
      position: 'relative',

      '& .cropper-container': {
        width: '100% !important',
      },
      '& .cropper-modal': {
        opacity: '0.65',
      },
    },
    cropperControlBlock: {
      backgroundColor: colorPrimaryLight2,
      display: 'flex',
      justifyContent: 'center',
    },
    editButton: {
      color: colorWhite,
      fontSize: 22,
      display: 'block',
      margin: '0 3px',
      padding: 6,
    },
    cropperControlDivider: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      height: 'auto',
      width: 1,
    },
    cropperTooltip: {
      backgroundColor: 'rgba(97, 97, 97, 0.9)',
      color: colorWhite,
      fontSize: '0.625rem',
      lineHeight: 1.4,
      padding: '4px 8px',
      boxShadow: 'none',
      border: 'none',
    },

    // Upload block
    uploadImageBlock: {
      height: 215,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      backgroundColor: bgColorLightGrey2,
      border: `1px solid ${colorBlue}`,
      borderRadius: borderRadiusSmallSize,

      [theme.breakpoints.up('sm')]: {
        height: 326,
      },
      '&:hover': {
        backgroundColor: bgColorLightGrey,
      },
    },
    uploadImageBlockUploaded: {
      borderColor: borderPrimaryColor,
    },
    uploadButtonArea: {
      width: '100%',
      height: '100%',
      color: colorBlueDisable,
      fontSize: 20,
      fontWeight: 600,
      padding: 15,
      borderRadius: borderRadiusSmallSize,

      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        padding: 30,
      },
    },
    uploadedImage: {
      width: '100%',
      height: '100%',
      borderRadius: borderRadiusSmallSize,

      '& img': {
        width: 'auto',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    uploadedImageButtonBlock: {
      position: 'absolute',
      bottom: 3,
      right: 3,
      display: 'flex',
      flexDirection: 'column',
    },
    uploadedImageButton: {
      height: buttonSize,
      width: buttonSize,
      borderRadius: '50%',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',

      '&:not(:first-child)': {
        marginTop: 7,
      },
      '& svg': {
        fontSize: 25,
      },
    },
  });
