import { AUTO_APPROVALS_TYPE } from 'types';

export const AUTO_APPROVALS_TYPE_OPTIONS = [
  {
    checked: true,
    value: AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
  },
  {
    value: AUTO_APPROVALS_TYPE.OFF_ALL_INVITE,
    checked: false,
  },
];
