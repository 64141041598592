import React, { memo } from 'react';

import { Tooltip } from 'app/components/ui/Tooltip';
import { Avatar } from 'app/components/ui/Avatar';
import { ROLES_AVATAR_COLOR, ROLES_TITLE } from 'app/components/auth/constants';

export interface Props {
  roles: Array<{ name: string; id: number }>;
}

function RoleCell({ roles }: Props) {
  return roles ? (
    <>
      {roles
        .sort((role1, role2) => role2.id - role1.id)
        .map(role => (
          <Tooltip
            key={role.name}
            title={ROLES_TITLE[role.name]}
            size="medium"
            arrow
            placement="top"
            style={{ margin: 3 }}
          >
            <Avatar
              color={ROLES_AVATAR_COLOR[role.name]}
              size="small"
              name={ROLES_TITLE[role.name]}
            />
          </Tooltip>
        ))}
    </>
  ) : (
    <></>
  );
}

export default memo(RoleCell);
