import { createStyles } from '@material-ui/core/styles';

import {
  borderGreyColor,
  colorPrimaryDark,
  colorWhite,
  textPrimary,
} from 'configs/variables';

export default () =>
  createStyles({
    tooltip: {},
    tooltipInitBlock: {
      display: 'inline-flex',
      maxWidth: '100%',
    },

    tooltipArrow: {},

    // Color
    whiteColor: {
      backgroundColor: colorWhite,
      color: textPrimary,
      border: `1px solid ${borderGreyColor}`,

      '& $tooltipArrow': {
        color: colorWhite,

        '&:before': {
          border: `1px solid ${borderGreyColor}`,
        },
      },
    },
    primaryColor: {
      backgroundColor: colorPrimaryDark,
      color: colorWhite,
      border: `1px solid ${colorPrimaryDark}`,

      '& $tooltipArrow': {
        color: colorPrimaryDark,

        '&:before': {
          border: `1px solid ${colorPrimaryDark}`,
        },
      },
    },
    defaultColor: {},

    //Size
    defaultSize: {},
    smallSize: {},
    mediumSize: {
      fontSize: 12,
      fontWeight: 600,
      padding: ' 7px 14px',
    },
    largeSize: {},
  });
