import React from 'react';
import { Formik } from 'formik';
import { apiClient } from 'app/config/axios';

import { TextImage } from 'app/components/forms';
import objectToFormData from 'utils/objectToFormData';

export function TestImage() {
  const onFileUpload = image => {
    const data = { file: image, folder: '', isCircle: false };
    const formData = objectToFormData(data);
    apiClient.patch('http://localhost:3000/onboarding/testImage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Formik onSubmit={() => {}} initialValues={{ image: null }}>
      <TextImage
        name="image"
        variant="outlined"
        type="text"
        onSuccessChange={onFileUpload}
      />
    </Formik>
  );
}
