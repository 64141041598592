import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '545px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
    formContainer: { marginTop: '30px' },
    switchBlock: { display: 'flex', alignItems: 'baseline' },
    switch: { marginRight: '12px' },
    questionIcon: { fontSize: '20px' },
  });
