import React from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import dayjs from 'dayjs';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { ROLES, TYPES } from 'app/components/auth/constants';
import RoleCell from 'app/components/grid/roleCell/RoleCell';
import { UserSearch } from 'app/components/forms';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { DATE_TIME_FORMAT } from 'app/constants';

import { ReactComponent as editLightIcon } from 'images/icon/FontAwesome5Pro/Light/edit.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as paperPlaneLightIcon } from 'images/icon/FontAwesome5Pro/Light/paper-plane.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId?: string;
  backLink?: string;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
  handleResendInvite: (...data: any) => void;
  handleRemoveShareClick: (id: number) => void;
}

export default function TrialPeopleList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  backLink,
  initialStateAdditionalParams = {},
  handleRemoveShareClick,
  indexQuery,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      Header: t(translations.table.columns.name),
      accessor: 'fullName',
      disableSortBy: true,
      customProps: row => `/trial-users/${row.id}/edit`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.roles),
      accessor: 'roles',
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) => <RoleCell roles={value} />,
      className: classes.rolesCell,
    },
    {
      Header: t(translations.table.columns.dateShared),
      accessor: ({ sharedUsers }) => {
        return { sharedUsers };
      },
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) =>
        value?.sharedUsers
          ? dayjs(value?.sharedUsers[0].createdAt).format(DATE_TIME_FORMAT)
          : '-',
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: item => item,
      Cell: ({ cell: { value } }: { cell: Cell }) => {
        const { signatureUsers, id } = value;

        return (
          <div className="action-cell-wrapper">
            <Link
              href={`/trial-signature-users/${signatureUsers[0]?.id}/edit`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={pencilIcon}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </Link>

            <Link
              className={classes.actionItem}
              href={`/trial-users/${id}/edit`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editAccount)}
              >
                <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
              </Tooltip>
            </Link>
            <span className={classes.disabled}>
              <SvgIcon component={paperPlaneLightIcon} viewBox="0 0 512 512" />
            </span>
            <span className={classes.actionItem}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.deleteTrialUser)}
              >
                <SvgIcon
                  component={trashIcon}
                  onClick={() => handleRemoveShareClick(id)}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const initialState = {
    globalFilter: {
      'filterRoles.name': ROLES.TRIAL_USER,
      type: { $ne: TYPES.DELETED },
      $or: [
        { trialCompanyId: companyId },
        { 'co.originalCompanyId': companyId },
      ],
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper
      backLink={backLink}
      header={
        <Typography variant="h5">
          {t(translations.names.trials.people)}
        </Typography>
      }
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <UserSearch
              name="filter"
              setGlobalFilter={setGlobalFilter}
              initialState={initialState}
              isGhost={false}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
