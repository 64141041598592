/**
 * Asynchronously loads the component
 */

import React from 'react';
import { Loader } from 'app/components/ui/Loader';
import { lazyLoad } from 'utils/loadable';

export const CollectionsPage = lazyLoad(
  () => import('./index'),
  module => module.CollectionsPage,
  {
    fallback: <Loader loading />,
  },
);
