import { createStyles, Theme } from '@material-ui/core/styles';

import {
  bgAuthorization,
  colorPrimary,
  colorWhite,
  boxShadow,
} from 'configs/variables';

import authorizationBgUrl from 'images/bg-authorization-page@2x.png';

export default ({ breakpoints }: Theme) =>
  createStyles({
    authorizationContainer: {
      backgroundColor: colorPrimary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
      padding: 28,

      [breakpoints.down('xs')]: {
        paddingTop: 60,
      },
      [breakpoints.up('sm')]: {
        padding: 38,
        backgroundColor: bgAuthorization,
      },
    },
    authorizationBlock: {
      backgroundColor: colorPrimary,
      color: colorWhite,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',

      [breakpoints.down('xs')]: {
        flexGrow: 1,
        justifyContent: 'space-between',
      },
      [breakpoints.up('sm')]: {
        width: 500,
        borderRadius: 20,
        maxWidth: '100%',
        ...boxShadow,
      },
    },
    withDecoratorBlock: {
      [breakpoints.up('sm')]: {
        minHeight: 500,
        flexDirection: 'row',
        width: 1000,
      },
      '& $authorizationContent': {
        [breakpoints.up('sm')]: {
          width: '60%',
        },
        [breakpoints.only('sm')]: {
          padding: 30,
        },
        [breakpoints.up('md')]: {
          width: '50%',
        },
      },
    },
    authorizationDecoratorBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',

      [breakpoints.up('sm')]: {
        background: `url(${authorizationBgUrl}) no-repeat 50% / cover`,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        width: '40%',
        padding: '34px 20px',
        order: -1,
      },
      [breakpoints.up('md')]: {
        width: '50%',
      },
    },
    authorizationDecoratorFooter: {
      marginTop: 30,
    },
    authorizationContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',

      [breakpoints.up('sm')]: {
        padding: '40px 50px',
      },
      [breakpoints.up('md')]: {
        padding: '50px 65px',
      },
    },
  });
