import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';

import { translations } from 'locales/i18n';
import MenuDropdownList from 'app/components/ui/Dropdown/components/MenuDropdownList';
import { RoleAvatar } from 'app/components/ui/RoleAvatar';
import { Loader } from 'app/components/ui/Loader';
import { useConfigMenu } from 'app/components/auth/hooks/useConfigMenu';
import { useAcl, useAuth } from 'app/components/auth';
import { SAVE_MSG_SHOWTIME } from 'app/components/forms/constants';
import { selectImpersonateUser } from 'app/components/auth/store/selectors';
import { ROLES_TITLE } from 'app/components/auth/constants';
import ImpersonateDropdown from 'app/components/ui/Dropdown/components/ImpersonateDropdown';

import SavingContext from './SavingContext';
import { Sidebar } from './Sidebar';

import { ReactComponent as impersonateIcon } from 'images/icon/impersonate.svg';
import { ReactComponent as barsLightIcon } from 'images/icon/FontAwesome5Pro/Light/bars.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as circleNotchLightIcon } from 'images/icon/FontAwesome5Pro/Light/circle-notch.svg';

import styles from '../styles/dashboard';

const useStyles = makeStyles(styles);

export interface Props {
  children?: React.ReactNode;
  showLoader?: boolean;
}

const DashboardLayout = ({ children, showLoader = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isAdmin } = useAcl();
  const impersonateUser = useSelector(selectImpersonateUser);
  const { setMenuConfig, menuConfig } = useConfigMenu();
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const setSaving = (value = true) => {
    setIsSaved(false);
    setIsSaving(value);
  };

  let timeout;
  const setSaved = () => {
    setIsSaved(true);
    setIsSaving(false);
    clearTimeout(timeout);
    timeout = setTimeout(() => setIsSaved(false), SAVE_MSG_SHOWTIME);
  };

  const controls = { setIsSaving: setSaving, setIsSaved: setSaved };

  const menuKey = `${user?.id}#`;
  const getInitialMenuConfig = () =>
    menuConfig.hasOwnProperty(menuKey)
      ? menuConfig[menuKey].drawerMinimize
      : menuConfig.default.drawerMinimize;

  const [drawerMinimize, setDrawerMinimize] = React.useState(
    getInitialMenuConfig(),
  );
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerMinimizeToggle = useCallback(() => {
    setDrawerMinimize(!drawerMinimize);
    setMenuConfig({ [menuKey]: { drawerMinimize: !drawerMinimize } });
  }, [drawerMinimize, menuKey, setMenuConfig]);

  const handleDrawerMobileToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <Container className={classes.rootContainer}>
      <Loader loading={showLoader} />

      <AppBar
        className={cx(classes.header, {
          [classes.headerShift]: !drawerMinimize,
        })}
        position="fixed"
        color="transparent"
      >
        <Toolbar className={classes.headerToolbar}>
          <IconButton
            className={cx(classes.menuButton)}
            color="inherit"
            edge="start"
            onClick={
              respUpMd ? handleDrawerMinimizeToggle : handleDrawerMobileToggle
            }
          >
            <SvgIcon component={barsLightIcon} viewBox="0 0 448 512" />
          </IconButton>
          <Box display="flex" alignItems="center">
            {(isSaving || isSaved) && (
              <Box
                className={classes.headerSavingBox}
                display="flex"
                alignItems="center"
              >
                {isSaving ? (
                  <>
                    <SvgIcon
                      component={circleNotchLightIcon}
                      viewBox="0 0 512 512"
                    />
                    {t(translations.form.labels.saving)}
                  </>
                ) : (
                  <>
                    <SvgIcon
                      component={checkCircleLightIcon}
                      viewBox="0 0 512 512"
                    />
                    {t(translations.form.labels.saved)}
                  </>
                )}
              </Box>
            )}
            {isAdmin || impersonateUser ? (
              <ImpersonateDropdown
                className={cx(classes.impersonateButton, {
                  impersonate: !!impersonateUser,
                })}
                placement="bottom-start"
                paperFullWidth
                buttonStartIcon={
                  <SvgIcon component={impersonateIcon} viewBox="0 0 18 21" />
                }
              />
            ) : null}
            <MenuDropdownList
              className={classes.headerUserDropdown}
              classNamePopper={classes.headerUserDropdownPopper}
              buttonStartIcon={<RoleAvatar role={user?.mainRole} />}
              buttonText={
                <div className={classes.headerUserDropdownText}>
                  <span>{user?.firstName && `Hello, ${user?.firstName}`}</span>
                  <span className={classes.headerUserDropdownSubText}>
                    {user?.mainRole && ROLES_TITLE[user?.mainRole]}
                  </span>
                </div>
              }
              buttonArrow
              placement="bottom-end"
              paperFullWidth
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar
        drawerMinimize={drawerMinimize}
        mobileOpen={mobileOpen}
        handleDrawerMobileToggle={handleDrawerMobileToggle}
        handleDrawerMinimizeToggle={handleDrawerMinimizeToggle}
      />
      <main className={classes.mainContainer}>
        <Toolbar disableGutters />
        <SavingContext.Provider value={controls}>
          <div className={cx(classes.mainContent, classes.mainContentIndent)}>
            {children}
          </div>
        </SavingContext.Provider>
      </main>
    </Container>
  );
};

export default memo(DashboardLayout);
