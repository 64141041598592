import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'cropperjs/dist/cropper.css';
import Cropper, { ReactCropperElement, ReactCropperProps } from 'react-cropper';

import {
  makeStyles,
  Divider,
  IconButton,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import {
  Refresh,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons';

import { translations } from 'locales/i18n';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  image: any;
  cropperProps?: ReactCropperProps;
  cropper: any;
  setCropper: (data: any) => void;
  isGif?: boolean;
  filename?: string;
  isCircle?: boolean;
}

const defaultOptions: ReactCropperProps = {
  restore: false,
  highlight: false,
  zoomOnWheel: false,
  zoomOnTouch: false,
  viewMode: 1, // crop box to not exceed the size of the canvas.
  initialAspectRatio: 1,
  minContainerHeight: 10,
};

const ImageEditorInner: React.FC<Props> = ({
  image,
  cropperProps,
  cropper,
  setCropper,
  isGif = false,
  isCircle = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const cropperRef = useRef<ReactCropperElement>(null);
  useEffect(() => {
    const cropper = cropperRef.current?.cropper;
    setCropper(cropper);
  }, [cropperRef]);

  const ratioProps = isCircle ? { aspectRatio: 1 } : {};

  return (
    <div className={classes.cropperBlock}>
      {!isGif ? (
        <Cropper
          {...{ ...defaultOptions, ...cropperProps, ...ratioProps }}
          ref={cropperRef}
          src={image}
        />
      ) : (
        <Avatar
          variant="square"
          src={image}
          className={classes.uploadedImage}
        />
      )}
      {cropper && (
        <div className={classes.cropperControlBlock}>
          <Tooltip
            title={t(translations.ui.buttons.zoomIn)}
            placement="bottom"
            arrow={true}
          >
            <IconButton
              className={classes.editButton}
              color="primary"
              onClick={() => cropper.zoom(0.2)}
            >
              <ZoomIn />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={t(translations.ui.buttons.zoomOut)}
            placement="bottom"
            arrow={true}
          >
            <IconButton
              className={classes.editButton}
              color="primary"
              onClick={() => cropper.zoom(-0.2)}
            >
              <ZoomOut />
            </IconButton>
          </Tooltip>

          <Divider className={classes.cropperControlDivider} />

          <Tooltip
            title={t(translations.ui.buttons.rotateRight)}
            placement="bottom"
            arrow={true}
          >
            <IconButton
              className={classes.editButton}
              color="primary"
              onClick={() => cropper.rotate(90)}
            >
              <RotateRight />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={t(translations.ui.buttons.rotateLeft)}
            placement="bottom"
            arrow={true}
          >
            <IconButton
              className={classes.editButton}
              color="primary"
              onClick={() => cropper.rotate(-90)}
            >
              <RotateLeft />
            </IconButton>
          </Tooltip>

          <Divider className={classes.cropperControlDivider} />

          <Tooltip
            title={t(translations.ui.buttons.reset)}
            placement="bottom"
            arrow={true}
          >
            <IconButton
              className={classes.editButton}
              color="primary"
              onClick={() => cropper.reset()}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ImageEditorInner;
