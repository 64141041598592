import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';

import { translations } from 'locales/i18n';
import logoImage from 'images/logo.svg';
import logoDarkImage from 'images/logo-dark.svg';

import styles from '../styles/dashboard';

const useStyles = makeStyles(styles);

export interface Props {
  link?: string;
  dark?: boolean;
  className?: string;
  hardReload?: boolean;
}

export function Logo({
  link = '/',
  dark = false,
  className,
  hardReload = false,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link
      onClick={e => {
        if (hardReload) {
          e.stopPropagation();
          e.preventDefault();
          window.location.href = '/';

          return false;
        }
      }}
      className={cx(classes.logo, className, { [classes.logoDark]: dark })}
      color="inherit"
      component={NavLink}
      to={link}
    >
      <img
        className={classes.logoImage}
        src={dark ? logoDarkImage : logoImage}
        alt={t(translations.ui.titles.sigSuite)}
      />
      <span className={cx(classes.logoName, 'logo-name')}>
        {t(translations.ui.titles.sigSuite)}
      </span>
    </Link>
  );
}
