import React from 'react';

import { Text } from 'app/components/forms';

export default function Users({ users, ...props }) {
  const selectOptions = users.map(({ id, fullName }) => ({
    id,
    name: fullName,
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Text type="text" {...props} select options={selectOptions} />;
}
