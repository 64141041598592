import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';

import {
  fontFamily,
  colorPurple,
  colorPrimaryDark,
  colorBlue,
  borderPrimaryColor,
  borderRadiusSmallSize,
  bgColorLightGrey,
  bgColorLightGrey2,
  bgColorLightGrey3,
  textSecondary,
  textGrey,
} from 'configs/variables';

const scrollbarSize = 6;

export const GlobalStyle = createGlobalStyle`
  
  // Layout
  a:focus,
  [tabindex]:focus {
    outline: 0;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  img {
    max-width: 100%;
  }

  //Quill editor
  .ql-toolbar.ql-snow {
    border: 1px solid ${borderPrimaryColor};
    border-radius: ${borderRadiusSmallSize}px ${borderRadiusSmallSize}px 0 0;
    background: ${bgColorLightGrey};

    .ql-picker-label {
      border: none;
      color: ${textSecondary};

      &:hover,
      &:focus {
        outline: none;
      }
    }
    .ql-picker.ql-expanded .ql-picker-label {
      color: ${textSecondary};
    }
    .ql-stroke {
      stroke: ${textSecondary};
    }

    button:hover,
    button.ql-active,
    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: ${textGrey};

      .ql-fill,
      .ql-stroke.ql-fill {
        fill: ${textGrey};
      }

      .ql-stroke,
      .ql-stroke-miter {
        stroke: ${textGrey};
      }
    }
  }
  .ql-container {
    font-family: ${fontFamily} !important;
    font-size: 14px !important;

    &.ql-snow {
      border: 1px solid ${borderPrimaryColor};
      border-radius: 0 0 ${borderRadiusSmallSize}px ${borderRadiusSmallSize}px;
    }
    .ql-editor {
      min-height: 185px;
      max-height: 500px;
      overflow-y: auto;
      padding: 19px 15px;
      cursor: auto !important;
    }
  }


  //Perfect scrollbar Custom Styles
  .ps {
    &.ps-outer-scroll {
      &.ps--active-y {
        padding-right: 7px;
      }
      &.ps--active-x {
        padding-bottom: 7px;
      }
    }
    &.grey-scrollbar {
      .ps__rail-x,
      .ps__rail-y {
        background-color: ${bgColorLightGrey3} !important;
      }
      .ps__thumb-x,
      .ps__thumb-y {
        background-color: ${textGrey} !important;
      }
    }
  }

  .ps__rail-x,
  .ps__rail-y {
    display: block;
    opacity: 1;
    background-color: ${colorPurple};
    cursor: auto;

    .ps-bordered-rail > & {
      box-shadow: inset 0 0 0 1px #FFF;
    }
  }
  .ps__rail-x,
  .ps__thumb-x {
    height: ${scrollbarSize}px;
    bottom: 0;
    border-radius: 50px;
  }
  .ps__rail-y,
  .ps__thumb-y {
    width: ${scrollbarSize}px;
    right: 0;
    border-radius: 50px;
  }
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    background-color: ${colorPurple};
  }
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    opacity: 1;
  }
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: ${colorPurple};
    opacity: 1;
  }

  .ps__thumb-x,
  .ps__thumb-y {
    background-color: ${colorPrimaryDark};
    opacity: 0.9;
  }
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: ${colorPrimaryDark};
    opacity: 1;
  }
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    height: ${scrollbarSize}px;
  }
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    width: ${scrollbarSize}px;
  }

  .empty-content {
    background: ${bgColorLightGrey2};
    color: ${textSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid ${borderPrimaryColor};
    border-radius: ${borderRadiusSmallSize}px;
    min-height: 215px;
  }

  .ace_scrollbar-v {
    width: ${scrollbarSize}px !important;
  }
  .ace_scrollbar-h {
    height: ${scrollbarSize}px !important;
  }

  //Custom scrollbar
  .native-custom-scrollbar {
    &,
    & .emoji-mart-scroll,
    & .ql-editor,
    & .ace_scrollbar {
      cursor: default;
      scrollbar-color: ${colorPrimaryDark} ${colorPurple}; /* thumb and track color for Firefox */
      scrollbar-width: thin;
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 ${scrollbarSize}px rgba(0, 0, 0, 0.1);
        background-color: ${colorPurple};
      }

      &::-webkit-scrollbar {
        width: ${scrollbarSize}px;
        height: ${scrollbarSize}px;
        background-color: ${colorPurple};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        -webkit-box-shadow: inset 0 0 ${scrollbarSize}px rgba(0, 0, 0, .1);
        background-color: ${colorPrimaryDark};
      }
    }
  }
  //color variant

  .native-custom-scrollbar.grey-scrollbar {
    &,
    & .emoji-mart-scroll,
    & .ql-editor,
    & .ace_scrollbar {
      scrollbar-color: ${textGrey} ${bgColorLightGrey3};

      &::-webkit-scrollbar-track {
        background-color: ${bgColorLightGrey3};
      }

      &::-webkit-scrollbar {
        background-color: ${bgColorLightGrey3};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${textGrey};
      }
    }
  }

  /* Crop circle Start */
  .cropper-view-box {
    outline-color: ${colorBlue} !important;
  }
  .cropper-line,
  .cropper-point,
  .cropper-point.point-se:before {
    background-color: ${colorBlue} !important;
  }

  .circle-crop {
    .cropper-crop-box,
    .cropper-view-box {
      border-radius: 50%;
    }
    .cropper-circle-remove {
      .cropper-crop-box,
      .cropper-view-box {
        border-radius: 0;
      }
    }
    .cropper-view-box {
      box-shadow: 0 0 0 1px ${colorBlue};
    }
  }
  /* Crop circle End */

  .emoji {
    height: 17px;
    margin-bottom: -3px;
  }

  .flag-circle {
    clip-path: circle();
    width: 30px !important;
    min-width: 30px !important;
  }
`;
