import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    selectWrapper: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiOutlinedInput-root': {
        width: '156px',
        height: '40px',
        borderRadius: '20px',
        background: 'white',
      },
    },
    selectLabel: {
      marginRight: '24px',
    },
    selectRoot: {
      height: '30px',
    },
  });
