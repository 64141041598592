import { createStyles, Theme } from '@material-ui/core/styles';

import selectStyle from 'app/components/forms/styles/select';

import { borderRadiusSize } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    ...selectStyle(),
    colorTextField: {
      '& .text-input-wrapper': {
        color: 'inherit',

        '&.text-input-disabled': {
          '& .text-input, & .text-field-icon-block': {
            opacity: 0.5,
          },
        },
      },
      '& .text-input': {
        textTransform: 'uppercase',
        cursor: 'pointer',
      },
    },
    colorPreview: {
      border: `1px solid rgba(0,0,0,0.12)`,
      height: 25,
      width: 25,
    },
    colorPopper: {
      zIndex: theme.zIndex.modal + 1,
    },
    colorPaper: {
      borderRadius: borderRadiusSize,
      padding: 5,

      '& .sketch-picker': {
        boxShadow: 'none !important',
      },
    },
  });
