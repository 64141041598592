import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  readonly children: React.ReactNode;
  readonly className?: string;
}

const PaperSubheader = ({ children, className = '' }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.paperSubheader, className)}>{children}</div>
  );
};

export default memo(PaperSubheader);
