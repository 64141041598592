import React, { useCallback } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';

import { CELL_WIDTH_DEFAULT } from '../constants';
import { DataCellOption } from '../types';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  cells: DataCellOption[];
  isHead?: boolean;
  classNameRow?: string;
  classNameCell?: string;
  initialValues?: object;
  validationSchema?: object;
  withForm?: boolean;
  headRemove?: boolean;
}

export function DataTableRow({
  cells,
  isHead = false,
  classNameRow,
  classNameCell,
  initialValues = {},
  validationSchema = {},
  withForm = true,
  headRemove = false,
}: Props) {
  const classes = useStyles();

  const getCell = (cell, setFieldValue, values, setFieldTouched) => ({
    ...cell,
    props: {
      ...cell.props,
      setFieldValue,
      allFormValues: values,
      setFieldTouched,
    },
  });

  const getContent = useCallback(
    (setFieldValue?, values?, setFieldTouched?) => (
      <>
        {cells?.map(
          ({
            id,
            cell,
            width = CELL_WIDTH_DEFAULT,
            maxWidth,
            className,
            head,
          }) => (
            <TableCell
              className={cx(
                classes.tableCell,
                classes.dataTableCell,
                'data-table-cell',
                classNameCell,
                className,
              )}
              key={id}
              component="div"
              style={{
                flex: `${width} 0 auto`,
                minWidth: width,
                width,
                maxWidth,
              }}
            >
              {!headRemove && (
                <Hidden mdUp>
                  <div
                    className={cx(classes.mobileCellHead, 'mobile-cell-head')}
                  >
                    {head}
                  </div>
                </Hidden>
              )}
              {isHead ? (
                <div>{head}</div>
              ) : (
                <div className={cx(classes.cellContent, 'cell-content')}>
                  {cell &&
                    (Array.isArray(cell)
                      ? cell.map(cellItem =>
                          getCell(
                            cellItem,
                            setFieldValue,
                            values,
                            setFieldTouched,
                          ),
                        )
                      : getCell(cell, setFieldValue, values, setFieldTouched))}
                </div>
              )}
            </TableCell>
          ),
        )}
      </>
    ),
    [
      cells,
      classNameCell,
      classes.cellContent,
      classes.dataTableCell,
      classes.mobileCellHead,
      classes.tableCell,
      headRemove,
      isHead,
    ],
  );

  return (
    <TableRow
      className={cx(
        classes.tableRow,
        classes.dataTableRow,
        isHead ? classes.dataTableHeadRow : classes.dataTableBodyRow,
        classNameRow,
      )}
      component="div"
    >
      {withForm ? (
        <Formik
          initialValues={initialValues}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange
        >
          {({ setFieldValue, values, setFieldTouched }) =>
            getContent(setFieldValue, values, setFieldTouched)
          }
        </Formik>
      ) : (
        <>{getContent()}</>
      )}
    </TableRow>
  );
}
