/**
 * Asynchronously loads the component
 */

import React from 'react';
import { Loader } from 'app/components/ui/Loader';
import { lazyLoad } from 'utils/loadable';

export const LocationEdit = lazyLoad(
  () => import('./index'),
  module => module.LocationEdit,
  {
    fallback: <Loader loading />,
  },
);
