import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { ROLES, TYPES } from 'app/components/auth/constants';
import { PAGE_TYPE } from 'app/containers/constants';
import RoleCell from 'app/components/grid/roleCell/RoleCell';
import { UserSearch } from 'app/components/forms/';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { translations } from 'locales/i18n';
import { LocationEntity } from 'app/components/entities/locations';
import { useConfirmation } from 'app/components/ui/Modal';

import { SignatureEntity } from '../../signatures';

import { ReactComponent as modalClockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-clone.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  indexQuery: any;
  fetchData: (data: any) => void;
  locations?: LocationEntity[];
  pageType: PAGE_TYPE;
  backLink?: string;
  refetch: () => void;
  initialStateAdditionalParams?: Record<string, any>;
  handleResendInvite: (...data: any) => void;
  handleRemoveShareClick: (id: number) => void;
  handleClonePeople: (id: any) => void;
  handleDeletePeople: (id: any) => void;
}

export default function GhostPeopleList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  locations = [],
  indexQuery,
  handleClonePeople,
  handleDeletePeople,
  refetch,
  initialStateAdditionalParams = {},
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [people, setPeople] = useState<Record<string, any> | null>();
  const [signatures, setSignatures] = useState<SignatureEntity[]>([]);

  const [showCloneConfirmation] = useConfirmation({
    onSubmit: () => handleClonePeople(people?.id),
    description: t(translations.ui.titles.confirmationClonePeopleMessage),
    agreeLabel: t(translations.ui.buttons.clone),
    modalIcon: modalClockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: '',
    },
  });

  const clonePeople = item => {
    setPeople(item);
    showCloneConfirmation();
  };

  const [showGhostDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeletePeople(people?.id),
    onClose: () => (signatures.length ? refetch() : null),
    title: t(translations.ui.titles.confirmationGhostPeopleDeleteTitle),
    description: (
      <>
        {signatures.length ? (
          <>
            {t(translations.ui.titles.confirmationGhostPeopleDelete2)} <br />
            <br />
            {signatures.map(signature => {
              return (
                <>
                  <Link
                    target="_blank"
                    href={`/ghost-signatures/${signature.id}/edit-data`}
                  >
                    {signature?.name}
                  </Link>
                  <br />
                </>
              );
            })}
          </>
        ) : (
          <>
            {t(translations.ui.titles.confirmationGhostPeopleDelete1)} <br />
          </>
        )}
      </>
    ),
    maxWidth: 'md',
    withAgreeButton: !signatures.length,
    withCheckBox: !signatures.length,
    isValid: !signatures.length,
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleGhostPeopleDeleteClick = useCallback(
    item => {
      setPeople(item);
      setSignatures(
        item?.signatureUsers
          ?.map(sigUser => sigUser?.signature)
          .filter(signature => signature.userId === item.id),
      );
      showGhostDeleteConfirmation();
    },
    [showGhostDeleteConfirmation],
  );

  const columns = [
    {
      Header: t(translations.table.columns.name),
      accessor: 'fullName',
      disableSortBy: true,
      customProps: row => `/ghost-users/${row.id}/edit`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.roles),
      accessor: 'roles',
      disableSortBy: true,
      Cell: ({ cell: { value } }: { cell: Cell }) => <RoleCell roles={value} />,
      className: classes.rolesCell,
    },
    {
      Header: t(translations.table.columns.companies),
      id: 'signature.location.companyId',
      accessor: ({ signatureUsers }) =>
        signatureUsers.length
          ? signatureUsers
              .reduce((acc, item) => {
                const {
                  signature: { locationId },
                } = item;
                const locationItem = locations?.find(
                  ({ id }) => id === locationId,
                );

                if (
                  !acc.some(
                    companyItem => companyItem.id === locationItem?.companyId,
                  )
                ) {
                  acc.push({
                    name: locationItem?.company?.name,
                    id: locationItem?.companyId,
                  });
                }

                return acc;
              }, [])
              .map(({ name, id }) => {
                return (
                  <>
                    <Link
                      component={RouterLink}
                      to={`/ghost-companies/${id}/edit`}
                      color="inherit"
                    >
                      {name}
                    </Link>
                    <span className={classes.listSeparator}>, </span>
                  </>
                );
              })
          : '-',
      disableSortBy: true,
    },
    {
      Header: t(translations.table.columns.locations),
      id: 'signature.locationId',
      accessor: ({ signatureUsers }) =>
        signatureUsers.length
          ? signatureUsers
              .reduce((acc, item) => {
                const {
                  signature: { locationId },
                } = item;
                const locationItem = locations?.find(
                  ({ id }) => id === locationId,
                );

                if (!acc.some(locItem => locItem.id === locationItem?.id)) {
                  acc.push({
                    name: locationItem?.name,
                    id: locationItem?.id,
                  });
                }

                return acc;
              }, [])
              .map(({ name, id }) => {
                return (
                  <>
                    <Link
                      component={RouterLink}
                      to={`/ghost-locations/${id}/edit`}
                      color="inherit"
                    >
                      {name}
                    </Link>
                    <span className={classes.listSeparator}>, </span>
                  </>
                );
              })
          : '-',
      disableSortBy: true,
    },
    {
      Header: t(translations.table.columns.signatures),
      id: 'signatureId',
      accessor: ({ signatureUsers }) =>
        signatureUsers.length
          ? signatureUsers.map(({ signature: { name, id } }) => {
              return (
                <>
                  <Link
                    component={RouterLink}
                    to={`/ghost-signatures/${id}/edit`}
                    color="inherit"
                  >
                    {name}
                  </Link>
                  <span className={classes.listSeparator}>, </span>
                </>
              );
            })
          : '-',
      disableSortBy: true,
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: item => item,
      Cell: ({ cell: { value: item } }: { cell: Cell }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              href={`/ghost-users/${item.id}/edit`}
              className={classes.actionItem}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editAccount)}
              >
                <SvgIcon
                  className={classes.actionItem}
                  component={pencilIcon}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </Link>

            <span
              className={classes.actionItem}
              onClick={() => clonePeople(item)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.clone)}
              >
                <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
            <span
              className={classes.actionItem}
              onClick={() => handleGhostPeopleDeleteClick(item)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.delete)}
              >
                <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const initialState = {
    globalFilter: {
      'filterRoles.name': ROLES.GHOST_USER,
      type: { $ne: TYPES.DELETED },
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper>
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <UserSearch
              name="filter"
              setGlobalFilter={setGlobalFilter}
              initialState={initialState}
              isGhost={true}
            />

            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
