import { createStyles } from '@material-ui/core';
import { colorDanger } from 'configs/variables';

export default () =>
  createStyles({
    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    disabled: {
      opacity: 0.5,
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
    },
    textWarning: {
      color: colorDanger,
      fontSize: 14,
      fontWeight: 400,
    },
    messageText: {
      fontSize: 14,
    },
    textWarningTitle: {
      color: colorDanger,
      fontSize: 18,
      fontWeight: 600,
    },
  });
