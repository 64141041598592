/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const SignaturesPage = lazyLoad(
  () => import('./index'),
  module => module.SignaturesPage,
);
