import { isWindows, isMacOs, isIOS, isAndroid } from 'react-device-detect';
import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getUrlValidation } from 'utils/dataTable';
import { TYPES } from 'app/components/auth/constants';
import { watermark as createWatermark } from '../../constants';
import { OS } from '../../constants';

dayJs.extend(utc);
dayJs.extend(timezone);

export const DELETE_SIGNATURE_AFTER_DAYS = 90;
const EXTEND_DAYS = 30;
const DELETE_NOT_ACTIVATED_DAYS = 30;
const TRIAL_DAYS = 14;

export function removeEntity(key: string): string {
  return key.replace(/^(company|location|user|signature|timeplate)\./, '');
}

export function toggleName(fieldName: string): string {
  return `${fieldName}_toggle`;
}

export function stopPropagation(event) {
  event.stopPropagation();
}

export function getLinksValidationSchema(linkWithLinkType, validationMessage) {
  return Object.entries(linkWithLinkType).reduce(
    (acc, [fieldName, linkType]) => {
      acc[fieldName] = getUrlValidation(
        null,
        linkType,
        null,
        validationMessage,
      );

      return acc;
    },
    {},
  );
}

export const addWatermark = (html, watermark) => {
  if (!html || !watermark) {
    return '';
  }

  if (html.match(/<!--watermark section-->(.*)<!--watermark section-->/gms)) {
    return html;
  }

  const timeplateCode = html;

  if (timeplateCode.match('</body>')) {
    if (timeplateCode.match('</tbody>')) {
      let tbodyEndIndex;
      const tbodyRegex = new RegExp('</tbody>', 'g');
      const matches = [...timeplateCode.matchAll(tbodyRegex)];
      if (matches.length >= 2) {
        tbodyEndIndex = matches[matches.length - 2].index;
      } else {
        tbodyEndIndex = matches[0].index;
      }
      const firstHalf = timeplateCode.slice(0, tbodyEndIndex);
      const secondHalf = timeplateCode.slice(tbodyEndIndex);
      return firstHalf + createWatermark(watermark, true) + secondHalf;
    }

    const bodyIndex = timeplateCode.indexOf('</body>');
    const firstHalf = timeplateCode.slice(0, bodyIndex);
    const secondHalf = timeplateCode.slice(bodyIndex);
    return firstHalf + createWatermark(watermark, false) + secondHalf;
  }

  return timeplateCode + createWatermark(watermark, false);
};

export const removeWatermark = html => {
  if (!html) {
    return '';
  }

  if (!html.match(/<!--watermark section-->(.*)<!--watermark section-->/gms)) {
    return html;
  }

  return html.replace(
    /<!--watermark section-->(.*)<!--watermark section-->/gms,
    '',
  );
};

export const getOperatingSystem = () => {
  if (isWindows) return OS.WINDOWS;
  if (isMacOs) return OS.MAC_OS;
  if (isIOS) return OS.IOS;
  if (isAndroid) return OS.ANDROID;
  return null;
};

export const isExpired = (
  timeZone: string,
  trialPeriodEndDate: string,
): boolean => {
  return dayJs().tz(timeZone).isAfter(dayJs.tz(trialPeriodEndDate, timeZone));
};

export const allowExtend = (
  timeZone: string,
  trialPeriodStartDate: string,
  trialPeriodEndDate: string,
): boolean => {
  const startDate = dayJs.tz(trialPeriodStartDate, timeZone);
  const endDate = dayJs.tz(trialPeriodEndDate, timeZone);
  const now = dayJs().tz(timeZone).startOf('day');

  const trialDeleteDate = dayJs
    .tz(startDate, timeZone)
    .add(DELETE_SIGNATURE_AFTER_DAYS + 1, 'days');

  return (
    endDate.diff(startDate, 'days') < EXTEND_DAYS &&
    trialDeleteDate.diff(now, 'days') > EXTEND_DAYS
  );
};

export const getDaysToDelete = (
  timeZone: string,
  trialPeriodStartDate: string,
): number => {
  const startDate = dayJs.tz(trialPeriodStartDate, timeZone);
  const deleteSignatureDate = startDate.add(
    DELETE_SIGNATURE_AFTER_DAYS + 1,
    'd',
  );
  const now = dayJs().tz(timeZone).startOf('day');
  const diff = deleteSignatureDate.diff(now, 'd');

  if (diff < 0) {
    return 0;
  }

  if (diff > DELETE_SIGNATURE_AFTER_DAYS) {
    return DELETE_SIGNATURE_AFTER_DAYS;
  }

  return diff;
};

export const getDaysToTrialEnd = (
  timeZone: string,
  trialPeriodEndDate: string,
  extendable: boolean,
): number => {
  const endDate = dayJs.tz(trialPeriodEndDate, timeZone);
  const now = dayJs().tz(timeZone).startOf('day');
  const diff = endDate.diff(now, 'd');

  if (diff < 0 || diff === 0) {
    return 0;
  }

  let result = diff;
  if (extendable) {
    if (result > TRIAL_DAYS) {
      result = TRIAL_DAYS;
    }
  } else if (result > EXTEND_DAYS) {
    result = EXTEND_DAYS;
  }

  return result;
};

enum ENTITIES_ENDPOINTS {
  'company' = '/company-fields',
  'location' = '/location-fields',
  'signature' = '/signature-fields',
  'timeplate' = '/timeplate-fields',
  'user' = '/signature-user-fields',
}

export const getEntityFieldUpdateEndpoint = (entity: string) => {
  return ENTITIES_ENDPOINTS[entity];
};

export const getDaysToDeleteNotActivatedUser = (createdAt: string): number => {
  const diff = dayJs(createdAt)
    .add(DELETE_NOT_ACTIVATED_DAYS + 1, 'day')
    .diff(dayJs(), 'day');

  if (diff < 0) {
    return 0;
  }
  if (diff > DELETE_NOT_ACTIVATED_DAYS) {
    return DELETE_NOT_ACTIVATED_DAYS;
  }

  return diff;
};

export const getStatusStyle = (
  timeZone: string,
  trialPeriodStartDate: string,
  trialPeriodEndDate: string,
  type?,
): string => {
  let statusStyle = '';
  if (type === TYPES.DELETED) {
    statusStyle = 'grey';
  } else if (!trialPeriodStartDate || !trialPeriodEndDate) {
    statusStyle = 'yellow';
  } else if (getDaysToDelete(timeZone, trialPeriodStartDate) <= 0) {
    statusStyle = 'grey';
  }

  return statusStyle;
};
