import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/icons/CloseRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { textDefault } from 'configs/variables';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DialogTitleProps {
  onClose?: () => void;
  children?: React.ReactNode;
  content?: boolean;
  modalTitleBlock?: string;
  titleIcon?: any;
  subTitle?: string;
}

const ModalTitle = ({
  onClose,
  children,
  subTitle,
  content = false,
  modalTitleBlock = 'standard',
  titleIcon,
  ...dialogTitleProps
}: Props) => {
  const classes = useStyles();

  return (
    <DialogTitle
      className={cx('modal-title-block', {
        [classes[`${modalTitleBlock}ModalTitleBlock`]]: modalTitleBlock,
      })}
      disableTypography
      {...dialogTitleProps}
    >
      {children && (
        <div className={cx({ [classes.modalTitleBlock]: !content })}>
          {titleIcon ? (
            <SvgIcon
              viewBox="0 0 56 56"
              className={classes.titleIcon}
              component={titleIcon}
            />
          ) : null}
          <div>
            <Typography variant="h4" className={classes.modalTitle}>
              {children}
            </Typography>
            <Typography className={classes.modalSubTitle}>
              {subTitle}
            </Typography>
          </div>
        </div>
      )}
      {onClose && (
        <IconButton className={classes.modalCloseButton} onClick={onClose}>
          <CloseRoundedIcon
            {...(modalTitleBlock === 'default' && { htmlColor: textDefault })}
            fontSize="inherit"
          />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default memo(ModalTitle);
