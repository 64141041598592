import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as searchLightIcon } from 'images/icon/FontAwesome5Pro/Light/search.svg';

import styles from '../../../styles/search';

const useStyles = makeStyles(styles);

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  className?: string;
  handleInputChange: (...data: any) => void;
}

export function Search({
  id,
  name,
  placeholder = 'Search...',
  margin = 'none',
  fullWidth = true,
  className,
  handleInputChange,
}: Props) {
  const classes = useStyles();

  // Use Formik if there are more than two fields
  return (
    <TextField
      onChange={handleInputChange}
      className={cx(classes.searchField, className)}
      id={id}
      name={name}
      placeholder={placeholder}
      margin={margin}
      fullWidth={fullWidth}
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.searchInputWrapper,
          focused: classes.searchInputWrapperFocused,
          input: classes.searchInput,
          notchedOutline: classes.searchNotchedOutline,
        },
        startAdornment: (
          <InputAdornment
            className={classes.searchIconWrapper}
            position="start"
          >
            <SvgIcon component={searchLightIcon} viewBox="0 0 512 512" />
          </InputAdornment>
        ),
      }}
    />
  );
}
