import React, { memo } from 'react';

import { ROLES_TITLE } from 'app/components/auth/constants';

export interface Props {
  role: { name: string; id: number };
}

function RoleCell({ role }: Props) {
  return <>{ROLES_TITLE[role.name]}</>;
}

export default memo(RoleCell);
