export enum PAGE_TYPE {
  GHOSTS = 'ghosts',
  TRIALS = 'trials',
  PAID = 'paid',
}

export enum COMPANY_TYPES {
  GHOST = 'ghost',
  TRIAL = 'trial',
  PAID = 'paid',
}

export const PAGE_COMPANY_MAP = {
  [PAGE_TYPE.GHOSTS]: COMPANY_TYPES.GHOST,
  [PAGE_TYPE.TRIALS]: COMPANY_TYPES.TRIAL,
  [PAGE_TYPE.PAID]: COMPANY_TYPES.PAID,
};
