import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SvgIcon from '@material-ui/core/SvgIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { Table } from 'app/components/table';
import { translations } from 'locales/i18n';
import {
  UserRoles,
  UserCompany,
  UserLocation,
  ROLES_TITLE,
  ROLES_IDS,
  ROLES,
} from 'app/components/auth/constants';
import RoleCell from 'app/components/grid/roleCell/RoleCell';
import RoleNameCell from 'app/components/grid/roleCell/RoleNameCell';
import { useConfirmation, useModal } from 'app/components/ui/Modal';
import { TextSearch } from 'app/components/forms/';
import useAcl from 'app/components/auth/hooks/useAcl';

import { AssignRoleModal } from './AssignRoleModal';

import { ReactComponent as timesCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/times-circle.svg';
import { ReactComponent as arrowCircleRightLightIcon } from 'images/icon/FontAwesome5Pro/Light/arrow-circle-right.svg';
import { ReactComponent as removeUserIcon } from 'images/icon/remove-user.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

const emptyCell = <div>-</div>;

export interface Props {
  isImpersonated?: boolean;
  isProfile?: boolean;
  userRoles: UserRoles[];
  userCompanies?: UserCompany[];
  userLocations?: UserLocation[];
  handleAssignToRole: (...args: any) => void;
  handleRemoveRole: (...args: any) => void;
}

export default function RolesList({
  userRoles,
  userCompanies,
  userLocations,
  handleAssignToRole,
  handleRemoveRole,
  isImpersonated = false,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const respMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [roleId, setRoleId] = useState();
  const [roleSearch, setRoleSearch] = useState('');
  const { isOneOf, user } = useAcl();

  const [showRemoveConfirmation] = useConfirmation({
    onSubmit: async () => {
      await handleRemoveRole(roleId);
    },
    title: t(translations.people.removeRole),
    description: (
      <Typography paragraph>
        {t(translations.people.removeRoleConfirmation)}
      </Typography>
    ),
    cancelLabel: t(translations.ui.buttons.cancel),
    agreeLabel: t(translations.ui.buttons.remove),
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button button-danger',
      variant: 'contained',
      size: 'medium',
      startIcon: (
        <SvgIcon component={timesCircleLightIcon} viewBox="0 0 512 512" />
      ),
    },
    agreeButtonProps: {
      size: 'medium',
      startIcon: (
        <SvgIcon component={arrowCircleRightLightIcon} viewBox="0 0 512 512" />
      ),
    },
  });

  const handleRemoveClick = useCallback(
    item => {
      setRoleId(item.id);
      showRemoveConfirmation();
    },
    [showRemoveConfirmation],
  );

  const isLowestUserRole = isOneOf([
    ROLES.BILLING_ADMIN,
    ROLES.SIGNATURE_USER,
    ROLES.COMPANY_USER,
    ROLES.LOCATION_USER,
  ]);

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'isActive',
          Cell: () => <FiberManualRecordIcon className="table-status-icon" />,
          disableSortBy: true,
          className: 'table-info-first-cell table-status-cell',
          headerClassName: 'table-status-cell',
        },
        {
          Header: t(translations.table.columns.role),
          accessor: 'role',
          Cell: ({ cell: { value } }: { cell: Cell }) => (
            <RoleNameCell role={value} />
          ),
          className: 'table-header-cell-mobile left-indent',
          minWidth: 200,
          maxWidth: 300,
          disableSortBy: true,
        },
        {
          Header: t(translations.table.columns.icon),
          accessor: 'roles',
          disableSortBy: true,
          Cell: ({ cell: { row } }) => <RoleCell roles={[row.original.role]} />,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          Header: t(translations.table.columns.company),
          accessor: 'company',
          disableSortBy: true,
          Cell: ({ cell: { value } }) =>
            value ? <div>{value.name}</div> : emptyCell,
          maxWidth: 250,
        },
        {
          Header: t(translations.table.columns.location),
          accessor: 'location',
          disableSortBy: true,
          Cell: ({ cell: { value } }) =>
            value ? <div>{value.name}</div> : emptyCell,
        },
        {
          Header:
            !isImpersonated && isLowestUserRole
              ? ''
              : t(translations.table.columns.quickActions),
          id: 'id',
          minWidth: 180,
          maxWidth: 180,
          disableSortBy: true,
          className: 'table-action-cell',
          accessor: item => item,
          Cell: ({ cell: { value: item } }: { cell: Cell }) => {
            const isImpersonatedAndNotPrimaryRole =
              isImpersonated &&
              item.roleId !== ROLES_IDS.ACCOUNT_HOLDER_PRIMARY &&
              item.roleId !== ROLES_IDS.COMPANY_ADMIN_PRIMARY &&
              item.roleId !== ROLES_IDS.LOCATION_ADMIN_PRIMARY &&
              item.roleId !== ROLES_IDS.SIGNATURE_USER &&
              item.roleId !== ROLES_IDS.SUPER_ADMIN;

            const defaultPermission =
              item.roleId === ROLES_IDS.ACCOUNT_HOLDER_PRIMARY ||
              item.roleId === ROLES_IDS.COMPANY_ADMIN_PRIMARY ||
              item.roleId === ROLES_IDS.LOCATION_ADMIN_PRIMARY ||
              item.roleId === ROLES_IDS.SIGNATURE_USER ||
              item.roleId === ROLES_IDS.COMPANY_USER ||
              item.roleId === ROLES_IDS.LOCATION_USER ||
              item.roleId === ROLES_IDS.SUPER_ADMIN ||
              ((user?.mainRoleId === ROLES_IDS.LOCATION_ADMIN_PRIMARY ||
                user?.mainRoleId === ROLES_IDS.LOCATION_ADMIN) &&
                (item.roleId === ROLES_IDS.BILLING_ADMIN ||
                  item.roleId === ROLES_IDS.COMPANY_ADMIN)) ||
              item.roleId === user?.mainRoleId ||
              (item.roleId === ROLES_IDS.ACCOUNT_HOLDER &&
                !isOneOf([
                  ROLES.SUPER_ADMIN,
                  ROLES.ACCOUNT_HOLDER_PRIMARY,
                  ROLES.ACCOUNT_HOLDER,
                ]));

            return (
              <div className="action-cell-wrapper">
                {isLowestUserRole && !isImpersonated ? (
                  <></>
                ) : isImpersonatedAndNotPrimaryRole || !defaultPermission ? (
                  <span className={classes.actionItem}>
                    <Tooltip
                      placement="top"
                      arrow={true}
                      title={t(translations.actions.names.removeRole)}
                    >
                      <SvgIcon
                        component={removeUserIcon}
                        viewBox="0 0 23 19"
                        onClick={() => handleRemoveClick(item)}
                      />
                    </Tooltip>
                  </span>
                ) : (
                  <span className={classes.disabled}>
                    <SvgIcon component={removeUserIcon} viewBox="0 0 23 19" />
                  </span>
                )}
              </div>
            );
          },
        },
      ].filter(Boolean),
    [],
  );

  const dataWithActions = useMemo(() => {
    return userRoles
      ? userRoles.reduce<Array<Record<string, any>>>((acc, item) => {
          const {
            role: { name },
          } = item;
          if (
            !roleSearch ||
            ROLES_TITLE[name].toLowerCase().includes(roleSearch)
          ) {
            acc.push({
              ...item,
            });
          }

          return acc;
        }, [])
      : null;
  }, [roleSearch, userRoles]);

  const [showAssignToRole] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: AssignRoleModal,
    props: { userCompanies, userLocations, handleSubmit: handleAssignToRole },
    unmount: true,
  });

  if (!dataWithActions) {
    return <div className="table-no-data">{t(translations.table.noData)}</div>;
  }

  return (
    <Table
      renderGlobalFilter={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <TextSearch name="filter" handleSearch={setRoleSearch} />
          </Grid>
          <Grid item xs={12} sm="auto">
            {isImpersonated || !isLowestUserRole ? (
              <Button
                className="cta-button"
                variant="contained"
                color="secondary"
                onClick={showAssignToRole}
                startIcon={<AddRoundedIcon style={{ fontSize: 22 }} />}
                fullWidth={respMobile}
              >
                {t(translations.people.buttons.assignToRole)}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      )}
      isPagination={false}
      data={dataWithActions}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
    />
  );
}
