export const TABS_CONFIG = {
  DATA: {
    fields: {
      // key - row name
      // value - row is bordered (true || false)
      firstName: true,
      middleName: true,
      lastName: true,
      credentials: true,
      title: true,
      birthday: true,
      displayName: true,
      salutation: true,
      role: true,
      tagline: true,
      mobile: true,
      email: true,
      website: true,
      telephone: true,
      telephoneExt: true,
      fax: true,
      addressLine1: false,
      addressLine2: false,
      city: false,
      state: false,
      stateInitials: false,
      zip: false,
      country: false,
      countryInitials: true,
      postalLine1: false,
      postalLine2: false,
      postalCity: false,
      postalState: false,
      postalStateInitials: false,
      postalZip: false,
      postalCountry: false,
      postalCountryInitials: false,
    },
  },
  MESSAGES: {
    fields: {
      status: true,
      green: true,
      customMessage: true,
      unsubscribe: true,
    },
  },
  IMAGES: {
    fields: {
      photo: true,
      userLogo: true,
      primaryImage: true,
      secondaryImage: true,
      addressMap: true,
      postalMap: true,
      image1: true,
      image2: true,
      image3: true,
      image4: true,
      image5: true,
      image6: true,
      image7: true,
      image8: true,
      image9: true,
      banner1: true,
      banner2: true,
      banner3: true,
      banner4: true,
      banner5: true,
      banner6: true,
      banner7: true,
      banner8: true,
      banner9: true,
    },
  },
  BRANDING: {
    fields: {
      featureColor: true,
      baseColor: true,
      iconStyle: true,
      iconSize: true,
      originalColor: true,
      iconFeatureColor: true,
      iconBaseColor: true,
      fontFamily: true,
      fontSize: true,
      statusMessageFont: true,
      statusMessageFontSize: true,
      statusMessageColor: true,
      statusMessageLinkColor: true,
      brandStatusMessage: true,
      greenMessageFont: true,
      greenMessageFontSize: true,
      greenMessageColor: true,
      greenMessageLinkColor: true,
      brandGreenMessage: true,
      customMessageFont: true,
      customMessageFontSize: true,
      customMessageColor: true,
      customMessageLinkColor: true,
      brandCustomMessage: true,
      unsubscribeMessageFont: true,
      unsubscribeMessageFontSize: true,
      unsubscribeMessageColor: true,
      unsubscribeMessageLinkColor: true,
      brandUnsubscribeMessage: true,
    },
  },
  ICONS: {
    fields: {
      customIcon1: true,
      customIcon2: true,
      customIcon3: true,
      customIcon4: true,
      customIcon5: true,
      customIcon6: true,
      customIcon7: true,
      customIcon8: true,
      customIcon9: true,
    },
  },
};

export enum GDPR_LABELS {
  GLOBAL_TERMS = 'global_terms',
  CREATE_ACCOUNT = 'create_account',
  ADM_AND_BILLING = 'adm_and_billing',
  CORRESPONDENCE = 'correspondence',
  NEWSLETTERS = 'newsletters',
  ALL_USERS = 'all_users',
  LOCAL_TERMS = 'local_terms',
}

export const GDPR = {
  [GDPR_LABELS.GLOBAL_TERMS]: 1,
  [GDPR_LABELS.CREATE_ACCOUNT]: 2,
  [GDPR_LABELS.ADM_AND_BILLING]: 4,
  [GDPR_LABELS.CORRESPONDENCE]: 8,
  [GDPR_LABELS.NEWSLETTERS]: 16,
  [GDPR_LABELS.ALL_USERS]: 32,
  [GDPR_LABELS.LOCAL_TERMS]: 64,
};

export const GDPR_COUNTRIES_LIST = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];
