import React from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { Modal, ModalContent, ModalTitle } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';
import { Avatar } from 'app/components/ui/Avatar';
import { USER_SIGNATURES_STATUSES } from 'app/components/entities/queue/types';
import SignatureRoleNameCell from 'app/components/grid/roleCell/SignatureRoleNameCell';
import {
  ROLES_TITLE,
  ROLES_AVATAR_COLOR,
  ROLES_STATUSES_TITLE,
  ROLES,
} from 'app/components/auth/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

interface Props {
  onClose: () => void;
  open: boolean;
}

export const RolesModal: React.FC<Props> = ({ onClose, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const rolesInfo = [
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.ACCOUNT_HOLDER]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.ACCOUNT_HOLDER]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.ACCOUNT_HOLDER],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.accountHolder,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.ACCOUNT_HOLDER_PRIMARY]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.ACCOUNT_HOLDER_PRIMARY]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.ACCOUNT_HOLDER_PRIMARY],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal
          .accountHolderPrimary,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.COMPANY_ADMIN]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.COMPANY_ADMIN]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.COMPANY_ADMIN],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.companyAdmin,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.COMPANY_ADMIN_PRIMARY]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.COMPANY_ADMIN_PRIMARY]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.COMPANY_ADMIN_PRIMARY],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal
          .companyAdminPrimary,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.LOCATION_ADMIN]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.LOCATION_ADMIN]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.LOCATION_ADMIN],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.locationAdmin,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.LOCATION_ADMIN_PRIMARY]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.LOCATION_ADMIN_PRIMARY]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.LOCATION_ADMIN_PRIMARY],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal
          .locationAdminPrimary,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.SIGNATURE_USER]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.SIGNATURE_USER]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.SIGNATURE_USER],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.signatureUser,
      ),
    },
    {
      avatar: (
        <SignatureRoleNameCell
          className={classes.avatar}
          status={USER_SIGNATURES_STATUSES.QUEUED}
        />
      ),
      roleName: ROLES_STATUSES_TITLE[USER_SIGNATURES_STATUSES.QUEUED],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal
          .signatureUserQueued,
      ),
    },
    {
      avatar: (
        <SignatureRoleNameCell
          className={classes.avatar}
          status={USER_SIGNATURES_STATUSES.PROCESSED}
        />
      ),
      roleName: ROLES_STATUSES_TITLE[USER_SIGNATURES_STATUSES.PROCESSED],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal
          .signatureUserProcessed,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.COMPANY_USER]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.COMPANY_USER]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.COMPANY_USER],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.companyUser,
      ),
    },
    {
      avatar: (
        <Avatar
          name={ROLES_TITLE[ROLES.LOCATION_USER]}
          className={classes.avatar}
          size="small"
          color={ROLES_AVATAR_COLOR[ROLES.LOCATION_USER]}
        />
      ),
      roleName: ROLES_TITLE[ROLES.LOCATION_USER],
      description: t(
        translations.accountHolderOnboarding.steps.sixth.modal.locationUser,
      ),
    },
  ];
  return (
    <Modal open={open} maxWidth={'xl'}>
      <ModalTitle onClose={onClose}>
        {t(translations.accountHolderOnboarding.steps.sixth.modal.title)}
      </ModalTitle>
      <ModalContent>
        <Typography>
          {t(translations.accountHolderOnboarding.steps.sixth.modal.headText)}
        </Typography>
        <div className={classes.rolesList}>
          {rolesInfo.map(role => {
            return (
              <div className={classes.roleBlock}>
                <div className={classes.avatarBlock}>
                  {role.avatar} {role.roleName}
                </div>
                <Typography>{role.description}</Typography>
              </div>
            );
          })}
        </div>
        <Typography>
          {t(translations.accountHolderOnboarding.steps.sixth.modal.footerText)}
        </Typography>
      </ModalContent>
    </Modal>
  );
};
