import { createStyles } from '@material-ui/core/styles';

import {
  avatarColors,
  colorPrimary,
  colorPrimary300,
  colorSecondary,
  colorYellow,
  colorYellowExtraDark,
  colorPinkDark2,
  colorPinkExtraDark,
  colorBlue,
  colorPurple,
  colorWhite,
  colorBlue3,
} from 'configs/variables';

export default () =>
  createStyles({
    avatarRoot: {
      fontWeight: 700,
    },

    // Avatar Size
    small: {
      width: 30,
      height: 30,
      fontSize: 14,
      fontWeight: 400,
    },
    normal: {
      width: 40,
      height: 40,
      fontSize: 16,
    },
    large: {
      width: 50,
      height: 50,
      fontSize: 20,
    },

    // Avatar Color
    avatar0: {
      backgroundColor: avatarColors[0],
    },
    avatar1: {
      backgroundColor: avatarColors[1],
    },
    avatar2: {
      backgroundColor: avatarColors[2],
    },
    avatar3: {
      backgroundColor: avatarColors[3],
    },
    avatar4: {
      backgroundColor: avatarColors[4],
    },
    avatar5: {
      backgroundColor: avatarColors[5],
    },
    primaryColor: {
      backgroundColor: colorPrimary,
    },
    secondaryColor: {
      backgroundColor: colorSecondary,
    },
    yellowColor: {
      backgroundColor: colorYellow,
    },

    // Role
    role1Color: {
      backgroundColor: colorYellow,
    },
    role1BColor: {
      backgroundColor: colorYellow,
      border: `2px solid ${colorYellowExtraDark}`,
    },
    role2Color: {
      backgroundColor: colorYellow,
    },
    role2BColor: {
      backgroundColor: colorYellow,
      border: `2px solid ${colorPinkExtraDark}`,
    },
    role3Color: {
      color: colorWhite,
      backgroundColor: colorBlue,
    },
    role4Color: {
      backgroundColor: colorPrimary300,
    },
    role5Color: {
      backgroundColor: colorPinkDark2,
    },
    role5BColor: {
      backgroundColor: colorPinkDark2,
      border: `2px solid ${colorPinkExtraDark}`,
    },
    role6Color: {
      backgroundColor: colorPrimary300,
    },
    role6BColor: {
      backgroundColor: colorPrimary300,
      border: `2px solid ${colorPinkExtraDark}`,
    },
    role7Color: {
      backgroundColor: colorPurple,
    },
    role8BColor: {
      backgroundColor: colorWhite,
      border: `2px solid ${colorBlue3}`,
      color: colorBlue3,
    },
  });
