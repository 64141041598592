import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';

import { translations } from 'locales/i18n';
import useAcl from 'app/components/auth/hooks/useAcl';
import AutocompleteField from 'app/components/ui/Autocomplete';
import {
  ROLES,
  TO_ASSIGN_ROLES_TITLE,
  UserCompany,
  UserLocation,
} from 'app/components/auth/constants';

export interface Props {
  handleSubmit: (...data: any) => void;
  userCompanies?: UserCompany[];
  userLocations?: UserLocation[];
}

const customOptionLabel = ({ name }) => name;

interface RoleType {
  id: string;
  name: string;
}
const initialValues: {
  roleType: RoleType | null;
  company?: UserCompany | null;
  location?: UserLocation | null;
} = {
  roleType: null,
  company: null,
  location: null,
};

export default function AssignRoleForm({
  handleSubmit,
  userCompanies,
  userLocations,
}: Props) {
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState(null);
  const { isOneOf } = useAcl();

  const RoleOptions = Object.keys(TO_ASSIGN_ROLES_TITLE).reduce<
    Array<{ id: string; name: string }>
  >((acc, key) => {
    if (
      ![ROLES.ACCOUNT_HOLDER_PRIMARY, ROLES.ACCOUNT_HOLDER].includes(
        key as ROLES,
      ) ||
      isOneOf([
        ROLES.SUPER_ADMIN,
        ROLES.ACCOUNT_HOLDER_PRIMARY,
        ROLES.ACCOUNT_HOLDER,
      ])
    ) {
      acc.push({
        id: key,
        name: TO_ASSIGN_ROLES_TITLE[key],
      });
    }
    return acc;
  }, []);

  const userCompaniesOptions = useMemo(
    () =>
      userCompanies?.map(({ id, name }) => ({
        id,
        name,
      })),
    [userCompanies],
  );
  const userLocationsOptions = useMemo(
    () =>
      userLocations?.reduce<UserLocation[]>((prev, item) => {
        if (item.companyId === companyId) {
          prev.push({
            id: item.id,
            name: item.name,
          });
        }

        return prev;
      }, []),
    [companyId, userLocations],
  );

  const validationSchema = yup.object().shape({
    roleType: yup
      .object()
      .nullable()
      .required(t(translations.validation.required)),
    company: yup
      .object()
      .nullable()
      .when('roleType', {
        is: roleType =>
          roleType &&
          ![ROLES.ACCOUNT_HOLDER_PRIMARY, ROLES.ACCOUNT_HOLDER].includes(
            roleType.id,
          ),
        then: () =>
          yup.object().nullable().required(t(translations.validation.required)),
      }),
    location: yup
      .object()
      .nullable()
      .when('roleType', {
        is: roleType =>
          roleType &&
          [ROLES.LOCATION_ADMIN_PRIMARY, ROLES.LOCATION_ADMIN].includes(
            roleType.id,
          ),
        then: () =>
          yup.object().nullable().required(t(translations.validation.required)),
      }),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <AutocompleteField
            disableClearable={false}
            name="roleType"
            label={t(translations.people.fields.roleType)}
            options={RoleOptions}
            customOptionLabel={customOptionLabel}
            onChange={() => {
              setCompanyId(null);
              setFieldValue('company', null);
              setFieldValue('location', null);
            }}
          />
          {values.roleType &&
            ![
              ROLES.ACCOUNT_HOLDER_PRIMARY as string,
              ROLES.ACCOUNT_HOLDER as string,
            ].includes(values.roleType.id) && (
              <AutocompleteField
                disableClearable={false}
                name="company"
                label={t(translations.people.fields.company)}
                options={userCompaniesOptions}
                customOptionLabel={customOptionLabel}
                onChange={value => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setCompanyId(value?.id || null);
                  setFieldValue('location', null);
                }}
              />
            )}
          {values.roleType &&
            [
              ROLES.LOCATION_ADMIN_PRIMARY as string,
              ROLES.LOCATION_ADMIN as string,
            ].includes(values.roleType.id) && (
              <AutocompleteField
                disableClearable={false}
                name="location"
                label={t(translations.people.fields.location)}
                options={userLocationsOptions}
                customOptionLabel={customOptionLabel}
              />
            )}
          <div className="modal-button-block modal-dialog-actions">
            <Button
              className="modal-button cta-button"
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
            >
              {t(translations.ui.buttons.save)}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
