import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';
import dayjs from 'dayjs';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { useConfirmation, useModal } from 'app/components/ui/Modal';
import { translations } from 'locales/i18n';
import { PAGE_TYPE } from 'app/containers/constants';
import { TimeplatesSearch } from 'app/components/forms/components/Edit/Search/TimeplatesSearch';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { ROLES, useAuth } from 'app/components/auth';

import { PreviewModal } from '../index';

import { ReactComponent as editLightIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';
import { ReactComponent as pencilPaintbrushLightIcon } from 'images/icon/FontAwesome5Pro/Light/pencil-paintbrush.svg';
import { ReactComponent as banLightIcon } from 'images/icon/FontAwesome5Pro/Light/ban.svg';
import { ReactComponent as modalBlockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-block.svg';
import { ReactComponent as modalCheckIcon } from 'images/icon/FontAwesome5Pro/Light/modal-check.svg';
import { ReactComponent as modalClockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-clone.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as calendarClockIcon } from 'images/icon/FontAwesome5Pro/Light/calendar-clock.svg';

import styles from 'app/components/entities/styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId: string;
  locationId?: string;
  signatureId?: string;
  backLink?: string;
  onTimeplateStatusChange: (id: any, isDisabled: boolean) => void;
  handleCloneTimeplate: (id: any) => void;
  handleDeleteTimeplate: (id: any) => void;
  pageType: PAGE_TYPE;
  indexQuery: any;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  companyId,
  locationId,
  signatureId,
  backLink,
  onTimeplateStatusChange,
  handleCloneTimeplate,
  handleDeleteTimeplate,
  pageType,
  indexQuery,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();

  const isSignatureUserRole = user?.mainRole === ROLES.SIGNATURE_USER;

  const urlEntityType = useCallback(
    params => {
      switch (pageType) {
        case PAGE_TYPE.GHOSTS:
          return `/ghost-${params}`;
        case PAGE_TYPE.PAID:
          return `/${params}`;
        default:
          return `/trial-${params}`;
      }
    },
    [pageType],
  );

  const [timeplate, setTimeplate] = useState<Record<string, any> | null>();

  const [versionId, setVersionId] = useState();
  const [showPreview] = useModal({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Modal: PreviewModal,
    props: { versionId },
  });

  const onShowPreview = useCallback(
    id => () => {
      setVersionId(id);
      showPreview();
    },
    [showPreview],
  );

  const [showDisableConfirmation] = useConfirmation({
    onSubmit: () => onTimeplateStatusChange(timeplate?.id, true),
    description: t(translations.ui.titles.confirmationMessageDisableTimeplate),
    agreeLabel: t(translations.ui.buttons.disableTimeplate),
    modalIcon: modalBlockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: 'button-danger',
    },
  });

  const [showEnableConfirmation] = useConfirmation({
    onSubmit: () => onTimeplateStatusChange(timeplate?.id, false),
    description: t(translations.ui.titles.confirmationMessageEnableTimeplate),
    agreeLabel: t(translations.ui.buttons.enableTimeplate),
    modalIcon: modalCheckIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      color: 'primary',
    },
  });

  const [showCloneConfirmation] = useConfirmation({
    onSubmit: () => handleCloneTimeplate(timeplate?.id),
    description: t(
      translations.ui.titles[
        timeplate?.startDate
          ? 'confirmationCloneTimeplateMessage1'
          : 'confirmationCloneTimeplateMessage2'
      ],
    ),
    agreeLabel: t(translations.ui.buttons.clone),
    modalIcon: modalClockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: '',
    },
  });

  const cloneTimeplate = item => {
    setTimeplate(item);
    showCloneConfirmation();
  };

  const [showDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeleteTimeplate(timeplate?.id),
    title: t(translations.ui.titles.confirmationTimeplateDeleteTitle),
    description: t(
      translations.ui.titles[
        !timeplate?.isActive
          ? 'confirmationTimeplateDelete1'
          : 'confirmationTimeplateDelete2'
      ],
    ),
    maxWidth: 'md',
    withCheckBox: true,
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleTimeplateDeleteClick = useCallback(
    item => {
      setTimeplate(item);
      showDeleteConfirmation();
    },
    [showDeleteConfirmation],
  );

  const handleChangeStatusClick = useCallback(
    (item, isDisabled) => {
      setTimeplate(item);
      if (isDisabled) {
        showEnableConfirmation();
      } else {
        showDisableConfirmation();
      }
    },
    [showDisableConfirmation, showEnableConfirmation],
  );

  const columns = [
    {
      Header: t(translations.table.columns.timeplates),
      accessor: 'name',
      disableSortBy: true,
      customProps: row => urlEntityType(`timeplates/${row.id}/designer`),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
      // minWidth: 150,
      // maxWidth: 300,
    },
    {
      Header: t(translations.table.columns.schedule),
      disableSortBy: true,
      accessor: item => item,
      Cell: ({ cell: { value: item } }: { cell: Cell }) =>
        item.isDefault
          ? 'Annual'
          : item.isAnnual
          ? t(translations.timeplate.field.annual)
          : t(translations.timeplate.field.once),
    },
    {
      Header: t(translations.table.columns.startDate),
      id: 'startDate',
      accessor: ({ startDate, isDefault }) =>
        isDefault ? 'Always' : dayjs(startDate).format('Do MMMM YYYY'),
    },
    {
      Header: t(translations.table.columns.endDate),
      id: 'endDate',
      accessor: ({ endDate, isDefault }) =>
        isDefault ? 'Always' : dayjs(endDate).format('Do MMMM YYYY'),
    },
    locationId
      ? null
      : {
          Header: t(translations.table.columns.locations),
          id: 'locationId',
          accessor: ({
            signature: {
              location: { name },
            },
          }) => name,
        },
    signatureId
      ? null
      : {
          Header: t(translations.table.columns.signatures),
          id: 'signatureId',
          accessor: ({ signature: { name } }) => name,
          maxWidth: 150,
          minWidth: 150,
        },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 250,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: item => item,
      Cell: ({ cell: { value: item } }: { cell: Cell }) => {
        const { id, isDisabled, activeVersionId, isDefault } = item;

        return (
          <div className="action-cell-wrapper">
            {isSignatureUserRole ? (
              <>
                <span className={classes.disabled}>
                  <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
                </span>
                <span className={classes.actionItem}>
                  <Tooltip
                    placement="top"
                    arrow={true}
                    title={t(translations.actions.names.preview)}
                  >
                    <SvgIcon
                      component={eyeLightIcon}
                      viewBox="0 0 22 17"
                      onClick={onShowPreview(activeVersionId)}
                    />
                  </Tooltip>
                </span>
                <span className={classes.disabled}>
                  <SvgIcon
                    component={pencilPaintbrushLightIcon}
                    viewBox="0 0 576 512"
                  />
                </span>

                <span className={classes.disabled}>
                  <SvgIcon component={calendarClockIcon} viewBox="0 0 22 19" />
                </span>
              </>
            ) : (
              <>
                <Link
                  className={classes.actionItem}
                  href={urlEntityType(`timeplates/${id}/edit`)}
                >
                  <Tooltip
                    placement="top"
                    arrow={true}
                    title={t(translations.actions.names.editData)}
                  >
                    <SvgIcon component={editLightIcon} viewBox="0 0 576 512" />
                  </Tooltip>
                </Link>
                <span className={classes.actionItem}>
                  <Tooltip
                    placement="top"
                    arrow={true}
                    title={t(translations.actions.names.preview)}
                  >
                    <SvgIcon
                      component={eyeLightIcon}
                      viewBox="0 0 22 17"
                      onClick={onShowPreview(activeVersionId)}
                    />
                  </Tooltip>
                </span>
                <Link
                  className={classes.actionItem}
                  href={urlEntityType(`timeplates/${id}/designer`)}
                >
                  <Tooltip
                    placement="top"
                    arrow={true}
                    title={t(translations.actions.names.designer)}
                  >
                    <SvgIcon
                      component={pencilPaintbrushLightIcon}
                      viewBox="0 0 576 512"
                    />
                  </Tooltip>
                </Link>
                <Link
                  className={classes.actionItem}
                  href={urlEntityType(`timeplates/${id}/edit-data`)}
                >
                  <Tooltip
                    placement="top"
                    arrow={true}
                    title={t(translations.actions.names.editTimeplate)}
                  >
                    <SvgIcon
                      component={calendarClockIcon}
                      viewBox="0 0 22 19"
                    />
                  </Tooltip>
                </Link>
              </>
            )}
            {isDisabled ? (
              <span
                className={cx(classes.actionItem, {
                  [classes.disabled]: isDefault,
                })}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.enable)}
                >
                  <SvgIcon
                    component={checkCircleLightIcon}
                    viewBox="0 0 576 512"
                    onClick={() =>
                      isDefault ? {} : handleChangeStatusClick(item, true)
                    }
                  />
                </Tooltip>
              </span>
            ) : (
              <span
                className={cx(classes.actionItem, {
                  [classes.disabled]: isDefault,
                })}
              >
                <Tooltip
                  title={
                    isDefault
                      ? t(translations.timeplate.message.tooltip.disableDefault)
                      : t(translations.actions.names.disable)
                  }
                  placement="top"
                  arrow={true}
                >
                  <SvgIcon
                    component={banLightIcon}
                    viewBox="0 0 576 512"
                    onClick={() =>
                      isDefault ? {} : handleChangeStatusClick(item, false)
                    }
                  />
                </Tooltip>
              </span>
            )}
            {pageType !== PAGE_TYPE.TRIALS ? (
              <span
                className={classes.actionItem}
                onClick={() => cloneTimeplate(item)}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.actions.names.clone)}
                >
                  <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
            {pageType !== PAGE_TYPE.TRIALS ? (
              <span
                className={cx(classes.actionItem, {
                  [classes.disabled]: isDefault,
                })}
                onClick={() =>
                  isDefault ? {} : handleTimeplateDeleteClick(item)
                }
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={
                    isDefault
                      ? t(translations.timeplate.message.tooltip.deleteDefault)
                      : t(translations.actions.names.delete)
                  }
                >
                  <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
                </Tooltip>
              </span>
            ) : null}
          </div>
        );
      },
    },
  ].filter(Boolean);

  const pageName = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.GHOSTS:
        return t(translations.names.ghost.timeplates);
      case PAGE_TYPE.PAID:
        return t(translations.names.paid.timeplates);
      default:
        return t(translations.names.trials.timeplates);
    }
  }, [pageType, t]);

  const initialState = {
    globalFilter: {
      ...(locationId
        ? { 'signature.locationId': locationId }
        : { 'signature.location.companyId': companyId }),
      ...(signatureId ? { signatureId } : {}),
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  return (
    <Paper
      header={<Typography variant="h5">{pageName()}</Typography>}
      backLink={backLink}
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <TimeplatesSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
