import { createStyles, Theme } from '@material-ui/core/styles';

import selectStyle from 'app/components/forms/styles/select';

import {
  textFieldHorizontalSize,
  colorWarning,
  colorWhite,
  colorPink,
  colorBlue,
  textSecondary,
  colorBlueDisable,
  textPrimary,
  borderPrimaryColor,
  borderRadiusSmallSize,
  colorPrimary,
  colorPrimaryExtraLight,
  colorDanger,
  bgColorLightGrey2,
  transition,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    ...selectStyle(),
    textFieldRoot: {},
    inputWrapper: {},
    inputWrapperStartIcon: {
      paddingLeft: 0,

      '& $textFieldIconBlockStart': {
        position: 'absolute',
        paddingLeft: textFieldHorizontalSize,
      },
      '& $input': {
        paddingLeft: 55,
      },
    },
    textFieldIconBlock: {
      zIndex: 1,

      [theme.breakpoints.up('md')]: {
        '.button-on-hover &': {
          opacity: 0,
          visibility: 'hidden',
          transitionProperty: 'opacity, visibility',
          transition,
        },
        '.button-on-hover:hover &': {
          opacity: 1,
          visibility: 'visible',
        },
      },
      '& button': {
        padding: 5,
        height: 30,
        width: 30,

        '&:not(:first-child)': {
          marginLeft: 8,
        },
      },
      '& svg': {
        fontSize: 18,
      },
      '& .text-button': {
        color: colorBlue,
        '&, &:hover': {
          backgroundColor: colorBlue,
        },

        '&:hover': {
          opacity: 0.8,
        },
        '& svg': {
          fontSize: 16,
          color: colorWhite,
        },
        '&.text-copy-button': {
          color: colorPink,
          '&, &:hover': {
            backgroundColor: colorPink,
          },
        },
        '&.text-clear-button': {
          color: colorDanger,
          '&, &:hover': {
            backgroundColor: colorDanger,
          },
        },
        '&.text-change-button': {
          color: bgColorLightGrey2,
          '&, &:hover': {
            backgroundColor: bgColorLightGrey2,
          },
        },
        '&.text-edit-button': {
          color: colorWarning,
          '&, &:hover': {
            backgroundColor: colorWarning,
          },
          '&.Mui-disabled': {
            opacity: '0.5',
          },
        },
        '&.text-action-button': {
          color: colorPrimaryExtraLight,
          '&, &:hover': {
            backgroundColor: colorPrimaryExtraLight,
          },
        },
      },

      '.text-input-disabled & .text-button': {
        color: colorWhite,
        backgroundColor: colorBlueDisable,
      },
    },
    textFieldIconBlockStart: {
      '& button': {
        marginLeft: -2,
      },
    },
    textFieldIconBlockEnd: {
      '& button': {
        marginRight: -2,
      },
    },
    input: {},
    inputFocused: {},
    inputError: {},
    textFieldWarning: {
      '& .text-label, & .text-label > span, & $textFieldHelperText': {
        color: colorWarning,
      },
      '& $inputNotchedOutline': {
        borderColor: `${colorWarning} !important`,
      },
    },
    textFieldFilled: {
      '& .text-input-wrapper': {
        backgroundColor: colorWhite,
      },
      '& .text-input-wrapper, & .text-label, & .text-label > span': {
        color: `${textPrimary} !important`,
      },
      '& $inputNotchedOutline': {
        borderColor: `${colorWhite} !important`,
      },
    },
    inputNotchedOutline: {},
    textFieldHelperText: {},
    textFieldAdornedEnd: {},

    // Text Multiline
    multilineWrapper: {
      paddingBottom: 7,

      '&, & $textFieldIconBlock': {
        alignItems: 'flex-start',
      },
    },
    inputMultiline: {
      resize: 'vertical',
      minHeight: 29,
      maxHeight: 300,
      marginRight: -8,
    },

    // Text Editor
    textEditorLabel: {
      position: 'relative',
      transform: 'none',
      margin: '0px 0 8px 15px',
    },

    // Text Date
    textFieldDate: {
      '&, & .text-input': {
        cursor: 'pointer',
      },
    },
    textDateIconButton: {
      color: textSecondary,

      '& svg': {
        fontSize: 16,
      },
    },
    quillEditorLabel: {
      backgroundColor: colorWhite,
      paddingLeft: 5,
      paddingRight: 5,
    },
    quillEditorWrap: {
      display: 'flex',
      borderRadius: borderRadiusSmallSize,
      border: `1px solid ${borderPrimaryColor}`,

      '&:hover': {
        borderColor: textPrimary,
      },
      '&:focus': {
        borderWidth: 2,
        borderColor: colorPrimary,
      },
    },
    quillEditor: {
      flex: 1,
      '& .ql-container': {
        border: 'none !important',

        '& .ql-editor': {
          maxHeight: 300,
        },
      },
    },
    quillEditorIcons: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 14px 10px 0',
      marginLeft: 8,
      '& button': {
        '&:not(:first-child)': {
          marginLeft: 10,
          marginTop: 8,
        },
      },
    },
    emojiPopper: {
      zIndex: 10000,
    },
    quillEmojiButton: {
      backgroundColor: colorWhite,
      border: `1px solid ${borderPrimaryColor}`,
      marginTop: 'auto',
    },

    gdpr: {
      fontSize: 10,
    },
    gdprTitle: {
      fontWeight: 500,
      marginBottom: 3,
    },
    gdprCheckbox: {
      marginRight: 0,
      marginLeft: -6,
    },
    gdprCheckboxIcon: {
      padding: 6,
    },
    gdprCheckboxLabel: {
      fontSize: 10,
    },
  });
