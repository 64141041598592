import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TooltipMaterial, { TooltipProps } from '@material-ui/core/Tooltip';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends TooltipProps {
  color?: 'white' | 'primary' | 'default';
  size?: 'small' | 'medium' | 'large' | 'default';
  className?: string;
}

const Tooltip = ({
  color,
  size,
  placement = 'top',
  children,
  className = '',
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <TooltipMaterial
      placement={placement}
      classes={{
        tooltip: cx(
          classes.tooltip,
          color ? classes[`${color}Color`] : '',
          size ? classes[`${size}Size`] : '',
          className,
        ),
        arrow: classes.tooltipArrow,
      }}
      {...props}
      enterTouchDelay={100}
    >
      <span className={cx(classes.tooltipInitBlock, 'tooltip-init-block')}>
        {children}
      </span>
    </TooltipMaterial>
  );
};

export default memo(Tooltip);
