import axios from 'axios';
import { getI18n } from 'react-i18next';

import { getToken, storeToken } from 'app/components/auth/utils/tokenStorage';
import { store } from 'app/config/store';
import { actions } from 'app/components/auth/store/slice';
import { destroyToken } from 'app/components/auth/utils/tokenStorage';
import { Token } from 'app/components/auth/interfaces';

const subscribers: Array<(toke: Token) => void> = [];

const subscribeTokenRefresh = (cb: (toke: Token) => void) =>
  subscribers.push(cb);

const onRefreshed = (token: Token) => subscribers.map(cb => cb(token));

const clearSubscribes = () => {
  subscribers.length = 0;
};

const logout = () => {
  store.dispatch(actions.clear());
  destroyToken();
};

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
});

export const apiClientWORedirect = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
});

export const httpClient = axios.create({
  responseType: 'json',
});

httpClient.interceptors.response.use(response => response.data);

export interface ApiValidationErrorMessage {
  field: string;
  message: string;
  constraint: string;
}

export interface ApiError {
  statusCode: number;
  message: ApiValidationErrorMessage[];
  error: string;
}

const requestInterceptor = async config => {
  const token = await getToken();
  const { language } = getI18n();

  if (language) {
    config.headers['Accept-Language'] = language;
  }

  if (token) {
    config.headers.Authorization = `${token.type || 'Bearer'} ${token.token}`;
  }

  return config;
};

apiClient.interceptors.request.use(requestInterceptor);
apiClientWORedirect.interceptors.request.use(requestInterceptor);

let isRefreshing = false;

const responseInterceptorOnFulfilled = response => response.data;

const getResponseInterceptorOnRejected =
  (allowRefreshToken: boolean, allowErrorRedirect = true) =>
  async error => {
    const { response, config } = error;

    if (response) {
      const { status, data } = response;
      const message = data.error ?? 'Something went wrong!';

      if (status === 401) {
        const token = await getToken();
        if (allowRefreshToken && token?.refreshToken) {
          if (!token?.refreshToken) {
            if (token) {
              logout();
            }

            return Promise.reject(data);
          }

          if (!isRefreshing) {
            axios
              .post(`${process.env.REACT_APP_API_HOST}/auth/refresh`, {
                refreshToken: token.refreshToken,
              })
              .then(({ data }) => {
                store.dispatch(actions.setToken(data));
                storeToken(data);
                onRefreshed(data);
                clearSubscribes();
                isRefreshing = false;
              })
              .catch(e => {
                console.log(e);
                logout();
              });
          }

          return new Promise(resolve => {
            subscribeTokenRefresh(token => {
              config.headers.Authorization = `${token.type} ${token.token}`;
              const retryClient = axios.create();
              retryClient.interceptors.request.use(requestInterceptor);
              retryClient.interceptors.response.use(
                responseInterceptorOnFulfilled,
                getResponseInterceptorOnRejected(false),
              );
              resolve(retryClient.request(config));
            });
          });
        } else {
          logout();
        }
      }

      if (status === 403) {
        window.location.assign('/access-denied');
      }

      console.error(`${status}: ${message}`);
      return Promise.reject(data);
    } else {
      console.error(error.message);
      if (allowErrorRedirect) window.location.assign('/something-went-wrong');
    }

    return Promise.reject(error);
  };

apiClient.interceptors.response.use(
  responseInterceptorOnFulfilled,
  getResponseInterceptorOnRejected(true),
);

apiClientWORedirect.interceptors.response.use(
  responseInterceptorOnFulfilled,
  getResponseInterceptorOnRejected(true, false),
);
