import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalContent, ModalTitle } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';
import { UserCompany, UserLocation } from 'app/components/auth/constants';

import AssignRoleForm from './AssignRoleForm';

interface Props {
  onClose: () => void;
  open: boolean;
  handleSubmit: (...data: any) => void;
  userCompanies?: UserCompany[];
  userLocations?: UserLocation[];
}

export const AssignRoleModal: React.FC<Props> = ({
  onClose,
  open,
  handleSubmit,
  userCompanies,
  userLocations,
}) => {
  const { t } = useTranslation();
  const onSubmit = useCallback(
    data => {
      handleSubmit(data, onClose);
    },
    [handleSubmit, onClose],
  );

  return (
    <Modal open={open} maxWidth="sm">
      <ModalTitle onClose={onClose} modalTitleBlock={'default'}>
        {t(translations.people.assignToRole)}
      </ModalTitle>
      <ModalContent>
        <AssignRoleForm
          handleSubmit={onSubmit}
          userCompanies={userCompanies}
          userLocations={userLocations}
        />
      </ModalContent>
    </Modal>
  );
};
