import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient, apiClientWORedirect } from 'app/config/axios';

import { PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { TimeplateVersionEntity } from '../interfaces';

const endpoint = '/timeplate-versions';

export const timeplateStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, TimeplateVersionEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const timeplateUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClientWORedirect.patch<any, TimeplateVersionEntity>(
    `${endpoint}/${id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const timeplateSettingsUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClientWORedirect.patch<any, TimeplateVersionEntity>(
    `${endpoint}/settings/${id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const timeplateShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, TimeplateVersionEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const timeplatePreviewShow = (
  id: number,
  queryData = {},
  withReplace: boolean,
  endpointPrefix = '',
) =>
  apiClient.get<FormData, TimeplateVersionEntity>(
    `${endpoint}${
      endpointPrefix ? `/${endpointPrefix}` : ''
    }/preview/${id}/${+withReplace}`,
    {
      params: queryData,
    },
  );

export const timeplateIndex = queryData =>
  apiClient.get<any, PaginatedResponse<TimeplateVersionEntity>>(endpoint, {
    params: queryData,
  });

export const useVersionIndex: <T = PaginatedResponse<TimeplateVersionEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexCompanies', any], any>(
    ['indexCompanies', queryData],
    () => timeplateIndex(queryData),
    options,
  );

export const useVersionStore = (
  options?: MutationOptions<TimeplateVersionEntity, FormData, any>,
) =>
  useMutation<TimeplateVersionEntity, FormData, any>(
    ['versionStore'],
    data => timeplateStore(data),
    {
      ...options,
    },
  );

export const useVersionUpdate = (
  id: number,
  options?: MutationOptions<TimeplateVersionEntity, any, any>,
) =>
  useMutation<TimeplateVersionEntity, any, any>(
    ['versionUpdate'],
    data => timeplateUpdate(id, data),
    {
      ...options,
    },
  );

export const useVersionSettingsUpdate = (
  id: number,
  options?: MutationOptions<TimeplateVersionEntity, any>,
) =>
  useMutation<TimeplateVersionEntity, any, any>(
    ['versionSettingsUpdate'],
    data => timeplateSettingsUpdate(id, data),
    {
      ...options,
    },
  );

export const useVersionShow = (
  id: number,
  options?: QueryObserverOptions<TimeplateVersionEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<TimeplateVersionEntity, ['timeplateShow', number], any>(
    [key || 'timeplateShow', id],
    () => timeplateShow(id, queryData),
    options,
  );

export const useVersionPreviewShow = (
  id: number,
  withReplace: boolean,
  options?: QueryObserverOptions<TimeplateVersionEntity, any>,
  queryData?,
  key?,
  endpointPrefix?,
) =>
  useQuery<TimeplateVersionEntity, ['timeplateShow', number], any>(
    ['useVersionPreviewShow'],
    () => timeplatePreviewShow(id, queryData, withReplace, endpointPrefix),
    options,
  );
