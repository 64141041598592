/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const TimeplateEditProfile = lazyLoad(
  () => import('./index'),
  module => module.TimeplateEditProfile,
);
