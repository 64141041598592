import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import {
  fontFamily,
  bgBody,
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryDisable,
  colorPrimaryLight,
  colorPrimaryLight2,
  colorPrimaryExtraLight,
  colorSecondary,
  colorSecondaryDark,
  colorBlue,
  colorPinkDark2,
  colorPinkDark3,
  colorDanger,
  colorSuccess,
  colorWhite,
  textPrimary,
  textSecondary,
  textDisabled,
  textFieldHorizontalSize,
  textFieldVerticalSize,
  borderRadiusSmallSize,
  borderRadiusSize,
  borderRadiusEllipse,
  ctaButtonWidth,
  tableBorderColor,
  tableBackground,
  tableMaxIndent,
  borderPrimaryColor,
  boxShadow,
  buttonSize,
  buttonSizeSmall,
  dialogHorizontal,
  dialogHorizontalMobile,
  dialogVertical,
  dialogVerticalMobile,
  bgOverlay,
  bgColorBlue,
  buttonColorBlue,
  buttonColorBlueDark,
  buttonColorBlueDisable,
  buttonColorPrimary,
  buttonColorPrimaryDisable,
  buttonColorPrimaryDark,
  buttonColorSecondary,
  buttonColorSecondaryDark,
  buttonColorSecondaryDisable,
  buttonColorDanger,
  buttonColorDangerDark,
  buttonColorPink,
  buttonColorPinkDark,
  buttonColorPrimaryLight,
  buttonColorPrimaryLight2,
  buttonColorDangerDisable,
  buttonOutColorPrimaryDark,
  buttonOutColorSecondaryDark,
  buttonOutColorPrimaryDisable,
  buttonOutColorSecondaryDisable,
  buttonOutColorBlueDark,
  buttonOutColorBlueDisable,
  buttonOutColorDangerDark,
  buttonOutColorDangerDisable,
  bgTooltip,
  checkBoxPrimary,
  checkBoxSecondary,
  radioSecondary,
  radioPrimary,
  switchSecondary,
  switchPrimary,
  switchBg,
  colorPrimary500,
} from 'configs/variables';

const breakpoints = createBreakpoints({});

export default createMuiTheme({
  breakpoints,
  palette: {
    primary: {
      light: colorPrimaryLight,
      main: colorPrimary,
      dark: colorPrimaryDark,
      contrastText: colorWhite,
    },
    secondary: {
      main: colorSecondary,
      dark: colorSecondaryDark,
      contrastText: colorWhite,
    },
    error: {
      main: colorDanger,
    },
    success: {
      main: colorSuccess,
    },
    background: {
      default: bgBody,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
  },
  typography: {
    fontFamily: fontFamily,
    fontSize: 14,
    h1: {
      fontSize: 60,
      fontWeight: 700,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
    },
    h3: {
      fontSize: 26,
      fontWeight: 700,

      [breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,

      [breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,

      [breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.6,
      textTransform: 'none',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          // Disable iOS Safari font scaling in landscape mode
          WebkitTextSizeAdjust: '100%',
        },
        body: {
          '&, & > .root-container': {
            height: '100%',
          },
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.5em',
      },
    },
    MuiButton: {
      root: {
        borderRadius: borderRadiusEllipse,
        padding: '10px 18px',
        minHeight: buttonSizeSmall,

        [breakpoints.up('md')]: {
          padding: '13px 24px',
          minHeight: buttonSize,
        },
        '&, &:hover, &:active': {
          boxShadow: 'none',
        },
        '&.cta-button': {
          maxWidth: '100%',
          textAlign: 'center',
          minWidth: ctaButtonWidth,
        },
        '&.cta-div-left': {
          textAlign: 'left',
        },
        '&.marginRight_10': {
          marginRight: 10,
        },
        '&.marginTop_10': {
          marginTop: 10,
        },
        '&.button-text': {
          padding: '2px 8px',
          minHeight: 0,
        },
        '&$sizeSmall, &$containedSizeSmall, &$outlinedSizeSmall': {
          fontSize: 14,
          [breakpoints.up('md')]: {
            minHeight: buttonSizeSmall,
          },
        },
      },
      textSizeSmall: {
        padding: '5px 10px',
      },
      containedSizeSmall: {
        padding: '5px 18px',
        minHeight: 38,
      },
      outlinedSizeSmall: {
        padding: '4px 18px',
        minHeight: 38,
      },
      contained: {
        color: colorWhite,
        backgroundColor: buttonColorBlue,

        '&:hover': {
          backgroundColor: buttonColorBlueDark,
        },
        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorBlueDisable,
        },
        '&, &:hover, &:active': {
          boxShadow: 'none',
        },
        '@media (hover: none)': {
          '&$root:hover': {
            backgroundColor: buttonColorBlue,
            boxShadow: 'none',
          },
        },

        '&.button-pink': {
          color: colorWhite,
          backgroundColor: buttonColorPink,
          '&:hover, &$disabled, &$disabled:hover': {
            color: colorWhite,
            backgroundColor: buttonColorPinkDark,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
        '&.button-danger': {
          color: colorWhite,
          backgroundColor: buttonColorDanger,
          '&:hover': {
            color: colorWhite,
            backgroundColor: buttonColorDangerDark,
          },

          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
            color: colorWhite,
            backgroundColor: buttonColorDangerDisable,
          },
        },
        '&.button-primary-light': {
          color: colorWhite,
          backgroundColor: buttonColorPrimaryLight,
          '&:hover, &$disabled, &$disabled:hover': {
            color: colorWhite,
            backgroundColor: buttonColorPrimaryLight2,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
      },
      containedPrimary: {
        backgroundColor: buttonColorPrimary,

        '&:hover': {
          backgroundColor: buttonColorPrimaryDark,
        },

        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorPrimaryDisable,
        },
        '@media (hover: none)': {
          '&$root:hover': {
            backgroundColor: buttonColorPrimary,
          },
        },
      },
      containedSecondary: {
        backgroundColor: buttonColorSecondary,
        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorSecondaryDisable,
        },
        '@media (hover: none)': {
          '&$root:hover': {
            backgroundColor: buttonColorSecondary,
          },
        },
      },
      outlined: {
        color: buttonColorBlueDark,
        borderColor: buttonColorBlueDark,
        padding: '9px 18px',

        [breakpoints.up('md')]: {
          padding: '12px 24px',
        },

        '&:hover': {
          color: buttonOutColorBlueDark,
          borderColor: buttonOutColorBlueDark,
        },

        '&$disabled, &$disabled:hover': {
          color: buttonOutColorBlueDisable,
          borderColor: buttonOutColorBlueDisable,
        },

        '&.button-pink': {
          color: buttonColorPink,
          borderColor: buttonColorPink,
          '&:hover, &$disabled, &$disabled:hover': {
            color: buttonColorPinkDark,
            borderColor: buttonColorPinkDark,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
        '&.button-danger': {
          color: buttonColorDangerDark,
          borderColor: buttonColorDangerDark,
          '&:hover': {
            color: buttonOutColorDangerDark,
            borderColor: buttonOutColorDangerDark,
          },

          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
            color: buttonOutColorDangerDisable,
            borderColor: buttonOutColorDangerDisable,
          },
        },
      },
      outlinedPrimary: {
        color: buttonColorPrimaryDark,
        borderColor: buttonColorPrimaryDark,

        '&:hover': {
          color: buttonOutColorPrimaryDark,
          borderColor: buttonOutColorPrimaryDark,
        },

        '&$disabled, &$disabled:hover': {
          color: buttonOutColorPrimaryDisable,
          borderColor: buttonOutColorPrimaryDisable,
        },
      },
      outlinedSecondary: {
        color: buttonColorSecondaryDark,
        borderColor: buttonColorSecondaryDark,

        '&:hover': {
          color: buttonOutColorSecondaryDark,
          borderColor: buttonOutColorSecondaryDark,
        },
        '&.hover-contained': {
          '&:hover, &.hovered': {
            color: colorWhite,
            borderColor: buttonColorSecondary,
            backgroundColor: buttonColorSecondary,
          },
        },
        '&$disabled, &$disabled:hover': {
          color: buttonOutColorSecondaryDisable,
          borderColor: buttonOutColorSecondaryDisable,
        },
      },
      colorInherit: {
        '&, &:hover': {
          color: 'inherit',
          borderColor: 'currentColor',
        },
      },
      startIcon: {
        marginLeft: -3,
        marginRight: 6,
      },
    },
    MuiFab: {
      root: {
        color: colorWhite,
        backgroundColor: buttonColorBlue,
        boxShadow: '0px 2px 4px rgba(0,0,0,16%)',

        '&:hover': {
          backgroundColor: buttonColorBlueDark,
        },
        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorBlueDisable,
        },
        '&.button-pink': {
          backgroundColor: buttonColorPink,
          '&:hover, &$disabled, &$disabled:hover': {
            backgroundColor: buttonColorPinkDark,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
        '&.button-error': {
          backgroundColor: buttonColorDanger,
          '&:hover, &$disabled, &$disabled:hover': {
            backgroundColor: buttonColorDangerDark,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
      },
      primary: {
        backgroundColor: buttonColorPrimary,
        '&:hover': {
          backgroundColor: buttonColorPrimaryDark,
        },
        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorPrimaryDisable,
        },
      },
      secondary: {
        '&$disabled, &$disabled:hover': {
          color: colorWhite,
          backgroundColor: buttonColorSecondaryDisable,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: buttonColorBlue,

        '&:hover': {
          backgroundColor: `${buttonColorBlue}0D`, // opacity 0.05
        },
        '& svg': {
          fontSize: 'inherit',
        },
        '&.button-bordered': {
          border: '1px solid currentColor',
        },
        '&.button-error': {
          color: buttonColorDanger,

          '&:hover': {
            color: buttonColorDangerDark,
          },
          '&$disabled, &$disabled:hover': {
            opacity: 0.5,
          },
        },
      },
      colorPrimary: {
        color: buttonColorPrimary,
        '&:hover': {
          color: buttonColorPrimaryDark,
        },
      },
      colorSecondary: {
        color: buttonColorSecondary,
        '&:hover': {
          color: buttonColorSecondaryDark,
        },
      },
      sizeSmall: {
        fontSize: 14,
        padding: 7,
      },
    },
    MuiLink: {
      underlineAlways: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },

    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 24,
      },
    },
    MuiTextField: {
      root: {
        // minWidth: 220,
      },
    },
    MuiInputBase: {
      root: {
        color: textSecondary,

        '&$disabled': {
          color: textDisabled,
        },
      },
      input: {
        padding: `${textFieldVerticalSize}px ${textFieldHorizontalSize}px`,
        textOverflow: 'ellipsis',

        '&$disabled': {
          WebkitTextFillColor: textSecondary,
          WebkitOpacity: 1,
          opacity: 1,
        },
      },
      inputMarginDense: {
        paddingTop: textFieldVerticalSize,
        paddingBottom: textFieldVerticalSize,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: borderRadiusSmallSize,
        '.text-field-white > &': {
          color: colorWhite,
          '&:not($error)': {
            '& $notchedOutline, &:hover $notchedOutline, &$focused $notchedOutline':
              {
                borderColor: colorWhite,
              },
          },
        },
        '.text-field-success > &': {
          color: colorSecondary,
          '&:not($error)': {
            '& $notchedOutline, &:hover $notchedOutline, &$focused $notchedOutline':
              {
                borderColor: colorSecondary,
              },
          },
        },
      },
      input: {
        padding: `${textFieldVerticalSize}px ${textFieldHorizontalSize}px`,
      },
      inputMarginDense: {
        paddingTop: textFieldVerticalSize,
        paddingBottom: textFieldVerticalSize,
      },
      notchedOutline: {
        borderColor: borderPrimaryColor,
        '$disabled &': {
          borderColor: `${borderPrimaryColor} !important`,
        },
      },
    },

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    PrivateNotchedOutline: {
      legendLabelled: {
        overflow: 'hidden',
      },
    },
    MuiFormLabel: {
      root: {
        '.text-field-white > &:not($error)': {
          '&, &$focused': {
            color: colorWhite,
          },
        },
        '.text-field-success > &:not($error)': {
          '&, &$focused': {
            color: colorSecondary,
          },
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        '&, &$marginDense:not($shrink)': {
          transform: `translate(${textFieldHorizontalSize}px, 20px) scale(1)`,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: 'inherit',
      },
    },
    MuiListItem: {
      root: {
        '&$selected, &$selected:hover': {
          color: colorWhite,
          backgroundColor: colorPrimaryLight,
        },
      },
      button: {
        '&:hover': {
          color: colorWhite,
          backgroundColor: colorPrimaryExtraLight,

          '@media (hover: none)': {
            backgroundColor: colorPrimaryExtraLight,
          },
        },
      },
      gutters: {
        paddingLeft: textFieldHorizontalSize,
        paddingRight: textFieldHorizontalSize,
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 'auto',
        paddingTop: 10,
        paddingBottom: 10,
      },
    },

    MuiAutocomplete: {
      tag: {
        margin: 2,
        maxWidth: 'calc(100% - 4px)',
      },
      inputRoot: {
        '&, &[class*="MuiOutlinedInput-root"], &[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
          {
            padding: `${textFieldVerticalSize}px ${textFieldHorizontalSize}px`,
          },
      },
      input: {
        padding: '0 !important',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        [breakpoints.down('xs')]: {
          minHeight: 280,
          minWidth: 280,
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        overflow: 'hidden',

        [breakpoints.down('xs')]: {
          minHeight: 190,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colorPrimaryLight2,

        [breakpoints.down('xs')]: {
          height: 75,
        },
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        minHeight: 0,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontWeight: 400,
        fontSize: 16,

        [breakpoints.down('xs')]: {
          'h6&': {
            fontSize: 14,
          },
        },
      },
      toolbarBtnSelected: {
        fontSize: 30,
        fontWeight: 400,

        [breakpoints.down('xs')]: {
          '&, h6&': {
            fontSize: 20,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: textSecondary,
      },
      [breakpoints.down('xs')]: {
        '& p': {
          fontSize: 15,
        },
      },
      dayLabel: {
        [breakpoints.down('xs')]: {
          width: 30,
        },
      },
    },
    MuiPickersDay: {
      day: {
        [breakpoints.down('xs')]: {
          width: 30,
          height: 30,
          '& p': {
            fontSize: 14,
          },
        },
      },
      daySelected: {
        backgroundColor: colorPrimaryLight2,

        '&:hover': {
          backgroundColor: colorPrimaryLight2,
        },
      },
      current: {
        backgroundColor: colorPrimaryDisable,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
        lineHeight: 1,
        marginTop: '0.4em',
        // marginBottom: 'calc(-1em - 0.4em)',

        '&, &$contained': {
          marginLeft: textFieldHorizontalSize,
          marginRight: textFieldHorizontalSize,
        },
      },
      contained: {},
    },
    MuiChip: {
      root: {
        fontSize: 14,
        fontWeight: 400,
        color: textPrimary,
      },
      colorSecondary: {
        color: textSecondary,
      },
      sizeSmall: {
        height: 24,
        fontSize: 12,

        '& $label': {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
      label: {
        lineHeight: 1.3,
      },
      deleteIcon: {
        color: textSecondary,
        width: 18,
        height: 18,
        fontSize: 18,
      },
      deleteIconSmall: {
        width: 14,
        height: 14,
        fontSize: 14,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: checkBoxSecondary,
        },
      },
      colorPrimary: {
        '&$checked': {
          color: checkBoxPrimary,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: radioSecondary,
        },
      },
      colorPrimary: {
        '&$checked': {
          color: radioPrimary,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: 36,
        height: 20,
        padding: 0,
        borderRadius: 10,
        marginRight: '6px',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      switchBase: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(16px)',
          color: colorWhite,
          '& + $track': {
            opacity: 1,
          },
        },
        '&$focusVisible $thumb': {
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 16,
        height: 16,
      },
      track: {
        borderRadius: 10,
        backgroundColor: switchBg,
      },
      colorSecondary: {
        '&$checked ': {
          color: colorWhite,

          '& + $track': {
            backgroundColor: switchSecondary,
          },
        },
        '&$disabled': {
          color: colorWhite,
          backgroundColor: switchBg,
          '& + $track': {
            opacity: 1,
            backgroundColor: switchBg,
          },
        },
      },
      colorPrimary: {
        '&$checked ': {
          color: colorWhite,

          '& + $track': {
            backgroundColor: switchPrimary,
          },
        },
        '&$disabled': {
          color: colorWhite,
          backgroundColor: switchBg,
          '& + $track': {
            opacity: 1,
            backgroundColor: switchBg,
          },
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 60,

        [breakpoints.up('sm')]: {
          minHeight: 80,
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 3px 9px rgba(0,0,0, 0.09)',
      },
      rounded: {
        borderRadius: borderRadiusSize,
      },
    },
    MuiTab: {
      root: {
        padding: '6px 24px !important',
      },
    },
    MuiTable: {
      root: {
        borderRadius: borderRadiusSize,
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: tableBackground,
        borderBottom: `1px solid ${tableBorderColor}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      head: {
        backgroundColor: tableBackground,
        '&, &:last-child': {
          borderBottom: `1px solid ${colorPrimary}`,
        },
      },
      footer: {
        justifyContent: 'flex-end',
        borderRadius: `0 0 ${borderRadiusSize}px ${borderRadiusSize}px`,
      },
    },
    MuiTableCell: {
      root: {
        padding: '14px 12px',
        borderBottom: 'none',
        wordBreak: 'break-word',

        '&:first-child': {
          paddingLeft: tableMaxIndent,
        },
        '&:last-child': {
          paddingRight: tableMaxIndent,
          justifyContent: 'flex-end',
        },
        '&.text-right': {
          textAlign: 'right',
        },
        '&.text-center': {
          textAlign: 'center',
        },
      },
      head: {
        color: colorPrimary,
        fontSize: 16,
        fontWeight: 600,
        position: 'relative',
        '&:last-child': {
          textAlign: 'right',
        },
      },
      body: {
        color: colorPrimary,
        fontSize: 14,
        fontWeight: 400,
      },
      stickyHeader: {
        backgroundColor: tableBackground,
      },
      footer: {
        justifyContent: 'flex-end',
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: tableBackground,
        borderRadius: `0 0 ${borderRadiusSize}px ${borderRadiusSize}px`,
        borderTop: `1px solid ${tableBorderColor}`,
      },
    },
    MuiTablePagination: {
      root: {
        color: colorPrimary,
        width: '100%',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        padding: 0,
        minHeight: 0,

        [breakpoints.up('md')]: {
          padding: `7px 10px 7px ${tableMaxIndent}px`,
        },
      },
      caption: {
        fontSize: 12,
        [breakpoints.up('md')]: {
          fontSize: 14,
        },
      },
      selectRoot: {
        [breakpoints.down('sm')]: {
          marginLeft: 5,
          marginRight: 15,
        },

        '& svg': {
          right: 4,
        },
      },
      select: {
        border: `1px solid ${colorPrimary}`,
        paddingTop: 6,
        paddingBottom: 6,

        '&, &:focus': {
          borderRadius: borderRadiusSize,
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&$focused': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: bgOverlay,
      },
    },
    MuiDialog: {
      paper: {
        ...boxShadow,
        [breakpoints.down('sm')]: {
          margin: 15,
        },
        [breakpoints.down('xs')]: {
          '&$paperScrollBody': {
            maxWidth: 'calc(100% - 30px) !important',
          },
          '&$paperScrollBody$paperFullScreen': {
            maxWidth: '100% !important',
          },
        },
      },
      paperWidthXs: {},
      paperWidthSm: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('sm')]: {
            maxWidth: 485,
          },
        },
      },
      paperWidthMd: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('sm')]: {
            maxWidth: 585,
          },
        },
      },
      paperWidthLg: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('md')]: {
            maxWidth: 800,
          },
        },
      },
      paperWidthXl: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('md')]: {
            maxWidth: 1300,
          },
        },
      },
      paperScrollBody: {},
      paperFullWidth: {
        width: 'calc(100% - 30px)',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${dialogVerticalMobile}px ${dialogHorizontalMobile}px`,
        [breakpoints.up('sm')]: {
          padding: `${dialogVertical}px ${dialogHorizontal}px`,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${dialogVerticalMobile}px ${dialogHorizontalMobile}px`,
        [breakpoints.up('sm')]: {
          padding: `${dialogVertical}px ${dialogHorizontal}px`,
        },
        '&:last-child': {
          paddingBottom: dialogVerticalMobile,
          [breakpoints.up('sm')]: {
            paddingBottom: dialogVertical,
          },
        },
        '&:first-child': {
          paddingTop: dialogVerticalMobile,
          [breakpoints.up('sm')]: {
            paddingTop: dialogVertical,
          },
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${dialogVerticalMobile}px ${dialogHorizontalMobile}px`,
        borderTop: `1px solid ${borderPrimaryColor}`,

        [breakpoints.up('sm')]: {
          padding: `${dialogVertical}px ${dialogHorizontal}px`,
        },
        '&.action-centered': {
          justifyContent: 'center',
        },
        '&.action-right': {
          justifyContent: 'flex-end !important',
        },
        '&.action-info': {
          color: colorBlue,
          backgroundColor: bgColorBlue,
          display: 'block',
          textAlign: 'center',

          '& .text-input': {
            backgroundColor: colorWhite,
          },
          '&$spacing > *': {
            marginLeft: 0,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: borderRadiusSize,
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.3,
        padding: '8px 12px',
        backgroundColor: bgTooltip,
        whiteSpace: 'pre-line',
      },
      touch: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.3,
        padding: '8px 12px',
      },
      tooltipPlacementTop: {
        margin: '8px 0',

        [breakpoints.up('sm')]: {
          margin: '8px 0',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0',

        [breakpoints.up('sm')]: {
          margin: '8px 0',
        },
      },
      arrow: {
        color: bgTooltip,
      },
    },
    PrivateBreadcrumbCollapsed: {
      root: {
        '.bc-separator + &': {
          color: colorPrimary500,
          display: 'flex',
          alignItems: 'center',
          height: buttonSizeSmall,
          marginLeft: 0,
          marginRight: 0,
          borderRadius: 0,

          [breakpoints.up('md')]: {
            height: buttonSize,
          },
          '&, &:hover': {
            backgroundColor: colorWhite,
          },
        },
        '& + .bc-separator': {
          paddingLeft: 4,

          [breakpoints.up('md')]: {
            paddingLeft: 6,
          },
        },
      },
    },
    MuiTabScrollButton: {
      root: {
        '&$disabled': {
          display: 'none',
        },
      },
    },
  },
});
