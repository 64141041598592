import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from '../../styles/data';

const useStyles = makeStyles(styles);

export interface Props {
  value?: string;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  view?: 'multiline' | 'input' | 'auto';
  margin?: 'none' | 'dense' | 'normal';
}

const DataField: React.FC<Props> = ({
  value,
  label,
  helperText,
  className = '',
  children,
  view = 'input',
  margin = 'none',
}) => {
  const classes = useStyles();

  return (
    <FormControl
      margin={margin}
      fullWidth
      className={cx(
        classes.dataField,
        label ? '' : classes.dataFieldWithoutLabel,
        className,
      )}
    >
      {label ? (
        <InputLabel className={classes.dataFieldLabel}>{label}</InputLabel>
      ) : (
        ''
      )}
      <div
        className={cx(
          classes.dataFieldData,
          view ? classes[`${view}Data`] : '',
          'data-field-data',
        )}
      >
        {value || children}
      </div>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : ''}
    </FormControl>
  );
};

export default memo(DataField);
