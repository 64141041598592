import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { Cell } from 'react-table';
import { Helmet } from 'react-helmet-async';

import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { getTabById } from 'utils/getTabById';
import { error, success } from 'app/components/snackbars/utils';
import { Loader } from 'app/components/ui/Loader';
import {
  CompaniesList,
  EditUser,
  LocationsList,
  RolesList,
  useAssignUserToCompany,
  useAssignUserToLocation,
  useAssignUserToRole,
  useRemoveFromCompany,
  useRemoveFromLocation,
  useRemoveRole,
  Users,
  useSendUserLoginInfo,
  useUserShow,
  useUserUpdate,
} from 'app/components/users';
import { Tabs, useSteps } from 'app/components/ui/Tabs';
import { Page, Paper } from 'app/components/layouts';
import { passwordStrengthCheckRegex } from 'app/components/onboarding/constants';
import { PAGE_TYPE } from 'app/containers/constants';
import { LinkCell, Table } from 'app/components/table';
import { ROLES, ROLES_TITLE, TYPES } from 'app/components/auth/constants';
import { getCompanyStatusStyle } from 'app/components/entities/companies/utils';
import { USER_TYPES } from 'app/interfaces';
import { LocationEntity } from 'app/components/entities/companies/interfaces';
import Settings from 'app/components/users/components/Settings';
import { useConfirmation } from 'app/components/ui/Modal';

export interface Props {
  ghostBackLink: string;
  trialBackLink: string;
  peopleBackLink: string;
  pageType: PAGE_TYPE;
}

export function GhostUserEdit({
  ghostBackLink,
  trialBackLink,
  peopleBackLink,
  pageType,
}: Props) {
  const userQueryObject = RequestQueryBuilder.create({
    join: [
      { field: 'trialCompany' },
      { field: 'trialCompany.trialUsers' },
      { field: 'trialCompany.trialUsers.roles' },
      { field: 'signatureUsers' },
      { field: 'signatureUsers.signature' },
      { field: 'signatureUsers.signature.location' },
      { field: 'signatureUsers.signature.timeplates' },
      { field: 'userRoles' },
      { field: 'userRoles.company' },
      { field: 'userRoles.location' },
      { field: 'userRoles.role' },
      { field: 'account' },
      { field: 'account.companies' },
      { field: 'account.companies.locations' },
    ],
  }).queryObject;

  const TABS = useMemo(
    () =>
      pageType === PAGE_TYPE.PAID
        ? {
            logins: 1,
            roles: 2,
            companies: 3,
            locations: 4,
            account: 5,
            signatures: 6,
            settings: 7,
          }
        : {
            account: 1,
            signatures: 2,
            logins: 3,
            roles: 4,
            companies: 5,
            locations: 6,
            settings: 7,
          },
    [pageType],
  );

  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isGhost = pageType === PAGE_TYPE.GHOSTS;
  const urlEntityType = {
    [PAGE_TYPE.GHOSTS]: 'ghost',
    [PAGE_TYPE.TRIALS]: 'trial',
    [PAGE_TYPE.PAID]: 'people',
  };

  const { value, onChange: handleTabsChange } = useSteps(
    tab && TABS[tab] ? TABS[tab] : 1,
  );

  const schema = yup.object().shape({
    firstName: yup.string().required(t(translations.validation.required)),
    lastName: yup.string().required(t(translations.validation.required)),
    email: yup
      .string()
      .required(t(translations.validation.required))
      .email(t(translations.validation.email)),
    password: yup
      .string()
      .matches(
        passwordStrengthCheckRegex,
        t(translations.validation.passwordStrong),
      ),
  });

  const [backLink, setBackLink] = useState<string>('');
  const [actualInfo, setActualInfo] = useState<Users>();

  const {
    isLoading: showLoading,
    isFetching,
    data: user,
    refetch,
  } = useUserShow(Number(id), userQueryObject, 'userData');

  useEffect(() => {
    if (user) {
      setActualInfo(user);
    }
  }, [user]);

  const { mutateAsync, isLoading: updateLoading } = useUserUpdate(Number(id), {
    onSuccess: setActualInfo,
  });

  const { mutateAsync: callRemoveRole } = useRemoveRole(Number(id));
  const { mutateAsync: callRemoveFromLocation } = useRemoveFromLocation(
    Number(id),
  );
  const { mutateAsync: callRemoveFromCompany } = useRemoveFromCompany(
    Number(id),
  );

  const {
    mutateAsync: callSendUserLoginInfo,
    isLoading: sendUserLoginInfoLoading,
  } = useSendUserLoginInfo(Number(id));

  const {
    mutateAsync: callAssignUserToRole,
    isLoading: assignUserToRoleLoading,
  } = useAssignUserToRole(Number(id));

  const {
    mutateAsync: callAssignUserToCompany,
    isLoading: assignUserToCompanyLoading,
  } = useAssignUserToCompany(Number(id));

  const {
    mutateAsync: callAssignUserToLocation,
    isLoading: assignUserToLocationLoading,
  } = useAssignUserToLocation(Number(id));

  const doAssignToRole = useCallback(
    async (data, closeModal) => {
      try {
        const result = await callAssignUserToRole(data);
        if (result && result.success) {
          success(t(translations.people.message.success.assignRole));
          refetch();
        } else if (result?.error === 'duplicate') {
          error(
            t(translations.people.message.errors.duplicate, {
              roleName: ROLES_TITLE[data.roleType.id],
            }),
          );
        } else if (result?.error === 'limit') {
          if (
            [ROLES.ACCOUNT_HOLDER_PRIMARY, ROLES.ACCOUNT_HOLDER].includes(
              data.roleType.id,
            )
          ) {
            error(
              t(translations.people.message.errors.limit, {
                roleName: ROLES_TITLE[ROLES.ACCOUNT_HOLDER],
              }),
            );
          } else {
            error(
              t(translations.people.message.errors.limit, {
                roleName: ROLES_TITLE[data.roleType.id],
              }),
            );
          }
        } else {
          error(t(translations.errors.somethingWentWrongError));
        }
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
      closeModal();
    },
    [callAssignUserToRole, refetch, t],
  );
  const [assignRoleData, setAssignRoleData] = useState<[any, any] | null>(null);

  const descriptionConfirmation = {
    [ROLES.ACCOUNT_HOLDER_PRIMARY]: t(
      translations.people.assignAHPConfirmation,
    ),
    [ROLES.COMPANY_ADMIN_PRIMARY]: t(translations.people.assignCAPConfirmation),
    [ROLES.LOCATION_ADMIN_PRIMARY]: t(
      translations.people.assignLAPConfirmation,
    ),
  };
  const primaryRoles = [
    ROLES.ACCOUNT_HOLDER_PRIMARY,
    ROLES.COMPANY_ADMIN_PRIMARY,
    ROLES.LOCATION_ADMIN_PRIMARY,
  ];

  const [showPrimaryConfirmation] = useConfirmation({
    onSubmit: () => {
      if (assignRoleData) {
        doAssignToRole(...assignRoleData);
      }
    },
    description: (
      <div>
        <div>{descriptionConfirmation[assignRoleData?.[0]?.roleType?.id]}</div>
      </div>
    ),
    titleBlockStyle: 'default',
    agreeLabel: t(translations.ui.buttons.assign),
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleAssignToRole = useCallback(
    async (data, closeModal) => {
      if (primaryRoles.includes(data?.roleType.id)) {
        setAssignRoleData([data, closeModal]);
        showPrimaryConfirmation();
      } else {
        await doAssignToRole(data, closeModal);
      }
    },
    [doAssignToRole, showPrimaryConfirmation],
  );

  const handleAssignToCompany = useCallback(
    async (data, closeModal) => {
      try {
        await callAssignUserToCompany(data);
        success(t(translations.people.message.success.assignGhostCompany));
        refetch();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
      closeModal();
    },
    [callAssignUserToCompany, refetch, t],
  );

  const handleAssignToLocation = useCallback(
    async (data, closeModal) => {
      try {
        await callAssignUserToLocation(data);
        success(t(translations.people.message.success.assignGhostLocation));
        refetch();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
      closeModal();
    },
    [callAssignUserToLocation, refetch, t],
  );

  const handleRemoveRole = useCallback(
    async roleId => {
      try {
        await callRemoveRole(roleId);
        success(t(translations.people.message.success.removeRole));
        refetch();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [callRemoveRole, refetch, t],
  );

  const handleRemoveFromCompany = useCallback(
    async companyId => {
      try {
        await callRemoveFromCompany(companyId);
        success(t(translations.people.message.success.removeGhostCompany));
        refetch();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [callRemoveFromCompany, refetch, t],
  );

  const handleRemoveFromLocation = useCallback(
    async locationId => {
      try {
        await callRemoveFromLocation(locationId);
        success(t(translations.people.message.success.removeGhostLocation));
        refetch();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [callRemoveFromLocation, refetch, t],
  );

  const isLoading = useMemo(
    () =>
      showLoading ||
      isFetching ||
      updateLoading ||
      sendUserLoginInfoLoading ||
      assignUserToRoleLoading ||
      assignUserToCompanyLoading ||
      assignUserToLocationLoading,
    [
      assignUserToCompanyLoading,
      assignUserToLocationLoading,
      assignUserToRoleLoading,
      isFetching,
      sendUserLoginInfoLoading,
      showLoading,
      updateLoading,
    ],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        await mutateAsync(data);
        await refetch();
        success(t(translations.user.message.success.update));
      } catch (e) {
        error(t(translations.user.message.error));
      }
    },
    [mutateAsync, refetch, t],
  );

  const handleSendUserLinkToLogin = useCallback(async () => {
    try {
      await callSendUserLoginInfo({
        sendUserLinkToLogin: true,
      });
      success(t(translations.user.message.success.emailSent));
    } catch (e) {
      error(t(translations.user.message.error));
    }
  }, [callSendUserLoginInfo, t]);

  const handleSendUserPasswordReset = useCallback(async () => {
    try {
      await callSendUserLoginInfo({
        sendUserPasswordReset: true,
      });
      success(t(translations.user.message.success.emailSent));
    } catch (e) {
      error(t(translations.user.message.error));
    }
  }, [callSendUserLoginInfo, t]);

  const onTabChange = useCallback(
    (event, tab) => {
      if (tab !== value) {
        navigate(
          `/${
            urlEntityType[pageType] === 'people'
              ? 'people'
              : `${urlEntityType[pageType]}-users`
          }/${id}/edit/${getTabById(tab, TABS)}`,
        );
        setActualInfo(undefined);
        handleTabsChange(event, tab);
      }
    },
    [getTabById, handleTabsChange, navigate, id, value],
  );

  useEffect(() => {
    if (user) {
      setActualInfo(user);
    }
  }, [user, value]);

  const statusStyle = useMemo(() => {
    if (isGhost) {
      return '';
    }

    if (actualInfo) {
      if (actualInfo.type === TYPES.DELETED) {
        return 'grey';
      }

      if (actualInfo.trialCompany) {
        return getCompanyStatusStyle(actualInfo.trialCompany);
      }

      return 'grey';
    }

    return 'grey';
  }, [actualInfo, isGhost]);

  const signatureColumns = [
    {
      accessor: 'isActive',
      Cell: () => {
        return (
          <FiberManualRecordIcon
            className={`table-status-icon ${
              statusStyle && 'table-status-icon-inactive-' + statusStyle
            }`}
          />
        );
      },
      disableSortBy: true,
      className: 'table-info-first-cell table-status-cell',
      headerClassName: 'table-status-cell',
    },
    {
      Header: 'Signature Name',
      accessor: 'signature.name',
      disableSortBy: true,
      customProps: row =>
        `/${urlEntityType[pageType]}-signatures/${row.signatureId}/edit`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
      className: 'table-header-cell-mobile left-indent',
      minWidth: 200,
      maxWidth: 300,
    },
    {
      Header: 'Timeplates',
      id: 'timeplates',
      disableSortBy: true,
      customProps: row =>
        `/${urlEntityType[pageType]}-timeplates/${row.signature.location.companyId}/${row.signature.locationId}/${row.signature.id}`,
      contentBuilder: row => row.signature.timeplates.length || '-',
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: 'Active Timeplate',
      id: 'avtiveTimeplate',
      disableSortBy: true,
      className: 'table-action-cell',
      customProps: row => {
        const timeplateId = row.signature.timeplates.reduce((acc, it) => {
          if (it.isActive) {
            return it.id;
          }

          return acc;
        }, '-');

        return `/${urlEntityType[pageType]}-timeplates/${timeplateId}/designer`;
      },
      contentBuilder: row =>
        row.signature.timeplates.reduce((acc, it) => {
          if (it.isActive) {
            return it.name;
          }

          return acc;
        }, '-'),
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
  ].filter(Boolean);

  const availableCompanies = useMemo(() => {
    if (user?.account?.companies) {
      if (user.companies) {
        return user.account.companies.filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          company => !user.companies.some(({ id }) => id === company.id),
        );
      }

      return user.account.companies;
    }

    return [];
  }, [user]);

  const availableLocations = useMemo(() => {
    if (user && user.account?.companies) {
      return user.account.companies.reduce<LocationEntity[]>((acc, item) => {
        if (user.companies && user.companies.some(({ id }) => id === item.id)) {
          item.locations.forEach(location => {
            if (!user.locations?.some(({ id }) => id === location.id)) {
              acc.push(location);
            }
          });
        }
        return acc;
      }, []);
    }

    return [];
  }, [user]);

  const renderTabs = useMemo(() => {
    if (user?.type) {
      if (user?.type === USER_TYPES.PAID) {
        return [
          {
            id: TABS.logins,
            label: t(translations.people.logins),
            content: actualInfo && (
              <Paper contentIndented>
                <EditUser
                  isActive={statusStyle !== 'grey'}
                  schema={schema}
                  onSubmit={handleSubmit}
                  defaultValues={actualInfo}
                  isLoading={isLoading}
                  pageType={pageType}
                  handleSendUserLinkToLogin={handleSendUserLinkToLogin}
                  handleSendUserPasswordReset={handleSendUserPasswordReset}
                />
              </Paper>
            ),
          },
          {
            id: TABS.roles,
            label: t(translations.people.roles),
            content: actualInfo && (
              <Paper>
                <RolesList
                  userRoles={user.userRoles}
                  userCompanies={user.companies}
                  userLocations={user.locations}
                  handleAssignToRole={handleAssignToRole}
                  handleRemoveRole={handleRemoveRole}
                />
              </Paper>
            ),
          },
          {
            id: TABS.companies,
            label: t(translations.people.companies),
            content: actualInfo && (
              <Paper>
                <CompaniesList
                  userName={user.firstName}
                  userRoles={user.userRoles}
                  userCompanies={user.companies}
                  allCompanies={availableCompanies}
                  handleAssignToCompany={handleAssignToCompany}
                  handleRemoveCompany={handleRemoveFromCompany}
                />
              </Paper>
            ),
          },
          {
            id: TABS.locations,
            label: t(translations.people.locations),
            content: actualInfo && (
              <Paper>
                <LocationsList
                  userRoles={user.userRoles}
                  userCompanies={user.companies}
                  userLocations={user.locations}
                  allLocations={availableLocations}
                  userName={user.firstName}
                  handleAssignToCompany={handleAssignToLocation}
                  handleRemoveLocation={handleRemoveFromLocation}
                />
              </Paper>
            ),
          },
          {
            id: TABS.settings,
            label: t(translations.people.settings),
            content: actualInfo && (
              <Paper>
                <Settings user={user} />
              </Paper>
            ),
          },
        ];
      }
      return [
        {
          id: TABS.account,
          label: t(translations.people.account),
          content: actualInfo && (
            <Paper contentIndented>
              <EditUser
                isActive={statusStyle !== 'grey'}
                schema={schema}
                onSubmit={handleSubmit}
                defaultValues={actualInfo}
                isLoading={isLoading}
                pageType={pageType}
              />
            </Paper>
          ),
        },
        {
          id: TABS.signatures,
          label: t(translations.people.signatures),
          content: (
            <Table
              columns={signatureColumns}
              data={user?.signatureUsers || []}
              isPagination={false}
            />
          ),
        },
      ];
    }
    return [];
  }, [
    user,
    TABS,
    t,
    actualInfo,
    statusStyle,
    handleSubmit,
    isLoading,
    pageType,
    signatureColumns,
    handleSendUserLinkToLogin,
    handleSendUserPasswordReset,
    handleAssignToRole,
    handleRemoveRole,
    availableCompanies,
    handleAssignToCompany,
    handleRemoveFromCompany,
    availableLocations,
    handleAssignToLocation,
    handleRemoveFromLocation,
    id,
  ]);

  useEffect(() => {
    if (
      [USER_TYPES.TRIAL, USER_TYPES.DELETED].includes(
        (user?.type || '') as USER_TYPES,
      )
    ) {
      setBackLink(trialBackLink);
    } else if (((user?.type || '') as USER_TYPES) === USER_TYPES.PAID) {
      setBackLink(peopleBackLink);
    } else {
      setBackLink(ghostBackLink);
    }
  }, [ghostBackLink, peopleBackLink, trialBackLink, user]);

  return (
    <Page>
      <Helmet>
        <title>{t(translations.metaInfo.ghostPersonEditPage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.ghostPersonEditPage.description)}
        />
      </Helmet>
      <Loader loading={isLoading} />
      <Paper
        header={
          <>
            <div>
              <Typography variant="h5">{actualInfo?.fullName || ''}</Typography>
            </div>
          </>
        }
        backLink={backLink}
      >
        <Tabs value={value} tabs={renderTabs} onChange={onTabChange} />
      </Paper>
    </Page>
  );
}
