import { createStyles } from '@material-ui/core/styles';

export default () =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '715px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
  });
