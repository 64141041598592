import React, { useCallback, useState } from 'react';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import { COMPANY_TYPES } from 'app/containers/constants';
import { Page } from 'app/components/layouts';
import { Loader } from 'app/components/ui/Loader';
import {
  GhostList,
  useCompanyFilterIndex,
  useCompanyClone,
  useCompanyDelete,
} from 'app/components/entities/companies';
import { success, error } from 'app/components/snackbars';

export function GhostCompaniesPage() {
  const { t } = useTranslation();

  const { mutateAsync: cloneCompany } = useCompanyClone();
  const { mutateAsync: deleteCompany } = useCompanyDelete();

  const [indexQuery, setIndexQuery] = useState({
    ...RequestQueryBuilder.create({
      ...{
        search: {
          type: COMPANY_TYPES.GHOST,
          originalCompanyId: { $isnull: true },
        },
      },
    }).queryObject,
    limit: 10,
    page: 1,
    sort: [`createdAt,DESC`],
  });

  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageCount: 1,
  });

  const {
    data: companies = data,
    isLoading,
    refetch,
  } = useCompanyFilterIndex(indexQuery, {
    refetchOnMount: true,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (companies !== data) setData(companies);

  const handleCloneCompany = useCallback(
    async id => {
      try {
        await cloneCompany(id);
        success(t(translations.company.message.success.clone));

        refetch();
      } catch (e) {
        error(e.message);
      }
    },
    [cloneCompany, refetch, t],
  );

  const handleDeleteCompany = useCallback(
    async id => {
      try {
        await deleteCompany(id);
        success(t(translations.company.message.success.delete));

        refetch();
      } catch (e) {
        error(e.message);
      }
    },
    [cloneCompany, refetch, t],
  );

  return (
    <Page>
      <Helmet>
        <title>{t(translations.metaInfo.ghostCompaniesPage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.ghostCompaniesPage.description)}
        />
      </Helmet>
      <Loader loading={isLoading} />
      <GhostList
        fetchData={setIndexQuery}
        indexQuery={indexQuery}
        data={companies.data}
        total={companies.total}
        page={companies.page}
        pageCount={companies.pageCount}
        handleCloneCompany={handleCloneCompany}
        handleDeleteCompany={handleDeleteCompany}
      />
    </Page>
  );
}
