import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { RequestQueryBuilder } from '@dataui/crud-request';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { translations } from 'locales/i18n';
import { Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { Checkbox } from 'app/components/ui/Checkbox';
import page from 'app/components/layouts/styles/page';
import { useUserFilterIndex } from 'app/components/users';
import { Loader } from 'app/components/ui/Loader';
import { TYPES } from 'app/components/auth/constants';

import { StepFooter } from '../StepFooter/StepFooter';

import styles from './styles';
const useStyles = makeStyles(styles);

export interface Props {
  userId: number;
  quantity: number;
  finishStep: (data: any) => Promise<void>;
}
function UsersList({ finishStep, userId, quantity }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState<number[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  const [indexQuery, setIndexQuery] = useState({
    limit: 1000,
    page: 1,
    sort: [`createdAt,ASC`],
    ...RequestQueryBuilder.create({
      search: {
        type: { $ne: TYPES.DELETED },
      },
    }).queryObject,
  });

  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageCount: 1,
  });

  const { data: users = data, isLoading: userIsLoading } = useUserFilterIndex(
    indexQuery,
    {
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    if (users && !userIsLoading && users !== data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setData(users);
    }
  }, [users, data]);

  useEffect(() => {
    if (users.data && users.data.length) {
      let newIsCheckedAll = true;
      users.data.forEach(row => {
        if (!checked.includes(row.id)) {
          newIsCheckedAll = false;
        }
      });
      setIsCheckedAll(newIsCheckedAll);
    }
  }, [page, users.data, checked, userIsLoading]);

  useEffect(() => {
    if (users.data && users.data.length) {
      let newList: number[] = [];
      const processedUserLength = users.data
        .slice(0, quantity)
        .filter(el => el.isMarkedProcessed).length;

      if (processedUserLength) {
        newList = [
          ...users.data
            .slice(0, quantity)
            .filter(el => el.isMarkedProcessed)
            .map(el => el.id),
        ];
      } else if (users.data.length >= quantity) {
        const selectedUser = users.data.find(user => user.id === userId);
        if (selectedUser) {
          newList = [selectedUser.id];
        }
      } else {
        newList = [...users.data.slice(0, quantity).map(el => el.id)];
      }
      setChecked(newList);
    }
  }, [userIsLoading]);

  const checkAll = useCallback(() => {
    const newList = [...checked];

    if (isCheckedAll) {
      users.data.forEach(row => {
        if (row.id !== userId) {
          newList.splice(newList.indexOf(row.id), 1);
        }
      });
    } else {
      users.data.forEach(row => {
        if (!newList.includes(row.id) && newList.length < quantity) {
          newList.push(row.id);
        }
      });
    }

    setChecked(newList);
  }, [checked, users.data, isCheckedAll]);

  const columns = [
    {
      Header: (
        <>
          <Checkbox
            color={'secondary'}
            name={`checkbox_all`}
            checked={isCheckedAll}
            disabled={
              (checked.length === 1 &&
                checked[0] === userId &&
                users.data.length === 1) ||
              checked.length === quantity
            }
            onChange={() => {
              checkAll();
            }}
            title={'name'}
          />
        </>
      ),
      accessor: 'isActive',
      Cell: ({ cell: { row } }) => {
        const idRow = Number(row.original.id);

        return checked.length === quantity && !checked.includes(idRow) ? (
          <Tooltip
            title={t(
              translations.accountHolderOnboarding.steps.second.tooltip
                .limitReached,
            )}
            placement="top"
            arrow={true}
          >
            <span>
              <Checkbox
                name={`checkbox_${idRow}`}
                color={'secondary'}
                disabled={true}
                checked={checked.includes(idRow)}
                onChange={() => {
                  if (!checked.includes(idRow)) {
                    setChecked([...checked, idRow]);
                  } else {
                    checked.splice(checked.indexOf(idRow), 1);
                    setChecked([...checked]);
                  }
                }}
                required
              />
            </span>
          </Tooltip>
        ) : (
          <Checkbox
            name={`checkbox_${idRow}`}
            color={'secondary'}
            disabled={
              idRow === userId ||
              (checked.length === quantity && !checked.includes(idRow))
            }
            checked={checked.includes(idRow)}
            onChange={() => {
              if (!checked.includes(idRow)) {
                setChecked([...checked, idRow]);
              } else {
                checked.splice(checked.indexOf(idRow), 1);
                setChecked([...checked]);
              }
            }}
            required
          />
        );
      },
      disableSortBy: true,
      className: 'table-status-cell',
      headerClassName: 'table-status-cell',
    },
    {
      Header: 'Name',
      accessor: 'fullName',
      disableSortBy: true,
      className: classes.nameColumn,
      headerClassName: classes.nameColumn,
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'id',
      width: 100,
    },
  ];

  return (
    <Paper>
      <Loader loading={userIsLoading} fullPage={false} />
      {!userIsLoading && (
        <>
          <Typography className={classes.signatureText} variant="h5">
            {`${checked.length}/${quantity} ${t(
              translations.accountHolderOnboarding.steps.second.text4,
            )}`}
          </Typography>
          <div className={classes.contentContainer}>
            <Table
              columns={columns}
              data={users.data}
              total={users.total}
              page={users.page}
              pageCount={users.pageCount}
              isPagination={false}
              initialState={{
                type: { $ne: TYPES.DELETED },
                sortBy: [
                  {
                    id: 'createdAt',
                    asc: true,
                  },
                ],
              }}
            />
          </div>
          <StepFooter
            activeStep={2}
            assignToMe={users.total > quantity}
            assignToMeHandle={() => {
              return;
            }}
            handleNext={async () => await finishStep({ usersIds: checked })}
          />
        </>
      )}
    </Paper>
  );
}

export default React.memo(UsersList);
