import { createStyles } from '@material-ui/core/styles';

import {
  textSecondary,
  colorWhite,
  badgePrimary,
  badgeSecondary,
  badgeBlue,
  badgeDanger,
  badgePink,
  badgeYellow,
  badgeBgDefault,
  badgeMelon,
  textDefault,
  badgeBgBlue,
  badgeBgDanger,
  badgeBgMelon,
  badgeBgPink,
  badgeBgPrimary,
  badgeBgSecondary,
  badgeBgYellow,
  badgeOutBlue,
  badgeOutDanger,
  badgeOutMelon,
  badgeOutPink,
  badgeOutPrimary,
  badgeOutSecondary,
  badgeOutYellow,
} from 'configs/variables';

export default () =>
  createStyles({
    chipRoot: {
      position: 'relative',
      lineHeight: 1.2,
      margin: '2px 8px 2px 0',
      boxShadow: 'none',
      maxWidth: '100%',

      '&:not($default)': {
        color: colorWhite,
      },
      '&:first-child:last-child': {
        margin: 0,
      },
    },

    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '& $labelContent': {
        display: 'inline',
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    fullText: {
      '& $label, & $labelContent': {
        wordBreak: 'break-word',
        overflow: 'visible',
        whiteSpace: 'normal',
      },
    },
    statusChip: {
      textTransform: 'uppercase',
    },
    labelContent: {
      '& > img': {
        '&, & + span': {
          verticalAlign: 'middle',
          lineHeight: 1.2,
        },
      },
    },
    chipClickable: {
      '&:hover, &:focus': {
        opacity: 0.9,
      },
    },
    chipDeletable: {},

    // Icon
    chipIcon: {
      fontSize: 18,
      width: '1em',
      height: '1em',

      '&, &:hover': {
        color: 'inherit',
      },
    },
    chipIconStart: {
      marginRight: 0,
      marginLeft: 8,
    },
    chipDeleteIcon: {
      marginRight: 8,
      marginLeft: 0,

      '&:hover': {
        opacity: 0.8,
      },
    },

    // Color
    default: {
      background: badgeBgDefault,
      '& $chipDeleteIcon': {
        '&, &:hover': {
          color: textSecondary,
        },
      },
    },
    'out-default': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgDefault,
          color: `${textDefault} !important`,
        },
    },

    primary: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgePrimary,
        },
    },
    'out-primary': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgPrimary,
          color: `${badgeOutPrimary} !important`,
        },
    },
    secondary: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeSecondary,
        },
    },
    'out-secondary': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgSecondary,
          color: `${badgeOutSecondary} !important`,
        },
    },
    blue: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBlue,
        },
    },
    'out-blue': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgBlue,
          color: `${badgeOutBlue} !important`,
        },
    },
    danger: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeDanger,
        },
    },
    'out-danger': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgDanger,
          color: `${badgeOutDanger} !important`,
        },
    },
    pink: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgePink,
        },
    },
    'out-pink': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgPink,
          color: `${badgeOutPink} !important`,
        },
    },
    melon: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeMelon,
        },
    },
    'out-melon': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgMelon,
          color: `${badgeOutMelon} !important`,
        },
    },
    yellow: {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeYellow,
        },
    },
    'out-yellow': {
      '&, &$chipClickable:hover, &$chipClickable:focus, &$chipDeletable:focus':
        {
          background: badgeBgYellow,
          color: `${badgeOutYellow} !important`,
        },
    },
  });
