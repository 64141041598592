import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '535px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
    subTitle: {
      marginBottom: '24px',
    },
    formContainer: { marginTop: '30px' },
    formRaw: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textFieldBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(50% - 8px)',
      },
    },
    textField: { marginRight: '8px' },
    questionIcon: { marginTop: '6px', fontSize: '20px' },
  });
