import React, { memo, useCallback, useMemo, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Text, { Props } from './Text';

const handleMouseDownPassword = event => {
  event.preventDefault();
};

const Password: React.FC<Props> = props => {
  const [showPassword, setShowPassword] = useState(props.showPassword || false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(isShown => !isShown);
  }, []);

  const endIcon = useMemo(
    () => (
      <IconButton
        className="text-button text-action-button"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        disabled={props.disabled}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ),
    [handleClickShowPassword, props.disabled, showPassword],
  );

  return (
    <Text
      {...props}
      endIcon={endIcon}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export default memo(Password);
