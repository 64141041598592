import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Schema } from 'yup';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';

import { Text, TextDate, Versions, Switch } from 'app/components/forms';
import { Paper } from 'app/components/layouts';
import { DATE_FORMAT } from 'app/components/forms/constants';
import { translations } from 'locales/i18n';

import { ReactComponent as codeLightIcon } from 'images/icon/FontAwesome5Pro/Light/code.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as plusCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/plus-circle.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';

import { TimeplateEntity } from '../interfaces';

export enum SCHEDULE {
  ONCE_OFF = 1,
  ANNUALLY,
}

export interface Props {
  defaultValues: TimeplateEntity;
  schema: Schema;
  onSubmit: (data, resetForm?) => void;
  onCancel: () => void;
  onCreateVersion: () => void;
  urlEntityType: (params: string) => string;
}

const TimeplateEdit = ({
  defaultValues: {
    name,
    startDate,
    endDate,
    isDefault,
    signature,
    versions = [],
    activeVersionId,
    isDisabled,
    isAnnual,
  },
  schema,
  onSubmit,
  onCreateVersion,
  urlEntityType,
}: Props) => {
  const { t } = useTranslation();

  const initialValues = {
    name,
    isDisabled,
    startDate: startDate ? dayjs(startDate).format(DATE_FORMAT) : '',
    endDate: endDate ? dayjs(endDate).format(DATE_FORMAT) : '',
    isAnnual: isAnnual ? SCHEDULE.ANNUALLY : SCHEDULE.ONCE_OFF,
    versionToEdit: activeVersionId,
    activeVersionId,
  };

  const SCHEDULE_SELECT_PROPS = [
    { id: 1, name: t(translations.timeplate.selectOptions.once_off) },
    { id: 2, name: t(translations.timeplate.selectOptions.annually) },
  ];

  const createNewVersion = () => onCreateVersion();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Paper
            header={
              <>
                <Typography variant="h6">
                  {t(translations.form.titles.editGhostTimeplate)}
                </Typography>
              </>
            }
            elevation={1}
            borderRadius="large"
            size="small"
            contentIndented
            backLink={urlEntityType(
              `timeplates/${signature?.location?.companyId}`,
            )}
          >
            <Text
              label={t(translations.form.labels.name)}
              name="name"
              type="text"
              variant="outlined"
              margin="dense"
              required
              disabled={isDefault}
            />
            <Switch
              label={t(translations.form.labels.timeplateDisabled)}
              name="isDisabled"
              margin="dense"
              disabled={isDefault}
            />
            {isDefault ? (
              <Text
                label={t(translations.timeplate.field.labels.startDate)}
                name="startDate"
                type="text"
                value="Always"
                variant="outlined"
                margin="dense"
                required
                disabled
              />
            ) : (
              <TextDate
                label={t(translations.timeplate.field.labels.startDate)}
                name="startDate"
                margin="dense"
                required
                disablePast
              />
            )}
            {isDefault ? (
              <Text
                label={t(translations.timeplate.field.labels.endDate)}
                name="endDate"
                type="text"
                value="Always"
                variant="outlined"
                margin="dense"
                required
                disabled
              />
            ) : (
              <TextDate
                label={t(translations.timeplate.field.labels.endDate)}
                name="endDate"
                margin="dense"
                required
                disablePast
              />
            )}
            {!isDefault && (
              <Text
                name="isAnnual"
                label={t(translations.timeplate.field.labels.schedule)}
                variant="outlined"
                type="text"
                select
                options={SCHEDULE_SELECT_PROPS}
                margin="dense"
                disabled={isDefault}
              />
            )}
            <Versions
              label={t(translations.timeplate.field.labels.activeVersion)}
              name="activeVersionId"
              versions={versions}
              margin="dense"
              startIcon={
                <SvgIcon component={eyeLightIcon} viewBox="0 0 22 17" />
              }
            />
            <Box textAlign="center" mb={3}>
              <Button
                className="cta-button"
                variant="contained"
                color="secondary"
                startIcon={
                  <SvgIcon
                    component={plusCircleLightIcon}
                    viewBox="0 0 512 512"
                  />
                }
                onClick={createNewVersion}
              >
                {t(translations.form.buttons.addVersion)}
              </Button>
            </Box>
            <Versions
              label={t(translations.timeplate.field.labels.versionToEdit)}
              name="versionToEdit"
              versions={versions}
              margin="dense"
              startIcon={
                <SvgIcon component={eyeLightIcon} viewBox="0 0 22 17" />
              }
            />
            <Box textAlign="center" mb={4}>
              <Button
                className="cta-button"
                variant="contained"
                startIcon={
                  <SvgIcon component={codeLightIcon} viewBox="0 0 576 512" />
                }
                component={NavLink}
                to={urlEntityType(
                  `timeplates/${values.versionToEdit}/edit-html`,
                )}
              >
                {t(translations.form.buttons.editCode)}
              </Button>
            </Box>
            <Box textAlign="center" marginX={-3}>
              <Divider />
            </Box>
            <Box textAlign="center" mt={4}>
              <Button
                className="cta-button"
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={
                  <SvgIcon
                    component={checkCircleLightIcon}
                    viewBox="0 0 512 512"
                  />
                }
              >
                {t(translations.form.buttons.save)}
              </Button>
            </Box>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default TimeplateEdit;
