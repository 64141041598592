import React from 'react';
import { SnackbarProvider } from 'notistack';

import PubSub from './PubSub';

export interface Props {
  readonly children: React.ReactNode;
}

const SnackbarsProvider: React.FC<Props> = ({ children, ...props }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      autoHideDuration={10000}
      dense={false}
      transitionDuration={300}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      <>
        {children}
        <PubSub />
      </>
    </SnackbarProvider>
  );
};

export default SnackbarsProvider;
