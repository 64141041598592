import React from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

import { translations } from 'locales/i18n';
import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { CompanySearch } from 'app/components/forms/components/Edit/Search/CompanySearch';
import { COMPANY_TYPES } from 'app/containers/constants';
import { success } from 'app/components/snackbars';
import { ROLES } from 'app/components/auth';

import { ReactComponent as mapMarkerAltLightIcon } from 'images/icon/FontAwesome5Pro/Light/map-marker-alt.svg';
import { ReactComponent as addressCardLightIcon } from 'images/icon/FontAwesome5Pro/Light/address-card.svg';
import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as mailIcon } from 'images/icon/FontAwesome5Pro/Light/mail.svg';
import { ReactComponent as usersLightIcon } from 'images/icon/FontAwesome5Pro/Light/users.svg';
import { ReactComponent as ticketLightIcon } from 'images/icon/FontAwesome5Pro/Light/ticket.svg';
import { ReactComponent as queueIcon } from 'images/icon/FontAwesome5Pro/Light/traffic-light-stop.svg';

import styles from '../../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  accountId: string;
  fetchData: (data: any) => void;
  backLink?: string;
  indexQuery?: any;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  backLink,
  indexQuery,
  accountId,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const copyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    success(t(translations.ui.notificationMessages.copiedToClipboard));
  };

  const columns = [
    {
      Header: t(translations.table.columns.companyName),
      accessor: 'name',
      customProps: row => `/locations/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.partner),
      accessor: 'partner.name',
    },
    {
      Header: t(translations.table.columns.locations),
      id: 'locationsCount',
      accessor: 'locationsCount',
      customProps: row => `/locations/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.signatures),
      id: 'signaturesCount',
      accessor: 'signaturesCount',
      customProps: row => `/signatures/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      customProps: row => `/signature-users/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      disableSortBy: true,
      minWidth: 400,
      className: 'table-action-cell',
      accessor: ({ id, users }) => {
        return { id, users };
      },
      Cell: ({
        cell: {
          value: { id, users },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link className={classes.actionItem} href={`/companies/${id}/edit`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link className={classes.actionItem} href={`/locations/${id}`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.paid.locations)}
              >
                <SvgIcon
                  component={mapMarkerAltLightIcon}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </Link>
            <Link className={classes.actionItem} href={`/signatures/${id}`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.paid.signatures)}
              >
                <SvgIcon
                  component={addressCardLightIcon}
                  viewBox="0 0 576 512"
                />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/signature-users/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.paid.users)}
              >
                <SvgIcon component={userLightIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link className={classes.actionItem} href={`/people/${id}`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.paid.people)}
              >
                <SvgIcon component={usersLightIcon} viewBox="0 0 640 512" />
              </Tooltip>
            </Link>
            <Link className={classes.actionItem} href={`/invites/${id}`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.invites)}
              >
                <SvgIcon component={ticketLightIcon} viewBox="0 0 576 512" />
              </Tooltip>
            </Link>
            <Link className={classes.actionItem} href={`/queue/${id}`}>
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.names.queue)}
              >
                <SvgIcon component={queueIcon} viewBox="0 0 320 512" />
              </Tooltip>
            </Link>
            <span className={classes.actionItem}>
              <SvgIcon
                component={mailIcon}
                viewBox="0 0 512 512"
                onClick={() =>
                  copyEmail(
                    users?.find(
                      user =>
                        user?.roles.some(
                          role => role.name === ROLES.ACCOUNT_HOLDER_PRIMARY,
                        ),
                    )?.email,
                  )
                }
              />
            </span>
          </div>
        );
      },
    },
  ];

  const initialState = {
    globalFilter: {
      type: COMPANY_TYPES.PAID,
      originalCompanyId: { $isnull: true },
      accountId,
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  };

  return (
    <Paper
      backLink={backLink}
      header={
        <Typography variant="h5">
          {t(translations.names.paid.companies)}
        </Typography>
      }
    >
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <CompanySearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
