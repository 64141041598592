import { LocalStorageStore, ImmortalStorage } from 'immortal-db';
import { Users } from 'app/components/users';

const stores = [LocalStorageStore];
const db = new ImmortalStorage(stores);
const key = 'impersonate-user';

export const storeImpersonateUser = (impersonateUser: Users) =>
  db.set(key, JSON.stringify(impersonateUser));
export const getImpersonateUser = async () => {
  let impersonateUser: Users | undefined;

  try {
    const stored = await db.get(key);
    impersonateUser =
      typeof stored === 'string' ? JSON.parse(stored) : undefined;
  } catch (e) {
    // ¯\_(ツ)_/¯
  }

  return impersonateUser;
};

export const destroyImpersonateUser = () => db.remove(key);
