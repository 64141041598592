import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectConfigTable } from 'app/components/auth/store/selectors';
import {
  storeTableConfig,
  getTableConfig,
} from 'app/components/auth/utils/configStorage';
import { actions } from 'app/components/auth/store/slice';

export const useConfigTable = () => {
  const dispatch = useDispatch();
  const tableConfig = useSelector(selectConfigTable);
  const [isReady, setIsReady] = useState(false);

  const setTableConfig = useCallback(
    newConfig => {
      dispatch(actions.setTableConfig({ ...tableConfig, ...newConfig }));
      storeTableConfig({ ...tableConfig, ...newConfig });
    },
    [tableConfig, dispatch],
  );

  useEffect(() => {
    if (!isReady) {
      if (!tableConfig || Object.keys(tableConfig).length <= 1) {
        const restoreTableConfig = async () => {
          try {
            const tableConfigFromStorage = await getTableConfig();

            if (tableConfigFromStorage) {
              dispatch(actions.setTableConfig(tableConfigFromStorage));
            }
          } catch (e) {
            // ¯\_(ツ)_/¯
          } finally {
            setIsReady(true);
          }
        };
        restoreTableConfig();
      } else {
        setIsReady(true);
      }
    }
  }, [tableConfig, isReady, dispatch]);

  return {
    setTableConfig,
    tableConfig,
  };
};
