import React, { useMemo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
// TODO file-loader
import IconProcessing from '!file-loader!images/icon/animation/icon-animation-processing.svg';
import IconSuccess from '!file-loader!images/icon/animation/icon-animation-success.svg';
import IconFailed from '!file-loader!images/icon/animation/icon-animation-failed.svg';

import styles from '../styles';

export interface LoaderProps {
  loading: boolean;
  children?: React.ReactNode;
  fullPage?: boolean;
  notUnmount?: boolean;
  animation?: 'processing' | 'success' | 'failed' | undefined;
  loaderText?: React.ReactNode;
  className?: string;
  size?: number;
}

const useStyles = makeStyles(styles);

const Loader = ({
  loading,
  children,
  fullPage = true,
  notUnmount = true,
  animation = undefined,
  loaderText,
  className,
  size = 50,
}: LoaderProps) => {
  const classes = useStyles();

  const animationIcon = useMemo(() => {
    switch (animation) {
      case 'processing':
        return IconProcessing;
      case 'success':
        return IconSuccess;
      case 'failed':
        return IconFailed;
      default:
        return undefined;
    }
  }, [animation]);

  return (
    <>
      {loading && (
        <div
          className={cx(classes.loaderWrapper, className, {
            [classes.loaderFullPageWrapper]: fullPage,
          })}
        >
          <div className={classes.loader}>
            {animation ? (
              <object
                className={classes.animationIcon}
                type="image/svg+xml"
                data={animationIcon as string}
              >
                svg-animation
              </object>
            ) : (
              <CircularProgress size={size} />
            )}
            {loaderText && (
              <div className={classes.loaderText}>{loaderText}</div>
            )}
          </div>
        </div>
      )}
      {(notUnmount || !loading) && children}
    </>
  );
};

export default Loader;
