import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import styles from '../styles';

const useStyle = makeStyles(styles);

export default function StyledCheckbox(props) {
  const classes = useStyle();

  return (
    <Checkbox
      color={'default'}
      className={classes.root}
      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
