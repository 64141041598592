import { LocalStorageStore, ImmortalStorage } from 'immortal-db';

import { TRIAL_STEPS } from 'app/components/onboarding/interfaces';

const stores = [LocalStorageStore];
const db = new ImmortalStorage(stores);
const trialStepKey = 'trialStep';
const defaultStep = TRIAL_STEPS.SIXTH;

export const storeStep = (step = defaultStep) =>
  db.set(trialStepKey, step.toString());

export const getStep = () => db.get(trialStepKey);
