import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SearchIcon from '@material-ui/icons/Search';

import { HtmlEditor } from 'app/components/ui/HtmlEditor';
import { Text } from 'app/components/forms';
import { PlaceholderCategory } from 'app/components/placeholders/interfaces';
import { translations } from 'locales/i18n';
import { useConfirmation } from 'app/components/ui/Modal';

import { TimeplateVersionEntity } from '../interfaces';

import { ReactComponent as barsLightIcon } from 'images/icon/FontAwesome5Pro/Light/bars.svg';
import { ReactComponent as bracketsCurlyLightIcon } from 'images/icon/FontAwesome5Pro/Light/brackets-curly.svg';
import { ReactComponent as chevronDownLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-down.svg';
import { ReactComponent as chevronLeftLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-left.svg';
import { ReactComponent as codeLightIcon } from 'images/icon/FontAwesome5Pro/Light/code.svg';
import { ReactComponent as eyeLightIcon } from 'images/icon/FontAwesome5Pro/Light/eye.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';

import styles from 'app/containers/TimeplatesPage/EditHtml/styles';

const useStyles = makeStyles(styles);

export interface Props {
  defaultValues?: TimeplateVersionEntity;
  onChange: (data: any) => Promise<void>;
  placeholders: PlaceholderCategory[];
  showPreview: () => void;
}

export function Html({
  defaultValues,
  onChange,
  placeholders,
  showPreview,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const urlEntityType = useCallback(
    params => {
      if (location.pathname.includes('ghost')) {
        return `/ghost-${params}`;
      }
      if (location.pathname.includes('trial')) {
        return `/trial-${params}`;
      }
      return `/${params}`;
    },
    [location.pathname],
  );

  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(true);
  const [isChanging, setIsChanging] = useState(false);
  const [tagSearchInput, setTagSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(new Array(6).fill(false));

  const handleSearchChange = (r, data) => {
    setTagSearchInput(data);
  };

  const filterPlaceholders = useMemo(() => {
    const expandAccordions = new Array(6);
    const filteredPlaceholders = placeholders.map(
      ({ placeholders, ...rest }, index) => {
        const filteredPlaceholders = placeholders.filter(
          tag => tag.name.search(tagSearchInput) !== -1,
        );
        expandAccordions.splice(
          index,
          1,
          !!(tagSearchInput && filteredPlaceholders.length),
        );
        return {
          ...rest,
          placeholders: filteredPlaceholders,
        };
      },
    );
    setIsOpen(expandAccordions);
    return filteredPlaceholders;
  }, [placeholders, tagSearchInput]);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleScrollRef = useCallback(
    index => (e, isExpanded) => {
      const expandAccordions = [...isOpen];
      expandAccordions.splice(index, 1, isExpanded);
      setIsOpen(expandAccordions);
    },
    [isOpen],
  );

  const handleBack = () =>
    navigate(
      urlEntityType(`timeplates/${defaultValues?.timeplateId}/edit-data`),
    );

  const [showTooltip, setShowTooltip] = useState(undefined);

  const copyPlaceholder = (name, id) => {
    setShowTooltip(id);

    setTimeout(() => {
      setShowTooltip(undefined);
    }, 1000);

    let copiedResult = `{{${name}}}`;

    if (name.match(/start/)) {
      copiedResult = `<!--Set tag state {{${name}[display:"off" | "on"]}}-->{{${name}}}`;
    }

    // return navigator.clipboard.writeText(`{{${name}}}`);
    return navigator.clipboard.writeText(copiedResult);
  };

  const onNotesSubmit = async data => {
    setIsChanging(false);
    await onChange(data);
  };

  const schema = yup.object().shape({
    notes: yup
      .string()
      .nullable()
      .required(t(translations.validation.required))
      .max(255, t(translations.validation.max255)),
  });

  const [showConfirmation] = useConfirmation({
    onSubmit: handleBack,
    description: t(translations.ui.titles.confirmationMessageHtmlGoBack),
    agreeLabel: t(translations.ui.buttons.leave),
  });

  return (
    <>
      <Drawer
        classes={{
          root: classes.templateSidebar,
          paper: classes.templateSidebarPaper,
        }}
        anchor="left"
        variant={respUpMd ? 'persistent' : 'temporary'}
        open={open}
        onClose={handleDrawerToggle}
        elevation={0}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          className={cx(classes.templateHeader, classes.templateSidebarHeader)}
        >
          {!respUpMd && (
            <IconButton
              className={classes.templateSidebarHeaderMobileButton}
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <CloseRoundedIcon />
            </IconButton>
          )}
          <Box
            className={classes.templateHeaderTitle}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon component={bracketsCurlyLightIcon} viewBox="0 0 576 512" />
            <span>{t(translations.form.labels.tags)}</span>
          </Box>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function*/}
        <Formik initialValues={{}} onSubmit={() => {}} enableReinitialize>
          <Form
            noValidate
            autoComplete="off"
            className={classes.tagSearchFieldForm}
          >
            <Text
              name="search"
              variant="outlined"
              className={classes.tagSearchField}
              placeholder="Search"
              onSuccessChange={handleSearchChange}
              startIcon={<SearchIcon />}
              debounceTime={500}
              margin="none"
            />
          </Form>
        </Formik>
        <div className={cx(classes.tagListBlock, 'native-custom-scrollbar')}>
          {filterPlaceholders.map(({ id, name, placeholders }, index) => (
            <Accordion
              className={classes.tagAccordionItem}
              key={id}
              square
              onChange={handleScrollRef(index)}
              expanded={isOpen[index]}
            >
              <AccordionSummary
                classes={{
                  root: classes.tagAccordionItemSummary,
                  content: classes.tagAccordionItemContent,
                  expanded: classes.tagAccordionItemExpanded,
                  expandIcon: classes.tagAccordionIcon,
                }}
                expandIcon={
                  <SvgIcon
                    component={chevronDownLightIcon}
                    viewBox="0 0 448 512"
                  />
                }
              >
                {name}
                {tagSearchInput && (
                  <div className={classes.searchResultCount}>
                    {placeholders.length}
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.tagAccordionItemDetails}>
                <div
                  className={cx(
                    classes.tagAccordionScrollbar,
                    'native-custom-scrollbar',
                  )}
                >
                  <List className={classes.tagListInner} component="div">
                    {placeholders.map(({ id, name }) => (
                      <ListItem
                        key={id}
                        className={classes.tagListItem}
                        button
                        onClick={() => copyPlaceholder(name, id)}
                      >
                        <Tooltip
                          id={id.toString()}
                          title="Copied to clipboard"
                          open={showTooltip === id}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="top-start"
                          arrow={true}
                        >
                          <div className={classes.tagListItemText}>{name}</div>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Drawer>

      {defaultValues && (
        <Formik
          initialValues={defaultValues}
          onSubmit={onNotesSubmit}
          validationSchema={schema}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className={classes.templateForm}
            >
              <main
                className={cx(classes.templateContentContainer, {
                  [classes.templateContentShift]: open,
                })}
              >
                <div className={classes.templateContentInner}>
                  <div
                    className={cx(
                      classes.templateHeader,
                      classes.templateContentHeader,
                    )}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <IconButton
                          className={classes.menuButton}
                          color="inherit"
                          onClick={handleDrawerToggle}
                        >
                          <SvgIcon
                            component={barsLightIcon}
                            viewBox="0 0 448 512"
                          />
                        </IconButton>
                        <Button
                          className={classes.backButton}
                          color="inherit"
                          startIcon={
                            <SvgIcon
                              component={chevronLeftLightIcon}
                              viewBox="0 0 256 512"
                            />
                          }
                          onClick={isChanging ? showConfirmation : handleBack}
                        >
                          {t(translations.form.buttons.returnToTemplate)}
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          className={classes.templateHeaderTitle}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <SvgIcon
                            component={codeLightIcon}
                            viewBox="0 0 576 512"
                          />
                          <span>{defaultValues?.timeplate?.name}</span>
                        </Box>
                      </Grid>
                      <Grid className={classes.alignRight} item xs={4}>
                        <Button
                          className={classes.saveButton}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={
                            <SvgIcon
                              component={checkCircleLightIcon}
                              viewBox="0 0 512 512"
                            />
                          }
                          type="submit"
                          disabled={!isChanging}
                        >
                          {t(translations.form.buttons.save)}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.templateContent}>
                    <HtmlEditor
                      className={cx(
                        classes.templateEditor,
                        'native-custom-scrollbar grey-scrollbar',
                      )}
                      name="html"
                      onChange={() => setIsChanging(true)}
                    />
                  </div>
                  <div className={classes.templateContentFooter}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box minWidth={152}>
                        {/* TODO: uncomment with revert functional */}
                        {/*  <Button*/}
                        {/*    className={classes.revertButton}*/}
                        {/*    startIcon={*/}
                        {/*      <SvgIcon component={undoLightIcon} viewBox="0 0 512 512" />*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    Revert Changes*/}
                        {/*  </Button>*/}
                      </Box>

                      <Text
                        className={classes.versionTextField}
                        label="Version Notes"
                        name="notes"
                        type="text"
                        variant="outlined"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // onSuccessChange={handleSubmit}
                        // wihDebounce
                        onChange={() => setIsChanging(true)}
                        margin="none"
                        startIcon={
                          <span>
                            v1.{defaultValues.versionNumber}&nbsp;-&nbsp;
                          </span>
                        }
                      />

                      <Box className={classes.alignRight} minWidth={152}>
                        <Button
                          className={classes.previewButton}
                          startIcon={
                            <SvgIcon
                              component={eyeLightIcon}
                              viewBox="0 0 22 17"
                            />
                          }
                          onClick={showPreview}
                        >
                          {t(translations.form.buttons.preview)}
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
              </main>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
