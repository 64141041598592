import React from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Button from '@material-ui/core/Button';

import { UsersSignaturesSearch } from 'app/components/forms/components/Edit/Search/UsersSignaturesSearch';
import { Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { translations } from 'locales/i18n';
import SignatureRoleNameCell from 'app/components/grid/roleCell/SignatureRoleNameCell';
import { USER_SIGNATURES_STATUSES } from 'app/components/entities/queue/types';

import { ReactComponent as pauseIcon } from 'images/icon/FontAwesome5Pro/Light/pause.svg';
import { ReactComponent as installsIcon } from 'images/icon/FontAwesome5Pro/Light/installs.svg';
import { ReactComponent as ellipsisHLightIcon } from 'images/icon/FontAwesome5Pro/Light/thumbs-up.svg';
import { ReactComponent as queueIcon } from 'images/icon/FontAwesome5Pro/Light/traffic-light-stop.svg';
import { ReactComponent as removeSignatureIcon } from 'images/icon/FontAwesome5Pro/Light/remove-signature.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  companyId?: string;
  locationId?: string;
  backLink?: string;
  userId: number;
  indexQuery: any;
  initialStateAdditionalParams?: Record<string, any>;
}

export default function UserProfileSignatureUserList({
  data,
  total,
  page,
  pageCount,
  fetchData,
  userId,
  initialStateAdditionalParams = {},
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      accessor: 'isActive',
      Cell: ({
        cell: {
          row: {
            original: { status },
          },
        },
      }) => {
        const statusStyle =
          status === USER_SIGNATURES_STATUSES.PROCESSED ||
          status === USER_SIGNATURES_STATUSES.QUEUED
            ? 'yellow'
            : status === USER_SIGNATURES_STATUSES.ACTIVE
            ? 'green'
            : 'red';
        return (
          <FiberManualRecordIcon
            className={`table-status-icon ${
              statusStyle && 'table-status-icon-inactive-' + statusStyle
            }`}
          />
        );
      },
      disableSortBy: true,
      className: 'table-status-cell',
      headerClassName: 'table-status-cell',
    },
    {
      Header: t(translations.table.columns.signature),
      accessor: 'signature.name',
      Cell: ({ cell: { value } }: { cell: Cell }) => value,
    },
    {
      Header: t(translations.table.columns.role),
      accessor: 'status',
      Cell: ({ cell: { value } }: { cell: Cell }) => (
        <SignatureRoleNameCell status={value} />
      ),
    },
    {
      Header: t(translations.table.columns.company),
      accessor: 'signature.location.company.name',
      Cell: ({ cell: { value } }: { cell: Cell }) => value,
    },
    {
      Header: t(translations.table.columns.location),
      accessor: 'signature.location.name',
      Cell: ({ cell: { value } }: { cell: Cell }) => value,
    },
    {
      Header: t(translations.table.columns.activeTimeplates),
      id: 'signature.timeplates.name',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      Cell: ({
        cell: {
          row: { original },
        },
      }) =>
        original.signature?.timeplates?.find(({ isActive }) => isActive)
          ?.name || '',
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 300,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({
        signatureId,
        status,
        signature: {
          location: { companyId },
        },
      }) => {
        return { companyId, signatureId, status };
      },
      Cell: ({
        cell: {
          value: { companyId, signatureId, status },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <span className={classes.disabled}>
              <SvgIcon component={pauseIcon} viewBox="0 0 12 12" />
            </span>
            <span className={classes.disabled}>
              <SvgIcon component={installsIcon} viewBox="0 0 22 19" />
            </span>
            {status === USER_SIGNATURES_STATUSES.QUEUED ? (
              <Link
                href={`/queue/${companyId}/queued/${signatureId}`}
                className={classes.actionItem}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.queue.dropdown.processQueue)}
                >
                  <SvgIcon component={queueIcon} viewBox="0 0 320 512" />
                </Tooltip>
              </Link>
            ) : (
              <span className={classes.disabled}>
                <SvgIcon component={queueIcon} viewBox="0 0 320 512" />
              </span>
            )}
            {status === USER_SIGNATURES_STATUSES.PROCESSED ? (
              <Link
                href={`/queue/${companyId}/processed/${signatureId}`}
                className={classes.actionItem}
              >
                <Tooltip
                  placement="top"
                  arrow={true}
                  title={t(translations.queue.dropdown.viewProcessed)}
                >
                  <SvgIcon
                    className={classes.actionItem}
                    component={ellipsisHLightIcon}
                    viewBox="0 0 512 512"
                  />
                </Tooltip>
              </Link>
            ) : (
              <span className={classes.disabled}>
                <SvgIcon component={ellipsisHLightIcon} viewBox="0 0 512 512" />
              </span>
            )}

            <span className={classes.disabled}>
              <SvgIcon component={removeSignatureIcon} viewBox="0 0 23 19" />
            </span>
          </div>
        );
      },
    },
  ];

  const initialState = {
    globalFilter: {
      userId,
    },
    sortBy: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
    additionalParams: initialStateAdditionalParams,
  };

  return (
    <Paper>
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <UsersSignaturesSearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <Button
              className="cta-button"
              variant="contained"
              color="secondary"
              disabled={true}
              startIcon={<AddRoundedIcon style={{ fontSize: 22 }} />}
            >
              {t(translations.actions.names.assignSignature)}
            </Button>
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
