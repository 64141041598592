import React, {
  memo,
  useCallback,
  ReactNode,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Autocomplete,
  FilterOptionsState,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab';

import { selectImpersonateUser } from 'app/components/auth/store/selectors';
import SavingContext from 'app/components/layouts/components/SavingContext';

import { ReactComponent as userIcon } from 'images/icon/select-user.svg';
import { ReactComponent as closeIcon } from 'images/icon/close-icon.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Options {
  id: string | number;
  name?: string | ReactNode;
  fullName?: string | ReactNode;
  email?: string | ReactNode;
  onClick?: () => void;
  optionType?: 'button';
  isDisabled?: boolean;
}

export interface Props {
  name: string;
  label?: string;
  onInputChange?: (value: string) => void;
  options?: Options[];
  onChange?: (value: Options | null | object) => void;
  multiple?: boolean;
  freeSolo?: boolean;
  filterOptions?: (
    options: Options[],
    state: FilterOptionsState<Options>,
  ) => Options[];
  margin?: 'none' | 'dense' | 'normal';
  disableClearable: boolean;
  onSuccessChange?: (
    required: boolean | undefined,
    data: (string | Options)[],
  ) => void;
  required?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  renderOption?: (...props: any) => React.ReactNode;
  customOptionLabel?: (...props: any) => React.ReactNode;
  placeholder?: string;
  className?: string;
  startedNoOptionsText?: string;
  optionsIsLoading?: boolean;
  disabled?: boolean;
  handelChangePage?: any;
  page: number;
  total: number;
}

const getDefaultAutocompleteValue = (multiple: boolean) =>
  multiple ? [] : null;

function AutocompleteField({
  name,
  options = [],
  multiple = false,
  freeSolo = false,
  filterOptions,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInputChange = () => {},
  label = '',
  margin = 'normal',
  disableClearable = false,
  onSuccessChange,
  onChange,
  required = false,
  startIcon,
  // renderOption = Option,
  customOptionLabel,
  placeholder = '',
  className,
  startedNoOptionsText = '',
  optionsIsLoading = false,
  disabled = false,
  handelChangePage,
  page,
  total,
}: Props) {
  const classes = useStyles();
  const [{ value: mainValue }, { error, touched }, { setValue }] =
    useField(name);
  const isError: boolean = useMemo(
    () => Boolean(touched && error),
    [error, touched],
  );
  const [inputValue, setInputValue] = useState('');
  const impersonateUser = useSelector(selectImpersonateUser);
  const { setIsSaving } = useContext(SavingContext);

  useEffect(() => {
    if (impersonateUser) {
      setValue(impersonateUser);
    }
  }, [impersonateUser]);

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useEffect(() => {
    if (isError) setIsSaving(false);
  }, [isError, setIsSaving]);

  const autocompleteValue = mainValue || getDefaultAutocompleteValue(multiple);

  if (mainValue === undefined) {
    setValue(autocompleteValue);
  }

  const cleanName = values => {
    return values.map(value => {
      if (
        typeof value === 'object' &&
        value.hasOwnProperty('id') &&
        typeof value.id !== 'number' &&
        value.hasOwnProperty('name')
      ) {
        return {
          ...value,
          name: value.name.replace('Add: ', '').replace(/["]+/g, '').trim(),
        };
      }
      return value;
    });
  };

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<object>,
      value: Array<Options>,
      type: AutocompleteChangeReason,
      reason: AutocompleteChangeDetails<Options>,
    ) => {
      if (freeSolo && multiple) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const { optionType, onClick = () => {} } = reason?.option || {};
        const normalizedValues = cleanName(value);
        const lastValue = normalizedValues.pop();

        if (
          reason.hasOwnProperty('option') &&
          typeof reason.option === 'string'
        )
          return;

        if (optionType === 'button' && type === 'select-option') {
          onClick();
        } else {
          const valuesFilteredByLast = normalizedValues.filter(
            value => value.name !== lastValue.name,
          );
          if (
            lastValue != null &&
            valuesFilteredByLast.length === normalizedValues.length
          ) {
            valuesFilteredByLast.push(lastValue);
          }
          setValue(valuesFilteredByLast);
        }

        if (onSuccessChange)
          onSuccessChange(
            false,
            value.map(option =>
              typeof option.id === 'number' ? option : option.id,
            ),
          );
      } else {
        onChange && onChange(value);
        setValue(value);
      }
      if (onSuccessChange) setIsSaving(true);
    },
    [freeSolo, multiple, onChange, onSuccessChange, setIsSaving, setValue],
  );

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<object>,
      value: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (reason === 'input') {
        setInputValue(value);
        onInputChange(value);
      }
    },
    [onInputChange],
  );

  const getOptionLabel = useCallback(
    option => {
      if (!option?.fullName) {
        return '';
      }
      if (customOptionLabel) {
        return customOptionLabel(option);
      }
      return typeof option.id === 'number'
        ? `${option.email}\n${option.fullName}`
        : option.id;
    },
    [customOptionLabel],
  );

  const getOptionDisabled = option =>
    optionsIsLoading
      ? optionsIsLoading
      : multiple
      ? !!autocompleteValue.find(
          ({ id, fullName }) =>
            [id, fullName].includes(option.id) || id === option.fullName,
        )
      : autocompleteValue
      ? autocompleteValue.id === option.id
      : option.isDisabled;

  const noOptionsText = useMemo(
    () =>
      !inputValue && startedNoOptionsText ? startedNoOptionsText : undefined,
    [inputValue, startedNoOptionsText],
  );

  function Option({ fullName, email }) {
    return (
      <div className={classes.optionBlock}>
        <SvgIcon
          component={userIcon}
          viewBox="0 0 18 21"
          className={classes.userIcon}
        />
        <div className={classes.infoBlock}>
          <Typography className={classes.userName}>{fullName}</Typography>
          <Typography className={classes.userEmail}>{email}</Typography>
        </div>
      </div>
    );
  }

  const handleScroll = event => {
    const target = event.target;

    if (
      target.scrollHeight - target.scrollTop < target.clientHeight + 1 &&
      page * 8 < total
    ) {
      handelChangePage(page + 1);
    }
  };

  return (
    <Autocomplete
      loading={optionsIsLoading}
      autoHighlight={multiple}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      classes={{
        root: className,
        endAdornment: classes.autocompleteEndAdornment,
        clearIndicator: classes.autocompleteClearIndicator,
        popupIndicator: classes.selectArrowDownIcon,
        focused: 'text-input-focused',
        paper: cx(
          classes.selectPaper,
          'native-custom-scrollbar grey-scrollbar',
          { [classes.hide]: !inputValue && !!autocompleteValue },
        ),
        listbox: classes.selectList,
        option: classes.selectOption,
      }}
      multiple={multiple}
      value={autocompleteValue}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      forcePopupIcon={false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={getOptionLabel}
      renderOption={Option}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      onInputChange={handleInputChange}
      closeIcon={
        <span>
          <SvgIcon component={closeIcon} viewBox="0 0 13 13" />
        </span>
      }
      getOptionDisabled={getOptionDisabled}
      renderInput={params => (
        <TextField
          placeholder={placeholder}
          {...params}
          margin={margin}
          label={label}
          variant="outlined"
          fullWidth
          multiline
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            readOnly: !!autocompleteValue,
            classes: {
              root: cx('text-input-wrapper', classes.inputRoot),
              notchedOutline: classes.input,
            },
            ...(startIcon
              ? {
                  startAdornment: (
                    <InputAdornment
                      className={classes.autocompleteStartAdornment}
                      position="start"
                    >
                      {autocompleteValue ? (
                        <SvgIcon
                          component={userIcon}
                          viewBox="0 0 18 21"
                          className={classes.selectedUserIcon}
                        />
                      ) : (
                        startIcon
                      )}
                    </InputAdornment>
                  ),
                }
              : {}),
            endAdornment: optionsIsLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
          error={isError}
          helperText={isError && error ? error : undefined}
        />
      )}
      noOptionsText={noOptionsText}
      disabled={disabled}
    />
  );
}

export default memo(AutocompleteField);
