import { createStyles, Theme } from '@material-ui/core';

import {
  transition,
  colorPrimary,
  colorPrimaryExtraLight,
  colorSecondary,
  colorWhite,
  colorBlue,
  bgColorDarkGrey,
  colorPink,
  drawerWidth,
} from 'configs/variables';

const sidebarWidth = 375;

export default (theme: Theme) =>
  createStyles({
    templateContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100%',
      maxWidth: '100%',
    },

    templateHeader: {
      color: colorWhite,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 63,
      minHeight: 63,
      padding: '6px 16px',
    },
    templateHeaderTitle: {
      fontSize: 18,
      fontWeight: 600,

      '& svg': {
        fontSize: 20,
        marginRight: 10,
      },
    },

    // Sidebar
    templateSidebar: {
      flexShrink: 0,
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        backgroundColor: colorPrimary,
      },
      '&, & $templateSidebarPaper': {
        width: drawerWidth,
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
          width: sidebarWidth,
        },
      },
    },
    templateSidebarPaper: {
      color: colorPrimaryExtraLight,
      backgroundColor: colorPrimary,
      transition: `transform ${transition} !important`,
    },
    templateSidebarHeader: {
      backgroundColor: 'rgba(0,0,0,.1)',
      position: 'relative',
    },
    templateSidebarHeaderMobileButton: {
      backgroundColor: 'rgba(0,0,0,.15)',
      position: 'absolute',
      right: 5,
      top: '50%',
      transform: 'translateY(-50%)',
      padding: 8,
    },

    tagListBlock: {
      padding: '10px 15px 30px 30px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    tagSearchFieldForm: {
      padding: '20px 15px 10px 30px',
    },
    tagSearchField: {
      '& .text-input-wrapper': {
        background: colorWhite,
      },
      '& .text-input': {
        paddingLeft: 50,
      },
    },
    searchResultCount: {
      color: colorPrimaryExtraLight,
      fontSize: 12,
      marginLeft: 'auto',
      marginRight: 10,
    },
    tagAccordionItem: {
      margin: '0 !important',
    },
    tagAccordionItemSummary: {
      color: colorWhite,
      padding: '12px 0',
      borderBottom: '1px solid rgba(255,255,255,.2)',
      minHeight: '0 !important',

      '& $tagListItemText': {
        fontWeight: 600,
      },
    },
    tagAccordionIcon: {
      color: colorWhite,
      padding: 6,
      marginRight: -6,
      transform: 'rotate(-90deg)',

      '& svg': {
        fontSize: 20,
        color: 'inherit',
      },
    },
    tagAccordionItemExpanded: {
      '&$tagAccordionIcon': {
        transform: 'rotate(0) !important',
      },
    },
    tagAccordionItemContent: {
      margin: '0 !important',
    },
    tagAccordionItemDetails: {
      display: 'block',
      padding: '10px 0',
      boxShadow: 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.25)',
    },
    tagAccordionScrollbar: {
      maxHeight: 400,
    },

    tagListInner: {
      padding: 0,
      width: '100%',
    },
    tagListItem: {
      color: colorPrimaryExtraLight,
      paddingLeft: 0,
      paddingRight: 0,

      '&:hover': {
        opacity: 0.8,
        backgroundColor: 'transparent',
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    tagListItemText: {
      flex: 1,
      wordBreak: 'break-word',
    },

    // Content
    templateForm: {
      display: 'flex',
      flexGrow: 1,
    },
    templateContentContainer: {
      flexGrow: 1,
      display: 'flex',
      minWidth: 0,
      overflow: 'auto',
      transition: `margin ${transition}`,

      [theme.breakpoints.up('md')]: {
        marginLeft: -sidebarWidth,
      },
    },
    templateContentShift: {
      marginLeft: 0,
    },
    templateContentInner: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      minWidth: 900,
      minHeight: 250,
    },
    templateContentHeader: {
      backgroundColor: bgColorDarkGrey,

      '& button': {
        fontWeight: 400,
      },
    },
    backButton: {},
    menuButton: {
      marginRight: 20,
      fontSize: 20,
      padding: 8,
      marginLeft: -8,

      '& svg': {
        fontSize: 'inherit',
      },
    },
    alignRight: {
      textAlign: 'right',
    },
    templateContent: {
      display: 'flex',
      flexGrow: 1,
    },
    templateEditor: {
      height: 'auto !important',
      flexGrow: 1,
    },
    templateContentFooter: {
      backgroundColor: bgColorDarkGrey,
      color: colorWhite,
      padding: '12px 16px 10px',

      '& button': {
        fontWeight: 400,
      },
    },
    templateSaveStatus: {
      color: colorSecondary,
      '& svg': {
        fontSize: 20,
        marginRight: 12,
      },
    },
    templateSaveStatusHidden: {
      color: colorSecondary,
      '& svg': {
        fontSize: 20,
        marginRight: 12,
      },
      display: 'none',
    },
    versionTextField: {
      width: 500,
      maxWidth: '100%',
      margin: '0 8px',

      [theme.breakpoints.up('lg')]: {
        width: 700,
      },
      '& .text-field-icon-block-start': {
        position: 'static',
        height: 'auto',
        marginRight: 0,
      },
      '& .text-label:not(.text-label-error), & .text-field-icon-block-start': {
        color: colorBlue,
      },
      '& .text-input': {
        color: colorWhite,
        paddingLeft: 0,
      },
      '& .text-input-wrapper:not(.text-input-error) fieldset': {
        borderColor: `${colorBlue} !important`,
      },
    },
    previewButton: {
      color: colorBlue,
    },
    revertButton: {
      color: colorPink,
      '&[disabled]': {
        color: colorPink,
        opacity: 0.4,
      },
      marginRight: '20px',
    },
    saveButton: {
      minWidth: 120,

      '&[disabled]': {
        opacity: 0.4,
      },
    },
  });
