import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiPaper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as chevronLeftLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-left.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  readonly children: React.ReactNode;
  readonly header?: React.ReactNode;
  readonly footer?: React.ReactNode;
  readonly backLink?: string;
  readonly elevation?: 0 | 1;
  readonly borderRadius?: 'none' | 'default' | 'large';
  readonly size?: 'small' | 'medium' | 'large' | 'default';
  readonly contentIndented?: boolean;
  readonly className?: string;
  readonly onBackClick?: () => void;
  readonly headerIcon?: React.ReactNode;
}

const Paper = ({
  children,
  header,
  footer,
  elevation = 0,
  borderRadius = 'default',
  size = 'default',
  contentIndented = false,
  className = '',
  onBackClick,
  backLink,
  headerIcon,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <MuiPaper
      classes={{
        root: cx(classes.paperRoot, className, {
          [classes[`${borderRadius}BorderRadius`]]: borderRadius,
          [classes[`${size}Size`]]: size,
        }),
        elevation1: classes.paperShadow,
      }}
      elevation={elevation}
    >
      {(header || backLink) && (
        <div
          className={cx(
            classes.paperHeader,
            backLink || headerIcon ? classes.paperHeaderWithBack : '',
            'paper-header',
          )}
        >
          {headerIcon && (
            <IconButton className={classes.paperBackButton} color="inherit">
              {headerIcon}
            </IconButton>
          )}
          {backLink && (
            <IconButton
              className={classes.paperBackButton}
              color="inherit"
              onClick={onBackClick ? onBackClick : () => navigate(-1)}
            >
              <SvgIcon component={chevronLeftLightIcon} viewBox="0 0 256 512" />
            </IconButton>
          )}
          {header}
        </div>
      )}
      <div
        className={cx(
          classes.paperContent,
          contentIndented ? classes.paperContentIndented : '',
          'paper-content',
        )}
      >
        {children}
      </div>
      {footer && (
        <div className={cx(classes.paperFooter, 'paper-footer')}>{footer}</div>
      )}
    </MuiPaper>
  );
};

export default memo(Paper);
