// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Cell,
  ColumnInstance,
  HeaderGroup,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useFlexLayout,
} from 'react-table';
import { RequestQueryBuilder, CreateQueryParams } from '@dataui/crud-request';
import cx from 'classnames';
import { usePrevious } from 'react-use';

import {
  makeStyles,
  TableHead,
  Table as MaUTable,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Collapse,
  Hidden,
  SvgIcon,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { PaperSubheader } from 'app/components/layouts';
import { translations } from 'locales/i18n';
import { useConfigTable } from 'app/components/auth/hooks/useConfigTable';
import { useAuth } from 'app/components/auth';

import TablePaginationActions from './PaginationActions';
import useFiltersState from '../hooks/useFiltersState';

import { ReactComponent as arrowCircleDownLightIcon } from 'images/icon/FontAwesome5Pro/Light/arrow-circle-down.svg';
import { ReactComponent as arrowSortAltLightIcon } from 'images/icon/FontAwesome5Pro/Light/sort-alt.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface SortedColumnInstance extends ColumnInstance {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

interface TableSortObject {
  id: string;
  desc: boolean;
}

interface Props<T extends object> {
  columns: object[];
  data: T[];
  fetchData?: (params: any) => void;
  loading?: false;
  pageCount?: number;
  page?: number;
  total?: number;
  isPagination?: boolean;
  isPaginationLimit?: boolean;
  isSort?: boolean;
  renderGlobalFilter?: (params: any) => any;
  initialState?: any;
  firing?: boolean;
  firingAction?: () => void;
  noDataText?: string;
  deleteFiring?: boolean;
  isScrollToTop?: boolean;
  renderExpandRow?: (row: Row<T>) => React.ReactNode;
  isStriped?: boolean;
  rowClassMaker?: (params: any) => string;
}

function Table<T extends object>({
  columns,
  data = [],
  fetchData,
  pageCount: controlledPageCount = 10,
  total = 0,
  renderGlobalFilter,
  renderExpandRow,
  isPagination = true,
  isPaginationLimit = false,
  isSort = true,
  isScrollToTop = true,
  initialState,
  firing = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  firingAction = () => {},
  noDataText,
  deleteFiring,
  isStriped = false,
  rowClassMaker = () => '',
}: Props<T>) {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { setTableConfig, tableConfig } = useConfigTable();
  const { user } = useAuth();

  const tableKey = `${user?.id}#${location.pathname.replace(/[/@.]/gi, '_')}`;

  const getInitialTableConfig = () =>
    tableConfig.hasOwnProperty(tableKey)
      ? tableConfig[tableKey]
      : tableConfig.default;

  const extendedColumns = useMemo(() => {
    const result = [];
    if (renderExpandRow) {
      result.push({
        accessor: 'expander',
        disableSortBy: true,
        Cell: ({
          isExpand,
          expandAction,
        }: {
          cell: Cell<T>;
          isExpand: boolean;
          expandAction: () => void;
        }) => (
          <IconButton size="small" color="primary" onClick={expandAction}>
            <KeyboardArrowDownIcon
              className={cx(
                classes.expandButtonIcon,
                isExpand ? classes.expandButtonIconOpen : '',
              )}
            />
          </IconButton>
        ),
        className: classes.expandButtonCell,
        headerClassName: classes.expandButtonCell,
        width: 30,
      });
    }

    result.push(...columns);

    return result;
  }, [
    classes.expandButtonCell,
    classes.expandButtonIcon,
    classes.expandButtonIconOpen,
    columns,
    renderExpandRow,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, globalFilter },
    setGlobalFilter,
  } = useTable<T>(
    {
      columns: extendedColumns,
      data,
      initialState: { ...initialState, ...getInitialTableConfig() },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      manualGlobalFilter: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
  );

  const togglePageSize = size =>
    setTableConfig({ [tableKey]: { pageSize: size } });

  const oldDeletedFiring = usePrevious(deleteFiring);

  const { filtersState, changePage, changeFilter } = useFiltersState({
    pageIndex,
    globalFilter: { ...globalFilter },
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    gotoPage(newPage);
    changePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    if (togglePageSize) togglePageSize(parseInt(event.target.value, 10));
    gotoPage(0);
    changePage(0);
  };

  const sort = useMemo(() => {
    return sortBy.map((sortObj: TableSortObject) => ({
      field: sortObj.id,
      order: sortObj.desc ? 'DESC' : 'ASC',
    }));
  }, [sortBy]);

  const querySearch = useMemo(() => {
    const querySearch = { ...filtersState.globalFilter };
    for (const [key, value] of Object.entries(querySearch)) {
      if (value === undefined) {
        delete querySearch[key];
      }
    }

    return querySearch;
  }, [filtersState.globalFilter]);

  const paginationQuery = useMemo(() => {
    let { pageIndex } = filtersState;
    if (
      deleteFiring !== oldDeletedFiring &&
      data.length === 1 &&
      pageIndex !== 0
    ) {
      pageIndex = pageIndex - 1;
    }
    gotoPage(pageIndex);
    if (isScrollToTop) {
      window.scrollTo(0, 0);
    }
    const page = pageIndex + 1;
    const limit = pageSize > 0 ? pageSize : 0;

    return { page, limit };
  }, [filtersState.pageIndex, pageSize, deleteFiring]);

  const queryObject = useMemo(() => {
    const { page, limit } = paginationQuery;

    const queryBuilderParams: CreateQueryParams = {
      sort,
      search: querySearch,
    };

    if (initialState?.additionalParams) {
      Object.entries(initialState.additionalParams).forEach(([key, value]) => {
        queryBuilderParams[key] = value;
      });
    }

    if (isPagination) {
      queryBuilderParams.limit = limit;
      queryBuilderParams.page = page;
    }

    return RequestQueryBuilder.create(queryBuilderParams).queryObject;
  }, [paginationQuery, querySearch, sort, isPagination]);

  const paginationAllowed = useMemo(() => {
    if (!isPagination) {
      return false;
    }

    return !isPaginationLimit || pageSize < total;
  }, [isPagination, isPaginationLimit, pageSize, total]);

  const handleSetGlobalFilter = useCallback(
    data => {
      setGlobalFilter(data);
      changeFilter(data);
    },
    [setGlobalFilter, changeFilter],
  );

  const [expand, setExpand] = useState<{ [key: string]: boolean }>({});

  const labelDisplayedRows = useCallback(
    ({ page, count }) => (
      <span>
        {t(translations.table.labels.pageOf, {
          pageNumber: page + 1,
          totalPages: Math.ceil(count / pageSize),
        })}
      </span>
    ),
    [pageSize, t],
  );

  useEffect(() => {
    if (fetchData) {
      fetchData(queryObject);
    }
    setExpand({});
  }, [fetchData, queryObject, firing]);

  return (
    <>
      {renderGlobalFilter && (
        <PaperSubheader>
          {renderGlobalFilter({
            globalFilter,
            setGlobalFilter: handleSetGlobalFilter,
          })}
        </PaperSubheader>
      )}
      <div className={classes.tableBlock}>
        <MaUTable
          classes={{
            root: cx(classes.table, isStriped ? classes.tableStriped : ''),
          }}
          {...getTableProps()}
        >
          <Hidden smDown>
            <TableHead
              classes={{ root: classes.tableHead }}
              {...getTableBodyProps()}
            >
              {headerGroups.map((headerGroup: HeaderGroup, index: number) => (
                <TableRow
                  classes={{ root: cx(classes.tableRow, classes.tableHeadRow) }}
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: SortedColumnInstance) => (
                    <TableCell
                      classes={{
                        root: cx(
                          classes.tableCell,
                          classes.tableHeadCell,
                          isSort && !column.disableSortBy
                            ? classes.tableHeadCellIsSort
                            : '',
                          column.isSorted ? classes.tableHeadCellIsSorted : '',
                          column.headerClassName || '',
                        ),
                      }}
                      key={column.id}
                      {...column.getHeaderProps([
                        isSort ? column.getSortByToggleProps() : {},
                        {
                          style: {
                            maxWidth: column.maxWidth,
                          },
                        },
                      ])}
                      title=""
                    >
                      {column.render('Header')}
                      {column.canSort && !column.disableSortBy && (
                        <span className={classes.tableHeaderCellSort}>
                          {column.isSorted ? (
                            <SvgIcon
                              className={cx(
                                classes.tableHeaderCellSortIcon,
                                column.isSortedDesc
                                  ? classes.tableHeaderCellSortIconOpen
                                  : '',
                              )}
                              component={arrowCircleDownLightIcon}
                              viewBox="0 0 512 512"
                            />
                          ) : (
                            <SvgIcon
                              className={classes.tableHeaderCellSortIcon}
                              component={arrowSortAltLightIcon}
                              viewBox="0 0 384 512"
                            />
                          )}
                        </span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          </Hidden>
          <TableBody
            classes={{ root: classes.tableBody }}
            {...getTableBodyProps()}
          >
            {data.length ? (
              rows.map((row: Row<T>) => {
                prepareRow(row);

                return (
                  <Fragment key={row.id}>
                    <TableRow
                      classes={{
                        root: cx(
                          classes.tableRow,
                          classes.tableBodyRow,
                          renderExpandRow ? classes.tableRowCanExpand : '',
                          rowClassMaker(row.values),
                        ),
                      }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell: Cell) => {
                        return (
                          <TableCell
                            classes={{
                              root: cx(
                                classes.tableCell,
                                classes.tableBodyCell,
                                cell.column.className || '',
                              ),
                            }}
                            key={`${cell.row.index}${cell.column.id}`}
                            {...cell.getCellProps({
                              style: { maxWidth: cell.column.maxWidth },
                            })}
                          >
                            <Hidden mdUp>
                              <div
                                className={cx(
                                  classes.mobileCellHead,
                                  'mobile-cell-head',
                                )}
                              >
                                {cell.column.Header}
                              </div>
                            </Hidden>
                            <div
                              className={cx(
                                classes.cellContent,
                                'cell-content',
                              )}
                            >
                              {cell.render('Cell', {
                                firingAction,
                                isExpand: expand[row.id],
                                expandAction: () =>
                                  setExpand(ce => {
                                    return { ...ce, [row.id]: !ce[row.id] };
                                  }),
                              })}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {renderExpandRow && expand[row.id] !== undefined && (
                      <TableRow
                        classes={{
                          root: cx(classes.tableRow, classes.tableRowExpand),
                        }}
                      >
                        <TableCell
                          classes={{
                            root: cx(
                              classes.tableCell,
                              classes.tableCellExpand,
                            ),
                          }}
                        >
                          <Collapse
                            classes={{
                              container: classes.tableCollapseContainer,
                            }}
                            in={expand[row.id]}
                          >
                            <div className={classes.tableExpandRowBlock}>
                              {renderExpandRow(row)}
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })
            ) : (
              <TableRow
                classes={{
                  root: cx(
                    classes.tableRow,
                    classes.tableBodyRow,
                    classes.tableNoDataRow,
                  ),
                }}
              >
                <TableCell
                  classes={{
                    root: cx(
                      classes.tableCell,
                      classes.tableBodyCell,
                      classes.tableNoDataCell,
                    ),
                  }}
                >
                  {noDataText || t(translations.messages.noData)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaUTable>
        {paginationAllowed && data.length > 0 && (
          <TableFooter classes={{ root: classes.tableFooter }} component="div">
            <TableRow
              classes={{
                root: cx(
                  classes.tableRow,
                  classes.tableBodyRow,
                  classes.tableFooterRow,
                ),
              }}
              component="div"
            >
              <TablePagination
                labelDisplayedRows={labelDisplayedRows}
                labelRowsPerPage={t(translations.table.labels.resultsPerPage)}
                rowsPerPageOptions={[5, 10, 25]}
                count={total}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: {
                    'aria-label': t(translations.table.labels.rowsPerPage),
                  },
                  variant: 'outlined',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                component="div"
              />
            </TableRow>
          </TableFooter>
        )}
      </div>
    </>
  );
}

export default Table;
