import React, { useEffect, useRef } from 'react';
import AceEditor from 'react-ace';
import { useField } from 'formik';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/mode-javascript';
import 'ace-builds/src-min-noconflict/theme-tomorrow_night_eighties';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-spellcheck';
import 'ace-builds/src-min-noconflict/snippets/javascript';
import 'ace-builds/src-min-noconflict/ext-searchbox';

import ace from 'ace-builds/src-noconflict/ace';
ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/',
);
ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-javascript.js',
);

interface Props {
  onLoad?: () => void;
  placeholder?: string;
  theme?: string;
  name: string;
  height?: string;
  width?: string;
  fontSize?: number;
  className?: string;
  onChange: () => void;
}

export default function HtmlEditor({
  onLoad,
  placeholder = '',
  theme = 'monokai',
  name,
  height = '500px',
  width = '100%',
  fontSize = 14,
  className = '',
  onChange,
}: Props) {
  const [{ value: code }, , { setValue }] = useField<string>(name);

  const onChangeHandle = data => {
    onChange();
    setValue(data);
  };

  const onLoadHandle = () => {
    if (onLoad) onLoad();
  };
  const aceRef = useRef(null);

  useEffect(() => {
    if (aceRef && aceRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      aceRef.current.editor.session.on('changeAnnotation', () => {
        if (aceRef.current) {
          const annotations =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            aceRef.current.editor.session.getAnnotations() || [];
          const len = annotations.length;
          let i = len;
          while (i--) {
            if (
              /^quirky doctype/i.test(annotations[i].text) ||
              /^erroneous doctype/i.test(annotations[i].text)
            ) {
              annotations.splice(i, 1);
            }
          }
          if (len > annotations.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            aceRef.current.editor.session.setAnnotations(annotations);
          }
        }
      });
    }
  }, [aceRef]);

  return (
    <AceEditor
      wrapEnabled
      ref={aceRef}
      className={className}
      placeholder={placeholder}
      mode="html"
      theme={theme}
      name={name}
      height={height}
      width={width}
      onLoad={onLoadHandle}
      onChange={onChangeHandle}
      fontSize={fontSize}
      showPrintMargin={false}
      showGutter
      highlightActiveLine
      focus
      value={code}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false,
      }}
    />
  );
}
