import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';

import Message from './Message';
import { SNACKBAR_SUBMISSION } from '../constants';
import { MessageOptions } from '../types';

export default function SnackbarsPubSub() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    PubSub.subscribe(
      SNACKBAR_SUBMISSION,
      (msg: string, { message = '', severity, ...options }: MessageOptions) => {
        enqueueSnackbar(message, {
          ...options,
          content: key => {
            return (
              <Message onClose={() => closeSnackbar(key)} severity={severity}>
                {message.split('\n').map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </Message>
            );
          },
        });
      },
    );

    return () => {
      PubSub.unsubscribe(SNACKBAR_SUBMISSION);
    };
  }, [closeSnackbar, enqueueSnackbar]);

  return null;
}
