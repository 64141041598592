import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '445px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
    formContainer: { marginTop: '30px' },
    formRaw: { display: 'flex', alignItems: 'center', marginBottom: '20px' },
    formText: { marginRight: '12px' },
    questionIcon: { fontSize: '20px' },
    selectField: {
      marginRight: '16px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
        marginBottom: '16px',
      },
    },
    textFieldBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '20px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(40% - 8px)',
      },
    },
    dateFieldBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        flexWrap: 'wrap',
      },
    },
    dateRaw: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
  });
