import React, { memo, useCallback, useMemo, useState } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import dayjs from 'dayjs';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIME,
  DB_DATE_FORMAT,
  DB_DATE_FORMAT_WITH_TIME,
} from '../../constants';

import { ReactComponent as calendarLightIcon } from 'images/icon/FontAwesome5Pro/Light/calendar.svg';

import styles from '../../styles';

const useStyles = makeStyles(styles);

interface Props {
  onChange?: (value: MaterialUiPickersDate | string) => void;
  name: string;
  required?: boolean;
  label?: string;
  margin?: 'none' | 'dense' | 'normal';
  fullWidth?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  endIcon?: React.ReactNode;
  className?: string;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
  tabIndex?: number;
  disabled?: boolean;
  withTime?: boolean;
}

const TextDate: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  name,
  required = false,
  margin = 'normal',
  label,
  fullWidth = true,
  minDate,
  maxDate,
  disablePast = false,
  disableFuture = false,
  endIcon = null,
  className = '',
  onSuccessChange,
  tabIndex = 0,
  disabled = false,
  withTime = false,
}) => {
  const classes = useStyles();
  const [field, { error, touched, value }, { setValue, setTouched }] =
    useField<string>(name);
  const { onChange: onChangeFormik } = field;

  const [open, setOpen] = useState(false);

  const format = useMemo(
    () => (withTime ? DATE_FORMAT_WITH_TIME : DATE_FORMAT),
    [withTime],
  );
  const valueFormat = useMemo(
    () => (withTime ? DB_DATE_FORMAT_WITH_TIME : DB_DATE_FORMAT),
    [withTime],
  );

  const handleChange = useCallback(
    (value: MaterialUiPickersDate) => {
      const dateString = value ? dayjs(value.toDate()).format(format) : '';

      setValue(dateString);
      onChange(value);
      if (onSuccessChange) onSuccessChange(required, dateString);
    },
    [onChangeFormik, onChange], // eslint-disable-line
  );

  const handleBlur = useCallback(() => setTouched(true), [setTouched]);

  const clear = () => {
    setValue('');
    onChange('');
    if (onSuccessChange) onSuccessChange(required, '');
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  return (
    <KeyboardDatePicker
      tabIndex={tabIndex}
      autoOk
      onClose={() => setOpen(false)}
      open={open}
      className={cx(
        className,
        'text-field text-date',
        !disabled ? classes.textFieldDate : '',
      )}
      variant="inline"
      inputVariant="outlined"
      format={format}
      margin={margin}
      label={label}
      value={value ? dayjs(value, format).format(valueFormat) : null}
      onChange={handleChange}
      onBlur={handleBlur}
      required={required}
      fullWidth={fullWidth}
      minDate={minDate}
      maxDate={maxDate}
      disablePast={disablePast}
      disableFuture={disableFuture}
      KeyboardButtonProps={{
        classes: {
          root: classes.textDateIconButton,
        },
      }}
      keyboardIcon={
        <SvgIcon component={calendarLightIcon} viewBox="0 0 448 512" />
      }
      InputAdornmentProps={{
        position: 'start',
        classes: {
          positionStart: cx(
            classes.textFieldIconBlock,
            'text-field-icon-block',
            classes.textFieldIconBlockStart,
          ),
        },
      }}
      InputProps={{
        classes: {
          input: cx(classes.input, 'text-input'),
        },
        endAdornment: (endIcon || (value && !required)) && (
          <InputAdornment
            className={cx(
              classes.textFieldIconBlock,
              'text-field-icon-block',
              classes.textFieldIconBlockEnd,
            )}
            position="end"
            onClick={e => e.stopPropagation()}
          >
            {value && !required && (
              <IconButton
                className="text-button text-clear-button"
                onClick={clear}
              >
                <ClearIcon />
              </IconButton>
            )}
            {endIcon}
          </InputAdornment>
        ),
        readOnly: true,
        onClick: handleOpen,
      }}
      helperText={touched && error}
      error={Boolean(touched && error)}
      disabled={disabled}
    />
  );
};

export default memo(TextDate);
