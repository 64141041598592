import {
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';

import { apiClient, ApiError } from 'app/config/axios';
import {
  BillingInfo,
  CardDetails,
  PaymentIntent,
  BillingDetails,
  BillingSettings,
} from 'app/components/entities/companies/interfaces';
import { PaginatedResponse } from 'app/interfaces';
import { InvoiceEntity } from '../types';

const iconPreviewEndpoint = '/timeplate-versions/icon-preview';
const companiesEndpoint = '/companies';

export const getIconPreview = (entity: string, entityId: number) =>
  apiClient.get(`${iconPreviewEndpoint}/${entity}/${entityId}`);

export const getBillingInfo = (companyId: number): Promise<BillingInfo> =>
  apiClient.get(`${companiesEndpoint}/${companyId}/billing`);

export const getCardDetailsInfo = (companyId: number): Promise<CardDetails[]> =>
  apiClient.get(`${companiesEndpoint}/${companyId}/card-details`);

export const getBillingDetailsInfo = (
  companyId: number,
): Promise<BillingDetails> =>
  apiClient.get(`${companiesEndpoint}/${companyId}/billing-details`);

export const getBillingSettings = (
  companyId: number,
): Promise<BillingSettings> =>
  apiClient.get(`${companiesEndpoint}/${companyId}/billing-settings`);

export const getPaymentIntent = (companyId: number): Promise<PaymentIntent> =>
  apiClient.get(`${companiesEndpoint}/${companyId}/setup-intent`);

export const updateSubscription = ({ companyId, ...data }): Promise<any> =>
  apiClient.post(`${companiesEndpoint}/${companyId}/subscription`, data);

export const cancelSubscription = ({ companyId }): Promise<any> =>
  apiClient.delete(`${companiesEndpoint}/${companyId}/subscription`);

export const sendInvoice = ({ companyId, ...data }): Promise<any> =>
  apiClient.post(`${companiesEndpoint}/${companyId}/send-invoice`, data);

export const updatePM = ({ companyId, ...data }): Promise<any> =>
  apiClient.post(`${companiesEndpoint}/${companyId}/payment-methods`, data);

export const updateBillingDetails = ({ companyId, ...data }): Promise<any> =>
  apiClient.post(`${companiesEndpoint}/${companyId}/billing-details`, data);

export const updateBillingSettings = ({ companyId, ...data }): Promise<any> =>
  apiClient.post(`${companiesEndpoint}/${companyId}/billing-settings`, data);

export const useGetIconPreview: <T = { icon: string }>(
  entity: string,
  entityId: number,
) => UseQueryResult<T, any> = (entity, entityId) =>
  useQuery<any, 'getIconPreview', any>(['getIconPreview'], () =>
    getIconPreview(entity, entityId),
  );

export const useGetBillingInfo = (companyId: number, options?) =>
  useQuery<BillingInfo, string>(
    ['billingInfo'],
    () => getBillingInfo(companyId),
    {
      ...options,
    },
  );

export const useGetCardDetailsInfo = (companyId: number, options?) =>
  useQuery<CardDetails[], string>(
    ['cardDetails'],
    () => getCardDetailsInfo(companyId),
    {
      ...options,
    },
  );

export const useGetBillingDetailsInfo = (companyId: number, options?) =>
  useQuery<BillingDetails, string>(
    ['billingDetails'],
    () => getBillingDetailsInfo(companyId),
    {
      ...options,
    },
  );

export const useGetBillingSettings = (companyId: number, options?) =>
  useQuery<BillingSettings, string>(
    ['billingSettings'],
    () => getBillingSettings(companyId),
    {
      ...options,
    },
  );

export const useGetPaymentIntent = (companyId: number, options?) =>
  useQuery<PaymentIntent, string>(
    ['paymentIntent'],
    () => getPaymentIntent(companyId),
    {
      ...options,
    },
  );

export const useUpdateSubscription = (options?) =>
  useMutation<any, any, any>(['updateSubscription'], updateSubscription, {
    ...options,
  });

export const useCancelSubscription = (options?) =>
  useMutation<any, any, any>(['cancelSubscription'], cancelSubscription, {
    ...options,
  });

export const useSendInvoice = (options?) =>
  useMutation<any, any, any>(['sendInvoice'], sendInvoice, { ...options });

export const useUpdatePM = (options?) =>
  useMutation<any, any, any>(['updatePM'], updatePM, { ...options });

export const useUpdateBillingDetails = (options?) =>
  useMutation<any, any, any>(['updateBillingDetails'], updateBillingDetails, {
    ...options,
  });

export const useUpdateBillingSettings = (options?) =>
  useMutation<any, any, any>(['updateBillingSettings'], updateBillingSettings, {
    ...options,
  });

export const invoiceIndex = (companyId: number, queryData) =>
  apiClient.get<any, PaginatedResponse<InvoiceEntity>>(
    `${companiesEndpoint}/${companyId}/invoices`,
    {
      params: queryData,
    },
  );

export const useInvoiceIndex: <T = PaginatedResponse<InvoiceEntity>>(
  companyId: number,
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (companyId: number, queryData, options) =>
  useQuery<any, ['indexInvoices', any], any>(
    ['indexInvoices', queryData],
    () => invoiceIndex(companyId, queryData),
    options,
  );

export const useInvoiceFilterIndex = (
  companyId: number,
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<InvoiceEntity>, ApiError>,
): {
  data: PaginatedResponse<InvoiceEntity> | undefined;
  isLoading: boolean;
  refetchInvoices: () => void;
} => {
  const {
    data,
    isFetching,
    refetch: refetchInvoices,
  } = useInvoiceIndex(companyId, queryData, options);

  return { data, isLoading: isFetching, refetchInvoices };
};
