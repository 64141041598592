import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';

import { apiClient, ApiError } from 'app/config/axios';
import { PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { SignatureUserEntity } from '../interfaces';

const endpoint = '/signature-users';

export const userStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, SignatureUserEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const userUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, SignatureUserEntity>(
    `${endpoint}/${id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const userShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, SignatureUserEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const userIndex = queryData =>
  apiClient.get<any, PaginatedResponse<SignatureUserEntity>>(endpoint, {
    params: queryData,
  });

export const useSignatureUserIndex: <
  T = PaginatedResponse<SignatureUserEntity>,
>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useSignatureUserIndex', any], any>(
    ['useSignatureUserIndex', queryData],
    () => userIndex(queryData),
    options,
  );

export const useSignatureUserStore = (
  options?: MutationOptions<SignatureUserEntity, FormData, any>,
) =>
  useMutation<SignatureUserEntity, FormData, any>(
    ['userStore'],
    data => userStore(data),
    {
      ...options,
    },
  );

export const useSignatureUserUpdate = (
  id: number,
  options?: MutationOptions<SignatureUserEntity, any, any>,
) =>
  useMutation<SignatureUserEntity, any, any>(
    ['userUpdate'],
    data => userUpdate(id, data),
    {
      ...options,
    },
  );

export const useSignatureUserShow = (
  id: number,
  options?: QueryOptions<SignatureUserEntity, any>,
  queryData?,
  key?: string,
) =>
  useQuery<SignatureUserEntity, ['useSignatureUserShow', number, string?], any>(
    ['useSignatureUserShow', id, key],
    () => userShow(id, queryData),
    options,
  );

export const useSignatureUserFilterIndex = (
  queryData,
  options?: QueryObserverOptions<
    PaginatedResponse<SignatureUserEntity>,
    ApiError
  >,
): {
  data: PaginatedResponse<SignatureUserEntity> | undefined;
  isLoading: boolean;
  refetch: () => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useSignatureUserIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};

export const signatureUserDelete = (
  id: number,
): Promise<SignatureUserEntity> => {
  return apiClient.delete(`${endpoint}/ghosts/${id}`);
};

export const useSignatureUserDelete = (
  options?: MutationOptions<SignatureUserEntity, any, any>,
) =>
  useMutation<any, SignatureUserEntity, any>(
    ['useSignatureUserDelete'],
    id => signatureUserDelete(id),
    {
      ...options,
    },
  );
