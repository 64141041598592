import React, { memo } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import useAcl from 'app/components/auth/hooks/useAcl';
import { ROLES } from 'app/components/auth';
import { sideBarActionConfig } from 'app/components/layouts/constants';

import { ReactComponent as buildingLightIcon } from 'images/icon/FontAwesome5Pro/Light/building.svg';
import { ReactComponent as addressCardLightIcon } from 'images/icon/FontAwesome5Pro/Light/address-card.svg';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface AccordionHeaderBlockProps {
  companyId: number;
  name: string;
  drawerMinimize: boolean;
  handleDrawerMinimizeToggle: any;
}

const AccordionHeaderBlock: React.FC<AccordionHeaderBlockProps> = ({
  companyId,
  name,
  drawerMinimize,
  handleDrawerMinimizeToggle,
}) => {
  const { isOneOf, is } = useAcl();
  const classes = useStyles();
  const navigate = useNavigate();

  if (isOneOf(sideBarActionConfig.locationAdmin)) {
    return (
      <div
        className={cx(classes.companyNameBlock, {
          minimize: drawerMinimize,
        })}
      >
        <div
          className={cx(classes.actionHeaderButton, {
            minimized: drawerMinimize,
          })}
        >
          <SvgIcon
            component={buildingLightIcon}
            viewBox="0 0 448 512"
            className={cx({ [classes.companyIcon]: !drawerMinimize })}
            onClick={
              drawerMinimize
                ? handleDrawerMinimizeToggle
                : () => {
                    return;
                  }
            }
          />
          <Tooltip placement="top" arrow={true} title={name}>
            <Typography
              className={cx({
                [classes.isMinimized]: drawerMinimize,
              })}
              noWrap
            >
              {name}
            </Typography>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (is(ROLES.BILLING_ADMIN)) {
    return (
      <div
        className={cx(classes.companyNameBlock, {
          minimize: drawerMinimize,
          isBillingAdmin: is(ROLES.BILLING_ADMIN),
        })}
      >
        <Button
          className={cx(classes.actionHeaderButton, {
            minimized: drawerMinimize,
          })}
          onClick={() => navigate(`/companies/${companyId}/edit/billing`)}
        >
          <SvgIcon
            className={cx({ [classes.companyIcon]: !drawerMinimize })}
            component={buildingLightIcon}
            viewBox="0 0 448 512"
          />
          <Tooltip placement="top" arrow={true} title={name}>
            <Typography
              className={cx({
                [classes.isMinimized]: drawerMinimize,
              })}
              noWrap
            >
              {name}
            </Typography>
          </Tooltip>
        </Button>
      </div>
    );
  }

  if (isOneOf([ROLES.COMPANY_USER, ROLES.LOCATION_USER])) {
    return <div></div>;
  }

  return (
    <div
      className={cx(classes.companyNameBlock, {
        minimize: drawerMinimize,
        isSignatureUser: is(ROLES.SIGNATURE_USER),
      })}
    >
      <Button
        className={cx(classes.actionHeaderButton, {
          minimized: drawerMinimize,
        })}
        onClick={() => navigate(`/user-signatures/active`)}
      >
        <SvgIcon
          className={cx({ [classes.companyIcon]: !drawerMinimize })}
          component={addressCardLightIcon}
          viewBox="0 0 576 512"
        />
        <Tooltip placement="top" arrow={true} title={name}>
          <Typography
            className={cx({
              [classes.isMinimized]: drawerMinimize,
            })}
            noWrap
          >
            Signatures
          </Typography>
        </Tooltip>
      </Button>
    </div>
  );
};

export default memo(AccordionHeaderBlock);
