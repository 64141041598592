import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { ROLES } from '../../constants';

export const AuthWrapper = () => {
  const { isLoading, user } = useAuthUser();

  const companyId = user?.companies[0]?.id;
  const isAdmin = useMemo(() => user?.mainRole === ROLES.SUPER_ADMIN, [user]);
  const isBillingAdmin = useMemo(
    () => user?.mainRole === ROLES.BILLING_ADMIN,
    [user],
  );
  const isCompanyOrLocationUser = useMemo(
    () =>
      user?.mainRole === ROLES.COMPANY_USER ||
      user?.mainRole === ROLES.LOCATION_USER,
    [user],
  );

  if (isLoading) {
    return null;
  }

  return isAdmin ? (
    <Navigate to={'/accounts'} replace />
  ) : isBillingAdmin ? (
    <Navigate to={`/companies/${companyId}/edit/billing`} replace />
  ) : isCompanyOrLocationUser ? (
    <Navigate to={`/profile/${user?.id}/edit/login`} replace />
  ) : (
    <Navigate to={`/user-signatures/active`} replace />
  );
};
