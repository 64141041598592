import { Theme, createStyles } from '@material-ui/core/styles';
import { bgInviteBlock, colorBlue5 } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '630px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
    inviteBlock: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '40px 0',
      margin: '40px 0 ',
      background: bgInviteBlock,
      [theme.breakpoints.down('sm')]: {
        padding: '0px 30px',
        margin: '20px 0px',
      },
    },
    shareText: {
      color: colorBlue5,
      fontSize: '16px',
      textAlign: 'center',
    },
    inviteField: { maxWidth: '400px' },
    copyIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    clickText: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    rolesList: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '20px 0',
    },
    roleBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(48% - 8px)',
      },
      '&:nth-child(odd)': {
        marginRight: '60px',
      },
    },
    avatarBlock: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
    },
    avatar: { marginRight: '12px' },
  });
