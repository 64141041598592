import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

interface Props {
  handleChange: (value: string) => void;
}

export default function FilterDropdown({ handleChange }: Props) {
  const classes = useStyles();
  const filterOptions = [
    { label: 'Newest', value: 'DESC' },
    { label: 'Oldest', value: 'ASC' },
  ];
  const [selectedOption, setSelectedOption] = useState(filterOptions[0].value);

  const handleChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <div className={classes.selectWrapper}>
      <Typography className={classes.selectLabel}>Sort by:</Typography>
      <TextField
        variant="outlined"
        select
        onChange={handleChangeSelect}
        value={selectedOption}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {filterOptions.map(({ value, label }: any) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
