import React from 'react';

import { Alert, Props as AlertProps } from 'app/components/ui/Alert';

const Message = React.forwardRef(
  (props: AlertProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Alert {...props} />
      </div>
    );
  },
);

export default Message;
