import {
  MutationOptions,
  QueryOptions,
  useMutation,
  useQuery,
  QueryObserverOptions,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { PaginatedResponse } from 'app/interfaces';
import { Users as UserEntity } from '../types';

const endpoint = '/queue';

export const userShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, UserEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const queueIndex = queryData =>
  apiClient.get<any, PaginatedResponse<UserEntity>>(endpoint, {
    params: queryData,
  });

export const queueUpdate = data => {
  return apiClient.patch<any, UserEntity>(`${endpoint}/status`, data);
};

export const processedUsersUpdate = data => {
  return apiClient.patch<any, UserEntity>(`${endpoint}/activate-users`, data);
};

export const useQueueIndex = (
  query: any,
  options?:
    | QueryOptions<UserEntity, any>
    | QueryObserverOptions<UserEntity, any>,
) =>
  useQuery<any, ['useQueueIndex', any], any>(
    ['useQueueIndex', query],
    () => queueIndex(query),
    options,
  );

export const useUserShow = (
  id: number,
  options?: QueryOptions<UserEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<UserEntity, ['userShow', number], any>(
    [key || 'userShow', id],
    () => userShow(id, queryData),
    options,
  );

export const useQueueFilterIndex = (queryData, options) => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch: refetchQueue,
  } = useQueueIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetchQueue };
};

export const useQueueUpdate = (
  options?: MutationOptions<UserEntity, any, any>,
) =>
  useMutation<UserEntity, any, any>(
    ['useQueueUpdate'],
    data => queueUpdate(data),
    {
      ...options,
    },
  );

export const useProcessedUsersUpdate = (
  options?: MutationOptions<UserEntity, any, any>,
) =>
  useMutation<UserEntity, any, any>(
    ['useProcessedUsersUpdate'],
    data => processedUsersUpdate(data),
    {
      ...options,
    },
  );
