import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import SvgIcon from '@material-ui/core/SvgIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { translations } from 'locales/i18n';

import Modal from './Modal';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';
import { Checkbox } from '../../Checkbox';
import { ModalProps } from '../types';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface ConfirmProps extends ModalProps {
  readonly title?: string;
  readonly subTitle?: string;
  readonly description?: React.ReactElement | string;
  readonly agreeLabel?: string;
  readonly cancelLabel?: string;
  readonly agreeButtonProps?: ButtonProps;
  readonly cancelButtonProps?: ButtonProps;
  readonly withCancelButton?: boolean;
  readonly className?: string;
  readonly actionPosition?: 'left' | 'right' | 'center';
  readonly modalTitleBlock?: 'standard' | 'danger';
  readonly maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  readonly titleBlockStyle?: 'default' | 'danger' | 'standard';
  readonly modalIcon?: any;
  readonly titleIcon?: any;
  readonly withCheckBox?: boolean;
  readonly isValid?: boolean;
  readonly withAgreeButton?: boolean;
}

const Confirmation: React.FC<ConfirmProps> = ({
  open,
  onSubmit,
  onClose,
  title,
  subTitle,
  description,
  agreeLabel,
  cancelLabel,
  agreeButtonProps,
  cancelButtonProps,
  withCancelButton = true,
  className,
  actionPosition = 'right',
  maxWidth = 'sm',
  titleBlockStyle,
  modalIcon,
  titleIcon,
  withCheckBox,
  withAgreeButton = true,
  isValid = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(!withCheckBox);

  const handleClose = () => {
    setIsChecked(false);
    onClose();
  };

  const handleSubmit = () => {
    setIsChecked(false);
    onSubmit();
  };

  return (
    <Modal
      className={cx(classes.modalConfirmation, className, {
        [classes[`${actionPosition}Position`]]: actionPosition,
      })}
      maxWidth={maxWidth}
      open={open}
      onBackdropClick={handleClose}
    >
      <div className={cx({ [classes.modalWithIcon]: modalIcon })}>
        {modalIcon ? (
          <SvgIcon
            viewBox="0 0 56 56"
            className={classes.modalIcon}
            component={modalIcon}
          />
        ) : null}
        <div className={classes.modalContentBlock}>
          <ModalTitle
            onClose={handleClose}
            modalTitleBlock={titleBlockStyle}
            titleIcon={titleIcon}
            subTitle={subTitle}
          >
            {title}
          </ModalTitle>

          <ModalContent>{description}</ModalContent>

          <DialogActions
            className={cx('modal-button-block', {
              'modal-button-block-with-checkbox': withCheckBox,
            })}
          >
            {withCheckBox && (
              <FormControlLabel
                value={isChecked}
                className={classes.modalCheckBox}
                control={<Checkbox />}
                label={t(translations.ui.buttons.understand)}
                labelPlacement="end"
                onChange={(event, checked: boolean) => setIsChecked(checked)}
              />
            )}
            {withCancelButton && (
              <Button
                className="modal-button"
                onClick={handleClose}
                color="primary"
                variant="outlined"
                size="small"
                {...cancelButtonProps}
              >
                {cancelLabel || t(translations.ui.buttons.cancel)}
              </Button>
            )}
            {withAgreeButton && (
              <Button
                className="modal-button"
                onClick={handleSubmit}
                color="secondary"
                variant="contained"
                size="small"
                disabled={(!isChecked && withCheckBox) || !isValid}
                {...agreeButtonProps}
              >
                {agreeLabel || t(translations.ui.buttons.yes)}
              </Button>
            )}
          </DialogActions>
        </div>
      </div>
    </Modal>
  );
};

export default Confirmation;
