import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import cx from 'classnames';
import * as yup from 'yup';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { Loader } from 'app/components/ui/Loader';
import { useFifthStep } from 'app/components/entities/accounts/hooks/crud';
import { error } from 'app/components/snackbars';
import { Switch, Text } from 'app/components/forms';
import {
  FREQUENCY_OPTIONS,
  TIME_OPTIONS,
  DAY_OPTIONS,
} from 'app/components/entities/components/tabs/Settings/constants';

import { StepFooter } from '../StepFooter/StepFooter';

import { ReactComponent as questionIcon } from 'images/icon/FontAwesome5Pro/Light/circle-question.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  nextStep: () => void;
}

export const FifthStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { mutateAsync: finishStep } = useFifthStep();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    autoPay: yup.boolean().required(t(translations.validation.required)),
    frequency: yup.string().required(t(translations.validation.required)),
    time: yup.string().required(t(translations.validation.required)),
    day: yup
      .string()
      .nullable()
      .when(' frequency', {
        is: frequency => frequency === 'weekly',
        then: () => yup.number().required(t(translations.validation.required)),
      }),
  });

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
    {
      refetchOnMount: true,
    },
  );

  const initialValues = useMemo(() => {
    return {
      autoPay: user && company ? company?.settings?.autoPay : true,
      frequency:
        user && company ? company?.settings?.autoPayPU?.frequency : 'daily',
      day: user && company ? company?.settings?.autoPayPU?.day : 1,
      time: user && company ? company?.settings?.autoPayPU?.time : '9',
      companyId: company?.id,
    };
  }, [user, company]);

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep(data);
        setIsLoading(false);
        nextStep();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={isCompanyLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!isCompanyLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.fifth.title)}
          </Typography>
          <Typography>
            {t(translations.accountHolderOnboarding.steps.fifth.text1)}
          </Typography>
          <br />
          <Typography>
            {t(translations.accountHolderOnboarding.steps.fifth.text2)}
          </Typography>
          <br />
          <Typography>
            {t(translations.accountHolderOnboarding.steps.fifth.text3)}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              return;
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values: data }) => (
              <div className={classes.stepContainer}>
                <form
                  className={classes.formContainer}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.formRaw}>
                    <div className={classes.textFieldBlock}>
                      <Typography className={classes.formText}>
                        {t(
                          translations.accountHolderOnboarding.steps.fifth.form
                            .autoPay,
                        )}
                      </Typography>
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.fifth
                            .tooltip.autoPay,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          component={questionIcon}
                          className={classes.questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <Switch
                        name="autoPay"
                        fullWidth={false}
                        color="primary"
                      />
                    </div>
                  </div>
                  <div className={cx(classes.formRaw, classes.dateRaw)}>
                    <div className={classes.textFieldBlock}>
                      <Typography className={classes.formText}>
                        {t(
                          translations.accountHolderOnboarding.steps.fifth.form
                            .autoPayProcessedUser,
                        )}
                      </Typography>
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.fifth
                            .tooltip.autoPayProcessedUser,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          component={questionIcon}
                          className={classes.questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                    <div className={classes.dateFieldBlock}>
                      <Text
                        className={classes.selectField}
                        name="frequency"
                        type="text"
                        variant="outlined"
                        margin="none"
                        select
                        disabled={!data.autoPay}
                        options={FREQUENCY_OPTIONS}
                      />
                      <Text
                        className={classes.selectField}
                        name="time"
                        type="text"
                        variant="outlined"
                        margin="none"
                        select
                        disabled={!data.autoPay}
                        options={TIME_OPTIONS}
                      />
                      {data.frequency === 'weekly' && (
                        <Text
                          name="day"
                          type="text"
                          variant="outlined"
                          margin="none"
                          select
                          disabled={!data.autoPay}
                          options={DAY_OPTIONS}
                        />
                      )}
                    </div>
                  </div>
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.fifth.text4)}
                  </Typography>
                </form>
                <StepFooter
                  activeStep={5}
                  isValid={isValid}
                  handleNext={async () => await handleFinishStep(data)}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
