import { LocalStorageStore, ImmortalStorage } from 'immortal-db';
import { menuConfig, tableConfig } from 'app/components/auth/interfaces';
const stores = [LocalStorageStore];
const db = new ImmortalStorage(stores);
const tableKey = 'table-config';
const menuKey = 'menu-config';

const storeConfig = (config: tableConfig | menuConfig, key: string) =>
  db.set(key, JSON.stringify(config));

export const storeTableConfig = (tableConfig: tableConfig) =>
  storeConfig(tableConfig, tableKey);

export const storeMenuConfig = (menuConfig: menuConfig) =>
  storeConfig(menuConfig, menuKey);

const getConfig = async <T>(key) => {
  let config: T | undefined;

  try {
    const stored = await db.get(key);
    config = typeof stored === 'string' ? JSON.parse(stored) : undefined;
  } catch (e) {
    // ¯\_(ツ)_/¯
  }

  return config;
};

export const getTableConfig = async () =>
  await getConfig<tableConfig>(tableKey);
export const getMenuConfig = async () => await getConfig<menuConfig>(menuKey);

export const destroyTableConfig = () => db.remove(tableKey);
export const destroyMenuConfig = () => db.remove(menuKey);
