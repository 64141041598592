import { createStyles } from '@material-ui/core/styles';

import {
  checkBoxBgDisabled,
  checkBoxBorderDisabled,
  checkBoxPrimary,
  checkBoxPrimaryBg,
  checkBoxSecondary,
  checkBoxSecondaryBg,
} from 'configs/variables';

import checkIcon from 'images/icon/FontAwesome5Pro/Light/check.svg';

export default () =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      border: `1px solid currentColor`,

      '$root.MuiCheckbox-colorSecondary:hover &': {
        background: checkBoxSecondaryBg,
        border: `1px solid ${checkBoxSecondary}`,
      },

      '$root.MuiCheckbox-colorPrimary:hover &': {
        background: checkBoxPrimaryBg,
        border: `1px solid ${checkBoxPrimary}`,
      },

      '$root.Mui-disabled &': {
        border: `1px solid ${checkBoxBorderDisabled} !important`,
        background: `${checkBoxBgDisabled} !important`,
      },
    },
    checkedIcon: {
      '$root.MuiCheckbox-colorPrimary &': {
        background: checkBoxPrimaryBg,
        border: `1px solid ${checkBoxPrimary}`,
      },
      '$root.MuiCheckbox-colorSecondary &': {
        background: checkBoxSecondaryBg,
        border: `1px solid ${checkBoxSecondary}`,
      },

      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundColor: 'currentColor',
        '-webkit-mask-image': `url(${checkIcon})`,
        'mask-image': `url(${checkIcon})`,
        content: '""',
      },
    },
  });
