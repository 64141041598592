import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { Loader } from 'app/components/ui/Loader';
import { useFourthStep } from 'app/components/entities/accounts/hooks/crud';
import { error } from 'app/components/snackbars';
import { Switch } from 'app/components/forms';
import { AUTO_APPROVALS_TYPE } from 'types';

import { StepFooter } from '../StepFooter/StepFooter';
import { AUTO_APPROVALS_TYPE_OPTIONS } from './constants';

import { ReactComponent as questionIcon } from 'images/icon/FontAwesome5Pro/Light/circle-question.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  nextStep: () => void;
}

export const FourthStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { mutateAsync: finishStep } = useFourthStep();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    autoApprovalsType: yup
      .mixed()
      .oneOf([...AUTO_APPROVALS_TYPE_OPTIONS.map(option => option.value)])
      .required(t(translations.validation.required)),
  });

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
  );

  const initialValues = useMemo(() => {
    return {
      autoApprovalsType:
        user && company
          ? company?.settings?.autoApprovalsType
          : AUTO_APPROVALS_TYPE.ON_ALL_INVITES,
      companyId: company?.id,
    };
  }, [user, company]);

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep(data);
        setIsLoading(false);
        nextStep();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={isCompanyLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!isCompanyLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.fourth.title)}
          </Typography>
          <Typography>
            {t(translations.accountHolderOnboarding.steps.fourth.text1)}
          </Typography>
          <br />
          <Typography>
            {t(translations.accountHolderOnboarding.steps.fourth.text2)}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              return;
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values: data }) => (
              <div className={classes.stepContainer}>
                <form
                  className={classes.formContainer}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.switchBlock}>
                    <Switch
                      label={t(
                        translations.accountHolderOnboarding.steps.fourth
                          .switchText,
                      )}
                      name="autoApprovalsType"
                      customValues={AUTO_APPROVALS_TYPE_OPTIONS}
                      margin="dense"
                      fullWidth={false}
                      className={classes.switch}
                      color="primary"
                    />
                    <Tooltip
                      title={t(
                        translations.accountHolderOnboarding.steps.fourth
                          .tooltip.autoApprovalsType,
                      )}
                      placement="top"
                      arrow={true}
                    >
                      <SvgIcon
                        color="primary"
                        component={questionIcon}
                        className={classes.questionIcon}
                        viewBox="0 0 512 512"
                      />
                    </Tooltip>
                  </div>

                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.fourth.text3)}
                  </Typography>
                  <br />
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.fourth.text4)}
                  </Typography>
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.fourth.text5)}
                  </Typography>
                  <br />
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.fourth.text6)}
                  </Typography>
                </form>
                <StepFooter
                  activeStep={4}
                  isValid={isValid}
                  handleNext={async () => await handleFinishStep(data)}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
