import { createStyles } from '@material-ui/core/styles';

import {
  radioBgDisabled,
  radioBorderDisabled,
  radioPrimary,
  radioPrimaryBg,
  radioSecondary,
  radioSecondaryBg,
} from 'configs/variables';

import checkIcon from 'images/icon/FontAwesome5Pro/Light/radio-check.svg';

export default () =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      border: `1px solid currentColor`,

      '$root.MuiRadio-colorSecondary:hover &': {
        background: radioSecondaryBg,
        border: `1px solid ${radioSecondary}`,
      },

      '$root.MuiRadio-colorPrimary:hover &': {
        background: radioPrimaryBg,
        border: `1px solid ${radioPrimary}`,
      },

      '$root.Mui-disabled &': {
        border: `1px solid ${radioBorderDisabled} !important`,
        background: `${radioBgDisabled} !important`,
      },
    },
    checkedIcon: {
      '$root.MuiRadio-colorPrimary &': {
        background: radioPrimaryBg,
        border: `1px solid ${radioPrimary}`,
      },
      '$root.MuiRadio-colorSecondary &': {
        background: radioSecondaryBg,
        border: `1px solid ${radioSecondary}`,
      },

      '&:before': {
        display: 'flex',
        borderRadius: '50%',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        '-webkit-mask-image': `url(${checkIcon})`,
        '-webkit-mask-repeat': 'no-repeat',
        '-webkit-mask-position': 'center',
        'mask-image': `url(${checkIcon})`,
        'mask-repeat': 'no-repeat',
        'mask-position': 'center',
        content: '""',
      },
    },
  });
