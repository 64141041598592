import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactQuill from 'react-quill';
import { useDebounce } from 'react-use';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import SavingContext from 'app/components/layouts/components/SavingContext';

import { INPUT_DEBOUNCE_DELAY } from '../../constants';

import styles from '../../styles';

const useStyles = makeStyles(styles);

export interface Props {
  value?: string;
  onChange: (data) => void;
  label?: string;
  fullWidth?: boolean;
  margin?: 'none' | 'normal' | 'dense';
  required?: boolean;
  className?: string;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
  wihDebounce?: boolean;
}

const TextEditor = ({
  value = '',
  onChange,
  label,
  className = '',
  fullWidth = true,
  margin = 'none',
  required = false,
  onSuccessChange,
  wihDebounce = false,
}: Props) => {
  const { setIsSaving } = useContext(SavingContext);
  const [data, setData] = useState(null);

  const onCustomChange = useCallback(
    data => {
      if (onChange) {
        onChange(data);
      }
      if (onSuccessChange) {
        setData(data);
      }
    },
    [onChange, onSuccessChange],
  );

  const handleDebounceChange = () => {
    if (wihDebounce && onSuccessChange && data !== null) {
      onSuccessChange(required, data);
    }
  };

  useDebounce(handleDebounceChange, INPUT_DEBOUNCE_DELAY, [data]);

  const handleChange = useCallback(
    data => {
      onCustomChange(data);
      setIsSaving(true);
    },
    [setIsSaving, onCustomChange],
  );

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  const classes = useStyles();

  return (
    <FormControl margin={margin} fullWidth={fullWidth} className={className}>
      {label ? (
        <InputLabel
          className={classes.textEditorLabel}
          disableAnimation
          required={required}
        >
          {label}
        </InputLabel>
      ) : (
        ''
      )}
      <ReactQuill
        className="native-custom-scrollbar grey-scrollbar"
        value={value}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default memo(TextEditor);
