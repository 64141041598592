import React from 'react';

function Option({ name, optionType }: any) {
  return (
    <span
      className={
        optionType === 'disabled'
          ? 'select-option-disabled'
          : optionType === 'button'
          ? 'select-option-button'
          : ''
      }
    >
      {name}
    </span>
  );
}

export default Option;
