import React from 'react';
import { useDebounce } from 'react-use';

import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { Search } from './Search';
import { SEARCHING_DELAY } from './constants';

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  className?: string;
  handleSearch: (...args: any) => void;
}

export function TextSearch({ handleSearch, ...props }: Props) {
  const [val, setVal] = React.useState('');

  useDebounce(
    () => {
      handleSearch(val || '');
    },
    SEARCHING_DELAY,
    [val],
  );

  return (
    <Search
      handleInputChange={({ currentTarget }) => {
        setVal(currentTarget.value);
      }}
      {...props}
    />
  );
}
