/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useRef } from 'react';
import { useField } from 'formik';
import Emoji from '@emoji-mart/react';
import { Skin } from '@emoji-mart/data';
import data from '@emoji-mart/data/sets/1/apple.json';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Popper from '@material-ui/core/Popper';

import useClickOutside from 'utils/useClickOutside';

import Text from './Text';
import styles from '../../styles/emoji';

const useStyles = makeStyles(styles);

export interface Props extends OutlinedTextFieldProps {
  name: string;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
  endIcon?: React.ReactNode;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  startIcon?: React.ReactNode;
}

const TextEmoji = (props: Props) => {
  const classes = useStyles();
  const [{ value }] = useField<string>(props.name);

  const pickerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const clearEmoji = () => {
    if (props.setFieldValue) props.setFieldValue(props.name, '', false);
    if (props.onSuccessChange) props.onSuccessChange(props.required, '');
  };

  const handleClick = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleEmojiSelect = (emoji: Skin) => {
    if (props.setFieldValue)
      props.setFieldValue(props.name, [value, emoji.unified].join(' '), false);
    if (props.onSuccessChange)
      props.onSuccessChange(props.required, [value, emoji.unified].join(' '));

    dismissPicker();
  };

  const searchEmoji = (emoji, emojis = data.emojis) => {
    const compareEmoji = ({ unified }) => unified === emoji;
    let result;
    for (const emojiObject of Object.values(emojis)) {
      //@ts-ignore
      if (!emojiObject.hasOwnProperty('unified')) {
        result = searchEmoji(emoji, emojiObject as any);
        //@ts-ignore
      } else if (compareEmoji(emojiObject)) {
        //@ts-ignore
        result = emojiObject.id;
      }

      if (result) break;
    }

    return result;
  };

  const dismissPicker = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useClickOutside([pickerRef, textRef], dismissPicker);

  return (
    <>
      <Text
        {...props}
        className={classes.emojiTextField}
        readOnly
        emptyField
        startIcon={
          (props.startIcon || value) && (
            <PerfectScrollbar
              className="ps-outer-scroll grey-scrollbar"
              options={{ suppressScrollY: true }}
            >
              {value
                ?.trim()
                ?.split(' ')
                .map(emoji => {
                  // @ts-ignore
                  const id = searchEmoji(emoji);
                  return id && <Emoji emoji={id} set="apple" size={24} />;
                })}
              {props.startIcon}
            </PerfectScrollbar>
          )
        }
        endIcon={
          (props.endIcon || value) && (
            <>
              {value && (
                <IconButton
                  className="text-button text-clear-button"
                  onClick={clearEmoji}
                >
                  <ClearIcon />
                </IconButton>
              )}
              {props.endIcon}
            </>
          )
        }
        onClick={handleClick}
        ref={textRef}
      />
      <Popper
        className={classes.emojiPopper}
        id={props.id}
        open={!!anchorEl}
        transition
        placement={'bottom'}
        anchorEl={anchorEl}
      >
        <div
          className={cx(
            classes.selectPaper,
            classes.emojiPaper,
            'native-custom-scrollbar grey-scrollbar',
          )}
          ref={pickerRef}
        >
          <Emoji
            emoji={value}
            title=""
            showPreview={false}
            showSkinTones={false}
            set="apple"
            onSelect={handleEmojiSelect}
            data={data}
          />
        </div>
      </Popper>
    </>
  );
};

export default memo(TextEmoji);
