import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorWhite,
  colorPurple,
  boxShadow,
  borderRadiusEllipse,
  colorPrimary,
  colorDisable,
  colorPrimaryLight4,
  bgOnboardingActive,
  colorRose1,
  colorRose2,
  colorRose3,
} from 'configs/variables';

const paperSmallSize = 130;

export default (theme: Theme) =>
  createStyles({
    dropdownButton: {},
    dropdownButtonLabel: {
      flexWrap: 'inherit',
    },
    dropdownOpen: {
      '& $dropdownButtonArrow': {
        transform: 'rotate(180deg)',
      },
    },
    dropdownButtonWithArrow: {
      position: 'relative',
      paddingRight: '35px !important',
    },
    dropdownButtonArrow: {
      fontSize: 15,
      color: colorPurple,
      position: 'absolute',
      right: 7,
      top: 'calc(50% - 7px)',
    },
    dropdownPopper: {
      minWidth: 150,
      marginTop: 20,
      zIndex: 1,

      '&[x-placement*="bottom"]': {
        '& $dropdownPaperArrow': {
          top: 0,
          left: 0,
          width: '3em',
          height: '1em',
          marginTop: '-0.9em',
          '&::before': {
            borderColor: `transparent transparent ${colorWhite} transparent`,
            borderWidth: '0 1em 1em 1em',
          },
        },
      },
      '&[x-placement*="top"]': {
        '& $dropdownPaperArrow': {
          left: 0,
          bottom: 0,
          width: '3em',
          height: '1em',
          marginTop: '-0.9em',
          '&::before': {
            borderColor: `${colorWhite} transparent transparent transparent`,
            borderWidth: '1em 1em 0 1em',
          },
        },
      },
      '&$paperSmall': {
        minWidth: `${paperSmallSize}px !important`,
      },
    },
    dropdownPaper: {
      backgroundColor: colorWhite,
      padding: 0,
      maxWidth: '100%',
      minWidth: 80,
      ...boxShadow,

      '&:not($dropdownPaperFullWidth)': {
        width: 180,
        [theme.breakpoints.up('sm')]: {
          width: 200,
          maxWidth: 350,
        },
      },
      '&$paperSmall': {
        width: `${paperSmallSize}px !important`,
      },
    },
    paperClear: {
      padding: 0,
    },
    paperSmall: {},
    paperDefault: {},
    dropdownCollapseContainer: {
      transitionDuration: '0ms !important',
    },
    dropdownScrollbar: {
      maxHeight: 300,
    },
    dropdownIconButton: {
      width: 'auto',
      minWidth: 0,
      height: 'auto',
      minHeight: 0,
      padding: 6,
      border: 'none',
      borderRadius: borderRadiusEllipse,
      '& svg': {
        fontSize: 'inherit',
      },
    },
    dropdownIconButtonPopper: {
      '&, & $dropdownPaper': {
        minWidth: 0,
        width: 'auto',
      },
    },
    dropdownPopperFullWidth: {
      '&, & $dropdownPaperFullWidth': {
        minWidth: 80,
      },
    },
    dropdownPaperFullWidth: {},
    dropdownPaperArrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    text: {
      fontSize: 16,
    },
    outlined: {},
    contained: {},

    // List
    dropdownList: {
      margin: 0,
      listStyle: 'none',
    },
    dropdownListItem: {
      color: colorPrimary,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      padding: '10px 15px',
      width: 'auto',

      '&:not(:last-child)': {
        marginBottom: 1,
      },
    },
    dropdownListItemIcon: {
      fontSize: 20,
      lineHeight: 1,
      display: 'inline-flex',
      marginRight: 16,

      '& svg': {
        fontSize: 'inherit',
        color: 'inherit',
      },
    },
    dropdownMenu: { width: '430px !important', left: '32px !important' },
    dropdownScroll: {
      maxHeight: '100%',
    },
    roleSwitcherText: { padding: '32px 24px' },
    roleSwitcherSubText: { color: colorDisable },
    dropdownMenuFooter: {
      padding: '40px 60px',
    },
    footerRaw: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    footerIconBlock: {
      padding: '8px 0',
      width: '120px',
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    footerIcon: { marginRight: '8px', fontSize: '12px' },
    footerText: {
      color: colorPrimaryLight4,
    },
    disabled: {
      '&:hover': {
        cursor: 'default',
      },
    },
    roleBlock: {
      padding: '24px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    roleIcon: {
      marginRight: '30px',
    },
    activeRole: {
      background: bgOnboardingActive,
      color: colorWhite,
      '&:hover': {
        cursor: 'default',
      },
      '& > svg': {
        fill: colorWhite,
        '& > path': {
          fill: colorWhite,
        },
      },
    },
    impersonateDropdown: { width: '430px !important', left: '6px !important' },
    impersonateText: {
      padding: '24px 32px',
      background: 'rgba(255, 245, 246, 0.4)',
      color: colorRose2,
    },
    impersonateSubText: { color: colorRose3 },
    impersonateSearchBlock: {
      background: colorRose1,
      display: 'flex',
      justifyContent: 'center',
    },
    userSelector: {
      width: '100%',
      maxWidth: '360px',
      padding: '32px 0',
    },
  });
