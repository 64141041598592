import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import {
  Modal,
  ModalContent,
  ModalTitle,
  useConfirmation,
} from 'app/components/ui/Modal';
import { ImageInner } from 'app/components/forms';
import { translations } from 'locales/i18n';
import { success } from 'app/components/snackbars/utils';

import ScaleImage from './ScaleImage';

import styles from 'app/components/forms/styles/image';

const useStyles = makeStyles(styles);

export interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: (data, isCircle, removeLoader) => void;
  handleScale: (width, height) => void;
  name: string;
  fromOnboarding?: boolean;
  valueFromOnboarding?: string;
  src?: any;
  isEditMode?: boolean;
  editFileName?: string;
  editIsCircle?: boolean;
}

enum STEPS {
  CROP = 0,
  SCALE,
}

const ImageModal = ({
  open,
  handleClose,
  handleSave,
  name,
  handleScale,
  fromOnboarding = false,
  valueFromOnboarding,
  src,
  isEditMode = false,
  editFileName = '',
  editIsCircle = false,
}: Props) => {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();
  const [activeStep, setActiveStep] = useState(STEPS.CROP);
  const [{ value: formikValue }] = useField(name);
  const [isGif, setIsGif] = useState(false);
  const [isCircle, setIsCircle] = useState(false);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEditMode && editIsCircle) {
      setIsCircle(true);
    }
  }, [editIsCircle, isEditMode]);

  const value = !fromOnboarding ? formikValue : valueFromOnboarding;

  const handleCrop = (file, isCircle) => {
    setLoading(true);

    Promise.resolve(handleSave(file, !isGif && isCircle, !isGif)).then(() => {
      setLoading(false);
    });

    setIsCircle(!isGif && isCircle);
    if (isGif) {
      onClose();
      showSuccess();
    } else {
      setActiveStep(STEPS.SCALE);
    }
  };
  const onScale = (width, height) => {
    handleScale(width, height);
    onClose();
    showSuccess();
  };

  const onClose = () => {
    setActiveStep(STEPS.CROP);
    setIsGif(false);
    setIsCircle(false);
    handleClose();
  };

  const [showConfirmation] = useConfirmation({
    onSubmit: onClose,
    description: t(translations.ui.titles.confirmationMessage),
    agreeLabel: t(translations.ui.buttons.stopUploading),
  });

  const onClickClose = () => {
    if (activeStep === STEPS.CROP && image) {
      showConfirmation();
    } else {
      onClose();
    }
  };

  const showSuccess = () => {
    success(t(translations.company.message.success.upload));
  };

  const classesStep = {
    label: classes.imageStepLabel,
    completed: classes.imageStepLabelCompleted,
    active: classes.imageStepLabelActive,
  };

  return (
    <Modal className={classes.imageModal} open={open} maxWidth="md">
      <ModalTitle onClose={onClickClose} content>
        <Typography variant="h5" color="inherit">
          {t(translations.ui.titles.uploadImage)}
        </Typography>
      </ModalTitle>
      <ModalContent>
        {!isGif && (
          <Stepper
            className={classes.imageStepper}
            activeStep={activeStep}
            connector={<div className={classes.imageStepConnector} />}
          >
            <Step className={classes.imageStep}>
              <StepLabel classes={classesStep} icon="">
                <span className={classes.imageStepIndex}>1</span>Choose/Crop
                Image
              </StepLabel>
            </Step>
            <Step className={classes.imageStep}>
              <StepLabel classes={classesStep} icon="">
                <span className={classes.imageStepIndex}>2</span>Scale Image
              </StepLabel>
            </Step>
          </Stepper>
        )}
        {activeStep === STEPS.CROP && (
          <ImageInner
            uploadAreaText={t(translations.form.labels.uploadDropImage)}
            name={name}
            onSubmit={handleCrop}
            handleClose={onClickClose}
            control={false}
            isGif={isGif}
            setIsGif={setIsGif}
            image={image}
            setImage={setImage}
            fromOnboarding={fromOnboarding}
            src={src}
            isEditMode={isEditMode}
            editFileName={editFileName}
            editIsCircle={editIsCircle}
          />
        )}
        {activeStep === STEPS.SCALE && (
          <ScaleImage
            value={value}
            onSubmit={onScale}
            handleClose={onClickClose}
            imageCircle={isCircle}
            loading={loading}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default memo(ImageModal);
