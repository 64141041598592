import React, { memo, useContext, useEffect } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import { ReactCropperProps } from 'react-cropper';

import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Cropper } from 'app/components/cropper';
import SavingContext from 'app/components/layouts/components/SavingContext';

import styles from '../../styles/image';

const useStyles = makeStyles(styles);

interface Props {
  name: string;
  label?: React.ReactNode;
  className?: string;
  cropperProps?: ReactCropperProps;
  canvasOptions?: object;
  uploadAreaText?: string;
  margin?: 'none' | 'dense' | 'normal';
  fullWidth?: boolean;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
}

const Image: React.FC<Props> = ({
  name,
  label,
  className = '',
  cropperProps,
  uploadAreaText,
  margin = 'normal',
  fullWidth = true,
  onSuccessChange,
  ...rest
}) => {
  const [{ value }, meta, helper] = useField(name);
  const classes = useStyles();
  const { error } = meta;
  const { setError, setValue } = helper;
  const { setIsSaving } = useContext(SavingContext);

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useEffect(() => {
    if (error) setIsSaving(false);
  }, [error, setIsSaving]);

  const handleChange = file => {
    setValue(file);
    if (onSuccessChange) {
      setIsSaving(true);
      onSuccessChange(false, file);
    }
  };

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      className={cx(
        classes.imageField,
        error ? classes.imageFieldError : '',
        className,
      )}
    >
      {label ? (
        <InputLabel className={classes.imageFieldLabel}>{label}</InputLabel>
      ) : (
        ''
      )}
      <Cropper
        onError={setError}
        onChange={handleChange}
        value={value}
        cropperProps={cropperProps}
        uploadAreaText={uploadAreaText}
        {...rest}
      />
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  );
};

export default memo(Image);
