import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'formik';
import { SketchPicker } from 'react-color';
import cx from 'classnames';
import { useDebounce } from 'react-use';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ClickAwayListener,
  Collapse,
  Popper,
  IconButton,
  OutlinedTextFieldProps,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import SavingContext from 'app/components/layouts/components/SavingContext';

import Text from './Text';
import { INPUT_DEBOUNCE_DELAY } from '../../constants';

import styles from '../../styles/color';

const useStyles = makeStyles(styles);

export interface Props extends OutlinedTextFieldProps {
  name: string;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  currentColor?: string;
  className?: string;
  removable?: boolean;
  withDebounce?: boolean;
  debounceTime?: number;
}

const TextColorPicker = ({
  removable = true,
  withDebounce = false,
  debounceTime = INPUT_DEBOUNCE_DELAY,
  ...props
}: Props) => {
  const classes = useStyles();
  const [{ value }, , { setValue }] = useField(props.name);
  const textRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setIsSaving } = useContext(SavingContext);

  const [valueField, setValueField] = useState(value);
  const [colorChanged, setColorChanged] = useState(false);
  const [debounceValue, setDebounceValue] = useState<string>();

  const color = useMemo(() => value || valueField, [value, valueField]);

  const handleWithDebounce = useCallback(() => {
    if (
      props.onSuccessChange &&
      colorChanged &&
      typeof debounceValue === 'string'
    ) {
      props.onSuccessChange(props.required, debounceValue);
    }
  }, [colorChanged, debounceValue, props]);

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useDebounce(handleWithDebounce, debounceTime, [debounceValue]);

  const handleChange = color => {
    setValue(color?.hex || '');

    if (props.setFieldValue)
      props.setFieldValue(props.name, color?.hex || '', false);
    if (props.onSuccessChange) {
      if (withDebounce) {
        setDebounceValue(color?.hex || '');
        setColorChanged(true);
        setIsSaving(true);
      } else {
        props.onSuccessChange(props.required, color?.hex || '');
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!props.disabled) setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const clearColor = () => {
    if (props.setFieldValue) props.setFieldValue(props.name, '', false);
    if (props.onSuccessChange) {
      props.onSuccessChange(props.required, '');
    }

    setValue('');
    setValueField('');
  };

  const dismissPicker = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Text
        {...props}
        className={cx(classes.colorTextField, props.className)}
        startIcon={
          (props.startIcon || color) && (
            <>
              {color && (
                <div
                  className={classes.colorPreview}
                  style={{ backgroundColor: color }}
                />
              )}
              {props.startIcon}
            </>
          )
        }
        endIcon={
          (props.endIcon || color) && (
            <>
              {color && !props.disabled && removable && (
                <IconButton
                  tabIndex={-1}
                  className="text-button text-clear-button"
                  onClick={clearColor}
                >
                  <ClearIcon />
                </IconButton>
              )}
              {props.endIcon}
            </>
          )
        }
        readOnly
        emptyField
        value={color}
        onClick={handleClick}
        ref={textRef}
      />
      <Popper
        className={classes.colorPopper}
        id={props.id}
        open={!!anchorEl}
        transition
        placement="bottom-start"
        anchorEl={anchorEl}
      >
        {({ TransitionProps }) => (
          <Collapse {...TransitionProps} timeout={400}>
            <div className={cx(classes.selectPaper, classes.colorPaper)}>
              <ClickAwayListener
                onClickAway={dismissPicker}
                mouseEvent="onMouseUp"
              >
                <SketchPicker
                  color={color || { hex: '' }}
                  onChange={handleChange}
                  disableAlpha
                />
              </ClickAwayListener>
            </div>
          </Collapse>
        )}
      </Popper>
    </>
  );
};

export default memo(TextColorPicker);
