import { createStyles, Theme } from '@material-ui/core/styles';
import { colorPrimaryLight4 } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '16px',
    },
    nameColumn: {
      [theme.breakpoints.up('md')]: {
        marginLeft: '12px',
      },
    },
    signatureText: {
      color: colorPrimaryLight4,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '400px',
      justifyContent: 'space-between',
      overflow: 'auto',
      marginBottom: '30px',
    },
  });
