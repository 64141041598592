import { createStyles, Theme } from '@material-ui/core/styles';

import selectStyle from 'app/components/forms/styles/select';

import { borderPrimaryColor, borderRadiusSize } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    ...selectStyle(),
    emojiTextField: {
      '& .text-field-icon-block-start': {
        width: 'calc(100% - 80px)',
        position: 'static',
        height: 'auto',

        '& .emoji-mart-emoji': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 30,
          width: 30,
          border: `1px solid ${borderPrimaryColor}`,
          lineHeight: 1,
          padding: 2,
          borderRadius: '50%',

          '&:not(:last-child)': {
            marginRight: 5,
          },
        },
        '& .scrollbar-container': {
          display: 'flex',
          alignItems: 'center',
        },
      },
      '& .text-input': {
        paddingLeft: 0,
      },
    },
    emojiPopper: {
      zIndex: theme.zIndex.drawer,
    },
    emojiPaper: {
      padding: 5,
      borderRadius: borderRadiusSize,

      [theme.breakpoints.up('md')]: {
        padding: 10,
      },

      '& .emoji-mart': {
        width: '300px !important',
        display: 'block',
        fontSize: 14,
      },
      '& .emoji-mart-search': {
        '@media(min-height: 600px) and (min-width: 600px)': {
          marginBottom: 10,
        },
      },
      '& .emoji-mart-scroll': {
        height: 200,

        [theme.breakpoints.up('sm')]: {
          height: 230,
        },
        '@media(max-height: 599px)': {
          height: 135,
        },
      },
      '& .emoji-mart-anchor': {
        '@media(max-height: 599px)': {
          padding: '6px 4px 4px',
        },
      },
      '& .emoji-mart-category-list': {
        '& *:focus': {
          outline: 0,
        },
      },
    },
  });
