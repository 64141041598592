import React from 'react';
import { useDebounce } from 'react-use';

import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { Search } from './Search';
import { SEARCHING_DELAY } from './constants';

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  type?: string;
  queueId?: string;
  className?: string;
  setGlobalFilter: (...args: any) => void;
}

export function QueuedSearch({
  setGlobalFilter,
  type,
  queueId,
  ...props
}: Props) {
  const [val, setVal] = React.useState('');

  useDebounce(
    () => {
      let data: Record<string, any> = {};
      if (val) {
        data = { search: val, type, queueId };
      } else {
        data = {
          type,
          queueId,
        };
      }
      setGlobalFilter(data);
    },
    SEARCHING_DELAY,
    [val],
  );

  return (
    <Search
      handleInputChange={({ currentTarget }) => {
        setVal(currentTarget.value);
      }}
      {...props}
    />
  );
}
