import React, { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import SvgIcon from '@material-ui/core/SvgIcon';

import { Page } from 'app/components/layouts';
import { Loader } from 'app/components/ui/Loader';
import { AccountsList } from 'app/components/entities/accounts';
import { useUserFilterIndex } from 'app/components/entities/accounts/hooks/crud';
import { TYPES, ROLES } from 'app/components/auth/constants';

import { ReactComponent as crownLightIcon } from 'images/icon/FontAwesome5Pro/Light/crown.svg';

export function AccountsPage() {
  const { t } = useTranslation();

  const [indexQuery, setIndexQuery] = useState({
    limit: 10,
    page: 1,
    sort: [`createdAt,DESC`],
    ...RequestQueryBuilder.create({
      search: {
        type: { $eq: TYPES.PAID },
        'filterRoles.name': {
          $in: [ROLES.ACCOUNT_HOLDER_PRIMARY],
        },
      },
    }).queryObject,
  });

  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageCount: 1,
  });

  const { data: users = data, isLoading } = useUserFilterIndex(indexQuery, {
    refetchOnMount: true,
  });

  useEffect(() => {
    if (users && users !== data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setData(users);
    }
  }, [users, data]);

  const breadcrumbs = useMemo(
    () => [
      {
        name: t(translations.names.accounts),
        icon: <SvgIcon component={crownLightIcon} viewBox="0 80 640 512" />,
        iconTo: '/',
      },
    ],
    [t],
  );

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{t(translations.metaInfo.accountsPage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.accountsPage.description)}
        />
      </Helmet>
      <Loader loading={isLoading} fullPage={false} />
      <AccountsList
        fetchData={setIndexQuery}
        indexQuery={indexQuery}
        data={users.data}
        total={users.total}
        page={users.page}
        pageCount={users.pageCount}
      />
    </Page>
  );
}
