export const SETTINGS_CONFIG = {
  fields: {
    allowLogin: {
      isShown: true,
      withTooltip: true,
      key: 'allowLogin',
      className: 'table-row-inner',
    },
    allowToCreateInstalls: {
      isShown: true,
      withTooltip: true,
      key: 'allowToCreateInstalls',
      className: 'table-row-inner',
    },
    prefixTimeplateStructuralTag: {
      isShown: true,
      withTooltip: true,
      key: 'prefixTimeplateStructuralTag',
      className: 'table-row-inner',
    },
  },
};

export const SETTINGS_CONFIG_PROFILE = {
  fields: {
    allowLogin: {
      isShown: true,
      withTooltip: true,
      key: 'allowLogin',
      className: 'table-row-inner',
    },
  },
};
