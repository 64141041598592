import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorPrimary,
  textSecondary,
  borderRadiusSize,
  bgColorLightGrey2,
  borderPrimaryColor,
  boxShadow,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    panelPaper: {
      flex: 1,
      border: `1px solid ${borderPrimaryColor}`,
      ...boxShadow,

      [theme.breakpoints.up('sm')]: {
        width: 680,
        maxWidth: '100%',
        margin: '0 auto',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        minHeight: 450,
        width: 1210,
        margin: 0,
      },
    },
    panelHeader: {
      backgroundColor: bgColorLightGrey2,
      color: colorPrimary,
      fontSize: 16,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      padding: '14px 16px',
      borderTopLeftRadius: borderRadiusSize,
      borderTopRightRadius: borderRadiusSize,

      [theme.breakpoints.between('sm', 'sm')]: {
        width: 680,
        maxWidth: '100%',
        margin: '0 auto',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        padding: '16px 24px',
        borderTopRightRadius: 0,
      },
    },
    previewHeader: {
      color: textSecondary,
      justifyContent: 'space-between',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      [theme.breakpoints.up('md')]: {
        borderTopRightRadius: borderRadiusSize,
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        fontSize: 20,
        padding: '0 6px',
        marginBottom: 16,
      },
    },
    contentBlock: {
      width: '100%',
      padding: '48px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '24px',
      },
    },
  });
