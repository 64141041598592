export const TaxCountriesOptions = [
  {
    id: 'au_abn__AU',
    name: 'Australia',
    countryCode: 'AU',
    originalId: 'au_abn',
  },
  {
    id: 'au_arn__AU',
    name: 'Australia',
    countryCode: 'AU',
    originalId: 'au_arn',
  },
  {
    id: 'eu_vat__AT',
    name: 'Austria',
    countryCode: 'AT',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__BE',
    name: 'Belgium',
    countryCode: 'BE',
    originalId: 'eu_vat',
  },
  {
    id: 'br_cnpj__BR',
    name: 'Brazil',
    countryCode: 'BR',
    originalId: 'br_cnpj',
  },
  {
    id: 'br_cpf__BR',
    name: 'Brazil',
    countryCode: 'BR',
    originalId: 'br_cpf',
  },
  {
    id: 'bg_uic__BG',
    name: 'Bulgaria',
    countryCode: 'BG',
    originalId: 'bg_uic',
  },
  {
    id: 'eu_vat__BG',
    name: 'Bulgaria',
    countryCode: 'BG',
    originalId: 'eu_vat',
  },
  {
    id: 'ca_bn__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_bn',
  },
  {
    id: 'ca_gst_hst__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_gst_hst',
  },
  {
    id: 'ca_pst_bc__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_pst_bc',
  },
  {
    id: 'ca_pst_mb__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_pst_mb',
  },
  {
    id: 'ca_pst_sk__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_pst_sk',
  },
  {
    id: 'ca_qst__CA',
    name: 'Canada',
    countryCode: 'CA',
    originalId: 'ca_qst',
  },
  {
    id: 'cl_tin__CL',
    name: 'Chile',
    countryCode: 'CL',
    originalId: 'cl_tin',
  },
  {
    id: 'eu_vat__HR',
    name: 'Croatia',
    countryCode: 'HR',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__CY',
    name: 'Cyprus',
    countryCode: 'CY',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__CZ',
    name: 'Czech Republic',
    countryCode: 'CZ',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__DK',
    name: 'Denmark',
    countryCode: 'DK',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__EE',
    name: 'Estonia',
    countryCode: 'EE',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_oss_vat__EU',
    name: 'European Union',
    countryCode: 'EU',
    originalId: 'eu_oss_vat',
  },
  {
    id: 'eu_vat__FI',
    name: 'Finland',
    countryCode: 'FI',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__FR',
    name: 'France',
    countryCode: 'FR',
    originalId: 'eu_vat',
  },
  {
    id: 'ge_vat__GE',
    name: 'Georgia',
    countryCode: 'GE',
    originalId: 'ge_vat',
  },
  {
    id: 'eu_vat__DE',
    name: 'Germany',
    countryCode: 'DE',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__GR',
    name: 'Greece',
    countryCode: 'GR',
    originalId: 'eu_vat',
  },
  {
    id: 'hk_br__HK',
    name: 'Hong Kong',
    countryCode: 'HK',
    originalId: 'hk_br',
  },
  {
    id: 'hu_tin__HU',
    name: 'Hungary',
    countryCode: 'HU',
    originalId: 'hu_tin',
  },
  {
    id: 'eu_vat__HU',
    name: 'Hungary',
    countryCode: 'HU',
    originalId: 'eu_vat',
  },
  {
    id: 'is_vat__IS',
    name: 'Iceland',
    countryCode: 'IS',
    originalId: 'is_vat',
  },
  {
    id: 'in_gst__IN',
    name: 'India',
    countryCode: 'IN',
    originalId: 'in_gst',
  },
  {
    id: 'id_npwp__ID',
    name: 'Indonesia',
    countryCode: 'ID',
    originalId: 'id_npwp',
  },
  {
    id: 'eu_vat__IE',
    name: 'Ireland',
    countryCode: 'IE',
    originalId: 'eu_vat',
  },
  {
    id: 'il_vat__IL',
    name: 'Israel',
    countryCode: 'IL',
    originalId: 'il_vat',
  },
  {
    id: 'eu_vat__IT',
    name: 'Italy',
    countryCode: 'IT',
    originalId: 'eu_vat',
  },
  {
    id: 'jp_cn__JP',
    name: 'Japan',
    countryCode: 'JP',
    originalId: 'jp_cn',
  },
  {
    id: 'jp_rn__JP',
    name: 'Japan',
    countryCode: 'JP',
    originalId: 'jp_rn',
  },
  {
    id: 'eu_vat__LV',
    name: 'Latvia',
    countryCode: 'LV',
    originalId: 'eu_vat',
  },
  {
    id: 'li_uid__LI',
    name: 'Liechtenstein',
    countryCode: 'LI',
    originalId: 'li_uid',
  },
  {
    id: 'eu_vat__LT',
    name: 'Lithuania',
    countryCode: 'LT',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__LU',
    name: 'Luxembourg',
    countryCode: 'LU',
    originalId: 'eu_vat',
  },
  {
    id: 'my_frp__MY',
    name: 'Malaysia',
    countryCode: 'MY',
    originalId: 'my_frp',
  },
  {
    id: 'my_itn__MY',
    name: 'Malaysia',
    countryCode: 'MY',
    originalId: 'my_itn',
  },
  {
    id: 'my_sst__MY',
    name: 'Malaysia',
    countryCode: 'MY',
    originalId: 'my_sst',
  },
  {
    id: 'eu_vat__MT',
    name: 'Malta',
    countryCode: 'MT',
    originalId: 'eu_vat',
  },
  {
    id: 'mx_rfc__MX',
    name: 'Mexico',
    countryCode: 'MX',
    originalId: 'mx_rfc',
  },
  {
    id: 'eu_vat__NL',
    name: 'Netherlands',
    countryCode: 'NL',
    originalId: 'eu_vat',
  },
  {
    id: 'nz_gst__NZ',
    name: 'New Zealand',
    countryCode: 'NZ',
    originalId: 'nz_gst',
  },
  {
    id: 'no_vat__NO',
    name: 'Norway',
    countryCode: 'NO',
    originalId: 'no_vat',
  },
  {
    id: 'eu_vat__PL',
    name: 'Poland',
    countryCode: 'PL',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__PT',
    name: 'Portugal',
    countryCode: 'PT',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__RO',
    name: 'Romania',
    countryCode: 'RO',
    originalId: 'eu_vat',
  },
  {
    id: 'ru_inn__RU',
    name: 'Russia',
    countryCode: 'RU',
    originalId: 'ru_inn',
  },
  {
    id: 'ru_kpp__RU',
    name: 'Russia',
    countryCode: 'RU',
    originalId: 'ru_kpp',
  },
  {
    id: 'sa_vat__SA',
    name: 'Saudi Arabia',
    countryCode: 'SA',
    originalId: 'sa_vat',
  },
  {
    id: 'sg_gst__SG',
    name: 'Singapore',
    countryCode: 'SG',
    originalId: 'sg_gst',
  },
  {
    id: 'sg_uen__SG',
    name: 'Singapore',
    countryCode: 'SG',
    originalId: 'sg_uen',
  },
  {
    id: 'eu_vat__SK',
    name: 'Slovakia',
    countryCode: 'SK',
    originalId: 'eu_vat',
  },
  {
    id: 'si_tin__SI',
    name: 'Slovenia',
    countryCode: 'SI',
    originalId: 'si_tin',
  },
  {
    id: 'eu_vat__SI',
    name: 'Slovenia',
    countryCode: 'SI',
    originalId: 'eu_vat',
  },
  {
    id: 'za_vat__ZA',
    name: 'South Africa',
    countryCode: 'ZA',
    originalId: 'za_vat',
  },
  {
    id: 'kr_brn__KR',
    name: 'South Korea',
    countryCode: 'KR',
    originalId: 'kr_brn',
  },
  {
    id: 'es_cif__ES',
    name: 'Spain',
    countryCode: 'ES',
    originalId: 'es_cif',
  },
  {
    id: 'eu_vat__ES',
    name: 'Spain',
    countryCode: 'ES',
    originalId: 'eu_vat',
  },
  {
    id: 'eu_vat__SE',
    name: 'Sweden',
    countryCode: 'SE',
    originalId: 'eu_vat',
  },
  {
    id: 'ch_vat__CH',
    name: 'Switzerland',
    countryCode: 'CH',
    originalId: 'ch_vat',
  },
  {
    id: 'tw_vat__TW',
    name: 'Taiwan',
    countryCode: 'TW',
    originalId: 'tw_vat',
  },
  {
    id: 'th_vat__TH',
    name: 'Thailand',
    countryCode: 'TH',
    originalId: 'th_vat',
  },
  {
    id: 'ua_vat__UA',
    name: 'Ukraine',
    countryCode: 'UA',
    originalId: 'ua_vat',
  },
  {
    id: 'ae_trn__AE',
    name: 'United Arab Emirates',
    countryCode: 'AE',
    originalId: 'ae_trn',
  },
  {
    id: 'gb_vat__GB',
    name: 'United Kingdom',
    countryCode: 'GB',
    originalId: 'gb_vat',
  },
  {
    id: 'eu_vat__GB',
    name: 'United Kingdom',
    countryCode: 'GB',
    originalId: 'eu_vat',
  },
  {
    id: 'us_ein__US',
    name: 'United States',
    countryCode: 'US',
    originalId: 'us_ein',
  },
];

export const SETTINGS_CONFIG = {
  fields: {
    autoApprovalsType: {
      isShown: true,
      withTooltip: true,
      key: 'autoApprovalsType',
      className: '',
    },
    autoPay: {
      isShown: true,
      withTooltip: true,
      key: 'autoPay',
      className: 'table-row-inner',
    },
    autoPayProcessedUser: {
      isShown: true,
      withTooltip: true,
      key: 'autoPayProcessedUser',
      className: 'table-row-inner',
    },
    autoPayEmails: {
      isShown: true,
      withTooltip: true,
      key: 'autoPayEmails',
      className: '',
    },
    domainRestrict: {
      isShown: true,
      withTooltip: true,
      key: 'domainRestrict',
      className: 'table-row-inner',
    },
    domainRestrictType: {
      isShown: true,
      withTooltip: false,
      key: 'domainRestrictType',
      className: '',
    },
    passwordProtect: {
      isShown: true,
      withTooltip: true,
      key: 'passwordProtect',
      className: 'table-row-inner',
    },
    passwordProtectType: {
      isShown: true,
      withTooltip: false,
      key: 'passwordProtectType',
      className: '',
    },
  },
};

export const SETTINGS_INVITES_CONFIG = {
  fields: {
    autoApprovalsType: {
      isShown: true,
      withTooltip: true,
      key: 'autoApprovalsType',
      className: '',
    },
    domainRestrict: {
      isShown: true,
      withTooltip: true,
      key: 'domainRestrict',
      className: '',
    },
    passwordProtect: {
      isShown: true,
      withTooltip: true,
      key: 'passwordProtect',
      className: '',
    },
  },
};
export const SETTINGS_BILLING_CONFIG = {
  fields: {
    autoPay: {
      isShown: true,
      withTooltip: true,
      key: 'autoPay',
      className: 'table-row-inner',
    },
    autoPayProcessedUser: {
      isShown: true,
      withTooltip: true,
      key: 'autoPayProcessedUser',
      className: 'table-row-inner',
    },
    // autoPayEmails: {
    //   isShown: true,
    //   withTooltip: true,
    //   key: 'autoPayEmails',
    //   className: '',
    // },
  },
};

export const FREQUENCY_OPTIONS = [
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'weekly',
    name: 'Weekly',
  },
];

export const RESTRICTIONS_TYPE_OPTIONS = [
  {
    id: 'per_invite',
    name: 'Per Invite',
  },
  {
    id: 'all_invites',
    name: 'All Invitee',
  },
];

export const TIME_OPTIONS = [
  { id: '0', name: '12:00am' },
  { id: '1', name: '1:00am' },
  { id: '2', name: '2:00am' },
  { id: '3', name: '3:00am' },
  { id: '4', name: '4:00am' },
  { id: '5', name: '5:00am' },
  { id: '6', name: '6:00am' },
  { id: '7', name: '7:00am' },
  { id: '8', name: '8:00am' },
  { id: '9', name: '9:00am' },
  { id: '10', name: '10:00am' },
  { id: '11', name: '11:00am' },
  { id: '12', name: '12:00pm' },
  { id: '13', name: '1:00pm' },
  { id: '14', name: '2:00pm' },
  { id: '15', name: '3:00pm' },
  { id: '16', name: '4:00pm' },
  { id: '17', name: '5:00pm' },
  { id: '18', name: '6:00pm' },
  { id: '19', name: '7:00pm' },
  { id: '20', name: '8:00pm' },
  { id: '21', name: '9:00pm' },
  { id: '22', name: '10:00pm' },
  { id: '23', name: '11:00pm' },
];

export const DAY_OPTIONS = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 7, name: 'Sunday' },
];

export const GROUPED_FIELDS_MAP = {
  autoPayProcessedUser: [
    'autoPayProcessedUser_frequency',
    'autoPayProcessedUser_time',
    'autoPayProcessedUser_day',
  ],
  domainRestrictType: [
    'domainRestrictType_type',
    { name: 'domainRestrictType_domain', isText: true },
  ],
  passwordProtectType: [
    'passwordProtectType_type',
    { name: 'passwordProtectType_password', isText: true },
  ],
};

export enum INVOICE_STATUSES {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  DELETED = 'deleted',
  UNCOLLECTIBLE = 'uncollectible',
  SCHEDULED = 'scheduled',
}
