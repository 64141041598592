import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';

import MuiSwitch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export interface Props {
  name: string;
  label?: React.ReactNode;
  customValues?: Array<{ checked: boolean; value: string }>;
  customChange?: (dada: any) => void;
  margin?: 'dense' | 'none' | 'normal';
  className?: string;
  fullWidth?: boolean;
  customValue?: boolean;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'default';
}

export default function Switch({
  name,
  label,
  customValues,
  customChange,
  margin = 'none',
  className,
  fullWidth = true,
  disabled = false,
  customValue = false,
  ...props
}: Props) {
  const [{ value }, , { setValue: setFieldValue, setTouched }] = useField<
    boolean | string
  >(name);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue: boolean | string = event.target.checked;
      if (customValues) {
        const item = customValues.find(i => i.checked === newValue);
        if (item) {
          newValue = item.value;
        }
      }
      if (customChange) {
        customChange(newValue);
      }
      setFieldValue(newValue);
      setTouched(true);
    },
    [customChange, customValues, setFieldValue, setTouched, customValue],
  );

  const checked = useMemo(() => {
    if (!customValues) {
      return Boolean(value);
    }
    const item = customValues.find(i => i.value === value);
    if (item) {
      return item.checked;
    }
    return false;
  }, [customValues, value]);

  return (
    <FormControl className={className} margin={margin} fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MuiSwitch
            color="secondary"
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            {...props}
          />
        }
        label={label}
      />
    </FormControl>
  );
}
