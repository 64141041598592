import { ApiError, apiClient } from 'app/config/axios';

import objectToFormData from 'utils/objectToFormData';
import { SignatureUserField } from '../interfaces';
import { PaginatedResponse } from 'app/interfaces';
import {
  QueryOptions,
  UseQueryResult,
  useQuery,
  QueryObserverOptions,
} from 'react-query';

const endpoint = '/signature-user-fields';

export const signatureUserFieldUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, SignatureUserField>(
    `${endpoint}/${id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const signatureUserFieldsIndex = queryData =>
  apiClient.get<any, PaginatedResponse<SignatureUserField>>(endpoint, {
    params: queryData,
  });

export const useSignatureUserFieldsIndex: <
  T = PaginatedResponse<SignatureUserField>,
>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useSignatureUserFieldsIndex', any], any>(
    ['useSignatureUserFieldsIndex', queryData],
    () => signatureUserFieldsIndex(queryData),
    options,
  );

export const useSignatureUserFieldsFilterIndex = (
  queryData,
  options?: QueryObserverOptions<
    PaginatedResponse<SignatureUserField>,
    ApiError
  >,
): {
  data: PaginatedResponse<SignatureUserField> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useSignatureUserFieldsIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};
