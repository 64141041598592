import * as yup from 'yup';

export enum ENTITY_TYPES {
  COMPANY = 'company',
  LOCATION = 'location',
  SIGNATURE = 'signature',
  USER = 'user',
  TIMEPLATE = 'timeplate',
}

export enum LINK_TYPES {
  URL = 1,
  PHONE,
  EMAIL,
  OTHER,
  NONE,
}

export enum FONTS {
  ARIAL = 1,
  HELVETICA,
  TIMES_NEW_ROMAN,
  TAHOMA,
  VERDANA,
  TREBUCHET,
  COURIER,
  LUCIDIA,
  GEORGIA,
  PALATINO,
  IMPACT,
  ARIAL_BLACK,
}

// Do not forget synchronize with API
export const FONTS_MAPPING = {
  [FONTS.ARIAL]: 'Arial, Helvetica, sans-serif',
  [FONTS.HELVETICA]: 'Helvetica, Arial, sans-serif',
  [FONTS.TIMES_NEW_ROMAN]: "'Times New Roman', Times, serif",
  [FONTS.TAHOMA]: 'Tahoma, Geneva, sans-serif',
  [FONTS.VERDANA]: 'Verdana, Geneva, sans-serif',
  [FONTS.TREBUCHET]: "'Trebuchet MS', Helvetica, sans-serif",
  [FONTS.COURIER]: 'Courier, Courier New, monospace',
  [FONTS.LUCIDIA]:
    "'Lucida Sans Typewriter', 'Lucida Console', Monaco, 'Bitstream Vera Sans Mono', monospace",
  [FONTS.GEORGIA]: 'Georgia, serif',
  [FONTS.PALATINO]: "Palatino, 'Palatino Linotype', 'Book Antiqua', serif",
  [FONTS.IMPACT]: 'Impact, Charcoal, sans-serif',
  [FONTS.ARIAL_BLACK]: "'Arial Black', Gadget, sans-serif",
};

export enum CUSTOM_ICON_TYPES {
  CUSTOM1 = 'custom 1',
  CUSTOM2 = 'custom 2',
  CUSTOM3 = 'custom 3',
  CUSTOM4 = 'custom 4',
  CUSTOM5 = 'custom 5',
  CUSTOM6 = 'custom 6',
  CUSTOM7 = 'custom 7',
  CUSTOM8 = 'custom 8',
  CUSTOM9 = 'custom 9',
}

export const FONT_SIZES = [8, 9, 10, 11, 12, 13, 14];

export const ICON_SIZES = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30];

export const configMap = {
  DISABLED: 'none',
  REQUIRED: 'required',
  TEXTAREA: 'textarea',
};

export const isRequired = validation => validation === configMap.REQUIRED;
export const isTextarea = validation => validation === configMap.TEXTAREA;

export const getLinkRegexp = (linkType: number): RegExp => {
  switch (+linkType) {
    case LINK_TYPES.URL:
      return /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    case LINK_TYPES.EMAIL:
      return /^([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})$/gi;
    case LINK_TYPES.PHONE:
      return /^(\+?[0-9]+)$/g;
    case LINK_TYPES.OTHER:
    case LINK_TYPES.NONE:
      return /./g;
  }
  return /./g;
};

export const hasUrlSchemaRegexp = /^https?:\/\//;

export const getUrlValidation = (
  config,
  linkType,
  requiredMsg,
  incorrectLinkMsg,
) => {
  let validation = yup.string().nullable();

  if (isRequired(config)) validation = validation.required(requiredMsg);

  if (+linkType)
    validation = validation.matches(getLinkRegexp(linkType), incorrectLinkMsg);

  return validation;
};

export const getStringValidation = (isRequired = false, requiredMsg = '') => {
  let validation = yup.string().nullable();

  if (isRequired) validation = validation.required(requiredMsg);

  return validation;
};

export const getNumberValidation = (
  isRequired = false,
  requiredMsg = '',
  positiveNumberMsg = '',
) => {
  let validation = yup
    .number()
    .nullable()
    .typeError(positiveNumberMsg)
    .positive(positiveNumberMsg)
    .integer(positiveNumberMsg);

  if (isRequired) validation = validation.required(requiredMsg);

  return validation;
};

export const getFieldValidation = (id, validation) => ({
  [`field${id}`]: yup.object().shape(validation),
});

export const onCopyTag = alias => () =>
  navigator.clipboard.writeText(`{{${alias}}}`);

export const copyButtonAlias = alias => `Tag: {{${alias}}}`;
