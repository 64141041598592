import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectConfigMenu } from 'app/components/auth/store/selectors';
import {
  storeMenuConfig,
  getMenuConfig,
} from 'app/components/auth/utils/configStorage';
import { actions } from 'app/components/auth/store/slice';

export const useConfigMenu = () => {
  const dispatch = useDispatch();
  const menuConfig = useSelector(selectConfigMenu);
  const [isReady, setIsReady] = useState(false);

  const setMenuConfig = useCallback(
    newConfig => {
      dispatch(actions.setMenuConfig({ ...menuConfig, ...newConfig }));
      storeMenuConfig({ ...menuConfig, ...newConfig });
    },
    [menuConfig, dispatch],
  );

  useEffect(() => {
    if (!isReady) {
      if (!menuConfig || Object.keys(menuConfig).length <= 1) {
        const restoreMenuConfig = async () => {
          try {
            const menuConfigFromStorage = await getMenuConfig();

            if (menuConfigFromStorage) {
              dispatch(actions.setMenuConfig(menuConfigFromStorage));
            }
          } catch (e) {
            // ¯\_(ツ)_/¯
          } finally {
            setIsReady(true);
          }
        };
        restoreMenuConfig();
      } else {
        setIsReady(true);
      }
    }
  }, [menuConfig, isReady, dispatch]);

  return {
    setMenuConfig,
    menuConfig,
  };
};
