/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useField } from 'formik';
import cx from 'classnames';
import Picker from '@emoji-mart/react';
import { useDebounce } from 'react-use';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import SavingContext from 'app/components/layouts/components/SavingContext';
import { stopPropagation } from 'app/components/onboarding/components/utils';

import { INPUT_DEBOUNCE_DELAY } from '../../constants';

import iconEmoji from 'images/img-smiling-face-with-sunglasses.png';

import styles from '../../styles';

export interface Props {
  name: string;
  endIcon?: React.ReactNode;
  label?: React.ReactNode;
  onSuccessChange?: (
    required: boolean | undefined,
    data: string,
    setError?: (value: any) => void,
  ) => void;
  required: boolean;
  disabled: boolean;
  fullWidth?: boolean;
  margin?: 'dense' | 'none' | 'normal';
  withDebounce?: boolean;
  lastUpdated?: number;
  tabIndex?: number;
}

const Image = Quill.import('formats/image');
Image.className = 'emoji';
Quill.register(Image, true);

const Block = Quill.import('blots/block');
Block.tagName = 'SPAN';
Quill.register(Block, true);

const modules = { toolbar: false, keyboard: { bindings: { tab: false } } };

const useStyles = makeStyles(styles);

const TextEmojiEditor = ({
  onSuccessChange,
  endIcon,
  label,
  required,
  name,
  disabled,
  margin = 'normal',
  fullWidth = true,
  withDebounce = true,
  lastUpdated,
  tabIndex = 0,
}: Props) => {
  const classes = useStyles();

  const [
    { value, onChange: onChangeFormik },
    { touched, error },
    { setError },
  ] = useField<string>(name);
  const quillRef = useRef(null);
  const previousSavedDateRef = useRef({ lastUpdated, value });
  const [textValue, setTextValue] = useState(value);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [textValueChanged, setTextValueChanged] = useState(false);

  useEffect(() => {
    if (
      lastUpdated &&
      previousSavedDateRef.current.lastUpdated !== lastUpdated
    ) {
      setTextValue(previousSavedDateRef.current.value);
      previousSavedDateRef.current.lastUpdated = lastUpdated;
    }
  }, [lastUpdated]);

  const { setIsSaving } = useContext(SavingContext);

  const isError = Boolean(touched && error);

  const handleClick = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const dismissPicker = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleChange = e => {
    // @ts-ignore
    setIsSaving(true);
    setTextValue(e);
    setTextValueChanged(true);
    onChangeFormik(e);
    if (onSuccessChange && !withDebounce) {
      onSuccessChange(required, e, setError);
    }
  };

  useEffect(() => {
    return () => setIsSaving(false);
  }, []);

  useEffect(() => {
    if (isError) setIsSaving(false);
  }, [isError, setIsSaving]);

  const customSubmit = () => {
    if (withDebounce && onSuccessChange && textValueChanged) {
      onSuccessChange(required, textValue, setError);
    }
  };

  useDebounce(customSubmit, INPUT_DEBOUNCE_DELAY, [textValue]);

  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      {label && (
        <InputLabel
          className={classes.quillEditorLabel}
          disableAnimation
          shrink
          variant="outlined"
        >
          {label}
        </InputLabel>
      )}
      <div
        className={classes.quillEditorWrap}
        onClick={stopPropagation}
        onFocus={stopPropagation}
      >
        <ReactQuill
          readOnly={disabled}
          key={previousSavedDateRef.current.lastUpdated}
          onChange={handleChange}
          modules={modules}
          ref={quillRef}
          defaultValue={textValue}
          className={cx(
            classes.quillEditor,
            'native-custom-scrollbar grey-scrollbar',
          )}
          tabIndex={tabIndex}
        />
        {!disabled && (
          <div
            className={cx(classes.quillEditorIcons, classes.textFieldIconBlock)}
          >
            {endIcon}
            <IconButton
              className={classes.quillEmojiButton}
              onClick={handleClick}
              tabIndex={-1}
            >
              <img src={iconEmoji} alt="" />
            </IconButton>
            <Popper
              open={!!anchorEl}
              transition
              placement="bottom-start"
              anchorEl={anchorEl}
              className={classes.emojiPopper}
            >
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={dismissPicker}
              >
                <div>
                  <Picker
                    showPreview={false}
                    showSkinTones={false}
                    set="apple"
                    onEmojiSelect={(emoji: { unified: any }) => {
                      // @ts-ignore
                      const range = quillRef.current.editor.getSelection(true);
                      // @ts-ignore
                      const cursorPosition = (
                        quillRef as any
                      ).current.editor.getSelection().index;
                      if (range) {
                        // @ts-ignore
                        quillRef.current.editor.deleteText(
                          range.index,
                          range.length,
                        );
                      }
                      // @ts-ignore
                      quillRef.current.editor.insertEmbed(
                        range ? range.index : 0,
                        'image',
                        `https://cdn.jsdelivr.net/npm/emoji-datasource-apple@latest/img/apple/64/${emoji.unified}.png`,
                      );
                      // @ts-ignore
                      quillRef.current.editor.setSelection(cursorPosition + 1);
                      dismissPicker();
                    }}
                  />
                </div>
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </div>
    </FormControl>
  );
};

export default memo(TextEmojiEditor);
