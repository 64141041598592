import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  Token,
  AuthState,
  tableConfig,
  menuConfig,
} from 'app/components/auth/interfaces';
import { Users } from 'app/components/users/types';

export const initialState: AuthState = {
  user: undefined,
  impersonateUser: undefined,
  token: undefined,
  tableConfig: { default: { pageSize: 10 } },
  menuConfig: { default: { drawerMinimize: false } },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<Token>) {
      state.token = action.payload;
    },
    setImpersonateUser(state, action: PayloadAction<Users>) {
      state.impersonateUser = action.payload;
    },
    setUser(state, action: PayloadAction<Users>) {
      const { roles, mainRoleId } = action.payload;
      const { name: mainRole } = roles.find(({ id }) => id === mainRoleId);

      state.user = { ...action.payload, mainRole };
    },
    setTableConfig(state, action: PayloadAction<tableConfig>) {
      state.tableConfig = action.payload;
    },
    setMenuConfig(state, action: PayloadAction<menuConfig>) {
      state.menuConfig = action.payload;
    },
    clear(state) {
      state.user = initialState.user;
      state.impersonateUser = initialState.impersonateUser;
      state.token = initialState.token;
    },
  },
});

export const { actions, reducer, name: sliceKey } = authSlice;
