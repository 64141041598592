import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '585px',
      justifyContent: 'space-between',
    },
    title: {
      marginBottom: '16px',
    },
    formContainer: { marginTop: '30px' },
    formRaw: { display: 'flex', justifyContent: 'space-between' },
    textFieldBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(65% - 8px)',
      },
    },
    textField: { marginRight: '8px' },
    footerText: { marginTop: '30px' },
    questionIcon: { marginTop: '8px', fontSize: '20px' },
    disabledIcon: { opacity: '0.5' },
  });
