import React, { memo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';

import { CELL_WIDTH_DEFAULT } from '../constants';
import { DataTableRow } from './DataTableRow';
import { DataRowOption } from '../types';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  rows: DataRowOption[];
  className?: string;
  withForm?: boolean;
  headRemove?: boolean;
}

function DataTable({ rows, className, withForm, headRemove = false }: Props) {
  const classes = useStyles();

  let getCellWidthSum = 0;

  if (!getCellWidthSum) {
    getCellWidthSum = rows?.[0]?.cells?.reduce(
      (sum, { width = CELL_WIDTH_DEFAULT }) => sum + width,
      0,
    );
  }

  return (
    <div className={cx(classes.tableBlock, classes.dataTableBlock, className)}>
      <Table className={classes.table} component="div">
        {!headRemove && (
          <Hidden smDown>
            <TableHead
              className={classes.tableHead}
              component="div"
              style={{ minWidth: getCellWidthSum }}
            >
              <DataTableRow
                isHead
                classNameRow={classes.tableHeadRow}
                classNameCell={classes.tableHeadCell}
                cells={rows?.[0]?.cells}
              />
            </TableHead>
          </Hidden>
        )}
        <TableBody
          className={classes.tableBody}
          component="div"
          style={{ minWidth: getCellWidthSum }}
        >
          {rows?.map(
            ({ rowClassName, cells, id, initialValues, validationSchema }) => (
              <DataTableRow
                classNameRow={cx(classes.tableBodyRow, rowClassName)}
                classNameCell={classes.tableBodyCell}
                key={id}
                cells={cells}
                initialValues={initialValues}
                validationSchema={validationSchema}
                withForm={withForm}
                headRemove={headRemove}
              />
            ),
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default memo(DataTable);
