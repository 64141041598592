import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Schema } from 'yup';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';
import { useLocation } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  Text,
  TextEditor,
  Image,
  Users as UsersField,
} from 'app/components/forms';
import { Paper } from 'app/components/layouts';
import { useTagFilterIndex } from 'app/components/tags/hooks/crud';
import { translations } from 'locales/i18n';
import AutocompleteField from 'app/components/ui/Autocomplete';
import { Users } from 'app/components/users';

import { SignatureCreate, SignatureEntity } from '../interfaces';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  defaultValues: SignatureEntity;
  schema: Schema;
  onSubmit: (data: SignatureCreate) => void;
  onCancel: () => void;
  users?: Users[];
}

const SignatureEdit = ({
  defaultValues,
  schema,
  onSubmit,
  users = [],
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isGhost = location.pathname.includes('ghost');
  const isTrial = location.pathname.includes('trial');

  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [search, setSearch] = useState('');

  const tagsQuery = RequestQueryBuilder.create();
  if (search) {
    tagsQuery.setFilter({
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
      value: search,
    });
  }
  tagsQuery.setFilter({
    field: 'type',
    operator: CondOperator.EQUALS,
    value: 'template',
  });
  const { data: tagsResponse } = useTagFilterIndex({
    builder: tagsQuery,
  });

  useEffect(() => {
    if (search) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setDropdownOptions(tagsResponse);
    }
  }, [tagsResponse, setSearch, setDropdownOptions, search]);

  const handleInputChange = useCallback(search => {
    setSearch(search);
  }, []);

  const handleFieldChange = useCallback(
    field => (required, value) => {
      const checkRequired = !required || value;

      if (checkRequired) {
        onSubmit({ [field]: value });
      }
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Paper
            header={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
              >
                <Typography variant="h6">
                  {t(
                    translations.form.titles[
                      isGhost
                        ? 'editGhostSignature'
                        : isTrial
                        ? 'editTrialSignature'
                        : 'editSignature'
                    ],
                  )}
                </Typography>
              </Box>
            }
            elevation={1}
            borderRadius="large"
            size="large"
            contentIndented
            backLink={`/ghost/signatures`}
          >
            <Grid container spacing={respUpMd ? 6 : 0}>
              <Grid
                className={classes.gridItemSeparatedRight}
                item
                xs={12}
                md={6}
              >
                <Text
                  label={t(translations.form.labels.name)}
                  name="name"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  required
                  onSuccessChange={handleFieldChange('name')}
                />
                <UsersField
                  label={t(translations.form.labels.defaultUser)}
                  name="userId"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  required
                  onSuccessChange={handleFieldChange('userId')}
                  users={users}
                  disabled={!isGhost}
                />
                <Field name="description">
                  {({ field }) => (
                    <TextEditor
                      label={t(translations.form.labels.description)}
                      value={field.value || ''}
                      onChange={field.onChange(field.name)}
                      onSuccessChange={handleFieldChange('description')}
                      margin="normal"
                      wihDebounce
                    />
                  )}
                </Field>
                <AutocompleteField
                  multiple
                  value={defaultValues.tags}
                  freeSolo
                  disableClearable
                  name="tags"
                  label={t(translations.form.labels.tags)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  filterOptions={(options, { inputValue }) => {
                    return [
                      ...options,
                      inputValue
                        ? {
                            id: inputValue,
                            name: t(translations.form.values.addOption, {
                              inputValue: inputValue,
                            }),
                          }
                        : null,
                    ].filter(Boolean);
                  }}
                  onInputChange={handleInputChange}
                  options={dropdownOptions}
                  onSuccessChange={handleFieldChange('tags')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" height="100%">
                  <Image
                    className={classes.signatureImage}
                    uploadAreaText={t(translations.form.labels.uploadImage)}
                    name="file"
                    onSuccessChange={handleFieldChange('file')}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default SignatureEdit;
