/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useCallback, useRef, useState } from 'react';
import { useField } from 'formik';
import SVG from 'react-inlinesvg';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import ClearIcon from '@material-ui/icons/Clear';

import { file, fileSvg } from 'app/components/files';
import { Text } from 'app/components/forms';

import { ReactComponent as cloudUploadLightIcon } from 'images/icon/FontAwesome5Pro/Light/cloud-upload.svg';
import { ReactComponent as drawCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/draw-circle.svg';

import styles from 'app/components/forms/styles/image';

const useStyles = makeStyles(styles);

export interface Props extends OutlinedTextFieldProps {
  name: string;
  onSuccessChange?: (data, removeLoader?: boolean) => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  onScale?: (width, height) => void;
  isCircle?: boolean;
  heightField?: string;
  widthField?: string;
  withoutClear?: boolean;
  isSvg?: boolean;
  uploadSvg?: boolean;
  uploadPng?: boolean;
  withReupload?: boolean;
  prefix?: string;
  baseColor?: string;
  featureColor?: string;
  readOnly?: boolean;
  maxWidth?: number;
  maxHeight?: number;
}

const TextImageBase = ({
  width = 200,
  height = 200,
  onScale,
  isCircle = false,
  widthField,
  heightField,
  onSuccessChange,
  withoutClear = false,
  isSvg = false,
  uploadSvg = false,
  uploadPng = false,
  prefix = '',
  baseColor,
  featureColor,
  withReupload = false,
  disabled,
  readOnly = false,
  maxHeight,
  maxWidth,
  ...props
}: Props) => {
  const classes = useStyles();
  const [{ value }, , { setTouched }] = useField<string>(props.name);
  const inputRef = useRef(null);
  const [image, setImage] = useState();
  const [error, setError] = useState('');

  const removeFile = () => {
    // @ts-ignore
    inputRef.current.value = '';
    setImage(undefined);
    if (onSuccessChange) onSuccessChange('', false);
  };

  const handleSave = (file, removeLoader = false) => {
    const reader = new FileReader();
    reader.onload = e => {
      const imageLoader = new Image();
      // @ts-ignore
      imageLoader.src = e.target.result;
      imageLoader.onload = () => {
        if (checkSize(imageLoader, file.type)) {
          // @ts-ignore
          setImage(e.target.result);
          if (onSuccessChange) onSuccessChange(file, removeLoader);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const src =
    (value &&
      (isSvg
        ? fileSvg(+value, prefix)
        : file(+value, undefined, undefined, isCircle, prefix))) ||
    image;

  const acceptedFileTypes = [
    uploadPng && 'image/png',
    uploadSvg && 'image/svg+xml',
  ].filter(Boolean);

  const acceptedFileTypeNames = [uploadPng && 'PNG', uploadSvg && 'SVG'].filter(
    Boolean,
  );

  const checkSize = (image, fileType) => {
    if (
      fileType === 'image/png' &&
      ((maxWidth && maxWidth < image.width) ||
        (maxHeight && maxHeight < image.height))
    ) {
      setTouched(true);
      setError(
        [
          maxWidth ? `Max image width ${maxWidth}px` : null,
          maxHeight ? `Max image height ${maxHeight}px` : null,
        ]
          .filter(Boolean)
          .join('; '),
      );
      return false;
    }

    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = [...(e.target.files as FileList)][0];

    if (file && file.name && acceptedFileTypes.includes(file.type)) {
      setError('');
      handleSave(file);
    } else {
      setTouched(true);
      setError(`${acceptedFileTypeNames.join(', ')} icons supported only!`);
    }
  };

  const handleUpload = useCallback(() => {
    const { current } = inputRef;
    // @ts-ignore
    current.click();
  }, []);

  const changeColor = (baseColor, featureColor) => code => {
    let result = code;
    if (!baseColor && !featureColor) return result;

    const colors = [featureColor, baseColor];

    const matches = code.match(/<[^>]+?fill=".*?"/g);
    colors.forEach((color, key) => {
      const match = matches[key];
      if (match && color) {
        result = result.replace(
          new RegExp(match),
          match.replace(/fill=".*?"/, `fill="${color}"`),
        );
      }
    });

    return result;
  };

  const svgIcon = src ? (
    <SVG
      cacheRequests
      className={classes.svgIcon}
      preProcessor={changeColor(baseColor, featureColor)}
      src={src}
      key={`${baseColor}${featureColor}`}
    />
  ) : (
    <Avatar className={classes.imageFieldAvatar}>
      <SvgIcon
        component={drawCircleLightIcon}
        viewBox="0 0 512 512"
        style={{ fontSize: 14 }}
      />
    </Avatar>
  );

  return (
    <>
      <input
        className={classes.imageInputUpload}
        accept={acceptedFileTypes.join(',')}
        type="file"
        ref={inputRef}
        onChange={handleChange}
      />
      <Text
        className={classes.imageField}
        {...props}
        readOnly
        emptyField
        disabled={disabled}
        startIcon={
          <>
            {props.startIcon}
            {value ? (
              <Tooltip
                classes={{
                  popper: classes.imageFieldTooltipPopper,
                  tooltip: classes.imageFieldTooltip,
                }}
                title={
                  <span className={classes.imageFieldTooltipImage}>
                    {isSvg ? svgIcon : <img src={src} alt="" />}
                  </span>
                }
                placement="bottom-start"
                arrow={true}
                enterTouchDelay={300}
                leaveTouchDelay={3000}
                disableFocusListener={disabled}
                disableHoverListener={disabled}
                disableTouchListener={disabled}
              >
                <span className={classes.svgIconBlock}>
                  {isSvg ? svgIcon : <img src={src} alt="" />}
                </span>
              </Tooltip>
            ) : (
              <span className={classes.svgIconBlock}>{svgIcon}</span>
            )}
          </>
        }
        endIcon={
          <>
            {!readOnly &&
              ((value || image) && !withReupload ? (
                !withoutClear && (
                  <IconButton
                    className="text-button text-clear-button"
                    onClick={removeFile}
                    disabled={disabled}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              ) : (
                <IconButton
                  className="text-button"
                  onClick={handleUpload}
                  disabled={disabled}
                  tabIndex={-1}
                >
                  <SvgIcon
                    component={cloudUploadLightIcon}
                    viewBox="0 0 640 512"
                  />
                </IconButton>
              ))}
            {props.endIcon}
          </>
        }
        ref={undefined}
        error={!!error.length}
        helperText={error}
      />
    </>
  );
};

export default memo(TextImageBase);
