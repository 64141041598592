import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { ROLES_TITLE } from 'app/components/auth/constants';
import MenuDropdownList from 'app/components/ui/Dropdown/components/MenuDropdownList';
import { RoleAvatar } from 'app/components/ui/RoleAvatar';
import ImpersonateDropdown from 'app/components/ui/Dropdown/components/ImpersonateDropdown';
import { useAcl } from 'app/components/auth';
import { selectImpersonateUser } from 'app/components/auth/store/selectors';

import { Logo } from './Logo';

import { ReactComponent as impersonateIcon } from 'images/icon/impersonate.svg';

import { styles } from '../styles/accountHolder';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  scrolling?: boolean;
}
const defaultRedirect = '/login';

const AccountHolderLayout = ({
  children,
  scrolling = false,
  title = translations.accountHolderOnboarding.pageTitle,
  className,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, isAuthorized, isLoading } = useAuthUser();
  const navigate = useNavigate();
  const { isAdmin } = useAcl();
  const impersonateUser = useSelector(selectImpersonateUser);

  useEffect(() => {
    if (!isAuthorized && !isLoading) {
      navigate(defaultRedirect);
    }

    if (user) {
      if (user?.account?.isPassOnboarding === true) {
        navigate(`/accounts`);
      }
    }
  }, [navigate, isAuthorized, isLoading, user]);

  return (
    <div
      className={cx(classes.onboard, className, {
        [classes.scrolling]: scrolling,
      })}
    >
      <div className={classes.header}>
        <Container className={classes.container} maxWidth="xl">
          <Box display="flex" alignItems="center">
            <Logo className={classes.logo} dark hardReload />
            <Typography
              className={classes.headerTitle}
              variant="h5"
              component="h1"
            >
              {t(title)}
            </Typography>
          </Box>
          <div>
            {isAdmin || impersonateUser ? (
              <ImpersonateDropdown
                className={cx(classes.impersonateButton, {
                  impersonate: !!impersonateUser,
                })}
                placement="bottom-start"
                paperFullWidth
                buttonStartIcon={
                  <SvgIcon component={impersonateIcon} viewBox="0 0 18 21" />
                }
              />
            ) : null}
            <MenuDropdownList
              className={classes.headerUserDropdown}
              classNamePopper={classes.headerUserDropdownPopper}
              buttonStartIcon={<RoleAvatar role={user?.mainRole} />}
              buttonText={
                <div className={classes.headerUserDropdownText}>
                  <span>{user?.firstName && `Hello, ${user?.firstName}`}</span>
                  <span className={classes.headerUserDropdownSubText}>
                    {user?.mainRole && ROLES_TITLE[user?.mainRole]}
                  </span>
                </div>
              }
              buttonArrow
              placement="bottom-end"
              paperFullWidth
            />
          </div>
        </Container>
      </div>
      <Container className={classes.content} maxWidth="xl">
        <>{children}</>
      </Container>
    </div>
  );
};

export default memo(AccountHolderLayout);
