import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import MenuDropdownList from 'app/components/ui/Dropdown/components/MenuDropdownList';
import { ROLES, ROLES_TITLE } from 'app/components/auth/constants';
import { useAcl } from 'app/components/auth';
import { selectImpersonateUser } from 'app/components/auth/store/selectors';
import ImpersonateDropdown from 'app/components/ui/Dropdown/components/ImpersonateDropdown';

import { Avatar } from '../../ui/Avatar';
import { SAVE_MSG_SHOWTIME } from '../../forms/constants';
import SavingContext from './SavingContext';
import { Logo } from './Logo';

import { ReactComponent as circleNotchLightIcon } from 'images/icon/FontAwesome5Pro/Light/circle-notch.svg';
import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as impersonateIcon } from 'images/icon/impersonate.svg';

import { styles } from '../styles/onboarding';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  scrolling?: boolean;
}

const OnboardingLayout = ({
  children,
  scrolling = false,
  title = translations.onboarding.pageTitle,
  className,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAuthorized, isLoading, user } = useAuthUser();
  const isTrialUser = user?.roles?.some(role => role.name === ROLES.TRIAL_USER);
  const { isAdmin } = useAcl();
  const impersonateUser = useSelector(selectImpersonateUser);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const setSaving = (value = true) => {
    setIsSaved(false);
    setIsSaving(value);
  };

  let timeout;
  const setSaved = () => {
    setIsSaved(true);
    setIsSaving(false);
    clearTimeout(timeout);
    timeout = setTimeout(() => setIsSaved(false), SAVE_MSG_SHOWTIME);
  };

  const controls = { setIsSaving: setSaving, setIsSaved: setSaved };

  return (
    <div
      className={cx(classes.onboard, className, {
        [classes.onboardScrolling]: scrolling,
      })}
    >
      <div className={classes.onboardHeader}>
        <Container className={classes.onboardHeaderContainer} maxWidth="xl">
          <Box display="flex" alignItems="center">
            <Logo className={classes.onboardLogo} dark hardReload />
            <Typography
              className={classes.onboardHeaderTitle}
              variant="h5"
              component="h1"
            >
              {t(title)}
            </Typography>
          </Box>
          {isAuthorized && !isLoading && (
            <Box display="flex" alignItems="center">
              {(isSaving || isSaved) && (
                <Box
                  className={classes.headerSavingBox}
                  display="flex"
                  alignItems="center"
                >
                  {isSaving ? (
                    <>
                      <SvgIcon
                        component={circleNotchLightIcon}
                        viewBox="0 0 512 512"
                      />
                      {t(translations.form.labels.saving)}
                    </>
                  ) : (
                    <>
                      <SvgIcon
                        component={checkCircleLightIcon}
                        viewBox="0 0 512 512"
                      />
                      {t(translations.form.labels.saved)}
                    </>
                  )}
                </Box>
              )}

              <div>
                {isAdmin || impersonateUser ? (
                  <ImpersonateDropdown
                    className={cx(classes.impersonateButton, {
                      impersonate: !!impersonateUser,
                    })}
                    placement="bottom-start"
                    paperFullWidth
                    buttonStartIcon={
                      <SvgIcon
                        component={impersonateIcon}
                        viewBox="0 0 18 21"
                      />
                    }
                  />
                ) : null}
                <MenuDropdownList
                  className={classes.headerUserDropdown}
                  classNamePopper={classes.headerUserDropdownPopper}
                  buttonStartIcon={<Avatar name={user?.firstName} />}
                  buttonText={
                    <div className={classes.headerUserDropdownText}>
                      <span>
                        {user?.firstName && `Hello, ${user?.firstName}`}
                      </span>
                      <span className={classes.headerUserDropdownSubText}>
                        {isTrialUser
                          ? ROLES_TITLE[ROLES.TRIAL_USER]
                          : user?.mainRole && ROLES_TITLE[user?.mainRole]}
                      </span>
                    </div>
                  }
                  buttonArrow
                  placement="bottom-end"
                  paperFullWidth
                />
              </div>
            </Box>
          )}
        </Container>
      </div>
      <Container className={classes.onboardContent} maxWidth="xl">
        <SavingContext.Provider value={controls}>
          {children}
        </SavingContext.Provider>
      </Container>
    </div>
  );
};

export default memo(OnboardingLayout);
