import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { Loader } from 'app/components/ui/Loader';
import { useEighthStep } from 'app/components/entities/accounts/hooks/crud';
import { error } from 'app/components/snackbars';

import { StepFooter } from '../StepFooter/StepFooter';

import styles from './styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(styles);

export const EighthStep = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { mutateAsync: finishStep } = useEighthStep();
  const [isLoading, setIsLoading] = useState(false);

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
  );

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep(data);
        navigate('/accounts');
        setIsLoading(false);
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={isCompanyLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!isCompanyLoading && (
        <div className={classes.stepContainer}>
          <div>
            <Typography
              className={classes.title}
              color={'primary'}
              variant={'h5'}
            >
              {t(translations.accountHolderOnboarding.steps.eighth.title)}
            </Typography>
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text1)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text2)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text3)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text4)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text5)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text6)}
            </Typography>
            <br />
            <Typography>
              {t(translations.accountHolderOnboarding.steps.eighth.text7)}
            </Typography>
          </div>
          <StepFooter
            activeStep={8}
            handleNext={async () =>
              await handleFinishStep({
                companyId: company?.id,
                accountId: user?.accountId,
              })
            }
          />
        </div>
      )}
    </>
  );
};
