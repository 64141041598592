import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { Loader } from 'app/components/ui/Loader';
import { useSixthStep } from 'app/components/entities/accounts/hooks/crud';
import { error, success } from 'app/components/snackbars';
import { Text } from 'app/components/forms';
import { useModal } from 'app/components/ui/Modal';

import { StepFooter } from '../StepFooter/StepFooter';
import { RolesModal } from './RolesModal';

import { ReactComponent as copyLightIcon } from 'images/icon/FontAwesome5Pro/Light/copy.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

const buildInviteLink = linkId =>
  `https://${window.location.host}/invite/${linkId}`;

export interface Props {
  nextStep: () => void;
}

export const SixthStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();

  const { data: invite, isLoading: isInviteLoading } = useSixthStep({
    companyId: Number(user?.companies[0]?.id),
  });

  const initialValues = useMemo(() => {
    return {
      invite: user && invite ? buildInviteLink(invite?.linkId) : '',
    };
  }, [user, invite]);

  const handleFinishStep = useCallback(() => {
    try {
      nextStep();
    } catch (e) {
      error(t(translations.errors.somethingWentWrongError));
    }
  }, [t]);

  const copyInviteLink = useCallback(
    (inviteLink: string) => {
      navigator.clipboard.writeText(inviteLink);
      success(t(translations.ui.notificationMessages.copiedToClipboard));
    },
    [t],
  );

  const [showRolesModal] = useModal({
    Modal: RolesModal,
    unmount: true,
  });

  return (
    <>
      <Loader loading={isInviteLoading} fullPage={false} />
      {!isInviteLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.sixth.title)}
          </Typography>
          <Typography>
            <Trans
              i18nKey="accountHolderOnboarding.steps.sixth.text1"
              values={{
                companyName: user?.companies[0]?.name,
              }}
            />
            {/* {t(translations.accountHolderOnboarding.steps.sixth.text1)} */}
          </Typography>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => {
              return;
            }}
          >
            {({ handleSubmit, values: data }) => (
              <div className={classes.stepContainer}>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <div className={classes.inviteBlock}>
                    <Typography className={classes.shareText}>
                      {t(
                        translations.accountHolderOnboarding.steps.sixth
                          .shareText,
                      )}
                    </Typography>

                    <Text
                      className={classes.inviteField}
                      name="invite"
                      type="text"
                      variant="outlined"
                      endIcon={
                        <SvgIcon
                          className={classes.copyIcon}
                          component={copyLightIcon}
                          viewBox="0 0 512 512"
                          onClick={() => copyInviteLink(data.invite)}
                        />
                      }
                    />
                  </div>
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.sixth.text2)}
                  </Typography>
                  <br />
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.sixth.text3)}{' '}
                    <span
                      className={classes.clickText}
                      onClick={showRolesModal}
                    >
                      {t(
                        translations.accountHolderOnboarding.steps.sixth
                          .clickHere,
                      )}
                    </span>
                  </Typography>
                  <br />
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.sixth.text4)}
                  </Typography>
                  <br />
                  <Typography>
                    {t(translations.accountHolderOnboarding.steps.sixth.text5)}
                  </Typography>
                </form>
                <StepFooter
                  activeStep={6}
                  handleNext={() => handleFinishStep()}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
