import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  respMobileContent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const Panel = ({ children, respMobileContent, className }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const respMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {respMobile && respMobileContent && (
        <div className={cx(classes.panelHeader, classes.previewHeader)}>
          {respMobileContent}
        </div>
      )}
      <Paper className={cx(classes.panelPaper, className)}>{children}</Paper>
    </>
  );
};
