import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';

import { translations } from 'locales/i18n';
import AutocompleteField from 'app/components/ui/Autocomplete';
import { UserCompany, UserLocation } from 'app/components/auth/constants';

import { ReactComponent as arrowCircleRightLightIcon } from 'images/icon/FontAwesome5Pro/Light/arrow-circle-right.svg';

export interface Props {
  handleSubmit: (...data: any) => void;
  companies?: UserCompany[];
  locations?: UserLocation[];
}

const customOptionLabel = ({ name }) => name;

const initialValues: {
  company: UserCompany | null;
  location: UserLocation | null;
} = {
  company: null,
  location: null,
};

export default function AssignLocationForm({
  handleSubmit,
  companies,
  locations,
}: Props) {
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState(null);

  const userCompaniesOptions = useMemo(
    () =>
      companies
        ?.map(({ id, name }) => ({
          id,
          name,
        }))
        .filter(
          ({ id }) => locations?.some(({ companyId }) => companyId === id),
        ),
    [companies, locations],
  );

  const userLocationsOptions = useMemo(
    () =>
      locations?.reduce<UserLocation[]>((prev, item) => {
        if (item.companyId === companyId) {
          prev.push({
            id: item.id,
            name: item.name,
          });
        }

        return prev;
      }, []),
    [companyId, locations],
  );

  const validationSchema = yup.object().shape({
    company: yup
      .object()
      .nullable()
      .required(t(translations.validation.required)),
    location: yup
      .object()
      .nullable()
      .required(t(translations.validation.required)),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <AutocompleteField
            disableClearable={false}
            name="company"
            label={t(translations.people.fields.company)}
            options={userCompaniesOptions}
            customOptionLabel={customOptionLabel}
            onChange={value => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setCompanyId(value?.id || null);
              setFieldValue('location', null);
            }}
          />
          <AutocompleteField
            disableClearable={false}
            name="location"
            label={t(translations.people.fields.location)}
            options={userLocationsOptions}
            customOptionLabel={customOptionLabel}
            disabled={!values.company?.id}
          />
          <div className="modal-button-block modal-dialog-actions">
            <Button
              className="modal-button cta-button"
              variant="contained"
              color="secondary"
              type="submit"
              startIcon={
                <SvgIcon
                  component={arrowCircleRightLightIcon}
                  viewBox="0 0 512 512"
                />
              }
            >
              {t(translations.ui.buttons.save)}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
