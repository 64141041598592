import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    disabled: {
      opacity: 0.5,
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
    },
    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });
