import { useCallback, useMemo } from 'react';

import { ROLES } from '../constants';
import useAuth from './useAuth';

export default function useAcl() {
  const { token, user, isLoading } = useAuth();

  const currentRoles = useMemo(() => {
    if (!token) {
      return [ROLES.ANONYMOUS];
    }

    if (user.roles.length && user.roles[0].name) {
      return user.roles.map(({ name }) => name);
    }

    return user.roles;
  }, [token, user]);

  const is = useCallback(role => user?.mainRole === role, [user?.mainRole]);

  const isOneOf = useCallback(
    roles => roles.some(role => user?.mainRole === role),
    [user?.mainRole],
  );

  const isAdmin = useMemo(
    () => user?.mainRole === ROLES.SUPER_ADMIN,
    [user?.mainRole],
  );

  return { is, isAdmin, isOneOf, user, currentRoles, isUserLoading: isLoading };
}
