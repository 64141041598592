import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { Text } from 'app/components/forms';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useCompanyShow } from 'app/components/entities/companies/hooks/crud';
import { Loader } from 'app/components/ui/Loader';
import { useFirstStep } from 'app/components/entities/accounts';
import { error } from 'app/components/snackbars';

import { StepFooter } from '../StepFooter/StepFooter';

import { ReactComponent as questionIcon } from 'images/icon/FontAwesome5Pro/Light/circle-question.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  nextStep: () => void;
}

export const FirstStep = ({ nextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { mutateAsync: finishStep } = useFirstStep();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    accountName: yup.string().required(t(translations.validation.required)),
    companyName: yup.string().required(t(translations.validation.required)),
    locationName: yup.string().required(t(translations.validation.required)),
    signatureName: yup.string().required(t(translations.validation.required)),
  });

  const { data: company, isFetching: isCompanyLoading } = useCompanyShow(
    Number(user?.companies[0]?.id),
  );

  const initialValues = useMemo(() => {
    return {
      accountName: user && company ? user?.account?.name : '',
      companyName: user && company ? company.name : '',
      locationName: user && company ? company?.locations[0]?.name : '',
      signatureName:
        user && company ? company?.locations[0]?.signatures[0]?.name : '',
      accountId: user && company ? user?.account?.id : null,
      companyId: user && company ? company.id : null,
      locationId: user && company ? company?.locations[0]?.id : null,
      signatureId:
        user && company ? company?.locations[0]?.signatures[0]?.id : null,
    };
  }, [user, company]);

  const handleFinishStep = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await finishStep(data);
        setIsLoading(false);
        nextStep();
      } catch (e) {
        error(t(translations.errors.somethingWentWrongError));
      }
    },
    [t, finishStep],
  );

  return (
    <>
      <Loader loading={isCompanyLoading} fullPage={false} />
      <Loader loading={isLoading} fullPage={true} />
      {!isCompanyLoading && !isLoading && (
        <div>
          <Typography
            className={classes.title}
            color={'primary'}
            variant={'h5'}
          >
            {t(translations.accountHolderOnboarding.steps.first.title)}
          </Typography>
          <Typography
            className={classes.subTitle}
            color={'secondary'}
            variant={'h6'}
          >
            {t(translations.accountHolderOnboarding.steps.first.subtitle)}
          </Typography>
          <Typography>
            {t(translations.accountHolderOnboarding.steps.first.text1)}
          </Typography>
          <br />
          <Typography>
            {t(translations.accountHolderOnboarding.steps.first.text2)}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              return;
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values: data }) => (
              <div className={classes.stepContainer}>
                <form
                  className={classes.formContainer}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.formRaw}>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.first.form
                            .accountName,
                        )}
                        name="accountName"
                        type="text"
                        variant="outlined"
                        required
                      />

                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.first
                            .tooltip.account,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.first.form
                            .companyName,
                        )}
                        name="companyName"
                        type="text"
                        variant="outlined"
                        required
                      />
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.first
                            .tooltip.company,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={classes.formRaw}>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.first.form
                            .locationName,
                        )}
                        name="locationName"
                        type="text"
                        variant="outlined"
                        required
                      />
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.first
                            .tooltip.location,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                    <div className={classes.textFieldBlock}>
                      <Text
                        className={classes.textField}
                        label={t(
                          translations.accountHolderOnboarding.steps.first.form
                            .signatureName,
                        )}
                        name="signatureName"
                        type="text"
                        variant="outlined"
                        required
                      />
                      <Tooltip
                        title={t(
                          translations.accountHolderOnboarding.steps.first
                            .tooltip.signature,
                        )}
                        placement="top"
                        arrow={true}
                      >
                        <SvgIcon
                          color="primary"
                          className={classes.questionIcon}
                          component={questionIcon}
                          viewBox="0 0 512 512"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </form>
                <StepFooter
                  activeStep={1}
                  isValid={isValid}
                  handleNext={async () => await handleFinishStep(data)}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
