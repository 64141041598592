import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalContent, ModalTitle } from 'app/components/ui/Modal/';
import { translations } from 'locales/i18n';
import { UserCompany, UserLocation } from 'app/components/auth/constants';

import AssignLocationForm from './AssignLocationForm';

interface Props {
  onClose: () => void;
  open: boolean;
  handleSubmit: (...data: any) => void;
  companies?: UserCompany[];
  locations?: UserLocation[];
}

export const AssignLocationModal: React.FC<Props> = ({
  onClose,
  open,
  handleSubmit,
  companies,
  locations,
}) => {
  const { t } = useTranslation();
  const onSubmit = useCallback(
    data => {
      handleSubmit(data, onClose);
    },
    [handleSubmit, onClose],
  );

  return (
    <Modal open={open} maxWidth="sm">
      <ModalTitle onClose={onClose}>
        {t(translations.people.assignToLocation)}
      </ModalTitle>
      <ModalContent>
        <AssignLocationForm
          handleSubmit={onSubmit}
          companies={companies}
          locations={locations}
        />
      </ModalContent>
    </Modal>
  );
};
