import { createStyles, Theme } from '@material-ui/core/styles';

import {
  bgBody,
  bgColorLightGrey2,
  borderPrimaryColor,
  borderRadiusSmallSize,
  boxShadowExtraSmall,
  colorGrey,
  colorPrimaryLight2,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    iconSizeBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    textFieldMedium: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 300,
      },
    },
    textFieldSmall: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 150,
      },
    },
    ghostButton: {
      width: 200,
      minWidth: 125,
      maxWidth: '100%',
    },
    ghostButtonIcon: {
      fontSize: 18,
      marginRight: 10,
    },
    iconButtonOutline: {
      border: '1px solid currentColor',
      fontSize: 18,
      padding: 6,

      '&:hover': {
        ...boxShadowExtraSmall,
      },
    },
    buttonEdit: {
      marginLeft: 'auto',
    },

    gridItemFlex: {
      display: 'flex',
      flexDirection: 'column',
    },
    gridItemSeparatedRight: {
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${borderPrimaryColor}`,
      },
    },

    signatureImageBlock: {
      backgroundColor: bgColorLightGrey2,
      color: colorGrey,
      fontSize: 14,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      border: `1px solid ${borderPrimaryColor}`,
      borderRadius: borderRadiusSmallSize,

      '&.empty-image': {
        height: 215,
      },

      [theme.breakpoints.up('sm')]: {
        '&.empty-image': {
          height: 326,
        },
      },

      '& p': {
        textAlign: 'center',
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    signatureImage: {
      width: '100%',
      height: '100%',
    },

    //Timeplate Preview Modal
    designRemoveModal: {
      backgroundColor: bgBody,

      '& .modal-button-block': {
        justifyContent: 'center',
      },
    },

    previewModalContent: {
      padding: '0 !important',
    },
    previewModalTabs: {
      margin: 0,
      '& .tabs-content-block': {
        padding: 24,
      },
    },

    widgetModalContent: {
      minHeight: 450,
      padding: '0 !important',
    },

    iconSizeExample: {
      marginLeft: 16,

      [theme.breakpoints.up('md')]: {
        marginLeft: 24,
      },
    },

    copyTag: {
      marginLeft: 10,
    },

    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },

    disabled: {
      opacity: 0.5,
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
    },
    informBlock: {
      display: 'inline-flex',
      alignItems: 'center',

      '& svg': {
        marginLeft: '6px',
        color: colorPrimaryLight2,
        fontSize: 16,
      },
    },
  });
