import { createStyles, Theme } from '@material-ui/core/styles';

import { colorPrimary, colorPurple, buttonSize } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    pageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 16,

      [theme.breakpoints.down('xs')]: {
        '&:not($pageHeaderWithAction)': {
          display: 'block',
          padding: 0,
        },
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 30,
        minHeight: buttonSize,
      },
    },
    pageHeaderWithAction: {},
    pageHeaderTitle: {
      paddingRight: 10,
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-word',

      [theme.breakpoints.up('md')]: {
        paddingRight: 20,
      },
    },
    pageHeaderTitleText: {
      color: colorPrimary,
    },
    pageHeaderTitleIcon: {
      color: colorPurple,
      fontSize: 24,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,

      [theme.breakpoints.up('md')]: {
        fontSize: 30,
        marginRight: 16,
      },
      '& svg': {
        color: 'inherit',
        fontSize: 'inherit',
      },
    },
    pageHeaderTitleAction: {
      marginLeft: 10,

      [theme.breakpoints.up('lg')]: {
        marginLeft: 20,
      },
      '& .custom-chip': {
        minWidth: 90,
      },
      '& .chip-label, & .chip-label-content': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    pageHeaderAction: {
      marginLeft: 'auto',

      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
        marginBottom: 10,
      },
      '& .cta-button': {
        '&:not(:first-child)': {
          marginLeft: 8,

          [theme.breakpoints.up('md')]: {
            marginLeft: 16,
          },
          [theme.breakpoints.up('lg')]: {
            marginLeft: 24,
          },
        },
        [theme.breakpoints.down('xs')]: {
          minWidth: 0,
        },
      },
    },
  });
