import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { translations } from 'locales/i18n';
import { ACCOUNT_HOLDER_STEPS } from 'app/containers/AccountHolderPage/interfaces';

import { ReactComponent as checkCircleLightIcon } from 'images/icon/FontAwesome5Pro/Light/check-circle.svg';
import { ReactComponent as checkIcon } from 'images/icon/FontAwesome5Pro/Light/cabinet-filing.svg';
import { ReactComponent as chevronLeftLightIcon } from 'images/icon/FontAwesome5Pro/Light/chevron-left.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  activeStep: number;
  steps: { name: string; content: JSX.Element }[];
  handleBack: () => void;
}

export const StepsPanel = ({ activeStep, steps, handleBack }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        classes.stepPanel,
        'native-custom-scrollbar grey-scrollbar',
      )}
    >
      <div className={classes.header}>
        <div className={classes.iconBlock}>
          {activeStep === ACCOUNT_HOLDER_STEPS.FIRST ? (
            <SvgIcon
              component={checkCircleLightIcon}
              className={classes.checkIcon}
              viewBox="0 0 512 512"
            />
          ) : (
            <IconButton
              className={classes.backIcon}
              color="primary"
              onClick={handleBack}
            >
              <SvgIcon
                component={chevronLeftLightIcon}
                viewBox="0 0 256 512"
                fontSize="inherit"
              />
            </IconButton>
          )}
          <Typography variant="h5">
            {t(translations.accountHolderOnboarding.headerTitle)}
          </Typography>
        </div>
        <Typography variant="h5">
          {`${activeStep} of ${steps.length}`}
        </Typography>
      </div>
      {steps.map((step, i) => {
        return (
          <div
            key={step.name}
            className={cx(classes.stepBlock, {
              [classes.isActiveStep]: i + 1 === activeStep,
              [classes.notActiveStep]: i + 1 > activeStep,
              [classes.doneStep]: i + 1 < activeStep,
            })}
          >
            <div className={classes.stepIcon}>
              {i + 1 >= activeStep ? (
                <Typography className={classes.stepName}>{i + 1}</Typography>
              ) : (
                <SvgIcon component={checkIcon} viewBox="0 0 18 12" />
              )}
            </div>
            <Typography className={classes.stepName}>{step.name}</Typography>
          </div>
        );
      })}
    </div>
  );
};
