import React, { memo, useCallback, useMemo, useState } from 'react';
import { useField } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

import { file } from 'app/components/files';

import Text from '../Text';
import ImageModal from './ImageModal';

import { ReactComponent as cloudUploadLightIcon } from 'images/icon/FontAwesome5Pro/Light/cloud-upload.svg';
import { ReactComponent as imageLightIcon } from 'images/icon/FontAwesome5Pro/Light/image.svg';

import styles from 'app/components/forms/styles/image';

const useStyles = makeStyles(styles);

export interface Props extends OutlinedTextFieldProps {
  name: string;
  onSuccessChange?: (data, isCircle, removeLoader?: boolean) => void;
  endIcon?: React.ReactNode;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  startIcon?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  onScale?: (width, height) => void;
  isCircle?: boolean;
  heightField?: string;
  widthField?: string;
  withoutClear?: boolean;
  fromOnboarding?: boolean;
  valueFromOnboarding?: string;
  label?: string;
  withEdit?: boolean;
  value?: string;
}

const TextImage = ({
  width = 200,
  height = 200,
  onScale,
  isCircle = false,
  widthField,
  heightField,
  onSuccessChange,
  withoutClear = false,
  fromOnboarding = false,
  valueFromOnboarding,
  label = '',
  withEdit = false,
  ...props
}: Props) => {
  const classes = useStyles();
  const [{ value }] = useField<string>(props.name);
  const [open, setIsOpen] = useState(false);

  const removeFile = () => {
    if (props.setFieldValue) props.setFieldValue(props.name, '', false);
    if (onSuccessChange) {
      onSuccessChange('', false, false);
    }
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const handleUpdateFile = useCallback(() => {
    setIsEditMode(true);
    setIsOpen(true);
  }, [setIsEditMode]);
  const uploadFile = useCallback(() => {
    setIsEditMode(false);
    setIsOpen(true);
  }, []);
  const handleClose = () => setIsOpen(false);
  const handleSave = (file, isCircle, removeLoader = false) => {
    if (onSuccessChange) {
      return onSuccessChange(file, isCircle, removeLoader);
    }
  };

  const handleScale = (width, height) => {
    if (onScale) onScale(width, height);
    if (props.setFieldValue && widthField && heightField) {
      props.setFieldValue(widthField, width);
      props.setFieldValue(heightField, height);
    }
  };

  const isByPass = (value || valueFromOnboarding) && +width >= 525;
  const src = useMemo(() => {
    if (valueFromOnboarding) {
      if (+valueFromOnboarding) {
        return file(
          +valueFromOnboarding,
          !isByPass ? +width : undefined,
          !isByPass ? +height : undefined,
          isCircle,
        );
      }
    } else {
      if (['string', 'number'].includes(typeof value)) {
        return file(
          +value,
          !isByPass ? +width : undefined,
          !isByPass ? +height : undefined,
          isCircle,
        );
      }
    }

    return '';
  }, [height, isByPass, isCircle, value, valueFromOnboarding, width]);

  return (
    <>
      <Text
        className={classes.imageField}
        {...props}
        readOnly
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={value && !withoutClear ? () => {} : uploadFile}
        emptyField
        label={label}
        startIcon={
          <>
            {props.startIcon}
            {src || fromOnboarding ? (
              <Tooltip
                classes={{
                  popper: classes.imageFieldTooltipPopper,
                  tooltip: classes.imageFieldTooltip,
                }}
                title={
                  <img
                    className={classes.imageFieldTooltipImage}
                    src={src}
                    // width={+width}
                    // height={+height}
                    alt=""
                  />
                }
                placement="bottom-start"
                arrow={true}
                enterTouchDelay={300}
                leaveTouchDelay={3000}
              >
                <Avatar
                  className={classes.imageFieldAvatar}
                  variant="circular"
                  src={src}
                />
              </Tooltip>
            ) : (
              <Avatar className={classes.imageFieldAvatar}>
                <SvgIcon
                  component={imageLightIcon}
                  viewBox="0 0 512 512"
                  style={{ fontSize: 14 }}
                />
              </Avatar>
            )}
          </>
        }
        endIcon={
          <>
            {value && !fromOnboarding ? (
              <>
                {!withoutClear && (
                  <IconButton
                    className="text-button text-clear-button"
                    onClick={removeFile}
                    tabIndex={-1}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {withEdit && (
                  <IconButton
                    className="text-button text-edit-button"
                    onClick={handleUpdateFile}
                    tabIndex={-1}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <IconButton
                className="text-button"
                onClick={uploadFile}
                tabIndex={-1}
              >
                <SvgIcon
                  component={cloudUploadLightIcon}
                  viewBox="0 0 640 512"
                />
              </IconButton>
            )}
            {props.endIcon}
          </>
        }
        ref={undefined}
      />
      <div
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}
      >
        <ImageModal
          open={open}
          handleClose={handleClose}
          handleSave={handleSave}
          handleScale={handleScale}
          name={props.name}
          fromOnboarding={fromOnboarding}
          valueFromOnboarding={valueFromOnboarding}
          isEditMode={isEditMode}
          src={src}
          editFileName={props.value}
          editIsCircle={isCircle}
        />
      </div>
    </>
  );
};

export default memo(TextImage);
