import React from 'react';
import { useDebounce } from 'react-use';

import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { TYPES, ROLES } from 'app/components/auth/constants';

import { Search } from './Search';
import { SEARCHING_DELAY } from './constants';

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  className?: string;
  setGlobalFilter: (...args: any) => void;
}

export function AccountsSearch({ setGlobalFilter, ...props }: Props) {
  const [val, setVal] = React.useState('');

  useDebounce(
    () => {
      let data: Record<string, any> = {};
      if (val) {
        data.$and = [
          {
            $or: [
              {
                email: {
                  $contL: val,
                },
              },
              isNaN(val as unknown as any)
                ? {}
                : {
                    id: {
                      $eq: val,
                    },
                  },
            ],
          },
          {
            type: { $eq: TYPES.PAID },
            'filterRoles.name': {
              $in: [ROLES.ACCOUNT_HOLDER_PRIMARY],
            },
          },
        ];
      } else {
        data = {
          type: { $eq: TYPES.PAID },
          'filterRoles.name': {
            $in: [ROLES.ACCOUNT_HOLDER_PRIMARY],
          },
        };
      }
      setGlobalFilter(data);
    },
    SEARCHING_DELAY,
    [val],
  );

  return (
    <Search
      handleInputChange={({ currentTarget }) => {
        setVal(currentTarget.value);
      }}
      {...props}
    />
  );
}
