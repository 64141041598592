import React, { useEffect } from 'react';
import { useNavigate, RouteProps, useLocation } from 'react-router-dom';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';
import { useConfigTable } from 'app/components/auth/hooks/useConfigTable';
import { useConfigMenu } from 'app/components/auth/hooks/useConfigMenu';
import { USER_TYPES } from 'app/interfaces';
import { ROLES } from '../../constants';

const defaultRedirect = '/login';

export const ProtectedRoute = (props: RouteProps) => {
  const { isLoading, isAuthorized, user } = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  useConfigTable();
  useConfigMenu();

  useEffect(() => {
    if (!isAuthorized && !isLoading) {
      navigate(defaultRedirect);
    }

    if (user) {
      if (user.type === USER_TYPES.TRIAL) {
        navigate(`/trial/${user.id}`);
      } else if (user.type === USER_TYPES.PAID_WAITING) {
        navigate(`/paid-waiting`);
      } else if (user?.account?.isPassOnboarding === false) {
        navigate(`/account-holder/onboarding`);
      }

      if (
        ['trial', 'ghost', 'accounts'].some(route =>
          location.pathname.includes(route),
        ) &&
        user.type === USER_TYPES.PAID &&
        user?.roles.every(({ name }) => name !== ROLES.SUPER_ADMIN)
      ) {
        navigate('/');
      }

      if (
        ['icon-sets'].some(route => location.pathname.includes(route)) &&
        !user?.roles.some(
          ({ name }) =>
            name === ROLES.SUPER_ADMIN ||
            name === ROLES.ACCOUNT_HOLDER_PRIMARY ||
            name === ROLES.ACCOUNT_HOLDER ||
            name === ROLES.COMPANY_ADMIN_PRIMARY ||
            name === ROLES.COMPANY_ADMIN,
        )
      ) {
        navigate('/');
      }
    }
  }, [navigate, isAuthorized, isLoading, user]);

  if (isLoading) {
    return null;
  }

  return <>{props.children}</>;
};
