import { RootState } from 'types';
import { initialState } from 'app/components/auth/store/slice';

export const selectToken = (state: RootState) =>
  state?.auth?.token || initialState.token;
export const selectUser = (state: RootState) =>
  state?.auth?.user || initialState.user;
export const selectImpersonateUser = (state: RootState) =>
  state?.auth?.impersonateUser || initialState.impersonateUser;
export const selectConfigTable = (state: RootState) =>
  state?.auth?.tableConfig || initialState.tableConfig;
export const selectConfigMenu = (state: RootState) =>
  state?.auth?.menuConfig || initialState.menuConfig;
