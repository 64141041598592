import { createStyles, Theme } from '@material-ui/core/styles';

import { bgBody, colorWhite, textGrey } from 'configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    layout: {
      backgroundColor: bgBody,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 0,
    },
    layoutHeaderContainer: {
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.up('sm')]: {
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    layoutHeader: {
      backgroundColor: colorWhite,
    },
    layoutLogo: {
      [theme.breakpoints.down('xs')]: {
        '& .logo-name': {
          display: 'none',
        },
      },
    },
    layoutHeaderTitle: {
      color: textGrey,
      borderLeft: `1px solid ${textGrey}`,
      paddingLeft: 10,
      marginLeft: 20,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 15,
        marginLeft: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    layoutContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '24px 16px',
      minHeight: 0,

      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
      [theme.breakpoints.up('md')]: {
        padding: 32,
      },
    },
  });
