/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { Page } from 'app/components/layouts';
import { Loader } from 'app/components/ui/Loader';
import { COMPANY_TYPES } from 'app/containers/constants';
import {
  GhostList,
  useSignatureFilterIndex,
  useSignatureClone,
  useSignatureDelete,
} from 'app/components/entities/signatures';

import { ReactComponent as ghostLightIcon } from 'images/icon/FontAwesome5Pro/Light/ghost.svg';
import { success, error } from 'app/components/snackbars';

export function GhostSignaturesPage() {
  const { t } = useTranslation();
  const { companyId, locationId } = useParams() as {
    companyId: string;
    locationId?: string;
  };

  const { mutateAsync: cloneSignature } = useSignatureClone();
  const { mutateAsync: deleteSignature } = useSignatureDelete();

  const [indexQuery, setIndexQuery] = useState({
    ...RequestQueryBuilder.create({
      search: {
        'location.company.type': { $eq: COMPANY_TYPES.GHOST },
      },
    }).queryObject,
    limit: 10,
    page: 1,
    sort: [`createdAt,DESC`],
  });

  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageCount: 1,
  });

  const {
    data: signatures = data,
    isLoading,
    refetch,
  } = useSignatureFilterIndex(indexQuery, {
    refetchOnMount: true,
  });

  // @ts-ignore
  if (signatures !== data) setData(signatures);

  const handleCloneSignature = useCallback(
    async id => {
      try {
        await cloneSignature(id);
        success(t(translations.signature.message.success.clone));

        refetch();
      } catch (e) {
        error(e.message);
      }
    },
    [cloneSignature, refetch, t],
  );

  const handleDeleteSignature = useCallback(
    async id => {
      try {
        await deleteSignature(id);
        success(t(translations.signature.message.success.delete));

        refetch();
      } catch (e) {
        error(e.message);
      }
    },
    [deleteSignature, refetch, t],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        name: t(translations.names.ghost.companies),
        to: '/ghost/companies',
        icon: <SvgIcon component={ghostLightIcon} viewBox="0 0 384 512" />,
        iconTo: '/ghost/companies',
        iconSize: 20,
        secondaryName: signatures?.data?.[0]?.location?.company?.name,
      },
      {
        name: t(translations.names.ghost.locations),
        to: `/ghost-locations/${companyId}`,
        secondaryName: signatures?.data?.[0]?.location?.name,
        show: !!locationId,
      },
      {
        name: t(translations.names.ghost.signatures),
        dropdown: [
          {
            to: `/ghost-locations/${companyId}`,
            name: t(translations.names.ghost.locations),
            show: !locationId,
          },
          {
            to: `/ghost-signature-users/${companyId}/${
              !!locationId ? locationId : ''
            }`,
            name: t(translations.names.ghost.users),
          },
        ],
      },
    ],
    [signatures, locationId],
  );

  return (
    <Page {...(locationId || companyId ? { breadcrumbs } : {})}>
      <Helmet>
        <title>{t(translations.metaInfo.ghostSignaturesPage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.ghostSignaturesPage.description)}
        />
      </Helmet>
      <Loader loading={isLoading} />
      <GhostList
        fetchData={setIndexQuery}
        indexQuery={indexQuery}
        data={signatures.data}
        total={signatures.total}
        page={signatures.page}
        pageCount={signatures.pageCount}
        companyId={companyId}
        locationId={locationId}
        handleCloneSignature={handleCloneSignature}
        handleDeleteSignature={handleDeleteSignature}
      />
    </Page>
  );
}
