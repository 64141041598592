import { createStyles, Theme } from '@material-ui/core';
import { colorDanger } from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    rolesCell: {
      '& .tooltip-init-block': {
        margin: '2px 4px 2px 0',

        [theme.breakpoints.up('sm')]: {
          margin: '4px 8px 4px 0',
        },
      },
    },
    textWarning: {
      color: colorDanger,
      fontSize: 14,
      fontWeight: 400,
    },
    messageText: {
      fontSize: 14,
    },
    textWarningTitle: {
      color: colorDanger,
      fontSize: 18,
      fontWeight: 600,
    },
    listSeparator: {
      '&:last-child': {
        display: 'none',
      },
    },
    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });
