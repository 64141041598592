/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactCropperProps } from 'react-cropper';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image/js';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fab from '@material-ui/core/Fab';
import SvgIcon from '@material-ui/core/SvgIcon';

import { translations } from 'locales/i18n';
import { useModal } from 'app/components/ui/Modal/';
import { file } from 'app/components/files';
import { ReactComponent as cloudUploadLightIcon } from 'images/icon/FontAwesome5Pro/Light/cloud-upload.svg';
import { ReactComponent as trashAltLightIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { Loader } from 'app/components/ui/Loader';

import ImageEditorModal from './ImageEditorModal';
import styles from '../styles';
import {
  MAX_FILE_SIZE_MB,
  MAX_IMAGE_DIMENSION,
  DEFAULT_IMAGE_NAME,
  IMAGES_FORMATS,
  IMAGES_STATIC_FORMATS,
} from '../constants';
import ImageEditorInner from './ImageEditorInner';
import { CropperBlob } from '../types';

const useStyles = makeStyles(styles);

interface Props {
  value: any;
  onError: (error: string) => any;
  onChange: (file: any) => any;
  cropperProps?: ReactCropperProps;
  variant?: 'circle' | 'rounded' | 'square';
  uploadAreaText?: string;
  canvasOptions?: object;
  showCrop?: boolean;
  isGif?: boolean;
  setShowCrop?: (data) => void;
  cropper?: any;
  filename?: any;
  setCropper?: (data: any) => void;
  setFilename?: (data: any) => void;
  setFile?: (data: any) => void;
  isCircle?: boolean;
  withDropZone?: boolean;
  setIsGif?: (isGif) => void;
  fromOnboarding?: boolean;
  src?: any;
  isEditMode?: boolean;
  editFileName?: string;
}

export default function UploadImage({
  value,
  onError,
  onChange,
  cropperProps,
  variant = 'rounded',
  uploadAreaText = 'Upload',
  showCrop: showCropProp,
  isGif = false,
  setShowCrop,
  cropper,
  setCropper,
  filename: filenameProp,
  setFilename: setFilenameProp,
  setFile,
  isCircle = false,
  withDropZone = false,
  setIsGif,
  fromOnboarding = false,
  src,
  isEditMode = false,
  editFileName = '',
  ...rest
}: Props) {
  const inputRef = useRef(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const [filename, setFilename] = useState(filenameProp || DEFAULT_IMAGE_NAME);
  const [image, setImage] = useState('');
  const [croppedImage, setCropped] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setCroppedImage = useCallback(
    crop => {
      onChange(crop);
    },
    [onChange],
  );

  const handleUpload = useCallback(() => {
    const { current } = inputRef;
    // @ts-ignore
    current.click();
  }, []);

  const handleRemove = useCallback(() => {
    setFilenameProp ? setFilenameProp('') : setFilename('');
    setImage('');
    setCroppedImage(null);
    setCropped('');
    // @ts-ignore
    inputRef.current.value = '';
  }, [setCroppedImage, setFilenameProp]);

  const [showCrop] = useModal({
    // @ts-ignore
    Modal: !showCropProp && ImageEditorModal,
    onSubmit: setCroppedImage,
    props: {
      image,
      filename,
      cropperProps,
      ...rest,
    },
  });

  useEffect(() => {
    if (value && value.type && value.type.match('image.*')) {
      const reader = new FileReader();
      reader.onload = e => {
        // @ts-ignore
        setCropped(e.target.result);
      };
      reader.readAsDataURL(value);
    }
  }, [value]);

  useEffect(() => {
    (async () => {
      if (src && isEditMode) {
        setIsLoading(true);
        const originalImage = await fetch(src);
        if (originalImage.status === 200) {
          const blobOriginalImage = await originalImage.blob();
          const cropperBlobOriginalImage = blobOriginalImage as CropperBlob;
          cropperBlobOriginalImage.name = editFileName;
          cropperBlobOriginalImage.lastModifiedDate = new Date();
          if (IMAGES_STATIC_FORMATS.includes(cropperBlobOriginalImage.type)) {
            const reader = new FileReader();
            reader.onload = e => {
              // @ts-ignore
              setCropped(e.target.result);
              loadImage(
                cropperBlobOriginalImage,
                img => {
                  const image = img.toDataURL();
                  setFilename(cropperBlobOriginalImage.name);
                  if (setFilenameProp)
                    setFilenameProp(cropperBlobOriginalImage.name);
                  // @ts-ignore
                  setImage(image);
                  // @ts-ignore
                  if (setFile) setFile(cropperBlobOriginalImage);
                  setShowCrop ? setShowCrop(true) : showCrop();
                  setIsLoading(false);
                },
                { orientation: true, canvas: true },
              );
            };
            reader.readAsDataURL(cropperBlobOriginalImage);
          } else {
            if (setIsGif) setIsGif(true);
            const reader = new FileReader();
            reader.onload = event => {
              const image = new Image();
              // @ts-ignore
              image.src = event.target.result;
              image.onload = () => {
                /**
                 * @see https://github.com/fengyuanchen/cropperjs/issues/239
                 */
                if (image.width < MAX_IMAGE_DIMENSION) {
                  setFilename(cropperBlobOriginalImage.name);
                  if (setFilenameProp)
                    setFilenameProp(cropperBlobOriginalImage.name);
                  // @ts-ignore
                  setImage(event.target.result);
                  // @ts-ignore
                  if (setFile) setFile(cropperBlobOriginalImage);
                  setShowCrop ? setShowCrop(true) : showCrop();
                } else {
                  onError(t(translations.errors.fileWidthError));
                }
                if (inputRef && inputRef.current) {
                  // @ts-ignore
                  inputRef.current.value = '';
                }

                setIsLoading(false);
              };
            };

            reader.readAsDataURL(cropperBlobOriginalImage);
          }
        } else {
          setIsLoading(false);
        }
      }
    })();
  }, [
    editFileName,
    isEditMode,
    setFile,
    setFilenameProp,
    setIsGif,
    setShowCrop,
    showCrop,
    src,
    t,
  ]);

  const handleChange = (e: any) => {
    setIsLoading(true);
    const file = withDropZone ? e[0] : [...e.target.files][0];
    if (file && file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      onError(
        t(translations.errors.fileSizeError, {
          maxFileSize: MAX_FILE_SIZE_MB,
        }),
      );
      setIsLoading(false);
      return;
    }

    if (file && file.name && file.type.match('image.*')) {
      if (IMAGES_STATIC_FORMATS.includes(file.type)) {
        loadImage(
          file,
          img => {
            const image = img.toDataURL();
            setFilename(file.name);
            if (setFilenameProp) setFilenameProp(file.name);
            // @ts-ignore
            setImage(image);
            // @ts-ignore
            if (setFile) setFile(file);
            setShowCrop ? setShowCrop(true) : showCrop();
            setIsLoading(false);
          },
          { orientation: true, canvas: true },
        );
      } else {
        if (setIsGif) setIsGif(true);
        const reader = new FileReader();

        reader.onload = event => {
          const image = new Image();
          // @ts-ignore
          image.src = event.target.result;
          image.onload = () => {
            /**
             * @see https://github.com/fengyuanchen/cropperjs/issues/239
             */
            if (image.width < MAX_IMAGE_DIMENSION) {
              setFilename(file.name);
              if (setFilenameProp) setFilenameProp(file.name);
              // @ts-ignore
              setImage(event.target.result);
              // @ts-ignore
              if (setFile) setFile(file);
              setShowCrop ? setShowCrop(true) : showCrop();
            } else {
              onError(t(translations.errors.fileWidthError));
            }
            if (inputRef && inputRef.current) {
              // @ts-ignore
              inputRef.current.value = '';
            }

            setIsLoading(false);
          };
        };

        reader.readAsDataURL(file);
      }
    } else {
      onError(t(translations.errors.fileExtError));
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleChange,
    multiple: false,
    accept: { 'image/*': IMAGES_FORMATS },
  });

  const inputArea = createRef<HTMLDivElement>();

  return (
    <>
      <Loader loading={isLoading} fullPage={false} />
      {!showCropProp && (
        <div
          className={cx(classes.uploadImageBlock, 'upload-image-block', {
            [`${classes.uploadImageBlockUploaded} upload-image-block-uploaded`]:
              croppedImage || (value && value.id),
          })}
        >
          <input
            accept={IMAGES_FORMATS.join(', ')}
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={handleChange}
          />
          {croppedImage || (value && value.id) ? (
            <>
              <Avatar
                variant={variant}
                src={croppedImage ? croppedImage : file(value.id)}
                className={cx(classes.uploadedImage, 'uploaded-image')}
              />
              <div
                className={cx(
                  classes.uploadedImageButtonBlock,
                  'uploaded-image-button-block',
                )}
              >
                <Fab
                  className={classes.uploadedImageButton}
                  onClick={handleUpload}
                >
                  <SvgIcon
                    component={cloudUploadLightIcon}
                    viewBox="0 0 640 512"
                  />
                </Fab>
                {!fromOnboarding && (
                  <Fab
                    className={cx(classes.uploadedImageButton, 'button-pink')}
                    onClick={handleRemove}
                  >
                    <SvgIcon
                      component={trashAltLightIcon}
                      style={{ fontSize: 20 }}
                      viewBox="0 0 448 512"
                    />
                  </Fab>
                )}
              </div>
            </>
          ) : (
            <>
              {withDropZone ? (
                <ButtonBase
                  className={cx(classes.uploadButtonArea, 'upload-button-area')}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div ref={inputArea} />
                  {uploadAreaText}
                </ButtonBase>
              ) : (
                <ButtonBase
                  className={cx(classes.uploadButtonArea, 'upload-button-area')}
                  onClick={handleUpload}
                >
                  {uploadAreaText}
                </ButtonBase>
              )}
            </>
          )}
        </div>
      )}
      {showCropProp && setCropper && (
        <ImageEditorInner
          onSubmit={setCroppedImage}
          image={image}
          filename={filenameProp}
          cropper={cropper}
          setCropper={setCropper}
          isGif={isGif}
          isCircle={isCircle}
          {...cropperProps}
        />
      )}
    </>
  );
}
