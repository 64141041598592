import { LocalStorageStore, ImmortalStorage } from 'immortal-db';
import { Token } from 'app/components/auth/interfaces';

const stores = [LocalStorageStore];
const db = new ImmortalStorage(stores);
const key = 'auth';

export const storeToken = (token: Token) => db.set(key, JSON.stringify(token));
export const getToken = async () => {
  let token: Token | undefined;

  try {
    const stored = await db.get(key);
    token = typeof stored === 'string' ? JSON.parse(stored) : undefined;
  } catch (e) {
    // ¯\_(ツ)_/¯
  }

  return token;
};

export const destroyToken = () => db.remove(key);
