import { RootState } from './RootState';

export type { RootState };

export enum AUTO_APPROVALS_TYPE {
  OFF_ALL_INVITE = 'OFF_ALL_INVITES',
  OFF_ON_PER_INVITE = 'OFF_ON_PER_INVITE',
  ON_ALL_INVITES = 'ON_ALL_INVITES',
}

export interface PageInfo {
  breadcrumbs: {
    pageName: string;
    pageIcon?: React.ReactElement;
    pageIconTo?: string;
    to?: string;
    dropdown: { to: string; name: string }[];
  }[];
  title: string;
  content?: string;
}
