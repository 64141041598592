import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { translations } from 'locales/i18n';
import { ACCOUNT_HOLDER_STEPS } from 'app/containers/AccountHolderPage/interfaces';
import { Loader } from 'app/components/ui/Loader';
import {
  getStep,
  storeStep,
} from 'app/containers/AccountHolderPage/utils/accountHolderStorage';

import { Panel } from './components/Panel';
import { StepsPanel } from './components/StepsPanel/StepsPanel';
import { FirstStep } from './components/FirstStep/FirstStep';
import { SecondStep } from './components/SecondStep/SecondStep';
import { ThirdStep } from './components/ThirdStep/ThirdStep';
import { FourthStep } from './components/FourthStep/FourthStep';
import { FifthStep } from './components/FifthStep/FifthStep';
import { SixthStep } from './components/SixthStep/SixthStep';
import { SeventhStep } from './components/SeventhStep/SeventhStep';
import { EighthStep } from './components/EighthStep/EighthStep';

import styles from './styles';

const useStyles = makeStyles(styles);

export const AccountHolderPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const maximumSteps = ACCOUNT_HOLDER_STEPS.__LENGTH - 1;

  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(ACCOUNT_HOLDER_STEPS.FIRST);

  useEffect(() => {
    (async function () {
      const step = await getStep();
      if (step) {
        setActiveStep(Number(step));
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    storeStep(activeStep);
  }, [activeStep]);

  const nextStep = useCallback(async () => {
    if (activeStep + 1 <= maximumSteps) {
      storeStep(activeStep + 1);
      setActiveStep(step => step + 1);
    }
  }, [activeStep, maximumSteps]);

  const previousStep = useCallback(() => {
    if (activeStep - 1 >= ACCOUNT_HOLDER_STEPS.FIRST) {
      storeStep(activeStep - 1);
      setActiveStep(step => step - 1);
    }
  }, [activeStep]);

  const steps = useMemo(
    () => [
      {
        name: t(translations.accountHolderOnboarding.steps.first.title),
        content: <FirstStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.second.title),
        content: <SecondStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.third.title),
        content: <ThirdStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.fourth.title),
        content: <FourthStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.fifth.title),
        content: <FifthStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.sixth.title),
        content: <SixthStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.seventh.title),
        content: <SeventhStep nextStep={nextStep} />,
      },
      {
        name: t(translations.accountHolderOnboarding.steps.eighth.title),
        content: <EighthStep />,
      },
    ],
    [t],
  );

  return (
    <>
      <Loader loading={isLoading} fullPage />
      {!isLoading && (
        <Panel>
          <StepsPanel
            steps={steps}
            handleBack={previousStep}
            activeStep={activeStep}
          />
          <div className={classes.contentBlock}>
            {steps[activeStep - 1].content}
          </div>
        </Panel>
      )}
    </>
  );
};
