import { createStyles } from '@material-ui/core/styles';

import selectStyle from 'app/components/forms/styles/select';

import {
  colorGrey,
  colorRose1,
  colorRose2,
  colorRose3,
  colorWhite,
} from 'configs/variables';

export default () =>
  createStyles({
    ...selectStyle(),
    autocompleteStartAdornment: {
      marginRight: 8,

      '& svg, & img': {
        width: 20,
        minWidth: 20,
      },
    },
    autocompleteEndAdornment: { marginRight: '14px', top: 'calc(50% - 20px)' },
    autocompleteClearIndicator: {
      padding: '12px',
      fontSize: 16,
      color: colorGrey,
      visibility: 'initial',
    },
    optionBlock: {
      width: '100%',
      display: 'flex',
      padding: '14px 10px',
      alignItems: 'center',
    },
    userIcon: {
      marginRight: '30px',
    },
    selectedUserIcon: {
      marginRight: '16px',
      marginLeft: '10px',
    },
    infoBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    userEmail: {
      color: colorRose2,
    },
    userName: {
      color: colorRose3,
    },
    hide: {
      display: 'none',
    },
    selectOption: {
      overflowX: 'hidden',
      '&:hover': {
        background: colorRose1,
      },
    },
    selectList: { padding: '0px' },
    inputRoot: {
      background: colorWhite,
      borderRadius: '30px',
      color: `${colorRose3} !important`,
    },
    input: {
      borderColor: `${colorRose3} !important`,
    },
    closeIcon: { marginRight: '20px' },
  });
