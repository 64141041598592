import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';
import { useNavigate, useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { getTabById } from 'utils/getTabById';
import { Page, Paper } from 'app/components/layouts';
import { Tabs, useSteps } from 'app/components/ui/Tabs';
import { GhostCompaniesPage } from 'app/containers/GhostCompaniesPage';
import { GhostSignaturesPage } from 'app/containers/GhostSignaturesPage';

import { GhostUsersPage } from '../GhostUsersPage';

import { ReactComponent as ghostLightIcon } from 'images/icon/FontAwesome5Pro/Light/ghost.svg';

export function GhostPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const TABS = {
    companies: 1,
    people: 2,
    signatures: 3,
  };
  const { value, onChange: handleTabsChange } = useSteps(
    tab && TABS[tab] ? TABS[tab] : 1,
  );

  const onTabChange = useCallback(
    (event, tab) => {
      if (tab !== value) {
        navigate(`/ghost/${getTabById(tab, TABS)}`);
        handleTabsChange(event, tab);
      }
    },
    [getTabById, handleTabsChange, navigate, id, value],
  );

  const breadcrumbs = [
    {
      name: t(translations.names.ghost.name),
      icon: <SvgIcon component={ghostLightIcon} viewBox="0 0 384 512" />,
    },
  ];

  const renderTabs = useMemo(() => {
    return [
      {
        id: TABS.companies,
        label: t(translations.names.ghost.companies),
        content: <GhostCompaniesPage />,
      },
      {
        id: TABS.people,
        label: t(translations.names.ghost.people),
        content: <GhostUsersPage />,
      },
      {
        id: TABS.signatures,
        label: t(translations.names.ghost.signatures),
        content: <GhostSignaturesPage />,
      },
    ];
  }, []);

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{t(translations.metaInfo.ghostPage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.ghostPage.description)}
        />
      </Helmet>
      <Paper
        header={
          <Typography variant="h5">
            {t(translations.names.ghost.name)}
          </Typography>
        }
      >
        <Tabs value={value} tabs={renderTabs} onChange={onTabChange} />
      </Paper>
    </Page>
  );
}
