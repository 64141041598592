import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { translations } from 'locales/i18n';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import {
  GDPR,
  GDPR_COUNTRIES_LIST,
  GDPR_LABELS,
} from 'app/components/entities/users/constants';
import { Checkbox } from 'app/components/ui/Checkbox';

import styles from '../../styles';

const useStyles = makeStyles(styles);

export interface Props {
  selectedCountry?: { id: string; name: string } | null;
  error: boolean;
}

export default function Gdpr({ selectedCountry, error }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [{ value }, , { setValue: setFieldValue }] = useField<number>('gdpr');
  const checkboxLabel = useMemo(
    () => (
      <>
        {t(translations.onboarding.steps.emailAccount.agreementCheckLabel)}{' '}
        <Link component={RouterLink} to="#" color="inherit" underline="always">
          {t(translations.onboarding.steps.emailAccount.termsLinkLabel)}
        </Link>
        *
      </>
    ),
    [t],
  );

  const isExtendedGdpr = useMemo(
    () => selectedCountry && GDPR_COUNTRIES_LIST.includes(selectedCountry.id),
    [selectedCountry],
  );

  const gdprList = useMemo(() => {
    if (isExtendedGdpr) {
      return [
        GDPR_LABELS.CREATE_ACCOUNT,
        GDPR_LABELS.ADM_AND_BILLING,
        GDPR_LABELS.CORRESPONDENCE,
        GDPR_LABELS.NEWSLETTERS,
        GDPR_LABELS.ALL_USERS,
        GDPR_LABELS.LOCAL_TERMS,
      ];
    }
    return [GDPR_LABELS.GLOBAL_TERMS];
  }, [isExtendedGdpr]);

  const selectedCheckBox = useMemo(
    () =>
      gdprList.reduce((acc, item) => {
        acc[item] = Boolean(value & GDPR[item]);
        return acc;
      }, {}),
    [gdprList, value],
  );

  const handleGDPRChange = useCallback(
    e => {
      if (selectedCheckBox[e.target.name]) {
        setFieldValue(value - GDPR[e.target.name]);
      } else {
        setFieldValue(value + GDPR[e.target.name]);
      }
    },
    [selectedCheckBox, setFieldValue, value],
  );

  return (
    <Box className={classes.gdpr} my={3}>
      {isExtendedGdpr && (
        <Box mb={2}>
          <div className={classes.gdprTitle}>
            {t(translations.user.gdpr.gdpr_title)}
          </div>
          <div>{t(translations.user.gdpr.gdpr_description)}</div>
        </Box>
      )}
      {Object.values(gdprList).map(gdprName => (
        <div key={gdprName}>
          <FormControlLabel
            classes={{
              root: classes.gdprCheckbox,
              label: classes.gdprCheckboxLabel,
            }}
            control={
              <Checkbox
                name={gdprName}
                checked={selectedCheckBox[gdprName]}
                onChange={handleGDPRChange}
                required
              />
            }
            label={
              gdprName === GDPR_LABELS.GLOBAL_TERMS
                ? checkboxLabel
                : `${t(translations.user.gdpr[gdprName])}*`
            }
          />
        </div>
      ))}
      {error && (
        <Box mt={2}>
          <FormHelperText error>
            {t(translations.onboarding.steps.emailAccount.gdprError)}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}
