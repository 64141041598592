import { createStyles, Theme } from '@material-ui/core/styles';

import {
  colorPurple,
  colorPink,
  borderPrimaryColor,
  boxShadowExtraSmall,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    designWidgetStatusIcon: {
      fontSize: 20,
      color: colorPurple,
    },
    templateVersionInfo: {
      color: colorPink,
    },
    gridItemSeparatedRight: {
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${borderPrimaryColor}`,
      },
    },
    columnTopIndentHeight: {
      [theme.breakpoints.up('md')]: {
        minHeight: 38,
      },
    },
    alignCenter: {
      alignItems: 'center',
    },
    actionItem: {
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    iconButtonOutline: {
      border: '1px solid currentColor',
      fontSize: 18,
      padding: 6,

      '&:hover': {
        ...boxShadowExtraSmall,
      },
    },
    disabled: {
      opacity: 0.5,
      '& > svg': {
        margin: '0 15px',
        fontSize: '1.3em',
      },
    },
    signatureImage: {
      display: 'block',

      '& .upload-image-block.upload-image-block-uploaded': {
        backgroundColor: 'transparent',
        border: 'none',

        '&, & .uploaded-image': {
          display: 'block',
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          textAlign: 'center',
        },
        '& .uploaded-image-button-block': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginTop: 20,

          '& button:last-child': {
            marginTop: 0,
            marginLeft: 10,
          },
        },
      },
    },
  });
