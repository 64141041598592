import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import { LinkCell, Table } from 'app/components/table';
import { Paper } from 'app/components/layouts';
import { CompanySearch } from 'app/components/forms/components/Edit/Search/CompanySearch';
import { useConfirmation } from 'app/components/ui/Modal';
import FilterDropdown from 'app/components/ui/FilterDropdown';
import { COMPANY_TYPES } from 'app/containers/constants';

import { ReactComponent as mapMarkerAltLightIcon } from 'images/icon/FontAwesome5Pro/Light/map-marker-alt.svg';
import { ReactComponent as addressCardLightIcon } from 'images/icon/FontAwesome5Pro/Light/address-card.svg';
import { ReactComponent as modalClockIcon } from 'images/icon/FontAwesome5Pro/Light/modal-clone.svg';
import { ReactComponent as userLightIcon } from 'images/icon/FontAwesome5Pro/Light/user.svg';
import { ReactComponent as pencilIcon } from 'images/icon/FontAwesome5Pro/Light/pencil.svg';
import { ReactComponent as cloneIcon } from 'images/icon/FontAwesome5Pro/Light/clone.svg';
import { ReactComponent as trashIcon } from 'images/icon/FontAwesome5Pro/Light/trash-alt.svg';
import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';

import styles from '../../styles';

const useStyles = makeStyles(styles);

const initialState = {
  globalFilter: {
    type: COMPANY_TYPES.GHOST,
    originalCompanyId: { $isnull: true },
  },
  sortBy: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
};

export interface Props {
  data: any;
  total: number;
  page: number;
  pageCount: number;
  fetchData: (data: any) => void;
  backLink?: string;
  indexQuery?: any;
  handleCloneCompany: (id: any) => void;
  handleDeleteCompany: (id: any) => void;
}

export default function List({
  data,
  total,
  page,
  pageCount,
  fetchData,
  indexQuery,
  handleCloneCompany,
  handleDeleteCompany,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState();

  const [showGhostDeleteConfirmation] = useConfirmation({
    onSubmit: () => handleDeleteCompany(companyId),
    title: t(translations.ui.titles.confirmationGhostCompanyDeleteTitle),
    description: t(translations.ui.titles.confirmationGhostCompanyDelete),
    withCheckBox: true,
    maxWidth: 'md',
    modalIcon: modalDeleteIcon,
    agreeLabel: t(translations.ui.buttons.delete),
    titleBlockStyle: 'default',
    agreeButtonProps: {
      className: 'button-danger',
    },
    cancelButtonProps: {
      className: 'modal-button-white',
    },
  });

  const handleGhostCompanyDeleteClick = useCallback(
    id => {
      setCompanyId(id);
      showGhostDeleteConfirmation();
    },
    [showGhostDeleteConfirmation],
  );

  const [showCloneConfirmation] = useConfirmation({
    onSubmit: () => handleCloneCompany(companyId),
    description: t(translations.ui.titles.confirmationCloneCompanyMessage),
    agreeLabel: t(translations.ui.buttons.clone),
    modalIcon: modalClockIcon,
    titleBlockStyle: 'default',
    cancelButtonProps: {
      className: 'modal-button-white',
    },
    agreeButtonProps: {
      className: '',
    },
  });

  const cloneCompany = id => {
    setCompanyId(id);
    showCloneConfirmation();
  };

  const columns = [
    {
      Header: t(translations.table.columns.companyName),
      accessor: 'name',
      customProps: row => `/ghost-companies/${row.id}/edit`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
      width: 200,
    },
    {
      Header: t(translations.table.columns.locations),
      id: 'locationsCount',
      accessor: 'locationsCount',
      customProps: row => `/ghost-locations/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.signatures),
      id: 'signaturesCount',
      accessor: 'signaturesCount',
      customProps: row => `/ghost-signatures/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.users),
      id: 'usersCount',
      accessor: 'usersCount',
      customProps: row => `/ghost-signature-users/${row.id}`,
      Cell: ({ cell }: { cell: Cell }) => <LinkCell cell={cell} />,
    },
    {
      Header: t(translations.table.columns.quickActions),
      id: 'id',
      minWidth: 250,
      disableSortBy: true,
      className: 'table-action-cell',
      accessor: ({ id }) => {
        return { id };
      },
      Cell: ({
        cell: {
          value: { id },
        },
      }: {
        cell: Cell;
      }) => {
        return (
          <div className="action-cell-wrapper">
            <Link
              className={classes.actionItem}
              href={`/ghost-companies/${id}/edit`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.editData)}
              >
                <SvgIcon component={pencilIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/ghost-locations/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.ghostLocation)}
              >
                <SvgIcon
                  component={mapMarkerAltLightIcon}
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/ghost-signatures/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.ghostSignatures)}
              >
                <SvgIcon
                  component={addressCardLightIcon}
                  viewBox="0 0 576 512"
                />
              </Tooltip>
            </Link>
            <Link
              className={classes.actionItem}
              href={`/ghost-signature-users/${id}`}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.ghostUsers)}
              >
                <SvgIcon component={userLightIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </Link>
            <span
              className={classes.actionItem}
              onClick={() => cloneCompany(id)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.clone)}
              >
                <SvgIcon component={cloneIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
            <span
              className={classes.actionItem}
              onClick={() => handleGhostCompanyDeleteClick(id)}
            >
              <Tooltip
                placement="top"
                arrow={true}
                title={t(translations.actions.names.delete)}
              >
                <SvgIcon component={trashIcon} viewBox="0 0 512 512" />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Paper>
      <Table
        renderGlobalFilter={({ setGlobalFilter }) => (
          <>
            <CompanySearch
              initialState={initialState}
              name="filter"
              setGlobalFilter={setGlobalFilter}
            />
            <FilterDropdown
              handleChange={value => {
                fetchData({
                  ...indexQuery,
                  sort: [`createdAt,${value}`],
                });
              }}
            />
          </>
        )}
        columns={columns}
        data={data}
        fetchData={fetchData}
        total={total}
        page={page}
        pageCount={pageCount}
        initialState={initialState}
      />
    </Paper>
  );
}
