import useModal, { Props as UseModalProps } from './useModal';
import Confirmation, { ConfirmProps } from '../components/Confirmation';

export interface Props
  extends Pick<
      UseModalProps,
      'onSubmit' | 'onClose' | 'isSupportShowProps' | 'unmount'
    >,
    Pick<
      ConfirmProps,
      | 'title'
      | 'subTitle'
      | 'description'
      | 'agreeLabel'
      | 'cancelLabel'
      | 'agreeButtonProps'
      | 'cancelButtonProps'
      | 'withCancelButton'
      | 'className'
      | 'actionPosition'
      | 'maxWidth'
      | 'titleBlockStyle'
      | 'modalIcon'
      | 'titleIcon'
      | 'withCheckBox'
      | 'isValid'
      | 'withAgreeButton'
    > {}

const useConfirmation = ({
  title = 'Confirm',
  description = 'Are you sure?',
  subTitle,
  agreeLabel,
  cancelLabel,
  agreeButtonProps,
  cancelButtonProps,
  withCancelButton,
  className,
  actionPosition,
  maxWidth,
  titleBlockStyle,
  modalIcon,
  titleIcon,
  withCheckBox,
  isValid,
  withAgreeButton,
  ...rest
}: Props) => {
  return useModal({
    ...rest,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Modal: Confirmation,
    props: {
      title,
      description,
      agreeLabel,
      cancelLabel,
      agreeButtonProps,
      cancelButtonProps,
      className,
      actionPosition,
      maxWidth,
      withCancelButton,
      titleBlockStyle,
      modalIcon,
      subTitle,
      titleIcon,
      withCheckBox,
      isValid,
      withAgreeButton,
    },
  });
};

export default useConfirmation;
