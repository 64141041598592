import { createStyles, Theme } from '@material-ui/core';

import {
  bgColorLightGrey,
  borderGreyColor,
  colorDanger,
  colorPrimaryLight2,
  colorWhite,
  ctaButtonWidth,
  dialogHorizontal,
  dialogHorizontalMobile,
  dialogVertical,
  dialogVerticalMobile,
  textDefault,
} from 'configs/variables';

export default (theme: Theme) =>
  createStyles({
    modalPaper: {
      '& .modal-dialog-actions': {
        padding: `${dialogVerticalMobile}px ${dialogHorizontalMobile}px 0`,
        margin: `${dialogVerticalMobile}px -${dialogHorizontalMobile}px 0`,

        [theme.breakpoints.up('sm')]: {
          padding: `${dialogVertical}px ${dialogHorizontal}px 0`,
          margin: `${dialogVertical}px -${dialogHorizontal}px 0`,
        },
      },
      '& .modal-card-block': {
        marginTop: '32px',
      },
      '& .modal-button-block': {
        display: 'block',

        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '@media(max-width: 320px)': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },

        '& .modal-button-white': {
          backgroundColor: colorWhite,
          color: textDefault,
          border: `1px solid ${borderGreyColor}`,
          '&:hover': {
            backgroundColor: bgColorLightGrey,
          },
        },

        '& .modal-button': {
          minWidth: 120,

          '&:not(:first-child)': {
            marginTop: 12,
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: 140,

            '&:not(:first-child)': {
              marginLeft: 12,
              marginTop: 0,
            },
          },
          [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: '0 !important',
          },
          '&.cta-button': {
            minWidth: ctaButtonWidth,
          },
        },
      },
      '& .modal-button-block-with-checkbox': {
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'normal',
          '& button': { marginTop: '10px' },
        },
      },
    },
    standardModalTitleBlock: {
      backgroundColor: colorPrimaryLight2,
      color: colorWhite,
      position: 'relative',
    },
    defaultModalTitleBlock: {
      backgroundColor: colorWhite,
      color: textDefault,
      position: 'relative',
    },
    dangerModalTitleBlock: {
      backgroundColor: colorDanger,
      color: colorWhite,
      position: 'relative',
    },
    modalWithIcon: {
      display: 'flex',

      '& .modal-button-block': {
        border: `0px`,
        padding: '16px 16px 16px 6px',

        [theme.breakpoints.down('xs')]: {
          padding: '16px 16px 16px 10px',
        },
      },
    },
    modalContentBlock: {
      width: '100%',
    },
    modalTitleBlock: {
      display: 'flex',
    },
    modalTitle: {
      fontSize: 18,

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    modalSubTitle: {
      fontSize: 12,

      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    modalCheckBox: {
      marginRight: 'auto',
    },
    modalCloseButton: {
      color: colorWhite,
      fontSize: '24px',
    },
    modalContent: {
      fontSize: 14,
      position: 'relative',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',

      [theme.breakpoints.up('sm')]: {
        fontSize: 15,
      },
    },
    modalContentDanger: {
      color: colorDanger,
    },
    modalIcon: {
      fontSize: '56px',
      fill: 'none',
      margin: '16px 0 0 16px',
      '& .modal-button-block': {
        border: 0,
      },
    },

    titleIcon: {
      fontSize: '56px',
      fill: 'none',
      marginRight: '16px',
    },
    // Confirmation
    modalConfirmation: {},
    leftPosition: {
      '& .modal-button-block': {
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'flex-start',
        },
      },
    },
    rightPosition: {
      '& .modal-button-block': {
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'flex-end',
        },
      },
    },
    centerPosition: {
      '& .modal-button-block': {
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        },
      },
    },
  });
