import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import Typography from '@material-ui/core/Typography';

import { Page } from 'app/components/layouts';
import { Loader } from 'app/components/ui/Loader';
import { GhostPeopleList } from 'app/components/entities/users';
import { useLocationFilterIndex } from 'app/components/entities/locations';
import {
  useUserFilterIndex,
  usePeopleClone,
  usePeopleDelete,
} from 'app/components/users/hooks/crud';
import { ROLES } from 'app/components/auth';
import { TYPES } from 'app/components/auth/constants';
import {
  useReSendInvitation,
  useRemoveTrialUser,
} from 'app/components/onboarding/hooks/trialCrud';
import { success, error } from 'app/components/snackbars';
import { useConfirmation } from 'app/components/ui/Modal';

import { ReactComponent as modalDeleteIcon } from 'images/icon/FontAwesome5Pro/Light/modal-delete.svg';
import { PAGE_TYPE } from '../constants';

export function GhostUsersPage() {
  const { t } = useTranslation();

  const {
    mutateAsync: callReSendInvitation,
    isLoading: reSendInvitationIsLoading,
  } = useReSendInvitation();
  const { mutateAsync: callRemoveTrialUser } = useRemoveTrialUser();
  const { mutateAsync: clonePeople } = usePeopleClone();
  const { mutateAsync: deletePeople } = usePeopleDelete();

  const [indexQuery, setIndexQuery] = useState({
    limit: 10,
    page: 1,
    sort: [`createdAt,DESC`],
    ...RequestQueryBuilder.create({
      search: {
        'filterRoles.name': ROLES.GHOST_USER,
        type: { $ne: TYPES.DELETED },
      },
    }).queryObject,
  });

  const [locationsIndexQuery, setLocationsIndexQuery] = useState({
    limit: 100,
    page: 1,
    sort: [],
  });

  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    pageCount: 1,
  });

  const {
    data: users = data,
    isLoading: usersIsLoading,
    refetchUsers,
  } = useUserFilterIndex(indexQuery, {
    refetchOnMount: true,
  });

  const [isEnabled, setIsEnabled] = useState(false);
  const { data: locations, refetch } = useLocationFilterIndex(
    locationsIndexQuery,
    {
      // refetchOnMount: false,
      enabled: isEnabled,
    },
  );

  useEffect(() => {
    if (users && users !== data) {
      setIsEnabled(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setData(users);
      const locationsIDs: number[] = [];
      users?.data?.forEach(({ signatureUsers }) => {
        signatureUsers.forEach(({ signature: { locationId } }) => {
          locationsIDs.push(locationId);
        });
      });

      const locationsIDsSet = [...new Set(locationsIDs)];
      if (locationsIDsSet.length && users?.data?.length) {
        setIsEnabled(true);
        setLocationsIndexQuery({
          ...locationsIndexQuery,
          ...RequestQueryBuilder.create({
            search: {
              id: { $in: locationsIDsSet },
            },
          }).queryObject,
        });
      }
    }
  }, [refetch, users]);

  const isLoading = useMemo(
    () => usersIsLoading || reSendInvitationIsLoading,
    [reSendInvitationIsLoading, usersIsLoading],
  );

  const [toRemoveShare, setToRemoveShare] = useState<number | null>(null);

  const clearToRemoveShare = useCallback(() => {
    setToRemoveShare(null);
  }, []);

  const handleRemoveShare = useCallback(async () => {
    try {
      if (toRemoveShare) {
        const result = await callRemoveTrialUser(toRemoveShare);
        if (result.success) {
          success(t(translations.trialUser.share.deletedSuccessfully));
          refetchUsers().then(() => {
            refetch();
          });
        } else {
          error(t(translations.trialUser.share.error));
        }
      }
    } catch (e) {
      error(t(translations.trialUser.share.error));
    }
  }, [callRemoveTrialUser, refetch, refetchUsers, t, toRemoveShare]);

  const [showRemoveShareConfirmation] = useConfirmation({
    onSubmit: handleRemoveShare,
    onClose: clearToRemoveShare,
    titleBlockStyle: 'default',
    title: t(translations.ui.titles.deleteTrialUser),
    description: (
      <Typography color="primary">
        {t(translations.trialUser.share.removeTrialUserWarning)}
      </Typography>
    ),
    modalIcon: modalDeleteIcon,
    cancelButtonProps: {
      className: 'modal-button button-danger modal-button-white',
      variant: 'contained',
    },
    agreeButtonProps: {
      className: 'button-danger',
    },
    agreeLabel: t(translations.ui.buttons.delete),
  });

  const handleResendInvite = useCallback(
    async id => {
      try {
        const result = await callReSendInvitation({ data: { id } });
        if (result.success) {
          success(t(translations.trialUser.share.sentSuccessfully));
        } else {
          error(t(translations.trialUser.share.error));
        }
      } catch (e) {
        error(t(translations.trialUser.share.error));
      }
    },
    [callReSendInvitation, t],
  );

  const handleRemoveShareClick = useCallback(
    id => {
      setToRemoveShare(id);
      showRemoveShareConfirmation();
    },
    [showRemoveShareConfirmation],
  );

  const handleDeletePeople = useCallback(
    async id => {
      try {
        await deletePeople(id);
        success(t(translations.user.message.success.delete));

        refetchUsers().then(() => {
          refetch();
        });
      } catch (e) {
        error(e.message);
      }
    },
    [deletePeople, refetch, t],
  );

  const handleClonePeople = useCallback(
    async id => {
      try {
        await clonePeople(id);
        success(t(translations.user.message.success.clone));

        refetchUsers().then(() => {
          refetch();
        });
      } catch (e) {
        error(e.message);
      }
    },
    [clonePeople, refetch, t],
  );

  return (
    <Page>
      <Helmet>
        <title>{t(translations.metaInfo.ghostPeoplePage.title)}</title>
        <meta
          name="description"
          content={t(translations.metaInfo.ghostPeoplePage.description)}
        />
      </Helmet>
      <Loader loading={isLoading} />
      <GhostPeopleList
        backLink={'/ghost/companies'}
        fetchData={setIndexQuery}
        refetch={refetchUsers}
        indexQuery={indexQuery}
        data={users.data}
        total={users.total}
        page={users.page}
        pageCount={users.pageCount}
        locations={locations?.data}
        pageType={PAGE_TYPE.GHOSTS}
        handleClonePeople={handleClonePeople}
        handleResendInvite={handleResendInvite}
        handleDeletePeople={handleDeletePeople}
        handleRemoveShareClick={handleRemoveShareClick}
      />
    </Page>
  );
}
