import React, { useEffect } from 'react';
import { useNavigate, RouteProps, useLocation } from 'react-router-dom';
import { useAuthUser } from 'app/components/auth/hooks/useAuthUser';

const defaultRedirect = '/';

export const GuestRoute = (props: RouteProps) => {
  const { isLoading, isAuthorized, logout } = useAuthUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const re = /\/reset-password\/:token/;
  const { path } = props;

  if (path && re.test(path.toString())) {
    logout();
  }

  useEffect(() => {
    if (isAuthorized && !isLoading) {
      const back = new URLSearchParams(search).get('back');
      let redirectPath = defaultRedirect;
      if (back) {
        try {
          redirectPath = decodeURIComponent(back);
        } catch (e) {
          // do nothing
        }
      }

      navigate(redirectPath);
    }
  }, [navigate, isAuthorized, isLoading, search]);

  if (isLoading) {
    return null;
  }

  return <>{props.children}</>;
};
