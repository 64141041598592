import { ApiError, apiClient } from 'app/config/axios';

import objectToFormData from 'utils/objectToFormData';
import { SignatureField } from '../interfaces';
import { PaginatedResponse } from 'app/interfaces';
import {
  QueryOptions,
  UseQueryResult,
  useQuery,
  QueryObserverOptions,
} from 'react-query';

const endpoint = '/signature-fields';

export const signatureFieldUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, SignatureField>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const signatureFieldsIndex = queryData =>
  apiClient.get<any, PaginatedResponse<SignatureField>>(endpoint, {
    params: queryData,
  });

export const useSignatureFieldsIndex: <T = PaginatedResponse<SignatureField>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['useSignatureFieldsIndex', any], any>(
    ['useSignatureFieldsIndex', queryData],
    () => signatureFieldsIndex(queryData),
    options,
  );

export const useSignatureFieldsFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<SignatureField>, ApiError>,
): {
  data: PaginatedResponse<SignatureField> | undefined;
  isLoading: boolean;
  refetch: (...args: any) => void;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useSignatureFieldsIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};
